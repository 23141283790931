//External Imports
import { StyleSheet } from "react-native";

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from "../../utils";

export default StyleSheet.create({
  childViewStyle: {
    backgroundColor: "#FFF",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  container: {
    height: '100%',
    width: "95%",
    backgroundColor: "#FFF",
    alignSelf: "center",
    borderWidth: 1
  },
  imageBoxContainer: {
    alignSelf: "center",
    marginTop: -60,
  },
  logoStyle: {
    height: 180,
    width: 180,
  },
  formContainer: {
    marginTop: 10,
    width: "90%",
    alignSelf: "center",
  },
  formHeadingText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#000",
  },
  inputContainerStyle: {
    marginTop: getHp(20),
  },
  renderExtrasContainer: {
    marginTop: getHp(20),
    flexDirection: "row",
    alignSelf: "center",
    alignItems: "center",
  },
  renderExtrasHeading: {
    fontSize: 16,
    color: "#000",
  },
  clickHereText: {
    color: "#EC2939",
    fontSize: 16,
  },
  errorContainer: {
    marginTop: getHp(10),
    marginLeft: getWp(20),
  },
  errorTextStyle: {
    fontSize: 15,
    color: "red",
  },
  avatarContainerStyle: {
    alignSelf: "center",
    marginTop: getHp(44),
    marginBottom: getHp(30),
    height: getHp(124),
    width: getHp(140),
    justifyContent: "center",
    alignItems: "center",
  },
  avatarImageStyle: {
    height: getHp(124),
    width: getHp(140),
  },
  bottomButtonsTray: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 25,
  },
  logoImgStyle: {
    height: 180,
    width: 180,
  },
});
