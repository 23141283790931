import React, {memo, useState} from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Input } from 'react-native-elements';

import Styles from './indexCss';
import Entypo from 'react-native-vector-icons/Entypo';

Entypo.loadFont();

const PasswordInput = (props) => {
    const [isSecure,setIsSecure] = useState(true);
    const {
        containerStyle = {},
        placeholder = "Placeholder",
        onChangeText,
        value = ""
    } = props;
    return(
        <View style={[Styles.inputViewContainerStyle,containerStyle]}>
            <Input 
                value={value}
                secureTextEntry={isSecure}
                onChangeText={onChangeText}
                placeholder={placeholder}
                style={[Styles.inputStyle]} 
                inputContainerStyle={{borderBottomWidth:0}}/>

            <TouchableOpacity onPress={()=>setIsSecure(s => !s)} style={Styles.eyeContainer}>
            <Entypo name={!isSecure ? "eye" : "eye-with-line"}  style={Styles.eyeStyle} />
            </TouchableOpacity>
        </View>
    );
}

export default memo(PasswordInput);