
//External Imports
import { StyleSheet } from 'react-native';

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from '../../utils';

export default StyleSheet.create({
    container: { 
        height: 50,
        flexDirection: "row",
        alignItems: "center",
        marginTop: 16
    },
    commonMargin: {
        marginLeft: 30
    },
    headerHeadingText: {
        fontSize: 18,
        fontWeight: "bold",
        color: '#000',
        marginLeft: 25
    }
});