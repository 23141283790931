import React, { useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  FlatList
} from "react-native";

const GameChallengesListView = (props) => {

    const [selectedPlayerId, setSelectedPlayerId] = useState("");
    const [filteredChallenges, setFilterdChallenges] = useState([]);
   
    const filterChallenge = (playerId) => {
        setSelectedPlayerId(playerId);
        const filtering = props.challenges.filter(challenge => 
            challenge.challengeGameWinnerId === playerId
        );
        setFilterdChallenges(filtering);
    };

    const renderItem = ({item}) => (
        <View style={{ height: 50 }}>
            <View style={{ flexDirection: 'row', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ width: 140, height: 40, backgroundColor: '#949AB1', borderRadius: 10, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{ color: 'white' }}>{findChallengeUserName(item.challengeSenderId)}</Text>
                </View>
                <Text style={{ marginLeft: 10 }}>{item.points} Points</Text>
                <Text>--&gt;</Text>
                <View style={{ width: 140, height: 40, backgroundColor: 'white', marginLeft: 10, borderRadius: 10, justifyContent: 'center', alignItems: 'center', borderColor: 'rgba(0,0,0,0.1)', borderWidth: 1}}>
                    <Text style={{ color: 'black' }}>
                        { item.opponent === 'all' ? 'All' : findChallengeUserName(item.opponent) }
                    </Text>
                </View>
            </View>
            <View style={{ backgroundColor: 'rgba(0,0,0,0.1)', height: 1, position: 'absolute', bottom: 0, left: 0, right: 0 }} />
        </View>
    )

    const findChallengeUserName = (userId) => {
        const challengeUser = props.challengeUsers.find(user => user.uid === userId);
        if (challengeUser !== undefined) {
            return challengeUser.userNickname;
        } else {
            console.log("Unknown user id => ", userId);
            return '---';
        }
    };

    useEffect(() => {
        setSelectedPlayerId(props.player1ID);
        filterChallenge(props.player1ID);
    }, []);

    return (
        <View style={{ width: '100%', height: '100%', backgroundColor: 'white', borderRadius: 10, borderColor: 'rgba(0,0,0,0.1)', padding: 15 }}>
            <View style={{ width: '100%', height: 40, borderRadius: 10, borderColor: 'rgba(0,0,0,0.1)', borderWidth: 1, backgroundColor: '#F9FAFC', flexDirection: 'row', overflow: 'hidden' }}>
                <TouchableOpacity style={{ flex: 1, backgroundColor: selectedPlayerId === props.player1ID ? 'black' : 'transparent', justifyContent: 'center', alignItems: 'center' }}
                    onPress={() => filterChallenge(props.player1ID)}>
                    <Text style={{color: selectedPlayerId === props.player1ID ? 'white' : 'black', fontWeight: selectedPlayerId === props.player1ID ? 'bold' : 'normal'}}>{props.player1Name}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ flex: 1, backgroundColor: selectedPlayerId === props.player2ID ? 'black' : 'transparent', justifyContent: 'center', alignItems: 'center' }}
                    onPress={() => filterChallenge(props.player2ID)}>
                    <Text style={{color: selectedPlayerId === props.player2ID ? 'white' : 'black', fontWeight: selectedPlayerId === props.player2ID ? 'white' : 'black'}}>{props.player2Name}</Text>
                </TouchableOpacity>
            </View>
            <FlatList
                style={{ flex: 1}}
                data={filteredChallenges}
                renderItem={renderItem}
                keyExtractor={item => item.id} />
        </View>
    );
};

export default GameChallengesListView;