 
//External Imports
import { StyleSheet } from 'react-native';

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from '../../../utils';

export default StyleSheet.create({
    childViewStyle:{
        backgroundColor: '#FFF',
        flexDirection: "column",
        justifyContent: "space-between"
    },
    container: {
        flex:1,
        //height: "100%",
        backgroundColor: "#fff", 
        
    },
    imageBoxContainer: { 
        alignSelf: "center",
        marginTop: 20
    },
    logoStyle: { 
        height: getHp(180),
        width: getHp(180)
    },
    formContainer: {
        marginTop: 30,
        width: "80%",
        alignSelf: "center"
    },
    formHeadingText: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#000"
    },
    inputContainerStyle: {
        marginTop: getHp(20)
    },
    renderExtrasContainer: {
        marginTop: getHp(20),
        flexDirection: "row",
        alignSelf: "center",
        alignItems: "center"
    },
    renderExtrasHeading: {
        fontSize: 16,
        color: '#000'
    },
    clickHereText: {
        color: '#EC2939',
        fontSize: 16,
    },
    errorContainer: {
        marginTop: getHp(10),
        marginLeft: getWp(20)
    },
    errorTextStyle: {
        fontSize: 15,
        color: "red"
    },
    avatarContainerStyle: {
        alignSelf: "center",
        marginTop: 44,
        marginBottom: 30,
        height: 124,
        width: 140,
        justifyContent: 'center',
        alignItems: 'center'
    },
    avatarImageStyle: {
        height: 124,
        width: 140,
    },
    bottomButtonsTray: {
        flexDirection: 'row',
        justifyContent: 'space-between', 
        alignSelf: 'center',
        width: '105%',
        marginVertical: 10
    }
});