 
//External Imports
import { StyleSheet } from 'react-native';

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from '../../../utils';

export default StyleSheet.create({
    
    container: {
        height: "100%",
        width: "70%",
        backgroundColor: "#FFF"
    },
    headerContainer: {
        marginTop: 40,
        width: "85%",
        alignSelf: "center",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    filterContainer: {
        marginTop: 30,
        alignSelf: "center",
        width: "85%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: 'center'
    },
    filterTextStyle: {
        fontSize: 18,
        lineHeight: 22,
        fontWeight: "bold",
        color: "#000",
        alignSelf: 'flex-start',
        marginTop: 10
        //width: '20%'
    },
    filterContentContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        width: "80%"
    },
    filterContentTextStyle: {
        fontSize: 17,
        lineHeight: 22,
        color: "#fff",
        marginLeft: 15,
    },
    filterContainerStyle: {
        marginTop: 12
    },
    eventsContainer: {
        marginTop: 25
    },
    eventsHeadingContainer: {
        backgroundColor: "#0B214D",
        // width: "100%",
        height: 35,
        justifyContent:'center'
        // justifyContent: 'space-evenly',
        // alignItems:'center',
        // flexDirection:'row'
    },
    eventsHeadingText: {
        color: "#FFFFFF",
        fontSize: 18,
        lineHeight: 22,
        fontWeight: "bold",
        marginLeft: 40
    },
    eventContainer: {
        width: "80%",
        alignSelf: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottomWidth: 1,
        borderBottomColor: "#F2F2F2",
        marginVertical: 18,
        paddingBottom: 18
    },
    eventImgContainer: {
        width: "20%",

    },
    eventImgView: {
        height: 80,
        width: 80,
        resizeMode: 'cover',
        backgroundColor: `#C4C4C4`
    },
    eventInfoContainer: {
        width: "70%",
        marginLeft: 20,
        paddingHorizontal:30,
    },
    eventNameText: {
        fontSize: 18,
        color: "#000",
        fontWeight: "bold",
        lineHeight: 22
    },
    numOfGameText: {
        fontSize: 16,
        color: "#000",
        lineHeight: 19,
        marginTop: 10,
        width: '100%',
    },
    eventCont: {
        marginTop: 25
    },
    sidebarContainer: {
        height: '100%',
        width: '85%',
        alignSelf: 'center',   
    },
    closeModalStyle: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 20,
    },
    containerCategoryStyle: {
         marginTop: 25
    },
    genreDropDownContainer: {
        marginTop: 20
    },
    fromDatePickerContainer: {
        marginTop: 30
    },
    selectContestTypeHeadingContainer: {
        marginTop: getHp(15), 
        width:50,
        height: 35
    },
    backTouchContainer: {
        marginTop: 50,
        flexDirection:'row',
        justifyContent: 'space-between'
    },
    DoneButtonTouch: {
        marginBottom:50,
        marginTop: 40,
        backgroundColor: '#fff',
        borderRadius: 20,
        alignItems: 'center',
    },
    DoneButtonStyle: {
        paddingVertical: 10,
        fontSize: 17,
        fontWeight: 'bold',
        color: '#0B214D'
    },
    dateLabelStyle: {
        marginTop: 30,
        fontSize: 17,
        fontWeight: 'bold',
        color: 'white'
    },
    touchBtnStyle: {
        backgroundColor: '#EDCF80',
        height: 60,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 100, 
    },
    profileTextStyle: {
        textAlign: 'center',
        paddingHorizontal: 10, 
        fontSize: 16,
        color: 'black',
        fontWeight: '700'
    },
    crearTextStyle: {
        fontSize: 16,
        fontWeight: '700',
        color: 'white'
    },
    touchFilterStyle: {
        borderRadius: 100,
        backgroundColor: '#0B214D',
        paddingVertical: 5,
        paddingRight: 10,
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
        marginLeft: 5
    },
    closeIconStyle: {
        fontSize: 15,
        marginLeft: 5
    },
    redeemEventTextStyle: {
        fontSize: 16,
        color: 'black',
        fontWeight: '600',
        marginRight: getWp(20)
    }
});