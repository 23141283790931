import { useState } from 'react';
// import DocumentPicker from 'react-native-document-picker';
import * as DocumentPicker from 'expo-document-picker';

const UsePickDocument = () => {
  const [pickedDocument, setPickedDocument] = useState(null);
  const pickDocument = async () => {
    try {
      const res = await DocumentPicker.getDocumentAsync();
      console.log("ressss",res);
      setPickedDocument(() => res);
      console.log("allll",
        res.uri,
        res.type, // mime type
        res.name,
        res.size
      );
      return res;

    } catch (err) {
      if (res.type == 'cancel') {
        // User cancelled the picker, exit any dialogs or menus and move on
        console.log("USER_CANCEL_IMAGE_PICKER")
      } else {
        throw err;
      }
    }
  }
  return {
    pickedDocument,
    pickDocument,
  }
}

export default UsePickDocument;