import React, { memo } from 'react';
import { View } from 'react-native';
import Image from 'react-native-remote-svg';
import LogoPNG from '../../assets/PRLLogoNew.svg';

import Styles from './indexCss';

const PRLLogoNew = (props) => {
    const {
        containerStyle = {},
        imgStyle = {}
    } = props;
    return (
        <View style={[Styles.container, containerStyle]}>
            <Image resizeMethod={'auto'} source={LogoPNG} style={[Styles.imgStyle, imgStyle]} resizeMode={'contain'} />
        </View>
    );
}

export default memo(PRLLogoNew);