import React, { useState, useEffect } from "react";
import { View } from "react-native";
import config from "../../app/classes/SquarePaymentWeb/config";
import { squareProduction } from "../../../app.json";
import "../../app/classes/SquarePaymentWeb/payment.css";
import Modal from "modal-enhanced-react-native-web";
const Square = ({
  paymentForm,
  onPaymentSuccess,
  onPaymentFail,
  btnTotal,
  total,
  AppId
}) => {
  paymentForm = new paymentForm(config(onPaymentSuccess, onPaymentFail, total, AppId));
  paymentForm.build();
  const requestCardNonce = () => {
    paymentForm.requestCardNonce();
  };

  return (
    <div id="form-container">
      <h3 id="payment-heading-text">Payment</h3>
      <div id="sq-card-number"></div>
      <div className="third" id="sq-expiration-date"></div>
      <div className="third" id="sq-cvv"></div>
      <div className="third" id="sq-postal-code"></div>
      <button
        id="sq-creditcard"
        className="button-credit-card"
        onClick={() => requestCardNonce()}
      >
        {" "}
        Pay {btnTotal}
      </button>
    </div>
  );
};

const SquarePayment = (props) => {
  const {
    onPaymentFail,
    onPaymentSuccess,
    isVisible = false,
    closePayment,
    btnTotal,
    total = 0,
    AppId
  } = props;
  const [isLoad, setLoad] = useState(false);
  useEffect(() => {
    console.log("Total Price ---> ", total);
    let sqPaymentScript = document.createElement("script");
    // sandbox: https://js.squareupsandbox.com/v2/paymentform
    // production: https://js.squareup.com/v2/paymentform
    sqPaymentScript.src = squareProduction
      ? "https://js.squareup.com/v2/paymentform"
      : "https://js.squareupsandbox.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      setLoad(true);
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  });

  const SquarePay = () => {
    if (isLoad) {
      return (
        <Square
          total={total}
          btnTotal={btnTotal}
          paymentForm={window.SqPaymentForm}
          onPaymentSuccess={onPaymentSuccess}
          onPaymentFail={onPaymentFail}
          AppId={AppId}
        />
      );
    }
    return null;
  };
  return (
    <Modal isVisible={isVisible} onBackdropPress={closePayment}>
      <View>
        <SquarePay />
      </View>
    </Modal>
  );
};

export default SquarePayment;
