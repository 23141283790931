
//External Imports
import { StyleSheet } from 'react-native';

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from '../../utils';

export default StyleSheet.create({
container: {
height: getHp(100),
width: getHp(100),
backgroundColor: "#C4C4C4",
justifyContent: "center",
alignItems: "center",
paddingHorizontal: '10%'
},
textStyle: {
alignSelf: "center",
fontSize: 12,
color: "#000000",
textAlign: 'center',
},
imageStyle: {
height: '100%',
width: '120%',
resizeMode: 'cover'
},
imageStyle2: {
height: '100%',
width: '100%',
resizeMode: 'contain'
},
modalContainer: {
height: '100%',
width: '100%',
},
renderModalImage: {
height: window.innerHeight*0.9,
width: window.innerWidth*0.8,
alignSelf: 'center',
resizeMode: 'contain',
},
closeContainer: {
position: 'absolute',
right: getWp(10),
top: getHp(10),
zIndex: 10,
borderRadius:20,
padding: getHp(4),
backgroundColor: 'red'
},
backgroundVideo: {
height: hp(70),
width: wp(85),
backgroundColor: 'transparent'
},
deleteIconView: {
position: 'absolute',
left: getWp(10),
top: getHp(10),
zIndex: 10,
color: "black",
borderRadius:20,
padding: getHp(4),
backgroundColor: 'red'
}
});