import React, { useState, useCallback, useEffect, useRef } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  View,
  StyleSheet,
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  Alert,
  Keyboard
} from "react-native";
// import { Formik } from "formik";
// import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useIsFocused } from "@react-navigation/native";
// import Toast from 'react-native-toast-message';
import ProfileModel from "./Profile.model";
import { COLOR, showToast } from "../../../utils";
// import { login } from "../../../store/actions";
import { CheckBox } from "react-native-elements";
import { createdNewProfile, toggleAlertBox } from "../../../store/actions";
import {
  // Root,
  PRLLogo,
  TextInput,
  TouchableButton,
  AuthFooter,
  PasswordInput,
  PRLLogoNew,
  ImageVideoPlaceholder,
  WebViewModal,
} from "../../../Component";
import {
  useKeyboardStatus,
  useLoader,
  useFirebaseUpload,
} from "../../../hooks";
import * as api from "../../../store/api";
import Styles from "./indexCss";
import { getHp } from "../../../utils";
import { usersCollection } from "../../../firebase";
//import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const RegisterModel = {
  email: "",
  password: "",
  confirmPassword: "",
  setData: function (key, data) {
    this[key] = data;
    return { ...this };
  },
  getData: function () {
    return { email: this.email.toLowerCase(), password: this.password };
  },
  isDisabled: function () {
    return !(
      this.email.length > 0 &&
      this.password.length > 0 &&
      this.confirmPassword.length > 0
    );
  },
  resetForm: function () {
    this.email = "";
    this.password = "";
    this.confirmPassword = "";
    return { ...this };
  },
};
let ErrorModel = {
  email: "",
  password: "",
  confirmPassword: "",
  setErrors: function (key, data) {
    this[key] = data;
    return { ...this };
  },
  resetError: function () {
    this.email = "";
    this.password = "";
    this.confirmPassword = "";
    return { ...this };
  },
};

const RegisterPageNew = ({ navigation }) => {
  const dispatch = useDispatch();
  const createdUserData = useSelector((state) => state.profile.createdUserData);
  const [profileModel, setProfileModel] = useState(ProfileModel);
  const { convertToBlob, uploadBlobToFirebase } = useFirebaseUpload();
  const formsRef = useRef({
    image: useRef(),
  });

  const [submitForm, setSubmitForm] = useState(false);
  const isKeyboardOpen = useKeyboardStatus();
  const [registerForm, setRegisterForm] = useState({ ...RegisterModel });
  const [error, setError] = useState(ErrorModel);
  // const [loader, setLoader] = useState(false);
  const [accept, setAccept] = useState(false);
  const [tcHTML, setTcHTML] = useState(false);
  const [ppHTML, setPpHTML] = useState(false);
  const [setLoader, LoaderComponent] = useLoader();
  const appInfoState = useSelector((state) => state.appInfoData.appInfoData);
  const upperCaseRegEx = new RegExp(/[A-Z]+/);
  const CapitalSmall = new RegExp(/[A-Z a-z]/);
  const lowerCaseRegEx = new RegExp(/[a-z]+/);
  const numberRegEx = new RegExp(/[0-9]+/);
  const charRegEx = new RegExp(/[!@#$%a^&*()-_+=.,;:'"`~]+/);
  const emailRegEx = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

  const showPopup = ({ text, onPressOK = () => {} }) => {
    return dispatch(
      toggleAlertBox({
        visible: true,
        text: text,
        onPress: () => {
          dispatch(toggleAlertBox("CLOSE"));
          onPressOK();
        },
      })
    );
  };
  const handleSubmit = async () => {
    try {
      setLoader(true);
      if (
        !(
          CapitalSmall.test(profileModel.userName) &&
          CapitalSmall.test(profileModel.userNickname)
        )
      ) {
        console.log("it should be string");
        // isError = true;
        // errorObj = error.setErrors("Name or Nickname", "Name Should be Valid");
      }
      let errorObj;
      let isError = false;
      // if (!registerForm.email.validateEmail()) {
      //     isError = true;
      //     errorObj = error.setErrors("email", "Invalid Email!");
      // }
      if (registerForm.email.length == 0) {
        isError = true;
        errorObj = error.setErrors("email", "Enter Email!");
        showPopup({
          text:
            "Enter Email!",
        });
    }
    if (registerForm.email.length > 0 && !emailRegEx.test(registerForm.email)) {
        isError = true;
        errorObj = error.setErrors("email", "Invalid Email!");
        showPopup({
          text:
            "Invalid Email!",
        });
    }
      if (
        registerForm.password.length < 6 &&
        lowerCaseRegEx.test(registerForm.password) &&
        numberRegEx.test(registerForm.password) &&
        upperCaseRegEx.test(registerForm.password) &&
        charRegEx.test(registerForm.password)
      ) {
        showPopup({
          text:
            "Password should have One special character, one digit, one capital text",
        });
        isError = true;
        errorObj = error.setErrors(
          "password",
          "Password min lenght should be 6!"
        );
        setLoader(false);
      }
      if (registerForm.password != registerForm.confirmPassword) {
        setLoader(false);
        isError = true;
        errorObj = error.setErrors(
          "confirmPassword",
          "Password should be matched!"
        );
      }
      if (isError) {
        setLoader(false);
        setError(errorObj);
        return;
      }
      if (!accept) {
        setLoader(false);
        return showPopup({ text: "Please read accept Terms and Conditions" });
      }

      setSubmitForm(true);
      const checkUserExist = await usersCollection
        .where("email", "==", registerForm.email.toLowerCase())
        .get();
      if (checkUserExist.size > 0) {
        setTimeout(() => {
          setSubmitForm(false);
          return showPopup({ text: "Email already registered!" });
        }, 500);
        return;
      }

      const values = await api.registerUser(registerForm.getData());
      console.log("REGISTER_VALUES - ", JSON.stringify(values));
      await dispatch(createdNewProfile(values.user));
      let validateFormResult = profileModel.validateForm();
      if (!validateFormResult.isValidate) {
        setLoader(false);
        return showPopup({ text: validateFormResult.message });
      }
      setLoader(false);
      setTimeout(async () => {
        let profileData = profileModel.saveToFirebase();
        const pictureBlob = await convertToBlob(
          profileData.userAvatar,
          "profileImages/"
        );
        uploadBlobToFirebase(pictureBlob).then(async (userAvatar) => {
          profileData.userAvatar = userAvatar;
          const updatedUserResponse = await usersCollection
            .doc(values.user.uid)
            .update(profileData);
          console.log("USER_PROFILE_UPDATED_SUCCESS - ", updatedUserResponse);

          setTimeout(() => {
            formsRef.current.image.current.reset();
            setProfileModel(profileModel.reset());
            setRegisterForm(registerForm.resetForm());
            setLoader(false);
            showPopup({
              text: "Registered Successfully!",
              onPressOK: navigation.navigate("LoginScreen"),
            });
          }, 200);
        });
      }, 500);
    } catch (error) {
      console.log("REGISTER_ERROR - ", error);
      setLoader(false);
      setTimeout(() => {
        showPopup({ text: error.message });
        //Alert.alert("Message", "Invalid Credentials!");
      }, 200);
    }
  };
  const isFocused = useIsFocused();

  // useEffect(() => {
  //   if (isFocused) {
  //     setAccept((i) => false);
  //     setError(error.resetError());
  //     setRegisterForm(registerForm.resetForm());
  //     setProfileModel(profileModel.reset());
  //   }
  // }, [isFocused]);

  const RenderExtras = ({ title, routeName }) => {
    return (
      <View style={Styles.renderExtrasContainer}>
        <Text style={Styles.renderExtrasHeading}>{title}</Text>
        <TouchableOpacity onPress={() => navigation.navigate("LoginScreen")}>
          <Text style={Styles.clickHereText}> {routeName}</Text>
        </TouchableOpacity>
      </View>
    );
  };
  const RenderError = ({ error }) => {
    return (
      <View style={Styles.errorContainer}>
        <Text style={Styles.errorTextStyle}>{error}</Text>
      </View>
    );
  };
  const RenderTermsConditions = () => {
    return (
      <View style={Styles.termsConditionContainer}>
        <CheckBox
          checked={accept}
          onPress={() => {
            if (submitForm) {
              return;
            }
            setAccept((i) => !i);
          }}
        />
        <Text style={Styles.privacyPolicyStyle}>
          {`I accept the `}
          <TouchableOpacity
            disabled={submitForm}
            onPress={() => {
              setTcHTML((i) => !i);
            }}
          >
            <Text
              style={Styles.privactyTouchTextStyle}
            >{`Terms and Conditions`}</Text>
          </TouchableOpacity>
          {` and `}
          <TouchableOpacity
            disabled={submitForm}
            onPress={() => {
              setPpHTML((i) => !i);
            }}
          >
            <Text
              style={Styles.privactyTouchTextStyle}
            >{`Privacy Policy`}</Text>
          </TouchableOpacity>
        </Text>
      </View>
    );
  };
  return (
    <View style={Styles.childViewStyle}>
      <LoaderComponent />
      <ScrollView
        style={Styles.container}
        keyboardShouldPersistTaps={"always"}
        // keyboardDismissMode={"on-drag"}
      >
        <PRLLogo
          containerStyle={Styles.imageBoxContainer}
          imgStyle={Styles.logoStyle}
        />

        <View style={Styles.formContainer}>
          <Text style={Styles.formHeadingText}>Create an Account</Text>
          <TextInput
            containerStyle={Styles.inputContainerStyle}
            placeholder={"E-mail*"}
            value={registerForm.email}
            onChangeText={(email) => {
              if (error.email.length > 0) {
                setError(error.setErrors("email", ""));
              }
              setRegisterForm(registerForm.setData("email", email));
            }}
          />
          {error.email.length > 0 && <RenderError error={error.email} />}
          <TextInput
            containerStyle={Styles.inputContainerStyle}
            placeholder={"Enter Name"}
            value={profileModel.userName}
            onChangeText={(userName) => {
              setProfileModel(profileModel.update("userName", userName));
            }}
          />

          <TextInput
            containerStyle={Styles.inputContainerStyle}
            placeholder={"Enter Nick Name"}
            value={profileModel.userNickname}
            onChangeText={(userNickname) => {
              setProfileModel(
                profileModel.update("userNickname", userNickname)
              );
            }}
          />

          <TextInput
            containerStyle={Styles.inputContainerStyle}
            placeholder={"Enter Cell Phone Number"}
            value={profileModel.userCellPhone}
            onChangeText={(userCellPhone) => {
              setProfileModel(
                profileModel.update("userCellPhone", userCellPhone)
              );
            }}
          />
          <View style={{ alignItems: "center", marginVertical: getHp(5) }}>
            <ImageVideoPlaceholder
              ref={formsRef.current.image}
              containerStyle={Styles.avatarContainerStyle}
              imageStyle={Styles.avatarImageStyle}
              type={"photo"}
              renderText={"Add Picture/Avatar"}
              selectedData={(userAvatar) => {
                setProfileModel(profileModel.update("userAvatar", userAvatar));
              }}
              resetViewURI={() => {
                setProfileModel(profileModel.update("userAvatar", ""));
              }}
            />
          </View>
          <PasswordInput
            containerStyle={Styles.inputContainerStyle}
            placeholder={"Password*"}
            onChangeText={(password) => {
              if (error.password.length > 0) {
                setError(error.setErrors("password", ""));
              }
              setRegisterForm(registerForm.setData("password", password));
            }}
            value={registerForm.password}
          />
          {error.password.length > 0 && <RenderError error={error.password} />}
          <PasswordInput
            containerStyle={Styles.inputContainerStyle}
            placeholder={"Confirm Password*"}
            onChangeText={(confirmPassword) => {
              if (error.confirmPassword.length > 0) {
                setError(error.setErrors("confirmPassword", ""));
              }
              setRegisterForm(
                registerForm.setData("confirmPassword", confirmPassword)
              );
            }}
            value={registerForm.confirmPassword}
          />
          <RenderTermsConditions />

          {error.confirmPassword.length > 0 && (
            <RenderError error={error.confirmPassword} />
          )}
          <View style={Styles.bottomButtonTrayContainer}>
            <TouchableButton
              type={"small"}
              backgroundColor={"#EC2939"}
              title={"Sign Up"}
              onPress={handleSubmit}
            />
            <TouchableButton
              type={"small"}
              backgroundColor={"#EDCF80"}
              title={"Clear"}
              onPress={() => {
                formsRef.current.image.current.reset();
                setProfileModel(profileModel.reset());
                setRegisterForm(registerForm.resetForm());
                setError(error.setErrors("email", ""))
                setError(error.setErrors("password", ""))
                setError(error.setErrors("confirmPassword", ""))
              }}
            />
            <TouchableButton
              type={"small"}
              backgroundColor={"#0B214D"}
              title={"Cancel"}
              onPress={() => {
                navigation.navigate("LoginScreen");
              }}
            />
          </View>
        </View>
        <RenderExtras title={"Already have an account?"} routeName={"Login"} />
      </ScrollView>
      {submitForm == false && !isKeyboardOpen && <AuthFooter />}
      <WebViewModal
        modalVisible={tcHTML}
        onClose={() => setTcHTML((i) => !i)}
        html={appInfoState["htmlTermsOfUse"]}
      />
      <WebViewModal
        modalVisible={ppHTML}
        onClose={() => setPpHTML((i) => !i)}
        html={appInfoState["htmlPrivacyPolicy"]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: "#fff",
  },
  container: {
    padding: 50,
    alignItems: "center",
  },
  inputStyle: {
    fontSize: 15,
    color: COLOR.blue,
  },
  inputContainerStyle: {
    borderBottomWidth: 3,
    borderBottomColor: COLOR.gold,
  },
  logo: {
    width: "100%",
    height: 150,
  },
});

export default RegisterPageNew;
