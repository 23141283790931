import React, { Fragment } from "react";
import { View, Text } from "react-native";
import "./Round.css";
const Round = (props) => {
  const {
    round,
    index,
    roundCount,
    onSelectOptionsFromRound,
    isViewMode,
    headData,
  } = props;
  let count = -1;
  console.log("first round =====>", round);
  return (
    <>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          width: "100%",
          justifyContent: "center",
          position: index === 2 ? "none" : "absolute",
          top: "35px",
          left: "0",
          zIndex: 12,
        }}
      >
        <h3>
          {headData?.options != undefined
            ? headData?.options[0].playerData.bracketRoundName +
              " - " +
              headData?.options[0].playerData.bracketRound
            : ""}
          <h6>
            {headData?.options != undefined
              ? headData?.options[0].playerData.bracketStartTime
              : ""}
          </h6>
        </h3>
      </div>
      <>
        {round.options.map((option, oIndex) => {
          let iIndex = (oIndex + 1) % 2;
          if (iIndex == 0) {
            count++;
          }
          let value =
            option.selectedValue == "Select"
              ? "Select"
              : JSON.stringify(option.selectedValue);
          let divClassName = "tournament-bracket__match";
          if (true) {
            divClassName = divClassName.concat(" tournament-bracket__win");
          } else {
            divClassName = divClassName.concat(" tournament-bracket__lose");
          }

          let bgColor = "";
          if (option.playerData.winnerID == "") {
            bgColor = "#FFF";
          } else if (option.guessData.winnerID == option.playerData.winnerID) {
            bgColor = "#00cc44";
          } else {
            bgColor = "#D2292E";
          }

          let winClassName = "m-2 fonts";
          if (bgColor == "#D2292E") {
            winClassName = winClassName.concat(" fontsCut");
          }

          return (
            <>
              {iIndex == 0 && (
                <div className="Game_desc">
                  {headData?.options[count].playerData.gameDescription}
                </div>
              )}
              <>
                {isViewMode ? (
                  // <Fragment>
                  //   <View
                  //     style={{
                  //       backgroundColor: bgColor,
                  //     }}
                  //   >
                  //     <View style={{ backgroundColor: bgColor }}>
                  //       <Text
                  //         style={{
                  //           fontWeight: "700",
                  //           fontSize: 15,
                  //           marginLeft: 10,
                  //           textDecorationLine:
                  //             bgColor == "#D2292E" ? "line-through" : "none",
                  //         }}
                  //       >
                  //         {option.guessData.winnerName}
                  //       </Text>
                  //     </View>
                  //     {bgColor == "#D2292E" && (
                  //       <View style={{ backgroundColor: "#FFF" }}>
                  //         <h6 class="m-2 fonts">
                  //           {option.playerData.winnerName}
                  //         </h6>
                  //       </View>
                  //     )}

                  //     {/* <h6 class="m-3 fonts">
                  //       Game ID #{option.playerData.gameID}
                  //     </h6> */}
                  //   </View>
                  // </Fragment>
                  <li class="team-item-1">
                    <div
                      style={{
                        border: "1px solid black",
                        borderRadius: "10px",
                      }}
                      class="custom-select2"
                    >
                      <div
                        className="player"
                        style={{
                          border: "none",
                          borderBottom: "1px solid black",
                          backgroundColor: bgColor,
                          textDecorationColor: "gray",
                          textDecorationThickness: "2px",
                          textDecorationLine:
                            bgColor == "#D2292E" ? "line-through" : "none",
                        }}
                      >
                        {option.guessData.winnerName}
                      </div>
                      {bgColor == "#D2292E" && (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <div style={{ fontSize: "12px" }}>
                            {option.playerData.winnerName}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div class="description">
                    {headData?.options[count]?.playerData?.gameDescription}
                  </div>
                  <div class="custom-select">
                    <select>
                      <option value="0">Player-1:- A2</option>
                      <option value="1">Player-1:- A2</option>
                    </select>
                  </div> */}
                  </li>
                ) : (
                  // <View
                  //   style={{
                  //     backgroundColor: "#fff",
                  //     // marginTop:
                  //     //   iIndex == 0
                  //     //     ? option.playerData.bracketRound >= 2
                  //     //       ? 150
                  //     //       : 20
                  //     //     : 0,
                  //   }}
                  // >
                  //   <select
                  //     onChange={(event) => {
                  //       let value = event.target.value;
                  //       let selectedValue =
                  //         value == "Select" ? "Select" : JSON.parse(value);
                  //       console.log("value -> ", selectedValue, oIndex);
                  //       onSelectOptionsFromRound(oIndex, selectedValue);
                  //     }}
                  //     value={value}
                  //     id="issueinput5"
                  //     class="form-control"
                  //   >
                  //     {option.selectionValues.map((values, vIndex) => {
                  //       if (values == "Select") {
                  //         return <option value={"Select"}>{"Select"}</option>;
                  //       }
                  //       if (Object.keys(values).length == 0) {
                  //         return null;
                  //       }
                  //       return (
                  //         <option value={JSON.stringify(values)}>
                  //           {values.playerName}
                  //         </option>
                  //       );
                  //     })}
                  //   </select>
                  // </View>
                  <li class="team-item-1">
                    <div class="custom-select">
                      <select
                        class="winner"
                        onChange={(event) => {
                          let value = event.target.value;
                          let selectedValue =
                            value == "Select" ? "Select" : JSON.parse(value);
                          console.log("value -> ", selectedValue, oIndex);
                          onSelectOptionsFromRound(oIndex, selectedValue);
                        }}
                        value={value}
                      >
                        {option.selectionValues.map((values, vIndex) => {
                          if (values == "Select") {
                            return <option value={"Select"}>{"Select"}</option>;
                          }
                          if (Object.keys(values).length == 0) {
                            return null;
                          }
                          return (
                            <option value={JSON.stringify(values)}>
                              {values.playerName}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* <div class="description">
                      {headData?.options[count]?.playerData?.gameDescription}
                    </div>
                    <div class="custom-select">
                      <select>
                        <option value="0">Player-1:- A2</option>
                        <option value="1">Player-1:- A2</option>
                      </select>
                    </div> */}
                  </li>
                )}
              </>
            </>
          );
        })}
      </>
    </>
  );
};

export default Round;

const SEMI_FINALS = (
  <div class="tournament-bracket__round tournament-bracket__round--semifinals">
    <h3 class="tournament-bracket__round-title">
      Semi-Finals
      <h6>March 26, 18:00</h6>
    </h3>
    <ul class="tournament-bracket__list">
      <li class="tournament-bracket__item">
        <div class="tournament-bracket__match" tabindex="0">
          <table class="tournament-bracket__table lastChild">
            <select id="issueinput5" class="form-control">
              <option selected>Warrior</option>
              <option>Kraken</option>
            </select>
          </table>
        </div>
      </li>
    </ul>
  </div>
);

// import React from "react";

// const Round = (props) => {
//   const { round, index, roundCount, onSelectOptionsFromRound, isViewMode } =
//     props;

//   return (
//     <div class="tournament-bracket__round tournament-bracket__round--semifinals">
//       <h3 class="tournament-bracket__round-title">
//         Round - {roundCount}
//         <h6>March 22, 18:00</h6>
//       </h3>
//       <ul class="tournament-bracket__list">
//         {round.options.map((option, oIndex) => {
//           let value =
//             option.selectedValue == "Select"
//               ? "Select"
//               : JSON.stringify(option.selectedValue);
//           return (
//             <li class="tournament-bracket__item">
//               <div class="tournament-bracket__match" tabindex="0">
//                 <table class="tournament-bracket__table">
//                   {isViewMode ? (
//                     <h6 class="m-2 fonts">{option.guessData.winnerName}</h6>
//                   ) : (
//                     <select
//                       onChange={(event) => {
//                         let value = event.target.value;
//                         let selectedValue =
//                           value == "Select" ? "Select" : JSON.parse(value);
//                         onSelectOptionsFromRound(oIndex, selectedValue);
//                       }}
//                       value={value}
//                       id="issueinput5"
//                       class="form-control"
//                     >
//                       {option.selectionValues.map((values, vIndex) => {
//                         if (values == "Select") {
//                           return <option value={"Select"}>{"Select"}</option>;
//                         }
//                         if (Object.keys(values).length == 0) {
//                           return null;
//                         }
//                         return (
//                           <option value={JSON.stringify(values)}>
//                             {values.playerName}
//                           </option>
//                         );
//                       })}
//                     </select>
//                   )}
//                 </table>
//               </div>
//             </li>
//           );
//         })}
//       </ul>
//     </div>
//   );
// };

// export default Round;

// const SEMI_FINALS = (
//   <div class="tournament-bracket__round tournament-bracket__round--semifinals">
//     <h3 class="tournament-bracket__round-title">
//       Semi-Finals
//       <h6>March 26, 18:00</h6>
//     </h3>
//     <ul class="tournament-bracket__list">
//       <li class="tournament-bracket__item">
//         <div class="tournament-bracket__match" tabindex="0">
//           <table class="tournament-bracket__table lastChild">
//             <select id="issueinput5" class="form-control">
//               <option selected>Warrior</option>
//               <option>Kraken</option>
//             </select>
//           </table>
//         </div>
//       </li>
//     </ul>
//   </div>
// );
