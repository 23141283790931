import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import "./index.css";
import { stripeProduction } from "../../../app.json";
import { stripeTransactionsCollection } from "../../firebase";
import FeesModel from "../../Screens/home/event/eventPaymentSignup/Fees.model";
import { useSelector } from "react-redux";

const ENV = {
  sandbox:
    "https://us-central1-players-recreation-league.cloudfunctions.net/createStripePaymentSandbox",
  production:
    "https://us-central1-players-recreation-league.cloudfunctions.net/createStripePayments",
};
const PAYMENT_URL =
  "https://us-central1-players-recreation-league.cloudfunctions.net/dynamicStripePayment";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#FFF",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

const StripePaymentForm = (props) => {
  const {
    btnTotal,
    total,
    eventName,
    charityName,
    charityID,
    eventID,
    email,
    setLoader,
    onPaymentSuccess,
    onPaymentFail,
    closePayment,
    config,
  } = props;
  const [success, setSuccess] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const [feesModel, setFeesModel] = useState(FeesModel);
  const authState = useSelector((state) => state.auth);

  const handleSubmit = async (event) => {
    console.log("Charity Name --> ", charityName)
    console.log("Charity ID --> ", charityID)
    console.log("Config --> ", config);
    setLoader(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      console.log("Payment Method --> ", JSON.stringify(paymentMethod));
      let reqBody = JSON.stringify({
        amount: total * 100,
        id: paymentMethod.id,
        eventName: eventName,
        charityName: charityName,
        email: email,
        eventID: eventID,
        charityID: config,
        type: stripeProduction ? "Production" : "Sandbox",
      });
      const validatePayment = await fetch(PAYMENT_URL, {
        method: "post",
        body: reqBody,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((resJson) => {
          //console.log("payment response --> ", JSON.stringify(resJson));
          return resJson;
        })
        .catch((e) => {
          return e;
          console.log("Error on firebase Catch --> ", e);
        });
      console.log("Payment Final ---> ", validatePayment);
      if (validatePayment?.success == true) {
        const stripeTransactionsBody = {};
        stripeTransactionsBody.paymentResponse =
          JSON.stringify(validatePayment);
        stripeTransactionsBody.eventID = eventID;
        stripeTransactionsBody.userID = authState.userId;
        stripeTransactionsBody.createdAt = new Date();

        stripeTransactionsBody.amountPaid =
          feesModel.getTotal(true).selectedTotalFees;

        stripeTransactionsBody.charityID = charityID
        stripeTransactionsBody.paidCharityID = config

        stripeTransactionsBody.charityName = charityName

        stripeTransactionsBody.env = stripeProduction
          ? "PRODUCTION"
          : "SANDBOX";
        const saveStripeTransaction = await stripeTransactionsCollection.add(
          stripeTransactionsBody
        );
        onPaymentSuccess();
        closePayment();
      } else {
        setLoader(false);
        closePayment();
        onPaymentFail();
      }
    } catch (error) {
      console.log("Error on Firebase function --> ", error);
      setLoader(false);
      closePayment();
      onPaymentFail();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className="FormGroup">
        <div className="FormRow">
          <CardElement options={CARD_OPTIONS} />
        </div>
      </fieldset>
      <div className="btnClass">
        <button className="PayButton" disabled={!stripe}>
          Pay {btnTotal}
        </button>
        <button className="cancelButton" onClick={() => closePayment()}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default StripePaymentForm;
