// External Imports
import React, { useEffect, useState } from "react";
import { View, Image, Text, ScrollView } from "react-native";
import styles from "./indexCss";
import { Button } from "react-native-elements";
import HeaderBlack from "../../../../Header/HeaderBlack";
import { useSelector, connect } from "react-redux";
import { getFromToDate, getHp } from "../../../../utils";
import { useBackHandler } from "@react-native-community/hooks";
import { Root } from "../../../../Component";
import { useLinkTo } from "@react-navigation/native";

function EPConfirmationScreen({ navigation, route }) {
  const linkTo = useLinkTo();
  const { firebaseAllCollectionData, auth, navReducer } = useSelector((state) => state);
  const [eventData, setEventData] = useState();
  const [charityData, setCharityData] = useState();
  const [selectedFeesData, setSelectedFeesData] = useState();
  const [loading, setLoading] = useState(true);
  const { eventId } = navReducer.paymentSignupToPaymentConfirmation;

  useEffect(() => {
    const NAV = navReducer.paymentSignupToPaymentConfirmation
    if(NAV.initialized == false){
      linkTo('/home');
      return false;
    }
    const firebaseCollectionData = {
      ...firebaseAllCollectionData.firebaseCollectionData,
    };
    setSelectedFeesData(NAV.selectedFeesData)
    let eventData = {};
    firebaseCollectionData.eventsData.map((event, index) => {
      if (event.eventID == eventId) {
        eventData = { ...event };
      }
    });
    let charityData = {};
    firebaseCollectionData.charityData.map((charity, index) => {
      if (charity.charityID == eventData.charityID) {
        charityData = { ...charity };
      }
    });
    setEventData(eventData);
    setCharityData(charityData);
    setLoading(false);
    console.log("TEST_EVENT_DATA - ", JSON.stringify(eventData));
  }, []);

  useBackHandler(() => {
    return navigation.pop(3);
  });

  const EventInfo = (
    <View
      style={{
        paddingVertical: 10,
        width: "90%",
        alignSelf: "center",
        marginBottom: 20,
      }}
    >
      <View style={{ padding: 5 }}>
        <Text style={{ fontSize: 16, color: "#0B214D", fontWeight: "bold" }}>
          Event Information
        </Text>
      </View>
      <View style={{ padding: 5 }}>
        <Text style={{ fontSize: 16, color: "#0B214D" }}>
          {eventData?.eventInformation}
        </Text>
      </View>
      {/*<View style={{ padding: 5 }}>
        <Text style={{ fontSize: 16, color: '#0B214D' }}>Game schedule will be sent on</Text>
      </View>
      <View style={{ padding: 5 }}>
        <Text style={{ fontSize: 16, color: '#0B214D' }}>Game schedule will be sent on</Text>
      </View>
      <View style={{ padding: 5 }}>
        <Text style={{ fontSize: 16, color: '#0B214D' }}>Good Luck!</Text>
      </View> */}
    </View>
  );

  const OrderDetails = (
    <View style={{ backgroundColor: "#FFFFFF", paddingHorizontal: 20 }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <Text style={{ fontSize: 16, color: "#0B214D" }}>Order Details</Text>
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-evenly",
          padding: 10,
        }}
      >
        <View>
          <Image
            source={{ uri: eventData?.eventLogo }}
            style={styles.profileImg}
          />
        </View>
        <View>
          <Text style={{ fontSize: 16, color: "#0B214D", fontWeight: "bold" }}>
            {eventData?.eventName}
          </Text>
          <Text style={{ fontSize: 16, color: "#0B214D" }}>
            From {getFromToDate(eventData?.eventDate, eventData?.eventDateEnd)}
          </Text>
          <Text style={{ fontSize: 16, color: "#0B214D" }}>
            {charityData?.charityName}
          </Text>
        </View>
      </View>
      <View style={{ padding: 5 }}>
        {selectedFeesData?.map((i) => {
          return (
            <Text style={{ fontSize: 16, color: "#0B214D", marginTop: 5 }}>
              {i.contestName}
            </Text>
          );
        })}
      </View>
    </View>
  );

  const ButtonBlock = (
    <View style={{ alignItems: "center", paddingBottom: 10 }}>
      <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          onPress={() => {
            navigation.navigate("PlayerProfileScreen", {
              userID: auth.userCol.uid,
              eventID: eventData?.eventID,
            });
          }}
          buttonStyle={styles.ButtonView}
          title={"Edit Your profile"}
        >
          {/* <Text style={styles.ButtonText}>Edit Your profile</Text> */}
        </Button>
        {/* {     how to give spacing here between buttons} */}
        <Button
          buttonStyle={styles.ButtonView}
          title={"Scout Competition"}
          onPress={() => {
            navigation.navigate("PlayerListScreen", {
              eventId: eventData?.eventID,
            });
          }}
        />
        {/* <Text buttonStyle={styles.ButtonText}>Scout Competition</Text> */}
      </View>
      <View>
        <Button
          onPress={() => {
            navigation.pop(4);
          }}
          buttonStyle={styles.ButtonView2}
          title={"See Other Events"}
        >
          {/* <Text style={styles.ButtonText}>See Other Events</Text> */}
        </Button>
      </View>
    </View>
  );

  return (
    <Root>
      <HeaderBlack
        hideMenu
        onMenuPress={() => navigation.openDrawer()}
        onBackPress={() => {
          return navigation.pop(4);
        }}
      />
      <ScrollView style={{ height: "100%", width: "70%" }}>
        {!loading ? (
          <>
            <View
              style={{
                paddingHorizontal: 20,
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <View style={{ paddinTop: 10 }}>
                <Text
                  style={{ fontSize: 20, color: "#0B214D", fontWeight: "bold" }}
                >
                  Order confirmed
                </Text>
              </View>
              <View style={{ paddingVertical: 10 }}>
                <Text
                  style={{ fontSize: 16, color: "#0B214D", fontWeight: "bold" }}
                >
                  An email receipt has been sent to {auth.userCol.email}
                </Text>
              </View>
            </View>

            <View
              style={{
                borderWidth: 0.7,
                borderColor: "black",
                width: "90%",
                alignSelf: "center",
              }}
            />

            {OrderDetails}
            <View
              style={{
                borderWidth: 0.7,
                borderColor: "black",
                width: "90%",
                alignSelf: "center",
              }}
            />
            {EventInfo}

            <View
              style={{
                backgroundColor: "#EDCF80",
                paddingVertical: 5,
                paddingLeft: 20,
              }}
            >
              <View>
                <Text
                  style={{ fontSize: 16, color: "#0B214D", fontWeight: "bold" }}
                >
                  Charity Note!
                </Text>
              </View>
              <View style={{ paddingVertical: 10 }}>
                <Text
                  style={{ fontSize: 16, color: "#0B214D", fontWeight: "bold" }}
                >
                  {eventData?.eventThankYou || ""}
                </Text>
              </View>
            </View>

            {ButtonBlock}
          </>
        ) : null}
      </ScrollView>
    </Root>
  );
}

export default connect()(EPConfirmationScreen);
