
//External Imports
import { StyleSheet } from 'react-native';

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from '../../../utils';

export default StyleSheet.create({
    childViewStyle: {
        backgroundColor: "#fff",
    },
    container: {
        backgroundColor: "#fff",
    }, 
    inputContainerStyle: {
        width: "80%",
        alignSelf: "center",
        marginTop: 20,
        height: 45,
        // borderWidth:1,
        // borderColor: "red"
    },
    inputStyle: {
        height: 45,
        // borderWidth:1,
        // borderColor: "green"
    },
    imagePlateContainer: {
        width: "85%",
        marginTop: 20,
        alignSelf: "center",
        justifyContent: "space-between",
        flexDirection : "row"
    },
    imagePlateRightChildView: {
        //borderWidth:1,
        borderColor: "red",
        width: "70%",
        
    },
    datePickerContainer: {
        flexDirection :"row",
        justifyContent: "space-between"
    },
    singleHeadingContainer: {
        marginTop: 15
    },
    eventDetailsContainer: {
        width: '95%',
        marginTop: 10, 
        alignSelf: "center"
    },
    eventDescriptionTextStyle: {
        marginTop: 20,
        height: 100
    },
    bottomTrayContainer: {
        flexDirection : "row",
        marginTop: 10,
        alignItems: 'center'
    },
    uploadVideoContainerStyle: {
        marginLeft: 15,
        height: 70,
        width: 112
    },
    bottomButtonsTray: {
        flexDirection : "row",
        marginTop: 30,
        justifyContent: "space-between",
        alignSelf: 'center',
         
    },
    createContestTypesContainer: {
        flexDirection: "row",
        justifyContent: "space-between", 
        alignItems: "center"
    },
    addContestTypeStyle: {
        marginTop: 25,
        
    },
    addContestTypeIconStyle: {
        fontSize: 25,
        color: "black"
    },
    sideButtonsContainer: {
        marginLeft: 20
    },
    enterFeesBtnContainer: {
        marginTop: 15
    },
    createProfileTitleStyle: {
        fontSize: 14
    },
    uploadPicStyle: {
        width: 200,
        height: 104
    },
    galleryLabelStyle: {
        marginTop: 25,
        fontSize:18,
        fontWeight: '700',
        color: 'black'

    },
    saveEditBtnStyle: {
        marginTop: 45,
        alignSelf: 'center',
        marginBottom: 30,
        
    },
    closeEditModelContainer: {
        position: 'absolute',
        right: 15,
        top: 28
    }
});





