//External Imports
import { StyleSheet } from "react-native";

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from "../../utils";

export default StyleSheet.create({
  container: {
    width: '70%',
    backgroundColor: "#E5E5E5",
  },
  headerContainer: {
    marginTop: 20,
    width: "85%",
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  seddingsTextStyle: {
    fontWeight: "400",
    fontSize: 18,
    marginLeft: 20,
    color: "black",
  },
  eventPlateContainer: {
    marginTop: 30,
    backgroundColor: "white",
    minHeight: 100,
    width: "90%",
    alignSelf: "center",
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 20,

    shadowColor: "#DCE4F9",
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
    shadowOpacity: 1,
    elevation: 2,
  },
  eventListData: {
    paddingVertical: 8,
    //marginVertical: 10),
    borderBottomWidth: 0.5,
    borderBottomColor: "grey",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20,
  },
  listEventNameText: {
    fontSize: 16,
    fontWeight: "400",
    marginLeft: 20,
    color: "black",
  },
  listEventImgStyle: {
    height: 50,
    width: 50,
    borderRadius: 50,
  },
  eventListAbsoluteContainer: {
    backgroundColor: "white",
    position: "absolute",
    top: 175,
    width: "89%",
    alignSelf: "center",
    minHeight: 250,
  },
  selectedEventTextStyle: {
    fontWeight: "700",
    fontSize: 18,
    color: "black",
  },
  totalPlayerTextStyle: {
    color: "black",
    marginTop: 10,
  },
  contestBracketTypeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 40,
    width: '90%',
    alignSelf: "center",
  },
  commonheadingStyle: {
    color: "black",
    fontWeight: "700",
    fontSize: 16,
  },
  numOfPerContainer: {
    alignSelf: "center",
    marginTop: 30,
    flexDirection: "row",
  },
  numOfPerText: {
    fontSize: 16,
    marginTop: 10,
    color: "black",
    fontWeight: "700",
  },
  inputContainerStyle: {
    height: 40,
    marginLeft: 20,
    width: 60,
  },
});
