
//External Imports
import { StyleSheet } from 'react-native';

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from '../../utils';

export default StyleSheet.create({
    containerStyle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelTextStyle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#FFF',
        width: 70
    },
    singleDateStyleContainer: {
        height: getHp(37),
        width: getHp(37),
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#FFF'
    },
    slashTextStyle: {
        fontSize: 24,
        fontWeight:'bold',
        color: 'white',
        marginTop: -5,
        marginHorizontal: getWp(2)
    },
    dateTextStyle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'black'
    }
});