import React, { useState, useCallback, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  View,
  StyleSheet,
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  Alert,
} from "react-native";
import * as api from "../../../store/api";
import { useKeyboardStatus, useLoader } from "../../../hooks";
import {
  transformFirebaseValues,
  sortArrayAlphabatically,
} from "../../../utils";
import { useLinkTo, useIsFocused } from "@react-navigation/native";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  TextInput,
  TouchableButton,
  PasswordInput,
  AuthFooter,
  PRLLogo,
  CustomPopup,
} from "../../../Component";
import { login } from "../../../store/actions";
import Styles from "./indexCss";
import { usersCollection, charitiesCollection } from "../../../firebase";
import { toggleAlertBox } from "../../../store/actions";
import { Button, CheckBox } from "react-native-elements";
import AntDesign from "react-native-vector-icons/AntDesign";

AntDesign.loadFont();

const LoginModel = {
  email: "",
  password: "",
  setData: function (key, data) {
    this[key] = data;
    return { ...this };
  },
  getData: function () {
    return { email: this.email, password: this.password };
  },
  isDisabled: function () {
    return !(this.email.length > 0 && this.password.length > 0);
  },
  resetForm: function () {
    this.email = "";
    this.password = "";
    return { ...this };
  },
};
let ErrorModel = {
  email: "",
  password: "",
  setErrors: function (key, data) {
    this[key] = data;
    return { ...this };
  },
  resetError: function () {
    this.email = "";
    this.password = "";
    return { ...this };
  },
  reset: function () {
    email = "";
    password = "";
  },
};

const LoginScreen = (props) => {
  const linkTo = useLinkTo();
  const [checked, setChecked] = useState(false);
  const isKeyboardOpen = useKeyboardStatus();
  const [loginForm, setLoginForm] = useState(LoginModel);
  const [error, setError] = useState(ErrorModel);
  const [setLoader, LoaderComponent] = useLoader();
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userChoice, setUserChoice] = useState(0);
  const [charityArray, setCharityArray] = useState();
  let isError = false;
  const isFocused = useIsFocused();
  const alertBoxState = useSelector((state) => state.appReducer.alertBox);
  const dispatch = useDispatch();
  const { navigateToSignup } = useSelector(s => s.navReducer);
  const showPopup = ({ text, onPressOK = () => { } }) => {
    return dispatch(
      toggleAlertBox({
        visible: true,
        text: text,
        onPress: () => {
          dispatch(toggleAlertBox("CLOSE"));
          onPressOK();
        },
      })
    );
  };

  const handleSubmit = async () => {
    if (checked) {
      await AsyncStorage.setItem("UserEmail", loginForm.email);
      await AsyncStorage.setItem("UserPassword", loginForm.password);
    } else {
      await AsyncStorage.removeItem("UserEmail");
      await AsyncStorage.removeItem("UserPassword");
    }
    if (loginForm.password.length < 6) {
      showPopup({ text: "Password should be 6 digits!" });
      setError(error.setErrors("password", "Password min length should be 6!"));
      isError = true;
    }
    if (isError) {
      return;
    }
    try {
      setLoader(true);
      const loginResponse = await api.login(loginForm.getData());
      const userColResponse = await usersCollection
        .doc(loginResponse.user.uid)
        .get();
      let userResData = userColResponse.data();
      if (!userResData) {
        setLoader(false);
        return showPopup({ text: "Invalid Credentials" });
      }
      let loginRes = { ...loginResponse, userCol: userResData };
      console.log("LOGIN_RESPONSE - ", JSON.stringify(loginRes));
      await AsyncStorage.setItem("userInfo", JSON.stringify(loginRes));

      setTimeout(async () => {
        await dispatch(login(loginRes));
        setLoader(false);
        if (navigateToSignup.initialized) {
          console.log('TEST_HERE_PH_1 - ', navigateToSignup);
          props.navigation.navigate("EventInfoStack", {
            screen: 'EventPaymentSignupScreen',
            params: {
              eventId: navigateToSignup.eventID

            }
          });
        } else {
          props.navigation.navigate("Home");
        }
      }, 500);
    } catch (error) {
      console.log("LOGIN_ERROR - ", error);
      setLoader(false);
      setTimeout(() => {
        showPopup({ text: "Invalid Credentials!" });
      }, 200);
    }
  };

  const initialFunc = async () => {
    let user = { USEREMAIL: "", USERPASSWORD: "" };
    user.USEREMAIL = await AsyncStorage.getItem("UserEmail");
    user.USERPASSWORD = await AsyncStorage.getItem("UserPassword");
    if (user.USEREMAIL == null || user.USERPASSWORD == null) {
      setLoginForm(loginForm.setData("email", ""));
      setLoginForm(loginForm.setData("password", ""));
      setChecked(false);
    } else if (user.USEREMAIL.length > 0 || user.USERPASSWORD.length > 0) {
      setLoginForm(loginForm.setData("email", user.USEREMAIL));
      setLoginForm(loginForm.setData("password", user.USERPASSWORD));
      setChecked(true);
    } else {
      setLoginForm(loginForm.setData("email", ""));
      setLoginForm(loginForm.setData("password", ""));
      setChecked(false);
    }
  };

  const getCharityData = async () => {
    const charityResponse = await charitiesCollection
      .where("charityID", "!=", 0)
      .get();
    let charityDataa = transformFirebaseValues(charityResponse, "charityName");
    let charityData = sortArrayAlphabatically(charityDataa, "charityName");
    setCharityArray(charityData);
  };

  const handleChoice = (choice) => {
    if (userChoice == 0 && choice == -1) setUserChoice(0);
    else if (userChoice == charityArray.length - 1 && choice == 1)
      setUserChoice(charityArray.length - 1);
    else setUserChoice(userChoice + choice);
  };

  useEffect(() => {
    // showToast('error', 'sorry', 'error msg');
    initialFunc();
    getCharityData();
  }, [isFocused]);

  const RenderExtras = ({ title, routeName = undefined }) => {
    return (
      <View style={Styles.renderExtrasContainer}>
        <Text style={Styles.renderExtrasHeading}>{title}</Text>
        <TouchableOpacity
          onPress={() => {
            setLoginForm(loginForm.resetForm());
            setError(error.resetError());
            props.navigation.navigate(routeName);
          }}
        >
          <Text style={Styles.clickHereText}> Click here</Text>
        </TouchableOpacity>
      </View>
    );
  };

  const RenderError = ({ error }) => {
    return (
      <View style={Styles.errorContainer}>
        <Text style={Styles.errorTextStyle}>{error}</Text>
      </View>
    );
  };

  const handleCheck = () => {
    if (loginForm.email !== null && loginForm.password !== null) {
      setChecked(!checked);
    } else {
      showPopup({ text: "Please enter the credential's first !" });
    }
  };
  return (
    <>
      <LoaderComponent />
      <View style={Styles.container} showsVerticalScrollIndicator={false}>
        <View style={{ width: "70%" }}>
          <View style={[Styles.imageBoxContainer]}>
            <PRLLogo
              imgStyle={{ height: 100, width: 180, resizeMode: "contain" }}
            />
          </View>

          <View style={Styles.formContainer}>
            <Text style={Styles.formHeadingText}>Login To your Account</Text>

            <TextInput
              containerStyle={Styles.inputContainerStyle}
              placeholder={"E-mail"}
              value={loginForm.email}
              onChangeText={(email) => {
                if (error.email.length > 0) {
                  setError(error.setErrors("email", ""));
                }
                setLoginForm(loginForm.setData("email", email));
              }}
            />
            {error.email.length > 0 && <RenderError error={error.email} />}

            <PasswordInput
              containerStyle={Styles.inputContainerStyle}
              placeholder={"Password"}
              onChangeText={(password) => {
                if (error.password.length > 0) {
                  setError(error.setErrors("password", ""));
                }
                setLoginForm(loginForm.setData("password", password));
              }}
              value={loginForm.password}
            />
            <View style={Styles.checkboxContainer}>
              <CheckBox
                checked={checked}
                color={"#0B214D"}
                style={Styles.CheckboxStyle}
                onPress={handleCheck}
              />
              <Text style={Styles.label}>Remember me</Text>
            </View>

            <TouchableButton
              containerStyle={[
                Styles.inputContainerStyle,
                { marginBottom: 10 },
              ]}
              type={"redBig"}
              title={"Login"}
              onPress={handleSubmit}
            // onPress={() => PickFile()}
            // disabled={loginForm.isDisabled()}
            />
            <RenderExtras title={"Register?"} routeName={"NewRegisterScreen"} />
            <RenderExtras
              title={"Forgot Password?"}
              routeName={"ResetPasswordScreen"}
            />
          </View>
        </View>

        <View
          style={{
            width: "30%",
            height: "100%",
            backgroundColor: "#0B214D",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "95%",
              justifyContent: "space-between",
              marginTop: 30,
              alignSelf: 'center',
              alignItems: 'center',
            }}
          >
            <TouchableOpacity onPress={() => handleChoice(-1)}>
              <AntDesign name="left" color="#FFF" size={30} />
            </TouchableOpacity>

            <TouchableOpacity onPress={() => {
              linkTo('/viewCharity')
            }}>
              <Image
                style={styles.logo}
                source={{
                  uri:
                    charityArray === undefined
                      ? "https://via.placeholder.com/150"
                      : `${charityArray[`${userChoice}`].charityLogo}`,
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => handleChoice(+1)}>
              <AntDesign name="right" color="#FFF" size={30} />
            </TouchableOpacity>
          </View>
          <TouchableOpacity onPress={() => {
            linkTo('/viewCharity')
          }} style={styles.NameView}>
            <Text style={styles.NameText} >{charityArray === undefined ? "" : charityArray[userChoice].charityName}</Text>
            <Text style={styles.DescText} >{charityArray === undefined ? "" : charityArray[userChoice].charityDescription}</Text>
          </TouchableOpacity>
        </View>
      </View>

      <AuthFooter />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  logo: {
    borderRadius: 50,
    backgroundColor: "#C4C4C4",
    height: 93,
    width: 93,
    resizeMode: "contain",
  },
  center: { alignItems: "center" },
  NameText: {
    color: '#FFF',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  DescText: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: '600',
    margin: 10,
    marginTop: 20
  },
  NameView: {
    marginTop: 40
  }
});

export default connect()(LoginScreen);
