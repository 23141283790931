import React, { Fragment } from "react";
import { StyleSheet, View, Text, ScrollView } from "react-native";

//Import Vector Icons
import Antdesign from "react-native-vector-icons/AntDesign";
import Feather from "react-native-vector-icons/Feather";

import Colors from "../../common/Colors";
import ContestDetail from "./ContestDetail";

//Load Vector Icons
Antdesign.loadFont();
Feather.loadFont();

class ContestDescSection extends React.Component {
  render() {
    const { Contest, Event, contestBracketTypes } = this.props;
    let bracketType = "";
    let currentBracketType = contestBracketTypes.find((b, i) => {
      if (b.contestBracketTypeID == Contest.contestBracketType) {
        return true;
      }
    });
    if (currentBracketType) {
      bracketType = currentBracketType.name;
    }
    return (
      <Fragment>
        <View style={styles.HeaderBar}>
          <View
            showsHorizontalScrollIndicator={false}
            horizontal
            style={{ maxWidth: 600 }}
          >
            <Text style={styles.HeaderText}>{Contest.contestScoringType}</Text>
          </View>

          <View
            showsHorizontalScrollIndicator={false}
            horizontal
            style={{ maxWidth: 600 }}
          >
            <Text style={styles.HeaderText2}>{bracketType}</Text>
          </View>
        </View>
        <View style={{ height: 30, margin: 15 }}>
          <Text style={{ fontSize: 16, lineHeight: 19, color: "#000" }}>
            {Contest.contestDescription}
          </Text>
        </View>
      </Fragment>
    );
  }
}

const styles = StyleSheet.create({
  HeaderBar: {
    backgroundColor: Colors.Yellow,
    height: 32,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  HeaderText: {
    color: Colors.Black,
    fontSize: 16,
    lineHeight: 19,
    fontWeight: "bold",
    paddingLeft: 15,
  },
  HeaderText2: {
    color: Colors.Black,
    fontSize: 16,
    lineHeight: 19,
    fontWeight: "bold",
    paddingRight: 15,
  },
});

export default ContestDescSection;
