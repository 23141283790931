//External Imports
import { StyleSheet } from "react-native";

//Internal Imports

export default StyleSheet.create({
  inputViewContainerStyle: {
    shadowColor: "#DCE4F9",
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
    shadowOpacity: 1,
    elevation: 2,
    borderRadius: 10,
    backgroundColor: "#FFF",
    justifyContent: "center",
    height: 55,
    marginBottom: 5,
  },
  inputStyle: {
    height: 55,
    fontSize: 16,
    //minHeight: 0
  },
  textInputStyle: {
    height: 55,
    // borderRadius: getWp10,
    // borderWidth: .3,
    // borderColor: "#000",
    paddingHorizontal: 20,
    alignItems: "center",
  },
  disabledViewTextStyle: {
    paddingLeft: 15,
    color: "black",
  },
});
