// External Imports
import React, { useState, useCallback, useEffect } from "react";
import { View, Text, ScrollView, Alert, Button } from "react-native";
import styles from "./indexCss";
import { useLoader } from "../../../hooks";
import { firebase } from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { Root, TextInput, PRLLogo, TouchableButton } from "../../../Component";
import { toggleAlertBox } from "../../../store/actions";

const ResetData = {
  email: "",
  setData: function (key, data) {
    this[key] = data;
    return { ...this };
  },
  getData: function () {
    return { email: this.email };
  },
  isDisabled: function () {
    return !(this.email.length > 0);
  },
  resetForm: function () {
    this.email = "";
    return { ...this };
  },
};

let ErrorModel = {
  email: "",
  password: "",
  setErrors: function (key, data) {
    this[key] = data;
    return { ...this };
  },
  resetError: function () {
    this.email = "";
    this.password = "";
    return { ...this };
  },
};

function ResetPassword({ navigation }) {
  const [ResetForm, setResetForm] = useState(ResetData);
  const [error, setError] = useState(ErrorModel);
  const [setLoader, LoaderComponent] = useLoader();
  const dispatch = useDispatch();

  const showPopup = ({ text = "", onPressOk }) => {
    return dispatch(
      toggleAlertBox({
        visible: true,
        text: text,
        onPress: () => {
          dispatch(toggleAlertBox("CLOSE"));
          onPressOk();
        },
      })
    );
  };

  const handleReset = async () => {
    setLoader(true)
    try {
      var auth = firebase.auth();
      var emailAddress = ResetForm.email;
      auth
        .sendPasswordResetEmail(emailAddress)
        .then(() => {
          setLoader(false);
          setTimeout(() => {
            navigation.navigate("LoginScreen");
          }, 300);
          showPopup({
            text: `Reset password link has been sent to ${emailAddress} !`,
            onPressOk: () => {
              navigation.navigate("LoginScreen");
            },
          });
        })
        .catch(function (error) {
          console.log(error);
          setLoader(false);
          showPopup({
            text: `Oops.. ${emailAddress} is not registered !`,
            onPressOk: () => {
              navigation.navigate("LoginScreen");
            },
          });
          // Alert.alert("Oops..", `${emailAddress} is not registered !`, [
          //   {
          //     text: "Ok",
          //     onPress: () => {
          //       navigation.navigate("LoginScreen");
          //     },
          //   },
          // ]);
        });
    } catch (error) {}
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "#FFFFFF",
        justifyContent: "center",
      }}
    >
    <LoaderComponent/>
      <View style={{ alignItems: "center" }}>
        <View style={styles.ImageView}>
          <PRLLogo />
        </View>
        <View>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 16,
              textAlign: "center",
              paddingHorizontal: 40,
              paddingVertical: 20,
            }}
          >
            Reset Password, Enter Email and we will send a reset password
          </Text>
        </View>
        <View style={{ width: "80%", paddingBottom: 40, paddingTop: 10 }}>
          <TextInput
            placeholder={"E-mail"}
            value={ResetForm.email}
            onChangeText={(email) => {
              if (error.email.length > 0) {
                setError(error.setErrors("email", ""));
              }
              setResetForm(ResetForm.setData("email", email));
            }}
          />
        </View>

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          {/* <View>
              <Button
                disabled={ResetForm.isDisabled()}
                style={[
                  styles.SubmitButton,
                  {
                    marginRight: 25,
                    backgroundColor: ResetForm.isDisabled()
                      ? "#E6E6E6"
                      : "#EC2939",
                  },
                ]}
                onPress={() => handleReset()}
              >
                <Text style={[styles.SubmitButtonView, { marginLeft: 25 }]}>
                  Submit{" "}
                </Text>
              </Button>
            </View> */}
          <TouchableButton
            containerStyle={styles.SubmitButtonView}
            type={"redBig"}
            title={"Submit"}
            onPress={handleReset}
            disabled={ResetForm.isDisabled()}
          />
          <TouchableButton
            containerStyle={styles.CancelButtonView}
            type={"paymentBTN"}
            title={"Cancel"}
            onPress={() => {
              setResetForm(ResetForm.resetForm());
              navigation.navigate("LoginScreen");
            }}
            disabled={false}
          />

          {/* <View>
            <Button
              style={[styles.CancelButton, { marginLeft: 25 }]}
              onPress={() => {}}
            >
              <Text style={[styles.CancelButtonView, { marginLeft: 25 }]}>
                Cancel
              </Text>
            </Button>
          </View> */}
        </View>

        <View style={{ paddingTop: 40, paddingHorizontal: 40 }}>
          <Text style={{ fontWeight: "normal", textAlign: "center" }}>
            A password reset email has been sent on the registered email
          </Text>
        </View>
      </View>
    </View>
  );
}

export default ResetPassword;
