import React, { Fragment } from "react";
import "./CockPit_css.css";
const Cockpit = (props) => {
  const { outerBracketData, isViewMode } = props;
  return (
    <div class="Cockpit_container">
      <br />
      <div class="row">
        <div class="col-sm-12">
          {outerBracketData.map((outerData, upBracketIndex, outerArr) => {
            let divBotClass = "col-sm-6";
            if (outerData.rounds.length == 1) {
              divBotClass = "col-sm-12";
            }
            return (
              <Fragment>
                {
                  <>
                    {divBotClass === "col-sm-6" && (
                      <div className="Shape_Curve">
                        <div class="shape">
                          <span class="upper-line"></span>
                          <span class="curly-line"></span>
                        </div>
                      </div>
                    )}
                    <div class="row mb-2">
                      {outerData.rounds.map((innerData, roundIndex) => {
                        let value =
                          innerData.selectedValue == "Select"
                            ? "Select"
                            : JSON.stringify(innerData.selectedValue);
                        //let showPlate = !(outerArr.length - 1 == upBracketIndex);
                        let selectedPlayer =
                          (isViewMode
                            ? innerData?.guessData?.winnerName
                            : innerData?.selectedValue?.playerName) || "";
                        return (
                          <div class={divBotClass}>
                            {/* <hr class="hr-line" /> */}
                            <div
                              style={{
                                border: "1px solid black",
                                width: "300px",
                                borderRadius: "10px",
                                padding: "10px",
                              }}
                            >
                              <p class="regionText">{`${innerData.playerData.bracketName} - ${innerData.playerData.bracketNumber}`}</p>
                              {isViewMode ? (
                                <h6 class="headingText">{selectedPlayer}</h6>
                              ) : (
                                <Fragment>
                                  <h6 class="headingText">{selectedPlayer}</h6>
                                  {/* <hr class="hr-line" /> */}
                                  {/* <input type="checkbox" id="hidesf2" />
                              <div id="randomDivsf2">
                                <label for="hidesf2">CHOOSE</label>
                              </div> */}

                                  <div id="">
                                    <div class="row">
                                      <div class="col-sm-3"></div>
                                      <div class="col-sm-6">
                                        <select
                                          style={{
                                            width: "173px",
                                            padding: " 0 10px",
                                            height: "35px",
                                            borderRadius: "10px",
                                            position: "relative",
                                          }}
                                          class="form-control mx-auto d-block "
                                          onChange={(event) => {
                                            let value = event.target.value;
                                            let selectedValue =
                                              value == "Select"
                                                ? "Select"
                                                : JSON.parse(value);
                                            props.onSelectFromUp(
                                              upBracketIndex,
                                              roundIndex,
                                              null,
                                              selectedValue
                                            );
                                          }}
                                          value={value}
                                        >
                                          {innerData.selectionValues.map(
                                            (values, vIndex) => {
                                              if (values == "Select") {
                                                return (
                                                  <option
                                                    value={"Select"}
                                                    className="Drop_option"
                                                    style={{
                                                      position: "absolute",
                                                    }}
                                                  >
                                                    {"Select"}
                                                  </option>
                                                );
                                              }
                                              if (
                                                Object.keys(values).length == 0
                                              ) {
                                                return null;
                                              }
                                              return (
                                                <option
                                                  className="Drop_option"
                                                  value={JSON.stringify(values)}
                                                >
                                                  {values.playerName}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                      <div class="col-sm-3"></div>
                                    </div>
                                  </div>
                                </Fragment>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                }
                <br />
                <br />
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Cockpit;

// import React, { Fragment } from "react";

// const Cockpit = (props) => {
//   const { outerBracketData } = props;
//   return (
//     <div class="container">
//       <br />
//       <div class="row">
//         <div class="col-sm-12">
//           {outerBracketData.map((outerData, outerIndex, outerArr) => {
//             let divBotClass = "col-sm-6";
//             if (outerData.rounds.length == 1) {
//               divBotClass = "col-sm-12";
//             }
//             return (
//               <Fragment>
//                 {
//                   <div class="row mb-2">
//                     {outerData.rounds.map((innerData, innerIndex) => {
//                       let value =
//                         innerData.selectedValue == "Select"
//                           ? "Select"
//                           : JSON.stringify(innerData.selectedValue);
//                       let showPlate = !(outerArr.length - 1 == outerIndex);
//                       let selectedPlayer =
//                         innerData?.selectedValue?.playerName || "";
//                       return (
//                         <div class={divBotClass}>
//                           <hr class="hr-line" />
//                           <p class="regionText">{`Bracket ${
//                             outerIndex + 1
//                           }`}</p>
//                           <h6 class="headingText">{selectedPlayer}</h6>

//                           {/* {showPlate && ( */}
//                             <Fragment>
//                               <hr class="hr-line" />
//                               {/* <input type="checkbox" id="hidesf2" />
//                               <div id="randomDivsf2">
//                                 <label for="hidesf2">CHOOSE</label>
//                               </div> */}

//                               <div id="">
//                                 <div class="row">
//                                   <div class="col-sm-3"></div>
//                                   <div class="col-sm-6">
//                                     <select
//                                       class="form-control mx-auto d-block "
//                                       onChange={(event) => {
//                                         let value = event.target.value;
//                                         let selectedValue =
//                                           value == "Select"
//                                             ? "Select"
//                                             : JSON.parse(value);
//                                         // onSelectOptionsFromRound(
//                                         //   oIndex,
//                                         //   selectedValue
//                                         // );
//                                       }}
//                                       value={value}
//                                     >
//                                       {innerData.selectionValues.map(
//                                         (values, vIndex) => {
//                                           if (values == "Select") {
//                                             return (
//                                               <option value={"Select"}>
//                                                 {"Select"}
//                                               </option>
//                                             );
//                                           }
//                                           if (Object.keys(values).length == 0) {
//                                             return null;
//                                           }
//                                           return (
//                                             <option
//                                               value={JSON.stringify(values)}
//                                             >
//                                               {values.playerName}
//                                             </option>
//                                           );
//                                         }
//                                       )}
//                                     </select>
//                                   </div>
//                                   <div class="col-sm-3"></div>
//                                 </div>
//                               </div>
//                             </Fragment>
//                           {/* )} */}
//                         </div>
//                       );
//                     })}
//                   </div>
//                 }
//                 <br />
//                 <br />
//               </Fragment>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Cockpit;
