import React, { createRef } from "react";
import {
  StatusBar,
  View,
  Text,
  Image,
  TouchableOpacity,
  Dimensions,
  FlatList,
  ScrollView,
} from "react-native";

import { Root } from "../../../Component";

import WebVideo from "../../../Component/WebVideo";

// import moment from 'moment';
import { format } from "date-fns";

import { firebase, firebaseAuth, contestsCollection } from "../../../firebase";
import "firebase/firestore";

import ArrowUpImage from "../../../assets/ArrowUp.png";
import ArrowDownImage from "../../../assets/ArrowDown.png";
import BetImage from "../../../assets/Bet.png";

import Entypo from "react-native-vector-icons/Entypo";
import { ScoringType } from "../playUtils";

const { width } = Dimensions.get("window");

Entypo.loadFont();

export default class CompletedGameView extends React.Component {
  loadUserProfiles = (game) => {
    firebase
      .firestore()
      .collection("users")
      .where(firebase.firestore.FieldPath.documentId(), "in", [
        game.player1ID,
        game.player2ID,
      ])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.id === game.player1ID) {
            this.setState({ player1Profile: doc.data() });
          } else {
            this.setState({ player2Profile: doc.data() });
          }
        });
      });
  };

  loadEventDetails = (eventID) => {
    firebase
      .firestore()
      .collection("events")
      .where("eventID", "==", eventID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((documentSnapshot) => {
          let event = documentSnapshot.data();
          this.setState({ event: event });
          return;
        });
      })
      .catch((error) => {});
  };

  loadGameScores = (gameID) => {
    firebase
      .firestore()
      .collection("gameScores")
      .where("gameID", "==", gameID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((documentSnapshot) => {
          let scores = documentSnapshot.data().scores;
          this.setState({ gamePlayingData: documentSnapshot.data() });
          if (scores !== undefined) {
            this.setState({ scores: scores });
          }
          return;
        });
      })
      .catch((error) => {});
  };

  splitIdsArray = (ids) => {
    if (ids.length > 0) {
      let splittedIds = [];
      let seeker = 0;
      while (seeker < ids.length) {
        let elementSplitIds = [];
        for (let idx = 0; idx < 10; idx++) {
          if (seeker + idx < ids.length) {
            elementSplitIds.push(ids[seeker + idx]);
          }
        }

        splittedIds.push(elementSplitIds);

        seeker = seeker + 10;
      }
      return splittedIds;
    } else {
      return [];
    }
  };

  loadChallengeUsers = async (userIds) => {
    let userSplittedIdsArray = this.splitIdsArray(userIds);
    let users = [];
    for (let idx = 0; idx < userSplittedIdsArray.length; idx++) {
      const querySnapshot = await firebase
        .firestore()
        .collection("users")
        .where("uid", "in", userSplittedIdsArray[idx])
        .get();

      querySnapshot.forEach((documentSnapshot) => {
        let user = documentSnapshot.data();
        users = [...users, user];
      });
    }

    this.setState({ challengeUsers: [...this.state.challengeUsers, ...users] });
  };

  findChallengeUserName = (userId) => {
    let user = this.state.challengeUsers.find((user) => user.uid == userId);
    if (user !== undefined) {
      return user.userName;
    } else {
      console.log("unknown user id - ", userId);
      return "???";
    }
  };

  loadGameChallenges = (gameScheduleId) => {
    firebase
      .firestore()
      .collection("challenges")
      .where("gameScheduleId", "==", gameScheduleId)
      .where("users", "array-contains", this.state.me.uid)
      .get()
      .then(async (snapshot) => {
        let challenges = [];
        let userIds = [];
        for (const doc of snapshot.docs) {
          let challenge = doc.data();

          if (
            challenge.status !== "expired" &&
            challenge.status !== "pending"
          ) {
            if (challenge.status !== "accepted") {
              console.log("Challenge not processed, yet");
            }

            if (challenge.challengeSenderId === this.state.me.uid) {
              if (challenge.opponent !== "all") {
                if (
                  !userIds.includes((userId) => userId == challenge.opponent)
                ) {
                  userIds = [...userIds, challenge.opponent];
                }
              }
            } else {
              if (
                !userIds.includes(
                  (userId) => userId == challenge.challengeSenderId
                )
              ) {
                userIds = [...userIds, challenge.challengeSenderId];
              }
            }
            challenges = [...challenges, challenge];
          }
        }
        await this.loadChallengeUsers(userIds);
        this.setState({ challenges: challenges });
      });
  };

  challengeSenderPointText = (challenge) => {
    if (challenge.gameRealWinnerId === challenge.challengeGameWinnerId) {
      return "+" + challenge.points;
    } else {
      return "-" + challenge.points;
    }
  };

  challengeReceiverPointText = (challenge) => {
    if (challenge.gameRealWinnerId === challenge.challengeGameWinnerId) {
      return "-" + challenge.points;
    } else {
      return "+" + challenge.points;
    }
  };

  challengeSenderName = (challenge) => {
    if (challenge.challengeSenderId === this.state.me.uid) {
      return this.state.me.email.substring(
        0,
        this.state.me.email.lastIndexOf("@")
      );
    } else {
      return this.findChallengeUserName(challenge.challengeSenderId);
    }
  };

  challengeReceiverName = (challenge) => {
    if (challenge.challengeSenderId === this.state.me.uid) {
      return this.findChallengeUserName(challenge.opponent);
    } else {
      return this.state.me.email.substring(
        0,
        this.state.me.email.lastIndexOf("@")
      );
    }
  };

  collapseRounds = () => {
    if (this.state.roundCollapsed) {
      this.setState({ roundCollapsed: false });
    } else {
      this.setState({ roundCollapsed: true });
    }
  };

  challengeTotalPointsIGot = () => {
    let totalPoints = 0;
    this.state.challenges.forEach((challenge) => {
      if (challenge.status !== "pending") {
        if (challenge.gameRealWinnerId === challenge.challengeGameWinnerId) {
          if (challenge.challengeSenderId === this.state.me.uid) {
            totalPoints += challenge.points;
          } else {
            totalPoints -= challenge.points;
          }
        } else {
          if (challenge.challengeSenderId === this.state.me.uid) {
            totalPoints -= challenge.points;
          } else {
            totalPoints += challenge.points;
          }
        }
      }
    });
    return totalPoints;
  };

  calculateVideoDuration = (durationInSecond) => {
    if (durationInSecond < 60) {
      return `${durationInSecond}s`;
    } else {
      // const secondsInInteger = parseInt(durationInSecond);
      return `${parseInt(durationInSecond / 60)}:${(durationInSecond % 60)
        .toString()
        .padStart(2, "0")}`;
    }
  };

  getProfileName = (userId) => {
    if (this.state.gameData.player1ID === userId) {
      if (this.state.player1Profile !== undefined) {
        return this.state.player1Profile.userNickname;
      } else {
        return "???";
      }
    } else if (this.state.gameData.player2ID === userId) {
      if (this.state.player2Profile !== undefined) {
        return this.state.player2Profile.userNickname;
      } else {
        return "???";
      }
    } else {
      return "???";
    }
  };

  audioCallback = () => {};

  renderVideoThumbnail = (video) => {
    console.log("Rendering room video => ", video);

    return (
      <View
        style={{
          backgroundColor: "black",
          flex: 1,
          borderRadius: 10,
          height: 300,
          margin: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {video.compositionStatus === "composition-available" ? (
          <WebVideo
            source={video.videoLink}
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <Text style={{ color: "white" }}>
            Video is being processed.({video.compositionProgress}%)
          </Text>
        )}
        {/* <View style={{backgroundColor: 'rgba(0, 0, 0, 0.2)', height: 50, alignItems: 'center', position: 'absolute', top: 0, width: '100%'}}>
          <Text style={{color: 'white'}}> from {format(new Date(video.startTime.seconds), 'h:mm a, MMM d')} to {format(new Date(video.endTime.seconds), 'h:mm a, MMM d')}</Text>
        </View> */}
      </View>
    );
  };

  renderCompletedChallenge = (challenge) => {
    return (
      <View
        style={{
          flexDirection: "column",
          padding: 15,
          marginTop: 10,
          marginLeft: 15,
          marginRight: 15,
          borderRadius: 5,
          borderWidth: 0,
          borderBottomWidth: 1,
          borderColor: "#fff2",
        }}
      >
        <View
          style={{
            height: 40,
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text style={{ textAlign: "center", fontWeight: "bold", flex: 1 }}>
            {challenge.challengeGameWinnerId === this.state.gameData.player1ID
              ? this.state.gameData.player1Name
              : this.state.gameData.player2Name}{" "}
            Win
          </Text>
          <View style={{ flexDirection: "column", flex: 2, marginLeft: 20 }}>
            <View
              style={{ flexDirection: "row", height: 30, alignItems: "center" }}
            >
              <Image
                source={BetImage}
                tintColor={"black"}
                style={{ tintColor: "black" }}
              />
              <Text style={{ marginLeft: 10, fontWeight: "bold" }}>
                {this.challengeSenderPointText(challenge)}
              </Text>
              <Text style={{ marginLeft: 10, flex: 1 }}>
                {this.challengeSenderName(challenge)}
              </Text>
            </View>
            <View
              style={{ flexDirection: "row", height: 30, alignItems: "center" }}
            >
              <Image
                source={BetImage}
                tintColor={"black"}
                style={{ tintColor: "black" }}
              />
              <Text style={{ marginLeft: 10, fontWeight: "bold" }}>
                {this.challengeReceiverPointText(challenge)}
              </Text>
              <Text style={{ marginLeft: 10, flex: 1 }}>
                {this.challengeReceiverName(challenge)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  snapshotTwilioRoomRecordingsList = (gameScheduleId) => {
    if (this.unsubscribeRef?.current) {
      console.log("unsubscribed snapshot in PlayerView.");
      this.unsubscribeRef?.current();
    }

    this.unsubscribeRef.current = firebase
      .firestore()
      .collection("gameSchedule")
      .doc(gameScheduleId)
      .collection("twilioRooms")
      .onSnapshot(async (twilioRooms) => {
        let rooms = [];
        twilioRooms.forEach((doc) => {
          rooms = [...rooms, doc.data()];
        });

        const videos = await fetch(
          `https://us-central1-players-recreation-league.cloudfunctions.net/fetchGameRecordingVideos?gameScheduleId=${gameScheduleId}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );

        const responseJSON = await videos.json();
        console.log("video links json response => ", responseJSON);

        const roomVideos = this.combineRoomsAndVideoLinks(rooms, responseJSON);
        console.log("Room Videos => ", roomVideos);
        this.setState({ roomVideos: roomVideos, recordingsLoaded: true });
      });
  };

  loadTwilioRoomIds = async (gameScheduleId) => {
    console.log("loading twilio rooms");

    const twilioRooms = await firebase
      .firestore()
      .collection("gameSchedule")
      .doc(gameScheduleId)
      .collection("twilioRooms")
      .get();

    let rooms = [];
    twilioRooms.forEach((doc) => {
      rooms = [...rooms, doc.data()];
    });

    if (rooms.length === 0) {
      this.setState({
        roomVideos: [],
        recordingsLoaded: true,
        noRecordings: true,
      });
    } else {
      const gameScheduleDoc = await firebase
        .firestore()
        .collection("gameSchedule")
        .doc(gameScheduleId)
        .get();

      if (true === gameScheduleDoc.data().processedVideosRecording) {
        // const videos = await fetch(`https://us-central1-players-recreation-league.cloudfunctions.net/fetchGameRecordingVideos?gameScheduleId=${gameScheduleId}`, {
        //   method: 'GET',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        // });
        // const responseJSON = await videos.json();
        // console.log("video links json response => ", responseJSON);
        // const roomVideos = this.combineRoomsAndVideoLinks(rooms, responseJSON);
        // console.log("Room Videos => ", roomVideos);
        // this.setState({ roomVideos: roomVideos, recordingsLoaded: true });
      } else {
        console.log("Now we need to do a composition action");
        await fetch(
          `https://us-central1-players-recreation-league.cloudfunctions.net/createCompositionsForGames?gameScheduleId=${gameScheduleId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            mode: "no-cors",
          }
        );
        this.setState({ roomVideos: [], recordingsLoaded: true });
      }

      this.snapshotTwilioRoomRecordingsList(gameScheduleId);
    }
  };

  combineRoomsAndVideoLinks = (rooms, videoLinks) => {
    console.log("Combining rooms and videoLinks : ", rooms, videoLinks);
    let mediaContainingRooms = rooms.filter((item) => {
      return (
        item.compositionSid !== undefined &&
        !(
          item.compositionProgress === "100" &&
          item.compositionStatus === "composition-progress"
        )
      );
    });
    return mediaContainingRooms.map((room) => {
      const obj = videoLinks.find(
        (videoLink) => videoLink.roomSid === room.roomSid
      );
      if (obj !== undefined) {
        return { ...room, videoLink: obj.uri };
      } else {
        return room;
      }
    });
  };
  getAllContest = (id) => {
    firebase
      .firestore()
      .collection("contests")
      .where("contestID", "==", id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((documentSnapshot) => {
          let contestDetail = documentSnapshot.data();
          this.setState({ contestDetails: contestDetail });
          console.log("Contest detail --> ", contestDetail);
          return;
        });
      })
      .catch((error) => {});
  };

  constructor(props) {
    super(props);

    let game = props.route.params.game;
    this.state = {
      gameData: game,
      event: {},
      scores: {},
      player1Profile: {},
      player2Profile: {},
      roundCollapsed: false,
      challengesCollapsed: true,
      recordingsCollapsed: true,
      recordingsLoaded: false,
      challengeUsers: [],
      challenges: [],
      me: null,
      roomVideos: [],
      gamePlayingData: {},
      noRecordings: false,
      contestDetails: {},
    };

    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ me: user }, () => {
          this.loadEventDetails(game.eventID);
          this.loadGameScores(props.route.params.game.gameID);
          this.loadUserProfiles(game);
          this.loadGameChallenges(game.gameScheduleId);
          this.loadTwilioRoomIds(game.gameScheduleId);
          this.getAllContest(game.contestID);
        });
      }
    });

    this.unsubscribeRef = createRef();
  }

  componentWillUnmount() {
    if (this.unsubscribeRef?.current) {
      console.log("unsubscribed snapshot in PlayerView componentWillUnmount.");
      this.unsubscribeRef?.current();
    }
  }

  renderRoundsLoops = () => {
    var roundsLoop = [];

    let totalRounds = this.state.gameData.gameTotalRounds;

    if (this.state.gamePlayingData.overtimeRounds !== undefined) {
      totalRounds += this.state.gamePlayingData.overtimeRounds;
    }
    for (let i = 0; i < totalRounds; i++) {
      roundsLoop.push(
        <View style={{ flexDirection: "row", marginTop: 10 }}>
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <Text
              style={{
                width: "100%",
                color:
                  i >= this.state.gameData.gameTotalRounds ? "red" : "black",
                fontWeight: "bold",
                fontSize: 16,
                textAlign: "center",
                marginTop: 10,
              }}
            >
              {this.state.scores.hasOwnProperty(this.state.gameData.player1ID)
                ? this.state.scores[
                    this.state.gameData.player1ID
                  ].hasOwnProperty(i)
                  ? this.state.scores[this.state.gameData.player1ID][i]
                  : 0
                : 0}
            </Text>
          </View>
          <View style={{ flex: 1.5, justifyContent: "center" }}>
            <Text
              style={{
                width: "100%",
                color:
                  i >= this.state.gameData.gameTotalRounds ? "red" : "black",
                fontSize: 14,
                textAlign: "center",
                marginTop: 10,
              }}
            >
              Round {i + 1}
            </Text>
          </View>
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <Text
              style={{
                width: "100%",
                color:
                  i >= this.state.gameData.gameTotalRounds ? "red" : "black",
                fontWeight: "bold",
                fontSize: 16,
                textAlign: "center",
                marginTop: 10,
              }}
            >
              {this.state.scores.hasOwnProperty(this.state.gameData.player2ID)
                ? this.state.scores[
                    this.state.gameData.player2ID
                  ].hasOwnProperty(i)
                  ? this.state.scores[this.state.gameData.player2ID][i]
                  : 0
                : 0}
            </Text>
          </View>
        </View>
      );
    }

    return roundsLoop;
  };

  render() {
    StatusBar.setBarStyle("light-content", true);

    const { navigation } = this.props;

    return (
      <Root>
        <ScrollView
          style={{ width: "100%", height: "99%", backgroundColor: "white" }}
        >
          <View
            style={{
              width: "100%",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: 1,
                backgroundColor: "white",
              }}
            />

            <View
              style={{
                width: "100%",
                flexDirection: "column",
              }}
            >
              <View
                style={{ height: 40, justifyContent: "center", padding: 10 }}
              >
                <TouchableOpacity
                  style={{
                    width: 40,
                    height: 40,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onPress={() => {
                    navigation.goBack();
                  }}
                >
                  <Text>Back</Text>
                </TouchableOpacity>
              </View>

              <ScrollView style={{ flex: 1 }}>
                <Text
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: 28,
                    textAlign: "center",
                  }}
                >
                  {this.state.event.eventName}
                </Text>

                <Text
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: 20,
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  {this.state.gameData.contestName}
                </Text>

                <Text
                  style={{
                    width: "100%",
                    fontSize: 16,
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  {this.state.gameData.gameDescription}
                </Text>

                <Text
                  style={{
                    width: "100%",
                    fontSize: 16,
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  Scoring:{" "}
                  {this.state.gameData.scoringType === undefined
                    ? ScoringType.Normal
                    : this.state.gameData.scoringType}
                </Text>

                <View
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                ></View>

                <Text
                  style={{
                    width: "100%",
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.gameData.winnerName}
                </Text>

                <View
                  style={{ flex: 1, flexDirection: "column", marginTop: 0 }}
                >
                  <View style={{ flexDirection: "row", height: 100 }}>
                    <TouchableOpacity
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onPress={() => {
                        navigation.navigate("EventInfoStack", {
                          screen: "PlayerProfileScreen",
                          params: {
                            ...{
                              userID: this.state.gameData.player1ID,
                              eventID: this.state.gameData.eventID,
                            },
                          },
                        });
                      }}
                    >
                      <Image
                        style={{
                          width: 80,
                          height: 80,
                          backgroundColor: "rgba(0, 0, 0, 0.2)",
                          borderRadius: 40,
                        }}
                        source={{ uri: this.state.player1Profile.userAvatar }}
                      />
                    </TouchableOpacity>
                    <View style={{ flex: 1.5, justifyContent: "center" }}>
                      <Text
                        style={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: 24,
                          position: "absolute",
                        }}
                      >
                        {this.state.gameData.player1Score}
                      </Text>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: 24,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {this.state.gameData.player2Score}
                      </Text>
                    </View>
                    <TouchableOpacity
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onPress={() => {
                        navigation.navigate("EventInfoStack", {
                          screen: "PlayerProfileScreen",
                          params: {
                            ...{
                              userID: this.state.gameData.player2ID,
                              eventID: this.state.gameData.eventID,
                            },
                          },
                        });
                      }}
                    >
                      <Image
                        style={{
                          width: 80,
                          height: 80,
                          backgroundColor: "rgba(0, 0, 0, 0.2)",
                          borderRadius: 40,
                        }}
                        source={{ uri: this.state.player2Profile.userAvatar }}
                      />
                    </TouchableOpacity>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          fontSize: 14,
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        {this.state.gameData.player1Name}
                      </Text>
                    </View>
                    <TouchableOpacity
                      style={{ flex: 1.5, justifyContent: "center" }}
                      onPress={() => {
                        this.collapseRounds();
                      }}
                    >
                      <Entypo
                        name={
                          this.state.roundCollapsed
                            ? "chevron-up"
                            : "chevron-down"
                        }
                        style={{ alignSelf: "center" }}
                        size={24}
                      />
                      {/* <Image style={{ alignSelf: 'center', width: 22, height: 14 }}
                        source={this.state.roundCollapsed ? ArrowUpImage : ArrowDownImage} /> */}
                    </TouchableOpacity>
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          fontSize: 14,
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        {this.state.gameData.player2Name}
                      </Text>
                    </View>
                  </View>
                  {this.state.roundCollapsed === true && (
                    <>
                      {this.renderRoundsLoops()}
                      <View
                        style={{
                          height: 1,
                          backgroundColor: "rgba(0, 0, 0, 0.2)",
                          marginTop: 15,
                        }}
                      />
                    </>
                  )}
                  <View style={{ flexDirection: "row", marginTop: 10 }}>
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          fontSize: 22,
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        {this.state.gameData.player1Score}
                      </Text>
                    </View>
                    <View style={{ flex: 1.5, justifyContent: "center" }}>
                      <Text
                        style={{
                          width: "100%",
                          fontSize: 18,
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        Total
                      </Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          fontSize: 22,
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        {this.state.gameData.player2Score}
                      </Text>
                    </View>
                  </View>
                </View>

                {this.state.contestDetails?.pickGuessingOn && (
                  <View style={{ marginTop: 15, marginBottom: 5 }}>
                    <View
                      style={{
                        backgroundColor: "rgba(0,0,0,0.1)",
                        padding: 10,
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{ fontSize: 16, fontWeight: "bold", flex: 1 }}
                      >
                        Picks
                      </Text>
                      {this.state.challenges.length > 0 && (
                        <TouchableOpacity
                          onPress={() => {
                            this.setState({
                              challengesCollapsed:
                                !this.state.challengesCollapsed,
                            });
                          }}
                        >
                          <Image
                            style={{ width: 22, height: 14 }}
                            source={
                              this.state.challengesCollapsed
                                ? ArrowUpImage
                                : ArrowDownImage
                            }
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                    {this.state.challenges.length === 0 ? (
                      <Text
                        style={{
                          fontSize: 12,
                          marginTop: 20,
                          textAlign: "center",
                        }}
                      >
                        No picks in the game.
                      </Text>
                    ) : (
                      <>
                        {this.state.challengesCollapsed && (
                          <FlatList
                            data={this.state.challenges}
                            renderItem={(item) =>
                              this.renderCompletedChallenge(item.item)
                            }
                            keyExtractor={(item) => item.id}
                          />
                        )}
                        <View style={{ padding: 15, flexDirection: "row" }}>
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              flex: 1,
                            }}
                          >
                            My Net Points Change for this game:
                          </Text>
                          <Text style={{ fontSize: 24, fontWeight: "bold" }}>
                            {this.challengeTotalPointsIGot()}pt
                          </Text>
                        </View>
                      </>
                    )}
                  </View>
                )}

                <View style={{ marginTop: 5, marginBottom: 20 }}>
                  <View
                    style={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                      padding: 10,
                      flexDirection: "row",
                    }}
                  >
                    <Text style={{ fontSize: 16, fontWeight: "bold", flex: 1 }}>
                      Recordings
                    </Text>
                    {this.state.roomVideos !== undefined &&
                      this.state.roomVideos.length > 0 && (
                        <TouchableOpacity
                          onPress={() => {
                            this.setState({
                              recordingsCollapsed:
                                !this.state.recordingsCollapsed,
                            });
                          }}
                        >
                          <Image
                            style={{ width: 22, height: 14 }}
                            source={
                              this.state.challengesCollapsed
                                ? ArrowUpImage
                                : ArrowDownImage
                            }
                          />
                        </TouchableOpacity>
                      )}
                  </View>
                  {this.state.roomVideos === undefined ||
                  this.state.roomVideos.length === 0 ? (
                    <Text
                      style={{
                        fontSize: 12,
                        marginTop: 20,
                        textAlign: "center",
                      }}
                    >
                      {!this.state.recordingsLoaded
                        ? "Loading recordings..."
                        : this.state.noRecordings
                        ? "There is no recording."
                        : "No recordings, or still in progress. Sometimes, it takes around several minutes to process recordings."}
                    </Text>
                  ) : (
                    <>
                      {this.state.recordingsCollapsed && (
                        <FlatList
                          style={{ margin: 10 }}
                          data={this.state.roomVideos}
                          numColumns={2}
                          renderItem={(item) =>
                            this.renderVideoThumbnail(item.item)
                          }
                          keyExtractor={(item) => item.roomSid}
                        />
                      )}
                    </>
                  )}
                </View>
              </ScrollView>
            </View>
          </View>
        </ScrollView>
      </Root>
    );
  }
}
