import React, { useEffect, useState } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import Header from "./components/header";
import PHONETALK from "./PHONETALK.png";
import EMAIL from "./EMAIL.png";
import PIN from "./PIN.png";
import Spinner from "react-native-loading-spinner-overlay";
import {
  WebViewModal,
  Root,
  TextInput,
  TouchableButton,
} from "../../../Component";
import { prlAboutTermsPrivacyCollection } from "../../../firebase";
import { transformFirebaseValues } from "../../../utils";
import { toggleAlertBox } from "../../../store/actions";
import { useSelector, connect, useDispatch } from "react-redux";
import { FirebaseEmail } from "../../../app/classes";

function AboutUs(props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const aboutUsData = useSelector((state) => state.appInfoData.appInfoData);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTC, setShowTC] = useState(false);
  const { showFeedback = true } = props;
  const { auth } = useSelector((s) => s);
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackProcess, setFeedBackProcess] = useState(false);

  const showPopup = ({ text = "", onPressOk = () => {} }) => {
    return dispatch(
      toggleAlertBox({
        visible: true,
        text: text,
        onPress: () => {
          dispatch(toggleAlertBox("CLOSE"));
          onPressOk();
        },
      })
    );
  };

  const saveFeedback = async () => {
    setFeedBackProcess(true);
    setTimeout(async () => {
      const feedbackResponse = await FirebaseEmail.sendMail(
        FirebaseEmail.feedBack({
          feedbackText: feedbackText,
          ccUids: auth.userId,
        })
      );
      
      setTimeout(() => {
        setFeedbackText("");
        setFeedBackProcess(false);
        return showPopup({text: "Your message was sent.\nThanks for the Feedback"});
      }, 1000);
    }, 400);
  };

  return (
    <>
      {props.hideTray ? (
        <View
          style={[{ flex: 1 }, props?.hideTray && { backgroundColor: "white" }]}
        >
          {/* <Spinner visible={loading} /> */}
          <WebViewModal
            modalVisible={showPrivacy}
            onClose={() => setShowPrivacy((i) => !i)}
            html={aboutUsData?.htmlPrivacyPolicy || ""}
          />
          <WebViewModal
            modalVisible={showTC}
            onClose={() => setShowTC((i) => !i)}
            html={aboutUsData?.htmlTermsOfUse || ""}
          />
          <Header
            renderMenu={false}
            onMenuPress={() => props.navigation.openDrawer()}
          />
          <View>
            <View style={{ padding: 15 }}>
              <Text
                style={{ fontSize: 16, color: "black", fontWeight: "bold" }}
              >
                What we Do
              </Text>
              <Text style={{ padding: 5, textAlign: "left" }}>
                {aboutUsData?.whatWeDo || ""}
              </Text>
            </View>

            <View style={{ padding: 15 }}>
              <Text
                style={{ fontSize: 16, color: "black", fontWeight: "bold" }}
              >
                Our Mission Statement
              </Text>
              <Text style={{ padding: 5, textAlign: "left" }}>
                {aboutUsData?.mission || ""}
              </Text>
            </View>
          </View>

          <View style={{ padding: 15 }}>
            <Text style={{ fontSize: 16, color: "black", fontWeight: "bold" }}>
              Contact Support
            </Text>

            <View
              style={{
                flexDirection: "row",
                paddingLeft: 20,
                paddingTop: 20,
                alignItems: "center",
              }}
            >
              <Image
                source={PHONETALK}
                height={20}
                style={{ marginRight: 20 }}
              />
              <Text
                style={{ fontSize: 13, color: "black", fontWeight: "bold" }}
              >
                {aboutUsData?.phone || ""}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                paddingLeft: 20,
                paddingTop: 12,
                alignItems: "center",
              }}
            >
              <Image source={EMAIL} height={20} style={{ marginRight: 20 }} />
              <Text
                style={{ fontSize: 13, color: "black", fontWeight: "bold" }}
              >
                {aboutUsData?.email || ""}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                paddingLeft: 20,
                paddingTop: 12,
                alignItems: "center",
              }}
            >
              <Image source={PIN} height={20} style={{ marginRight: 20 }} />
              <Text
                style={{ fontSize: 13, color: "black", fontWeight: "bold" }}
              >
                {aboutUsData?.website || ""}
              </Text>
            </View>
          </View>

          {!props?.hideTray && (
            <View
              style={{
                flexDirection: "row",
                position: "absolute",
                bottom: 40,
                alignSelf: "center",
                justifyContent: "space-around",
              }}
            >
              <View style={{ paddingRight: 5 }}>
                <TouchableOpacity
                  onPress={() => {
                    setShowPrivacy((i) => !i);
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#0B214D",
                      fontWeight: "bold",
                    }}
                  >
                    Privacy Policy
                  </Text>
                </TouchableOpacity>
              </View>
              {/* &nbsp; */}
              <View style={{ paddingLeft: 15 }}>
                <TouchableOpacity
                  onPress={() => {
                    setShowTC((i) => !i);
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#0B214D",
                      fontWeight: "bold",
                    }}
                  >
                    Terms and Condition's
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          )}

          <View
            style={{ position: "absolute", bottom: 10, alignSelf: "center" }}
          >
            <Text style={{ fontSize: 13, color: "black", fontWeight: "bold" }}>
              Founded 2020
            </Text>
          </View>
        </View>
      ) : (
        <Root>
          <ScrollView
            style={[
              { height: "100%", width: "70%" },
              props?.hideTray && { backgroundColor: "white" },
            ]}
          >
            {/* <Spinner visible={loading} /> */}
            <WebViewModal
              modalVisible={showPrivacy}
              onClose={() => setShowPrivacy((i) => !i)}
              html={aboutUsData?.htmlPrivacyPolicy || ""}
            />
            <WebViewModal
              modalVisible={showTC}
              onClose={() => setShowTC((i) => !i)}
              html={aboutUsData?.htmlTermsOfUse || ""}
            />
            <Header
              renderMenu={false}
              onMenuPress={() => props.navigation.openDrawer()}
            />
            <View>
              <View style={{ padding: 15 }}>
                <Text
                  style={{ fontSize: 16, color: "black", fontWeight: "bold" }}
                >
                  What we Do
                </Text>
                <Text style={{ padding: 5, textAlign: "left" }}>
                  {aboutUsData?.whatWeDo || ""}
                </Text>
              </View>

              <View style={{ padding: 15 }}>
                <Text
                  style={{ fontSize: 16, color: "black", fontWeight: "bold" }}
                >
                  Our Mission Statement
                </Text>
                <Text style={{ padding: 5, textAlign: "left" }}>
                  {aboutUsData?.mission || ""}
                </Text>
              </View>
            </View>

            <View style={{ padding: 15 }}>
              <Text
                style={{ fontSize: 16, color: "black", fontWeight: "bold" }}
              >
                Contact Support
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  paddingLeft: 20,
                  paddingTop: 20,
                  alignItems: "center",
                }}
              >
                <Image
                  source={PHONETALK}
                  height={20}
                  style={{ marginRight: 20 }}
                />
                <Text
                  style={{ fontSize: 13, color: "black", fontWeight: "bold" }}
                >
                  {aboutUsData?.phone || ""}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  paddingLeft: 20,
                  paddingTop: 12,
                  alignItems: "center",
                }}
              >
                <Image source={EMAIL} height={20} style={{ marginRight: 20 }} />
                <Text
                  style={{ fontSize: 13, color: "black", fontWeight: "bold" }}
                >
                  {aboutUsData?.email || ""}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  paddingLeft: 20,
                  paddingTop: 12,
                  alignItems: "center",
                }}
              >
                <Image source={PIN} height={20} style={{ marginRight: 20 }} />
                <Text
                  style={{ fontSize: 13, color: "black", fontWeight: "bold" }}
                >
                  {aboutUsData?.website || ""}
                </Text>
              </View>
            </View>

            {showFeedback && (
              <View style={{ paddingHorizontal: 18 }}>
                <Text
                  style={{ fontSize: 16, color: "black", fontWeight: "bold" }}
                >
                  Feedback
                </Text>
                <TextInput
                  editable={!feedbackProcess}
                  containerStyle={{ padding: 15, marginTop: 10, height: 100 }}
                  inputStyle={{
                    height: 90,
                    paddingHorizontal: 5,
                    fontSize: 14,
                  }}
                  multiline={true}
                  placeholder={"Share your feedback"}
                  value={feedbackText}
                  onChangeText={setFeedbackText}
                />

                {feedbackProcess ? (
                  <View style={{ marginTop: 1 }}>
                    {/* <NSpinner color={"#0B214D"} size={"large"} /> */}
                  </View>
                ) : (
                  <TouchableButton
                    containerStyle={{ marginTop: 10, alignSelf: "center" }}
                    type={"nextStep"}
                    title={"Save"}
                    propButtonStyle={{ width: 120 }}
                    onPress={saveFeedback}
                    titleStyle={{ fontSize: 16 }}
                    backgroundColor="#0B214D"
                  />
                )}
              </View>
            )}

            {!props?.hideTray && (
              <View
                style={{
                  flexDirection: "row",
                  // position: "absolute",
                  // bottom: 0,
                  marginTop: 40,
                  alignSelf: "center",
                  justifyContent: "space-around",
                }}
              >
                <View style={{ paddingRight: 5, marginTop: 10 }}>
                  <TouchableOpacity
                    onPress={() => {
                      setShowPrivacy((i) => !i);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 13,
                        color: "#0B214D",
                        fontWeight: "bold",
                      }}
                    >
                      Privacy Policy
                    </Text>
                  </TouchableOpacity>
                </View>
                {/* &nbsp; */}
                <View style={{ paddingLeft: 15, marginTop: 10 }}>
                  <TouchableOpacity
                    onPress={() => {
                      setShowTC((i) => !i);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 13,
                        color: "#0B214D",
                        fontWeight: "bold",
                      }}
                    >
                      Terms and Condition's
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}

            <View
              style={{ position: "absolute", bottom: 10, alignSelf: "center" }}
            >
              <Text
                style={{ fontSize: 13, color: "black", fontWeight: "bold" }}
              >
                Founded 2020
              </Text>
            </View>
          </ScrollView>
        </Root>
      )}
    </>
  );
}

export default connect()(AboutUs);
