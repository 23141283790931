import React, { useState, useEffect } from "react";
import {
  ScrollView,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Back, Filter, Menu, RightArrow } from "../../../icon";
import { bigHitSlop } from "../../../utils";
import { PlayerItem } from "./component";
import PlayerListModel from "./PlayerList.model";
import { SingleHeading, Root } from "../../../Component";
import { useLoader } from "../../../hooks";
import {
  eventsCollection,
  userEnteredContestsCollection,
  usersCollection,
  contestsCollection,
} from "../../../firebase";
import PlayerFilterSidebar from "./filterSidebar";
import { useSelector } from "react-redux";

const Player = (props) => {
  const [setLoader, LoaderComponent] = useLoader();
  const [showFilter, setShowfilter] = useState(false);
  const [playerModel, setPlayerModel] = useState(() => PlayerListModel);
  const { firebaseAllCollectionData } = useSelector((s) => s);

  let allEvents = [];
  let event = {};
  const fetchData = async () => {
    allEvents = [
      ...firebaseAllCollectionData.firebaseCollectionData.eventsData,
    ];
    firebaseAllCollectionData.firebaseCollectionData.eventsData.map((e) => {
      if (e.eventID == props.route.params.eventId) {
        let ED = { ...e };
        event = ED;
      }
    });
    const userEnteredContestColRes = await userEnteredContestsCollection.get();
    const userColRes = await usersCollection.get();
    const contestColRes = await contestsCollection.get();
    setPlayerModel(
      playerModel.loadContent(
        allEvents,
        userEnteredContestColRes,
        userColRes,
        event,
        contestColRes,
        event
      )
    );
    setLoader(false);
  };
  useEffect(() => {
    setLoader(true);
    if (firebaseAllCollectionData.loading) {
      return;
    }
    if (!playerModel.loading) {
      setPlayerModel(
        playerModel.updates([
          { playerList: [], loading: true, initLoaded: false },
        ])
      );
    }
    fetchData();
  }, [firebaseAllCollectionData.loading, props.route.params.eventId]);
  return (
    <Root>
      <ScrollView style={styles.scrolContainer}>
        <LoaderComponent />
        <PlayerFilterSidebar
          setPlayerModel={setPlayerModel}
          playerModel={playerModel}
          filterVisible={showFilter}
          setIsFilterVisible={() => setShowfilter((i) => !i)}
        />
        <View style={styles.header}>
          <View style={styles.rowCenter}>
            <View style={{ flexDirection: "row" }}>
              {/* <TouchableOpacity hitSlop={bigHitSlop} onPress={()=>props.navigation.openDrawer()}>
              <Menu />
            </TouchableOpacity> */}
              <TouchableOpacity
                hitSlop={bigHitSlop}
                onPress={() => props.navigation.goBack()}
              >
                <Back style={{ marginLeft: 20 }} />
              </TouchableOpacity>
            </View>
          </View>
          <TouchableOpacity
            onPress={() => {
              setShowfilter(!showFilter);
            }}
            style={{ marginRight: 30 }}
            hitSlop={bigHitSlop}
          >
            <Filter />
          </TouchableOpacity>
        </View>
        {showFilter && (
          <View style={styles.filterContainer}>
            <Text style={styles.filter}>Filters:</Text>
            <TouchableOpacity>
              <Text style={styles.tag}>Filters</Text>
            </TouchableOpacity>
            <TouchableOpacity>
              <Text style={styles.tag}>Filters</Text>
            </TouchableOpacity>
          </View>
        )}
        <View style={styles.listContainer}>
          <SingleHeading
            containerStyle={styles.eventNameHeadingStyle}
            textColor={"white"}
            placeholder={playerModel?.selectedEvent?.eventName || ""}
          />
          {playerModel?.initLoaded && playerModel?.playerList.length == 0 && (
            <Text style={styles.noPlayerAvailTextStyle}>
              No Players Available
            </Text>
          )}
          {playerModel?.initLoaded && playerModel?.playerList.length > 0 && (
            <FlatList
              data={playerModel?.playerList || []}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              renderItem={({ item, index }) => {
                return (
                  <PlayerItem
                    key={index}
                    event={event}
                    allEvents={allEvents}
                    item={item}
                    navigation={props.navigation}
                  />
                );
              }}
              keyExtractor={(item, index) => index}
            />
          )}
        </View>
      </ScrollView>
    </Root>
  );
};
const styles = StyleSheet.create({
  noPlayerAvailTextStyle: {
    alignSelf: "center",
    marginVertical: 30,
    fontSize: 16,
    color: "black",
    fontWeight: "600",
  },
  eventNameHeadingStyle: {
    marginTop: 30,
    alignItems: "flex-start",
    paddingHorizontal: 30,
    backgroundColor: "#0B214D",
  },
  tag: { fontSize: 18, lineHeight: 22, marginLeft: 10 },
  filter: { fontWeight: "bold", fontSize: 18, lineHeight: 22 },
  filterContainer: {
    marginTop: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  scrolContainer: { height: "100%", width: "70%", backgroundColor: "white" },
  rowCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: { fontSize: 18, lineHeight: 22, marginLeft: 30 },
  header: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 20,
    paddingHorizontal: 20,
  },
  listContainer: { marginTop: 10, paddingVertical: 0 },
});
export default Player;
