// External Imports
import React from "react";
import { ScrollView, View, Text, TouchableOpacity, Alert } from "react-native";
import Image from "react-native-remote-svg";
// import {TopProfileView, DrawerMenuItem} from '@components';
import styles from "./indexCss";
import Entypo from "react-native-vector-icons/Entypo";
import PRL from "../../assets/PRLWHITE.png";
import CHALLENGES from "../../assets/Beticon.png";
import HOME from "../../assets/HOME.png";
import BROADCAST from "../../assets/BROADCAST.png";
import SCORES from "../../assets/SCORES.png";
import ALLGAMES from "../../assets/AllGames.png";
import EVENTS from "../../assets/EVENTS.png";
import EVENTSVIEW from "../../assets/EventIconWhite.png";
import COMPETITOR from "../../assets/Competition.png";
import VIEWCHARITY from "../../assets/CharityWhite.png";
import CREATEICON from "../../assets/EditIconWhite.png";
import { connect, useSelector, useDispatch } from "react-redux";
import { PRLLogo } from "../../Component";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useLinkTo, useNavigation } from "@react-navigation/native";
import { expo } from "../../../app.json";
import { updateEventModel } from "../../store/actions";

Entypo.loadFont();

const SideNav = (props) => {
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  var eventModelProp = useSelector((state) => state.event.eventModel);
  var setEventModel = (newEventModel) => {
    dispatch(updateEventModel(newEventModel));
  };
  const userInfo = useSelector((state) => state.auth);
  const Items = [
    {
      render: userInfo.isAuth,
      image: HOME,
      menuName: "Home",
      location: () => {
        linkTo("/home");
        //props.navigation.navigate("Home", { screen: "Home_Screen" });
      },
    },
    {
      render: userInfo.isAuth,
      image: EVENTSVIEW,
      menuName: "Events",
      location: () => {
        linkTo("/joinEvent");
        //props.navigation.navigate("EventInfoStack", { screen: "EventScreen" });
      },
    },
    {
      render: userInfo.isAuth,
      image: COMPETITOR,
      menuName: "Competitor",
      location: () => {
        return navigation.navigate("CompetitorDrawerScreen");
        // navigation.navigate("ReviewProfileScreen", {
        //   isCompetition: true,
        // });
      },
    },
    {
      render: userInfo.isAuth,
      image: EVENTS,
      menuName: "My Games",
      location: () => {
        linkTo("/myAllGames");
      },
    },
    {
      render: userInfo.isAuth,
      image: ALLGAMES,
      menuName: "All Games",
      location: () => {
        linkTo("/allGames");
      },
    },
    {
      render: userInfo.isAuth,
      image: CHALLENGES,
      menuName: "Picks",
      location: () => {
        linkTo("/challengesList");
      },
    },
    {
      render: userInfo.isAuth,
      image: SCORES,
      menuName: "Your Profile",
      location: () => {
        navigation.navigate("Home", {
          screen: "ReviewProfileScreen",
          params: {
            isCompetition: false,
          },
        });
      },
    },
    {
      render: userInfo.isAuth,
      image: VIEWCHARITY,
      menuName: "View Charities",
      location: () => {
        linkTo("/viewCharity");
        // props.navigation.navigate("CharityStack", {
        //   screen: "ViewCharityScreen",
        // });
      },
    },
    {
      render: userInfo?.userCol?.permissions?.showHostEvents || false,
      image: CREATEICON,
      menuName: "Host Event",
      location: () => {
        navigation.navigate("EventStack", {
          screen: "CreateEventScreen",
          params: {
            resetForm: false,
          },
        });
      },
    },
    {
      render: userInfo?.userCol?.permissions?.showHostEvents || false,
      image: EVENTSVIEW,
      menuName: "Game Schedule",
      location: () => {
        return navigation.navigate("SeedingsScreen");
      },
    },
    {
      render: userInfo?.userCol?.permissions?.showHostEvents || false,
      image: CREATEICON,
      menuName: "Create Bracket Group",
      location: () => {
        //return linkTo("/createBracketGroup");
        navigation.navigate("EventInfoStack", {
          screen: "CreateBracketGroup", 
        });
      },
    },
    {
      render: userInfo.isAuth,
      image: CREATEICON,
      menuName: "Add Bracket Guessing",
      location: async () => {
        linkTo("/bracketEntry/add");
      },
    },
    {
      render: userInfo.isAuth,
      image: CREATEICON,
      menuName: "View Bracket Guessing",
      location: async () => {
        linkTo("/bracketEntry/view");
      },
    },
    {
      render: userInfo?.userCol?.permissions?.showAddCharities || false,
      image: CREATEICON,
      menuName: "Add Charities",
      location: () => {
        linkTo("/createCharity");
        // props.navigation.navigate("CharityStack", {
        //   screen: "CreateCharityScreen",
        // });
      },
    },
    {
      render: userInfo.isAuth,
      menuName: "About PRL",
      location: () => {
        navigation.navigate("AboutScreen");
      },
    },
    {
      render: userInfo.isAuth,
      menuName: "Setting",
      location: () => {
        navigation.navigate("UserSettingStack", {
          screen: "UserSettingScreen",
        });
      },
    },
    // {
    //   menuName: "Setting",
    //   location: () => {
    //     linkTo('/setting')
    //   },
    // },
    {
      render: userInfo.isAuth,
      menuName: "Logout",
      location: async () => {
        await AsyncStorage.removeItem("userInfo");
        props.logout();
      },
    },
  ];

  let RenderTagFilterData = ({ data }) => {
    return (
      <TouchableOpacity
        // style={{ width: "80%Create Event - Enter Event Fees" }}
        onPress={() => {
          if (typeof data.location == "function") {
            return data.location();
          }
          return props.navigation.navigate(data.location);
        }}
      >
        <View
          style={{
            marginLeft: 15,
            marginTop: 25,
            flexDirection: "row",
            width: "20%",
          }}
        >
          <View style={{ width: "80%" }}>
            {data.image ? (
              <Image source={data.image} size={40} style={styles.DrawerIcons} />
            ) : (
              <View style={{ width: 0 }} />
            )}
          </View>
          {/* <TouchableOpacity
          // style={{ width: "80%" }}
          onPress={() => {
            if (typeof data.location == "function") {
              return data.location();
            }
            return navigation.navigate(data.location);
          }}
        > */}
          <View>
            <Text
              style={{
                color: "#FFFFFF",
                fontSize: 15,
                marginTop: 5,
                marginLeft: 15,
              }}
            >
              {data.menuName}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <ScrollView
      scrollEnabled
      showsVerticalScrollIndicator
      style={styles.container}
    >
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View>
            <Image source={PRL} height={15} style={styles.profileImg} />
          </View>
          {/* <TouchableOpacity
          onPress={() => props.navigation.closeDrawer()}
          style={{ alignSelf: "flex-end" }}
        >
          <Entypo name="menu" size={40} color="white" style={{}} />
        </TouchableOpacity> */}
        </View>
        <View style={{ marginTop: 0 }}>
          {Items.map((data, index) => {
            if (!data.render) {
              return false;
            }
            return <RenderTagFilterData data={data} />;
          })}
          {userInfo.isAuth ? (
            <View style={{ alignSelf: "center" }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#FFF",
                  fontWeight: "bold",
                  marginVertical: 20,
                }}
              >
                Version: {expo.version}
              </Text>
            </View>
          ) : null}
        </View>
      </View>
    </ScrollView>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "LOGOUT_USER" }),
  };
};
export default connect(null, mapDispatchToProps)(SideNav);
