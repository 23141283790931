
//External Imports
import { StyleSheet } from 'react-native';

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from '../../utils';

export default StyleSheet.create({
    container: {
         
    },
    svgStyle: {

    },
    imgStyle: {
        height: 80,
        width: 180,
        resizeMode: 'contain',
    }
});