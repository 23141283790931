// External Imports
import React from "react";
import {
  ScrollView,
  View,
  Image,
  Text,
  TouchableOpacity,
  Alert,
} from "react-native";
// import {TopProfileView, DrawerMenuItem} from '@components';
import styles from "./indexCss";
import Entypo from "react-native-vector-icons/Entypo";
import PRL from "../../assets/PRLWHITE.png";
import HOME from "../../assets/HOME.png";
//import BROADCAST from "../src/assets/BROADCAST.png";
import SCORES from "../../assets/SCORES.png";
import EVENTS from "../../assets/EVENTS.png";
import { connect } from "react-redux";
import { PRLLogo } from "../../Component";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useLinkTo } from '@react-navigation/native'

Entypo.loadFont();

const SideDrawer = (props) => {
  const linkTo = useLinkTo()
  const Items = [
    {
      image: HOME,
      menuName: "Home",
      location: () => {
        linkTo('/home')
        //props.navigation.navigate("Home", { screen: "Home_Screen" });
      },
    },
    {
      image: EVENTS,
      menuName: "Events",
      location: () => {
        linkTo('/joinEvent')
        //props.navigation.navigate("EventInfoStack", { screen: "EventScreen" });
      },
    },
    {
      image: SCORES,
      menuName: "Competitor",
      location: () => {
        linkTo('/profile')
      },
    },
    {
      image: EVENTS,
      menuName: "My Games",
      location: () => {
        linkTo('/myAllGames')
      },
    },
    {
      image: EVENTS,
      menuName: "All Games",
      location: () => {
        linkTo('/allGames')
      },
    },
    {
      menuName: "Picks",
      location: () => {
        linkTo('/challengesList')
      //   navigation.navigate('EventInfoStack', {
      //     screen: 'PlayerListScreen',
      //     params: null
      // });
      },
    },
    {
      image: SCORES,
      menuName: "Your Profile",
      location: () => {
        linkTo('/profile')
      },
    },
    {
      menuName: "View Charities",
      location: () => {
        linkTo('/viewCharity')
        // props.navigation.navigate("CharityStack", {
        //   screen: "ViewCharityScreen",
        // });
      },
    },
    {
      image: EVENTS,
      menuName: "Host Event",
      location: () => {
        linkTo('/createEvent')
        // props.navigation.navigate("EventStack", {
        //   screen: "CreateEventScreen",
        // });
      },
    },
    {
      menuName: "Add Charities",
      location: () => {
        linkTo('/createCharity')
        // props.navigation.navigate("CharityStack", {
        //   screen: "CreateCharityScreen",
        // });
      },
    },
    {
      menuName: "About PRL",
      location: () => {
        linkTo('/about')
      },
    },
    {
      menuName: "Logout",
      location: async () => {
        await AsyncStorage.removeItem("userInfo");
        props.logout();
      },
    },
  ];

  let RenderTagFilterData = ({ data }) => {
    return (
      <View
        style={{
          marginLeft: 15,
          marginTop: 25,
          flexDirection: "row",
          alignItems: "center",
          width: "80%",
          alignSelf: "center",
        }}
      >
        <View style={{ width: "30%" }}>
          {data.image ? (
            <Image source={data.image} size={40} style={styles.DrawerIcons} />
          ) : (
            <View style={{ width: 0 }} />
          )}
        </View>
        <TouchableOpacity
          style={{ width: "70%" }}
          onPress={() => {
            if (typeof data.location == "function") {
              return data.location();
            }
            return props.navigation.navigate(data.location);
          }}
        >
          <Text style={{ color: "#FFFFFF", fontSize: 15 }}>
            {data.menuName}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: "#0B214D" }}
      >
        <View style={styles.container}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
            }}
          >
            <View>
              <Image source={PRL} height={15} style={styles.profileImg} />
            </View>
            <TouchableOpacity
              onPress={() => props.navigation.closeDrawer()}
              style={{ alignSelf: "flex-end" }}
            >
              <Entypo name="menu" size={40} color="white" style={{}} />
            </TouchableOpacity>
          </View>
          <View style={{ marginTop: 0 }}>
            {Items.map((data, index) => (
              <RenderTagFilterData data={data} />
            ))}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "LOGOUT_USER" }),
  };
};
export default connect(null, mapDispatchToProps)(SideDrawer);

// const Items = [
//   {
//     image: HOME,
//     menuName: "Home",
//     location: 'Home'
//   },
//   {
//     image: BROADCAST,
//     menuName: "Broadcast",
//     location: 'Broadcast'
//   },
//   {
//     image: SCORES,
//     menuName: "Watch",
//     location: 'WatchScreen'
//   },
//   {
//     image: EVENTS,
//     menuName: "Create Events",
//     location: () => {
//       props.navigation.navigate('EventStack', { screen: 'CreateEventScreen' });
//     }
//   },
//   {
//     image: EVENTS,
//     menuName: "Events",
//     location: () => {
//       props.navigation.navigate('EventStack', { screen: 'EventScreen' });
//     }
//   },
//   {
//     image: EVENTS,
//     menuName: "Create Charity",
//     location: () => {
//       props.navigation.navigate('EventStack', { screen: 'CreateCharityScreen' });
//     }
//   },
//   {
//     image: EVENTS,
//     menuName: 'Player List',
//     location: () => {
//       props.navigation.navigate('EventStack', { screen: 'PlayerListScreen' });
//     }
//   },
//   {
//     menuName: "Scores",
//     location: 'ScoresScreen'
//   },
//   {
//     menuName: "Profile",
//     location: 'Profile_screen'
//   },
//   {
//     menuName: "Game List",
//     location: 'GamesList'
//   },
//   {
//     menuName: "Challenge History",
//     location: 'ChallengesList'
//   },
//   {
//     menuName: "Twilio Test",
//     location: 'TwilioTestScreen'
//   },
//   {
//     menuName: "Logout",
//     location: () => props.logout()
//   }
// ]
