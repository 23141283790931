const data = {
  stripePaymentCredentials: {
    productionPublishableKey:
      "pk_live_51HQ0VUByjbstD2KstOvN1wBuQJPuZ7cAy4ydU8LR8VQK1hzbT8PLmEI40NcTnrkZhNhWlePH8aUFop3Z3k0OGUEE00lPUwBX2Z",
    restrictedSecretKey:
      "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",
    productionSecretKey:
      " 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",
    sandboxSecretKey:
      "sk_test_51HQ0VUByjbstD2KssOhVq2ZxxYT0Q40pU1phO7pmou6dVVR5GPs5QHC5YelYked3ohjUprsqCjViMt3hqOBNOcWI00FPM9wQ0m",
    sandboxPublishableKey:
      "pk_test_51HQ0VUByjbstD2KsSb6Ve2adXLsLdsgNO3IIsPAtwvPCbOata2544fLbbsaL4G1ki3G7ehArW2lqevPvZYPFuNv700JpcOpUFc",
    processingEnabled: true,
  },
  charityName: "Players Rec League",
  charityEmailCC: "rick@playersrecleague.com",
  paypalPaymentCredentials: {
    sandboxAccount: "sb-wmbir8423918@business.example.com",
    processingEnabled: false,
    productionClientID: "",
    productionSecretKey: "",
    sandboxSecretKey:
      "ECuq8Nu-mVhfLQFLueqio79cHaPhbN7nIkyuevQAFO6GIaY9p57T-W-abHOZmqQKXKKFOlrTd2hhajfD",
    sandboxClientID:
      "AQzTBLyTunS7Fyn2H_4V4u6zyvstIvJgviwYv5riI7ftBYeeOlDJp6a00qqdoGG4wIXoY8umbBY-yIwG",
    productionAccount: "",
  },
  signInEmail: "",
  charityID: 0,
  enablePaymentProcessing: true,
  squarePaymentCredentials: {
    sandboxApplicationID: "",
    productionApplicationID: "",
    processingEnabled: false,
  },
  showInPaymentList: true,
  paymentConfiguration: ["Stripe", "Paypal", "Square"],
};

class PaymentHostInformation {
  resData = {};

  currentProcessing = 0;
  activePaymentProcess = [];
  activePaymentHosts = [];
  activePaymentHostsKey = {};
  constructor(resData) {
    this.resData = resData;
  }

  initPaymentInfoProcess = () => {
    this.resData?.paymentConfiguration.map((paymentPre) => {
      const paymentDetailsMapper =
        PaymentHostInformation.PaymentOptions[paymentPre];
      const responsePaymentConfigData =
        this.resData[paymentDetailsMapper.configKey];
      if (paymentDetailsMapper && responsePaymentConfigData?.processingEnabled) {
        this.activePaymentHosts.push(paymentPre);
        this.activePaymentHostsKey = {
          ...this.activePaymentHostsKey,
          ...paymentDetailsMapper,
        };
        this.activePaymentProcess.push(
          Object.assign({}, paymentDetailsMapper, responsePaymentConfigData)
        );
      }
    });
  };
  getActivePaymentHosts = () => {
    return this.activePaymentHosts;
  };
  getActivePaymentHostsKey = () => {
    return this.activePaymentHostsKey;
  };
  getCurrentPaymentInfo = () => {
    return this.activePaymentProcess[this.currentProcessing];
  };
  nextPaymentProcessing = () => {
    if (this.activePaymentProcess.length - 1 == this.currentProcessing) {
      return;
    }
    this.currentProcessing++;
  };
  previousPaymentProcessing = () => {
    if (this.currentProcessing == 0) {
      return;
    }
    this.currentProcessing--;
  };
  static PaymentOptions = {
    Stripe: {
      configKey: "stripePaymentCredentials",
      isStripe: true,
    },
    Square: {
      configKey: "squarePaymentCredentials",
      isSquare: true,
    },
    Paypal: {
      configKey: "paypalPaymentCredentials",
      isPaypal: true,
    },
  };
}

export default PaymentHostInformation;
