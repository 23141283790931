import {
    TOGGLE_ALERT_BOX
} from '../actions';

const initialState = {
    alertBox: {
        visible: false,
        text: '',
        onPress: null
    }
}

export const appReducer = (state=initialState, action) => {
    const {
        payload,
        type
    } = action;

    switch(type) {
        case TOGGLE_ALERT_BOX: 
            return {
                ...state,
                alertBox: {
                    ...payload
                }
            }

        default: return state;
    }
}