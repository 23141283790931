import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Alert,
  SectionList,
  TouchableOpacity,
  RefreshControl,
  StatusBar,
  ActivityIndicator,
  ScrollView,
  Dimensions,
} from "react-native";

import { Root } from "../../Component";

import { firebase, firebaseAuth } from "../../firebase";
import "firebase/firestore";

import Feather from "react-native-vector-icons/Feather";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import AntDesign from "react-native-vector-icons/AntDesign";
import FilterEventSidebar from "./EventFilterSideBar";

// import Picker from '@react-native-community/picker'
import { Picker } from "@react-native-picker/picker";

MaterialIcons.loadFont();
Feather.loadFont();

const useComponentWillUnmount = (handler) => {
  return useEffect(() => handler, []);
};

const GamesListView = (props) => {
  const [filterEventDataLoaded, setFilterEventDataLoaded] = useState(false);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [genres, setGenres] = useState([]);
  const [contestTypes, setContestTypes] = useState([]);

  const [contests, setContests] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  const [filterEventCategoryName, setFilterEventCategoryName] = useState("");
  const [filterEventSubCategoryName, setFilterEventSubCategoryName] =
    useState("");
  const [filterEventGenreName, setFilterGenreName] = useState("");
  const [filterEventContestTypeName, setFilterEventContestTypeName] =
    useState("");

  const [allEvents, setAllEvents] = useState([]);
  const [allContests, setAllContests] = useState([]);

  const { navigation, route } = props;

  const [filterShowing, setFilterShowing] = useState(false);

  const [loadingData, setLoadingData] = useState(true);

  const [me, setMe] = useState(null);
  const [myProfile, setMyProfile] = useState(null);
  const [gameHostStatusArray, setGameHostStatusArray] = useState(null);

  const [loadingFilterData, setLoadingFilterData] = useState(false);

  const changeHostStatus = (gameScheduleId, newStatus) => {
    console.log("new status ", newStatus);
    if (newStatus === "") {
      return;
    }
    firebase
      .firestore()
      .collection("gameSchedule")
      .doc(gameScheduleId)
      .update({
        gameStatus: newStatus,
      })
      .then(() => {
        console.log("changed game status");
      });
  };

  const splitEventIdsArray = (eventIds) => {
    if (eventIds.length > 0) {
      let splittedIds = [];
      let seeker = 0;
      while (seeker < eventIds.length) {
        let elementSplitIds = [];
        for (let idx = 0; idx < 10; idx++) {
          if (seeker + idx < eventIds.length) {
            elementSplitIds.push(eventIds[seeker + idx]);
          }
        }

        splittedIds.push(elementSplitIds);

        seeker = seeker + 10;
      }
      return splittedIds;
    } else {
      return [];
    }
  };

  const loadEventDataForFiltering = async () => {
    let querySnapshot = await firebase
      .firestore()
      .collection("eventCategories")
      .get();

    let eventCategories = [];
    querySnapshot.forEach((documentSnapshot) => {
      let eventCategory = documentSnapshot.data();
      eventCategories = [...eventCategories, eventCategory];
    });

    setCategories(eventCategories);

    querySnapshot = await firebase
      .firestore()
      .collection("eventSubCategories")
      .get();

    let eventSubCategories = [];
    querySnapshot.forEach((documentSnapshot) => {
      let eventCategory = documentSnapshot.data();
      eventSubCategories = [...eventSubCategories, eventCategory];
    });

    setSubCategories(eventSubCategories);

    querySnapshot = await firebase
      .firestore()
      .collection("eventGenreTypes")
      .get();

    let eventGenreTypes = [];
    querySnapshot.forEach((documentSnapshot) => {
      let eventCategory = documentSnapshot.data();
      eventGenreTypes = [...eventGenreTypes, eventCategory];
    });

    setGenres(eventGenreTypes);

    querySnapshot = await firebase
      .firestore()
      .collection("contestTypes")
      .where("isActive", "==", true)
      .get();

    let eventContestTypes = [];
    querySnapshot.forEach((documentSnapshot) => {
      let eventCategory = documentSnapshot.data();
      eventCategory.value = eventCategory.contestType;
      eventContestTypes = [...eventContestTypes, eventCategory];
    });
    console.log("Event contest types => ", eventContestTypes);
    setContestTypes(eventContestTypes);

    setFilterEventDataLoaded(true);
    setLoadingFilterData(false);
  };

  const loadEvents = async (eventIds) => {
    let eventSplittedIdsArray = splitEventIdsArray(eventIds);
    let evs = [];
    for (let idx = 0; idx < eventSplittedIdsArray.length; idx++) {
      // let collectionRef = firebase.firestore().collection("events");

      const querySnapshot = await firebase
        .firestore()
        .collection("events")
        .where("eventID", "in", eventSplittedIdsArray[idx])
        .get();

      querySnapshot.forEach((documentSnapshot) => {
        let event = documentSnapshot.data();
        evs = [...evs, event];
      });
    }

    return evs
      .filter((ev) => ev.eventID !== undefined && ev.eventID !== "")
      .sort((event1, event2) => event1.eventName > event2.eventName);
  };

  const loadContests = async (eventIDs) => {
    let eventSplittedIdsArray = splitEventIdsArray(eventIDs);
    let cnts = [];

    for (let idx = 0; idx < eventSplittedIdsArray.length; idx++) {
      const querySnapshot = await firebase
        .firestore()
        .collection("contests")
        .where("eventID", "in", eventSplittedIdsArray[idx])
        .get();

      querySnapshot.forEach((documentSnapshot) => {
        let contest = documentSnapshot.data();
        cnts = [...cnts, contest];
      });
    }
    let filtered = cnts
      .filter((cnt) => cnt.contestID !== undefined && cnt.contestID !== "")
      .sort(
        (contest1, contest2) => contest1.contestName > contest2.contestName
      );
    return filtered;
  };

  let unsubscribeRefsArray = [];

  const [gameStatusArray, setGameStatusArray] = useState([]);

  useComponentWillUnmount(() => {
    console.log("Component will unmount!");
    if (unsubscribeRefsArray.length > 0) {
      unsubscribeRefsArray.forEach((ref) => {
        ref();
      });
    }
    unsubscribeRefsArray = [];
  });

  const configureGameStatusListening = (gameIds) => {
    let gameIdsSplittedArray = splitEventIdsArray(gameIds);

    if (unsubscribeRefsArray.length > 0) {
      unsubscribeRefsArray.forEach((ref) => {
        ref();
      });
    }

    unsubscribeRefsArray = [];

    for (let idx = 0; idx < gameIdsSplittedArray.length; idx++) {
      const unsubscribeRef = firebase
        .firestore()
        .collection("gameScheduleStatus")
        .where("gameID", "in", gameIdsSplittedArray[idx])
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            const statusArray = gameStatusArray;

            querySnapshot.forEach((documentSnapshot) => {
              let gameStatusData = documentSnapshot.data();

              const arrayIndex = statusArray.findIndex(
                (element) => element.gameID === gameStatusData.gameID
              );

              if (arrayIndex !== -1) {
                statusArray[arrayIndex] = gameStatusData;
              } else {
                statusArray.push(gameStatusData);
              }
            });

            setGameStatusArray([...statusArray]);
          }
        });

      unsubscribeRefsArray.push(unsubscribeRef);
    }
  };

  const loadMyProfile = () => {
    if (me != null) {
      const myUserId = me.uid;
      firebase
        .firestore()
        .collection("users")
        .doc(myUserId)
        .get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            setMyProfile(docSnapshot.data());
          }
        });
    }
  };

  const loadAllGames = async () => {
    console.log("Loading games");

    const myUserId = me.uid;
    const userContestsQuery = await firebase
      .firestore()
      .collection("userEnteredContests")
      .where("userID", "==", myUserId)
      .get();

    let myEventIds = [];

    userContestsQuery.forEach((documentSnapshot) => {
      const userContest = documentSnapshot.data();
      const eventId = userContest.eventID;
      if (eventId) {
        if (!myEventIds.includes(eventId)) {
          myEventIds = [...myEventIds, eventId];
        }
      }
    });

    console.log("my event ids => ", myEventIds);

    const myEvents = await loadEvents(myEventIds);
    setAllEvents(myEvents);

    const myContests = await loadContests(myEventIds);
    setAllContests(myContests);

    let eventSplittedIdsArray = splitEventIdsArray(myEventIds);
    let gameContests = [];

    let gameIds = [];

    for (let idx = 0; idx < eventSplittedIdsArray.length; idx++) {
      const gamesQuery = await firebase
        .firestore()
        .collection("gameSchedule")
        .where("eventID", "in", eventSplittedIdsArray[idx])
        .get();

      gamesQuery.forEach((documentSnapshot) => {
        let game = documentSnapshot.data();
        if (gameIds.includes(`${game.gameID}`) === false) {
          gameIds = [...gameIds, `${game.gameID}`];
        }

        if (
          route.params.isMine === false ||
          myUserId === game.player1ID ||
          myUserId === game.player2ID
        ) {
          game.gameScheduleId = documentSnapshot.id;
          let existingContest = gameContests.some(
            (e) => e.contestID === game.contestID
          );
          if (existingContest) {
            let contest = gameContests.find(
              (e) => e.contestID === game.contestID
            );
            contest.data = [...contest.data, game];
          } else {
            const gameContest = myContests.find(
              (contest) => contest.contestID == game.contestID
            );
            const gameEvent = myEvents.find(
              (event) => event.eventID == game.eventID
            );
            gameContests = [
              ...gameContests,
              {
                contestID: game.contestID,
                contestName:
                  gameContest === undefined
                    ? game.contestName
                    : gameContest.contestName,
                contestTypeID:
                  gameContest === undefined
                    ? game.contestTypeID
                    : gameContest.contestTypeID,
                eventID: game.eventID,
                eventCategory: gameEvent.eventCategory,
                eventContestType: gameEvent.eventContestType,
                eventGenre: gameEvent.eventGenre,
                eventSubCategory: gameEvent.eventSubCategory,
                eventName:
                  gameEvent === undefined ? "???" : gameEvent.eventName,
                data: [game],
              },
            ];
          }
        }
      });
    }

    console.log("Loaded game IDs list => ", gameIds);
    configureGameStatusListening(gameIds);

    gameContests.forEach((contest) => {
      contest.data.sort((game1, game2) => {
        if (game1.gameID > game2.gameID) {
          return 1;
        } else if (game1.gameID < game2.gameID) {
          return -1;
        } else {
          return 0;
        }
      });
    });

    gameContests.sort((contest1, contest2) => {
      let contestName1 = (
        contest1.eventName + contest1.contestName
      ).toLowerCase();
      let contestName2 = (
        contest2.eventName + contest2.contestName
      ).toLowerCase();
      if (contestName1 > contestName2) {
        return 1;
      } else if (contestName1 < contestName2) {
        return -1;
      } else {
        return 0;
      }
    });

    console.log("showing results => ", gameContests);

    setContests(gameContests);
    setFilteredResults(gameContests);
    setLoadingData(false);
    setRefreshing(false);
    console.log("Games loading completed");
  };

  const isPlayer1Live = (game) => {
    const status = gameStatusArray.find(
      (element) => element.gameID === `${game.gameID}`
    );
    if (status !== undefined) {
      if (status.player1Live === undefined) {
        return false;
      } else {
        return status.player1Live;
      }
    }

    if (game.player1IsLive === undefined) {
      return false;
    } else {
      return game.player1IsLive;
    }
  };

  const isPlayer2Live = (game) => {
    const status = gameStatusArray.find(
      (element) => element.gameID === `${game.gameID}`
    );
    if (status !== undefined) {
      if (status.player2Live === undefined) {
        return false;
      } else {
        return status.player2Live;
      }
    }

    if (game.player2IsLive === undefined) {
      return false;
    } else {
      return game.player2IsLive;
    }
  };

  const statusOfGame = (game) => {
    // 0 for live, 1 for completed, 2 for upcoming, 3 for On Deck, 4 for Sign In, 5 for Message

    const status = gameStatusArray.find(
      (element) => element.gameID === `${game.gameID}`
    );

    if (status !== undefined) {
      let statusText = status.status;

      if (!isJudgeScoreTypeGame(game)) {
        let player1Live = status.player1Live;
        let player2Live = status.player2Live;

        if (true === player1Live && true === player2Live) {
          return 0;
        }
      }

      if (statusText === "Final") {
        return 1;
      } else if (statusText === "Upcoming") {
        return 2;
      } else if (statusText === "On Deck") {
        return 3;
      } else if (statusText === "Sign In") {
        return 4;
      } else if (statusText === "Message") {
        return 5;
      } else {
        return 6;
      }
    }

    if (game.player1IsLive === true && game.player2IsLive === true) {
      return 0;
    } else if (game.gameStatus === "Final") {
      return 1;
    } else if (game.gameStatus === "Upcoming") {
      return 2;
    } else if (game.gameStatus === "On Deck") {
      return 3;
    } else if (game.gameStatus === "Sign In") {
      return 4;
    } else if (game.gameStatus === "Message") {
      return 5;
    } else {
      return 6;
    }
  };

  const isGameLive = (game) => {
    const status = gameStatusArray.find(
      (element) => element.gameID === `${game.gameID}`
    );
    if (status !== undefined) {
      let player1Live = status.player1Live;
      let player2Live = status.player2Live;

      return player1Live === true && player2Live === true;
    }

    return game.player1IsLive === true && game.player2IsLive === true;
  };

  const requestNotifyToPlayers = (game) => {
    fetch(
      "https://us-central1-players-recreation-league.cloudfunctions.net/pushNotificationToPlayers",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ gameScheduleId: game.gameScheduleId }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((jsonResponse) => {
        console.log("got json response => ", jsonResponse);
        console.log("Successfully sent notification to players.");
      });
  };

  const contestNameForContestId = (contestID) => {
    const contest = allContests.find(
      (element) => element.contestID === contestID
    );
    if (contest === undefined) {
      return "???";
    } else {
      if (contest.contestName === undefined) {
        return "???";
      } else {
        return contest.contestName;
      }
    }
  };

  const contestTypeIdForContestID = (contestID) => {
    const contest = allContests.find(
      (element) => element.contestID === contestID
    );
    if (contest === undefined) {
      return "";
    } else {
      if (contest.contestScoringType === undefined) {
        return "";
      } else {
        return contest.contestScoringType;
      }
    }
  };

  const eventNameForEventId = (eventID) => {
    const event = allEvents.find((element) => element.eventID === eventID);
    if (event === undefined) {
      return "???";
    } else {
      if (event.eventName === undefined) {
        return "???";
      } else {
        return event.eventName;
      }
    }
  };

  const eventLogoForEventId = (eventID) => {
    const event = allEvents.find((element) => element.eventID === eventID);
    return event.eventLogo;
  };

  const filterContestFully = () => {
    console.log(
      "filtering contests => ",
      filterEventCategoryName,
      filterEventSubCategoryName,
      filterEventGenreName
    );

    setFilteredResults(
      contests.filter((contest) => {
        let filtered = true;
        if (filterEventCategoryName !== "") {
          filtered =
            filtered && contest.eventCategory === filterEventCategoryName;
        }

        if (filterEventSubCategoryName !== "") {
          filtered =
            filtered && contest.eventSubCategory === filterEventSubCategoryName;
        }

        if (filterEventSubCategoryName !== "") {
          filtered =
            filtered && contest.eventSubCategory === filterEventSubCategoryName;
        }

        if (filterEventGenreName !== "") {
          filtered = filtered && contest.eventGenre === filterEventGenreName;
        }

        if (filterEventContestTypeName !== "") {
          filtered =
            filtered && contest.eventContestType === filterEventContestTypeName;
        }
        return filtered;
      })
    );
  };

  const gameStatusBackgroundColor = (game) => {
    if (game.gameStatus === "Open for Submissions") {
      return "rgba(0, 255, 0, 0.5)";
    } else if (game.gameStatus === "Submission Closing") {
      return "#FF7F509f";
    } else if (game.gameStatus === "Submission Closed") {
      return "#BFBFBF9F";
    } else if (game.gameStatus === "Submit and Judge") {
      return "#00f8209f";
    } else if (game.gameStatus === "Judging Open") {
      return "#00f8209f";
    } else if (game.gameStatus === "Judging Closing Soon") {
      return "#00ff009f";
    } else if (game.gameStatus === "Judging 5 Min Warning") {
      return "#BFBFBF9F";
    }

    const gameStatus = statusOfGame(game);
    if (gameStatus === 0) {
      return "rgba(0, 255, 0, 0.5)";
    } else if (gameStatus === 3) {
      return "#add8e6";
    } else if (gameStatus === 4) {
      return "#ff8000";
    } else {
      return "clear";
    }
  };

  const statusTextForGame = (game) => {
    const gameStatus = statusOfGame(game);
    if (gameStatus === 0) {
      return "Live";
    } else if (gameStatus === 1) {
      return "Completed";
    } else if (gameStatus === 2) {
      return "Upcoming";
    } else if (gameStatus === 3) {
      return "On Deck";
    } else if (gameStatus === 4) {
      return "Sign In";
    } else if (gameStatus === 5) {
      return "Message";
    } else {
      return game.gameStatus;
    }
  };

  const openGameDetails = (game) => {
    if (isJudgeScoreTypeGame(game)) {
      navigation.navigate("JudgeScoreGame", {
        gameScheduleId: game.gameScheduleId,
        eventID: game.eventID,
        contestID: game.contestID,
        eventName: eventNameForEventId(game.eventID),
        contestName: contestNameForContestId(game.contestID),
        gameID: game.gameID,
      });
    } else {
      if (game.player1ID === "" || game.player2ID === "") {
        Alert.alert(
          "",
          "The players of this game have not been determined yet.  Please check back later.",
          [{ text: "OK", onPress: () => {} }]
        );
      } else {
        let status = statusOfGame(game);
        if (status == 0) {
          // live
          navigation.navigate("GameScreen", {
            gameScheduleId: game.gameScheduleId,
            pageTitle: "Game #" + game.gameID,
            eventName: eventNameForEventId(game.eventID),
            contestName: contestNameForContestId(game.contestID),
            contestID: game.contestID,
          });
        } else if (status == 1) {
          // completed
          navigation.navigate("CompletedGameScreen", {
            game: game,
            gameScheduleId: game.gameScheduleId,
            eventName: eventNameForEventId(game.eventID),
            contestName: contestNameForContestId(game.contestID),
            contestID: game.contestID,
          });
        } else {
          // upcoming
          if (game.player1ID == me.uid || game.player2ID == me.uid) {
            // Player should open game page
            navigation.navigate("GameScreen", {
              gameScheduleId: game.gameScheduleId,
              pageTitle: "Game #" + game.gameID,
              eventName: eventNameForEventId(game.eventID),
              contestName: contestNameForContestId(game.contestID),
              contestID: game.contestID,
            });
          } else if (status == 1) {
            // completed
            navigation.navigate("CompletedGameScreen", {
              game: game,
              gameScheduleId: game.gameScheduleId,
              eventName: eventNameForEventId(game.eventID),
              contestName: contestNameForContestId(game.contestID),
              contestID: game.contestID,
            });
          } else {
            // upcoming
            if (game.player1ID == me.uid || game.player2ID == me.uid) {
              // Player should open game page
              navigation.navigate("GameScreen", {
                gameScheduleId: game.gameScheduleId,
                pageTitle: "Game #" + game.gameID,
              });
            } else {
              // For audience,  open upcoming game page
              navigation.navigate("UpcomingGameScreen", {
                game: game,
                gameScheduleId: game.gameScheduleId,
              });
            }
          }
        }
      }
    }
  };

  const handleOpenChannelRoom = (eventID) => {
    const url = urlForEventChannelRoomPopupPage(eventID);
    window.open(url, "popup", "width=600,height=600");
  };

  const urlForEventChannelRoomPopupPage = (eventId) => {
    if (me) {
      console.log("My username is ", me.userName);
      const url = `https://plr-video-service.web.app?style=popup&type=event_room&eventId=Event-${eventId}&userId=${me.uid}:::${myProfile.userName}`;
      return url;
    } else {
      return "";
    }
  };

  const isJudgeScoreTypeGame = (game) => {
    const contestTypeId = contestTypeIdForContestID(game.contestID);
    return contestTypeId === "Judges Scoring";
  };

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        setMe(user);
      } else {
        console.log("auth current user is Invalid");
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (
      myProfile !== null &&
      (myProfile.userType === "admin" || myProfile.userType === "host")
    ) {
      firebase
        .firestore()
        .collection("gameStatuses")
        .where("isActive", "==", true)
        .orderBy("sortOrder")
        .get()
        .then((statusQuerySnapshot) => {
          setGameHostStatusArray(
            statusQuerySnapshot.docs.map((doc) => doc.data())
          );
        })
        .catch((err) => {
          console.log("game host status array err => ", err);
        });
    }
  }, [myProfile]);

  useEffect(() => {
    if (me) {
      console.log("Loading games of mine or not ??? ", route.params.isMine);
      loadMyProfile();
      loadAllGames();
    }
  }, [me]);

  useEffect(() => {
    filterContestFully();
  }, [contests]);

  useEffect(() => {
    filterContestFully();
  }, [
    filterEventCategoryName,
    filterEventSubCategoryName,
    filterEventGenreName,
    filterEventContestTypeName,
  ]);

  const renderItem = (game) => {
    StatusBar.setBarStyle("dark-content", true);
    return (
      <TouchableOpacity
        style={{ backgroundColor: gameStatusBackgroundColor(game) }}
        onPress={() => openGameDetails(game)}
      >
        <View
          style={{ paddingTop: 15, paddingBottom: 15, flexDirection: "row" }}
        >
          <View style={{ justifyContent: "center", width: 90, marginLeft: 10 }}>
            {eventLogoForEventId(game.eventID) === undefined ? (
              <Image
                style={{ width: 72, height: 25 }}
                source={require("../../assets/PRLLogo300.png")}
              />
            ) : (
              <Image
                style={{ width: 72, height: 72 }}
                resizeMode={"contain"}
                source={{ uri: eventLogoForEventId(game.eventID) }}
              />
            )}
          </View>
          <View
            style={{
              flex: 2,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                paddingRight: 10,
                alignItems: "center",
              }}
            >
              <Text style={{ fontWeight: "bold", fontSize: 14, flex: 1 }}>
                Game #{game.gameID}
              </Text>
              <View style={{ alignItems: "center" }}>
                <Text style={{ color: "black" }}>
                  {statusTextForGame(game)}
                </Text>
              </View>
              {true == game.gameRecordingOn && (
                <MaterialIcons
                  name={
                    true == game.gameRecordingOn ? "videocam" : "videocam-off"
                  }
                  style={{ marginLeft: 10 }}
                  color="rgba(0, 0, 0, 0.6)"
                  size={24}
                />
              )}
            </View>
            <View
              style={{
                marginTop: 10,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View
                style={{ flexDirection: "row", flex: 1, alignItems: "center" }}
              >
                {game.player1ID === "" || game.player2ID === "" ? (
                  <Text style={{ fontSize: 12 }}>
                    The players of this game have not been determined yet.
                    Please check back later.
                  </Text>
                ) : (
                  <>
                    {isJudgeScoreTypeGame(game) ? (
                      <>
                        <View
                          style={{
                            backgroundColor: "green",
                            width: 10,
                            height: 10,
                            borderRadius: 5,
                          }}
                        />
                        <Text
                          style={{
                            marginLeft: 5,
                            fontWeight: "bold",
                            fontSize: 16,
                          }}
                        >
                          {game.gameDescription}
                        </Text>
                      </>
                    ) : (
                      <>
                        <View
                          style={{
                            backgroundColor: isPlayer1Live(game)
                              ? "red"
                              : "gray",
                            width: 10,
                            height: 10,
                            borderRadius: 5,
                          }}
                        />
                        <Text
                          style={{
                            marginLeft: 5,
                            fontWeight: "bold",
                            fontSize: 16,
                          }}
                        >
                          {game.player1ID == firebase.auth().currentUser.uid
                            ? "You"
                            : game.player1Name}
                        </Text>
                        <Text style={{ fontSize: 16 }}>&nbsp;vs&nbsp;</Text>
                        <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                          {game.player2ID == firebase.auth().currentUser.uid
                            ? "You"
                            : game.player2Name}
                        </Text>
                        <View
                          style={{
                            backgroundColor: isPlayer2Live(game)
                              ? "red"
                              : "gray",
                            width: 10,
                            height: 10,
                            borderRadius: 5,
                            marginLeft: 5,
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </View>
              {myProfile !== null &&
                (myProfile.userType === "admin" ||
                  myProfile.userType === "host") &&
                statusOfGame(game) !== 0 && (
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        width: 140,
                        height: 20,
                        borderRadius: 3,
                        borderColor: "gray",
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Picker
                        selectedValue=""
                        style={{ width: 140, height: 20 }}
                        onValueChange={(itemValue, itemIndex) =>
                          changeHostStatus(game.gameScheduleId, itemValue)
                        }
                      >
                        <Picker.Item label="Change status" value="" />
                        {gameHostStatusArray.map((gameHostStatus) => (
                          <Picker.Item
                            label={gameHostStatus.gameStatus}
                            value={gameHostStatus.gameStatus}
                          />
                        ))}
                      </Picker>
                    </TouchableOpacity>
                    {!isJudgeScoreTypeGame(game) &&
                      (!isPlayer1Live(game) || !isPlayer2Live(game)) &&
                      statusOfGame(game) !== 1 && (
                        <TouchableOpacity
                          style={{
                            backgroundColor: "green",
                            borderRadius: 3,
                            height: 24,
                            marginTop: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onPress={() => requestNotifyToPlayers(game)}
                        >
                          <Text style={{ color: "white" }}>
                            Request to sign on
                          </Text>
                        </TouchableOpacity>
                      )}
                  </View>
                )}
            </View>
            <Text style={{ color: "#3f3f3f", fontSize: 12, marginTop: 10 }}>
              {game.gameDescription}
            </Text>
          </View>
        </View>
        <View style={{ backgroundColor: "rgba(0, 0, 0, 0.1)", height: 1 }} />
      </TouchableOpacity>
    );
  };

  return (
    <Root>
      <ScrollView style={{ height: "100%", width: "99%" }}>
        <FilterEventSidebar
          filterVisible={filterShowing}
          categories={categories}
          subCategories={subCategories}
          genres={genres}
          contestTypes={contestTypes}
          setIsFilterVisible={() => {
            setFilterShowing((i) => !i);
          }}
          onCategorySelected={(eventCategory) => {
            setFilterEventCategoryName(eventCategory.name);
          }}
          onSubCategorySelected={(eventSubCategory) => {
            setFilterEventSubCategoryName(eventSubCategory.name);
          }}
          onGenreSelected={(genre) => {
            setFilterGenreName(genre.name);
          }}
          onContestTypeSelected={(contestType) => {
            console.log("Contest type => ", contestType);
            setFilterEventContestTypeName(contestType);
          }}
          onCleared={() => {
            setFilterEventCategoryName("");
            setFilterEventSubCategoryName("");
            setFilterGenreName("");
            setFilterEventContestTypeName("");
          }}
        />

        <View
          style={{
            width: "100%",
            alignSelf: "center",
            height: 50,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 15,
            paddingRight: 0,
          }}
        >
          {/* <TouchableOpacity onPress={() => navigation.openDrawer()}>
            <Feather name="menu" size={25} color={'#000'} />
          </TouchableOpacity> */}
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              flex: 1,
              textAlign: "center",
            }}
          >
            {route.params.isMine ? "My Games" : "All Games"}
          </Text>
          <TouchableOpacity style={{}} onPress={() => loadAllGames()}>
            {/* <Image source={RefreshImg} /> */}
            <Text>Refresh</Text>
          </TouchableOpacity>
          <View style={{ width: 20 }} />
          <TouchableOpacity
            style={{}}
            onPress={async () => {
              // toggleFilter()
              console.log("Opening filter => ", filterEventDataLoaded);
              if (filterEventDataLoaded === false) {
                setLoadingFilterData(true);
                await loadEventDataForFiltering();
              }
              setFilterShowing(true);
            }}
          >
            {/* <Image source={FilterImg} /> */}
            <Text>Filter</Text>
          </TouchableOpacity>
          <View style={{ width: 20 }} />
        </View>
        {loadingData ? (
          <ActivityIndicator />
        ) : (
          <>
            {filteredResults.length == 0 ? (
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: 20,
                  color: "#7f7f7f",
                }}
              >
                No Games have been scheduled yet for your Events. Please check
                back later
              </Text>
            ) : (
              <SectionList
                style={{ backgroundColor: "white" }}
                sections={filteredResults}
                extraData={gameStatusArray}
                renderItem={({ item }) => renderItem(item)}
                renderSectionHeader={({ section: { contestID, eventID } }) => (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      backgroundColor: "#0B214D",
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        padding: 10,
                        fontSize: 18,
                        flex: 1,
                      }}
                    >
                      {eventNameForEventId(eventID) +
                        " - " +
                        contestNameForContestId(contestID)}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate("ContestChatScreen", {
                          contestID,
                          eventID,
                          contestName: contestNameForContestId(contestID),
                          eventName: eventNameForEventId(eventID),
                        });
                      }}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        width: 44,
                        height: 44,
                      }}
                    >
                      <AntDesign name="message1" size={18} color="white" />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        handleOpenChannelRoom(eventID);
                        // navigation.navigate("ChannelRoom", {
                        //   eventId: eventID,
                        //   eventName:  eventNameForEventId(eventID)
                        // });
                      }}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        width: 44,
                        height: 44,
                      }}
                    >
                      <MaterialIcons name="group" color="white" size={24} />
                    </TouchableOpacity>
                  </View>
                )}
                keyExtractor={(item, index) => item + index}
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    onRefresh={() => {
                      setRefreshing(true);
                      loadAllGames();
                    }}
                  />
                }
              />
            )}
          </>
        )}
        {/* <Modal visible={isFilterVisible} animationType="slide">
          <GamesListFilterView
            allEvents={allEvents}
            allContests={allContests}
            allContestTypes={allContestTypes}
            filteringEventId={filteringEventId}
            filteringContestId={filteringContestId}
            onFilter={(eventID, contestID) => {
              filterGames(eventID, contestID);
            }}
            onDismiss={() => {
              toggleFilter();
            }}
            style={{ flex: 1 }}
          />
        </Modal> */}
      </ScrollView>

      {loadingFilterData && (
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "rgba(0,0,0,0.4)",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator />
        </View>
      )}
    </Root>
  );
};

export default GamesListView;
