import { filterAndMergeDataOnUniqueKey } from "../../utils";
import Round from "./Round";
import OptionModel from "./Option";

class Bracket {
  rounds = [];

  static fromJSON = (bracketData = [], bracketGuessData = []) => {
    const roundDetails = filterAndMergeDataOnUniqueKey(
      bracketData,
      "bracketRound"
    );
    let bracket = new Bracket();
    bracket.rounds = roundDetails.map((singleRound, index) => {
      return Round.fromJSON(singleRound, index, bracketGuessData);
    });

    return bracket;
  };

  // static fromUpJSON = (bracketData = [], bracketGuessData = []) => {
  //   let bracket = new Bracket();
  //   bracket.rounds = bracketData.map((singleRound, index) => {
  //     return new OptionModel(singleRound);
  //   });

  //   return bracket;
  // };

  static fromUpJSON = (bracketData = [], bracketGuessData = []) => {
    let bracket = new Bracket();

    bracket.rounds = bracketData.map((singleRound, index) => {
      //console.log("SINGLE_ROUND_TEST - ", JSON.stringify(singleRound));
      let bracketGuessRec = bracketGuessData.find(
        (b) => b.gameID == singleRound.gameID
      );
      return new OptionModel(singleRound, bracketGuessRec);
    });

    return bracket;
  };
}
export default Bracket;
