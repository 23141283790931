// External Imports
import React, { useState, useEffect } from "react";
import {
  View,
  Image,
  Text,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Alert,
} from "react-native";
import { Root, PRLLogo } from "../../../../Component";
import { Picker } from "@react-native-community/picker";
import Icon from "react-native-vector-icons/Feather";
import styles from "./indexCss";
import { Button, CheckBox } from "react-native-elements";
import HeaderBlack from "../../../../Header/HeaderBlack";
import DropDownPicker from "react-native-dropdown-picker";
import { SquarePayment } from "../../../../app/classes";
import {
  squareTransactionsCollection,
  eventProfileQuestionsCollection,
  playerEventProfileCollection,
} from "../../../../firebase";
import { useSelector, connect } from "react-redux";
import { transformFirebaseValues } from "../../../../utils";

function EPCreditCardScreen(props) {
  const [] = useState(playerEventProfileModal);
  const [squarePayment] = useState(() => new SquarePayment(onCardEntryComplete, onCardEntryCancel));
  const [showDropdown, setDropdown] = useState(true);
  const [selectedFeesType, setSelectedFeesType] = useState([]);
  const {
    eventData,
    selectedFeesData,
    saveFeesDataToFirebase = () => { }
  } = props.route.params;
  const { auth } = useSelector((state) => state);
  let playerEventProfileModal = {
    eventID: 0,
    profileA1: "",
    profileA2: "",
    profileA3: "",
    profileA4: "",
    profileFirstName: "",
    profileID: "",
    profileImage: "",
    profileImageQ: "",
    profileNickname: "",
    profilePlayerForCharityID: 1,
    profilePlayerPicture: "",
    profileQ1Label: "",
    profileQ2Label: "",
    profileQ3Label: "",
    profileQ4Label: "",
    profileVideo: "",
    profileVideoQ: "",
    userID: "",
  };
  const data2 = ["America", "California", "Los Angeles"];
  useEffect(() => {
    console.log("porpssss",selectedFeesData);
    console.log("prorprpp2" ,eventData);
    setSelectedFeesType(selectedFeesData);
    console.log('CURRENT_EVENT_DATA - ', JSON.stringify(eventData));
    console.log('SELECTED_FEES_DATA - ', JSON.stringify(selectedFeesData));
  }, [eventData, selectedFeesData]);

  const createPlayerProfileEntry = async () => {
    const getEventProfileQuestions = await eventProfileQuestionsCollection.get();
    const getEventProfileQuestionsTrans = transformFirebaseValues(getEventProfileQuestions, 'eventID');
    let profileQuestion = {};
    getEventProfileQuestionsTrans.map(i => {
      if (i.eventID == eventData.eventID) {
        profileQuestion = { ...i };
      }
    });
    console.log('GOT_PLAYER_QUESTIONS - ', JSON.stringify(profileQuestion));
    if (Object.keys(profileQuestion).length == 0) {
      return;
    }
    playerEventProfileModal.eventID = eventData.eventID;
    playerEventProfileModal.profilePlayerForCharityID = eventData.charityID,
      playerEventProfileModal.profileFirstName = auth.userCol.userName;
    playerEventProfileModal.profileID = Date.now();
    playerEventProfileModal.profileImageQ = profileQuestion.profileImageQ;
    playerEventProfileModal.profileNickname = auth.userCol.userNickname;
    playerEventProfileModal.profileQ1Label = profileQuestion.profileQ1Label;
    playerEventProfileModal.profileQ2Label = profileQuestion.profileQ2Label;
    playerEventProfileModal.profileQ3Label = profileQuestion.profileQ3Label;
    playerEventProfileModal.profileQ4Label = profileQuestion.profileQ4Label;
    playerEventProfileModal.profileVideoQ = profileQuestion.profileVideoQ;
    playerEventProfileModal.userID = auth.userId;
    console.log('CREATE_PLAYER_EVENT_PROFILE - ', JSON.stringify(playerEventProfileModal));
    const savePlayerEventProfile = await playerEventProfileCollection.add(playerEventProfileModal);
    console.log('PLAYER_EVENT_PROFILE_CREATED - ', savePlayerEventProfile.id);
    return savePlayerEventProfile.id;
  }
  async function onCardEntryComplete(paymentResponse) {
    try {
      const squareTransactionsBody = {}
      squareTransactionsBody.paymentResponse = JSON.stringify(paymentResponse);
      squareTransactionsBody.eventID = eventData.eventID;
      squareTransactionsBody.userID = auth.userId;
      console.log("SQUARE_TRANSACTION_SAVE - ", JSON.stringify(squareTransactionsBody));
      const saveSquareTransaction = await squareTransactionsCollection.add(squareTransactionsBody);
      console.log('SAVE_SQUARE_TRANSACTION_DONE - ', saveSquareTransaction.id);
      await createPlayerProfileEntry();
      saveFeesDataToFirebase();
      props.navigation.navigate('EPConfirmationScreen', {
        eventData,
        selectedFeesData,
      });
      return saveFeesDataToFirebase();
    } catch (error) {
      console.log('ERROR_SAVE_TRANSACTION_INFO - ', error);
      Alert.alert('Message', 'Something went wrong!');
    }
  }
  function onCardEntryCancel() {

  }

  const handlePayment = async () => {
    await squarePayment.onStartCardEntry(
      getTotalAmount() * 100,
      auth.userCol.email
    );

    props.navigation.navigate("EPConfirmationScreen", {
      eventData: props.route.params.eventData,
    });
  };
  const getTotalAmount = () => {
    if (selectedFeesType.length == 0) {
      return 0;
    }
    let total = 0;
    selectedFeesType.map(i => {
      total += parseInt(i.userContestPaidAmount / 100);
    });

    return total;
  }
  return (
    <Root >
      <HeaderBlack
        hideMenu
        onMenuPress={() => props.navigation.openDrawer()}
        onBackPress={() => props.navigation.goBack()}
      />
      <ScrollView>
        <View
          style={{ justifyContent: "space-between", flexDirection: "column" }}
        >
          <View style={{ alignSelf: "center", flex: 1 }}>
            <PRLLogo imgStyle={styles.profileImg} />
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 40,
              paddingTop: 10,
            }}
          >
            <View>
              <Text style={{ fontSize: 15, color: '#0B214D', fontWeight: 'bold' }}>{eventData.eventName}</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 0,
              }}
            >
              <Text style={{ fontSize: 15, color: '#0B214D', fontWeight: 'bold' }}>Total: ${getTotalAmount()}.00</Text>
              <Icon
                name="chevron-down"
                size={20}
                onPress={() => setDropdown(!showDropdown)}
                hitSlop={{ top: 50, bottom: 50, left: 50, right: 50 }} />
            </View>
          </View>

          {showDropdown &&
            <View style={{ paddingHorizontal: 40, paddingVertical: 5 }}>
              {
                selectedFeesType.length > 0 &&
                selectedFeesType.map((itr) => {
                  if(itr.contestID == 0) {
                    return null;
                  }
                  return (<View style={{ paddingBottom: 5, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ color: 'black' }}>{itr.contestName}</Text>
                    <Text style={{ fontWeight: 'bold', color: 'black' }}>${itr.userContestPaidAmount / 100}</Text>
                  </View>);
                })
              }
            </View>
          }
          <View
            style={{
              marginTop: 20,
              flexDirection: "row",
              justifyContent: "space-evenly",
              paddingBottom: 10,
            }}
          >
            <View>
              <Button
                onPress={() => handlePayment()}
                buttonStyle={styles.ButtonView}
                title={"Pay with Square"}
              >
                {/* <Text style={styles.ButtonText}>Pay with Square</Text> */}
              </Button>
            </View>
            {/* <View>
              <Button style={styles.ButtonView}>
                <Text style={styles.ButtonText}>Paypal</Text>
              </Button>
            </View> */}
          </View>
        </View>

        <Text
          style={{
            width: "80%",
            alignSelf: "center",
            color: "black",
            marginTop: 30,
            fontWeight: "700",
          }}
        >
          Note: Players Recreation League utilizes Square, a secure third party,
          for credit card processing. PRL does not save any of your credit card
          information.
        </Text>
        {/* <View style={{ alignItems: 'center', paddingTop: 10 }}>
          <View>
            <View >
              <Text style={{ fontSize: 14, color: '#0B214D', fontWeight: 'bold', textAlign: 'left' }}>Email</Text>
            </View>

            <View style={{ paddingBottom: 10 }}>
              <TextInput
                style={{ height: 40, width: 300, elevation: 2, backgroundColor: '#FFFFFF', borderRadius: 8 }}
                placeholder="example@gmail.com"

              />
            </View>

            <View >
              <Text style={{ fontSize: 14, color: '#0B214D', fontWeight: 'bold', textAlign: 'left' }}>Card Information</Text>
            </View>

            <View style={{ paddingBottom: 10 }}>
              <TextInput
                style={{ height: 40, width: 300, elevation: 2, backgroundColor: '#FFFFFF', borderRadius: 8 }}
                placeholder="number"

              />
            </View>

            <View style={{ paddingBottom: 10, justifyContent: 'space-between', flexDirection: 'row' }}>
              <TextInput
                style={{ height: 40, width: 148, elevation: 2, backgroundColor: '#FFFFFF', borderRadius: 8 }}
                placeholder="MM/YY"
              />
              <TextInput
                style={{ height: 40, width: 148, elevation: 2, backgroundColor: '#FFFFFF', borderRadius: 8 }}
                placeholder="CVV"
              />
            </View>
            <View>
              <Text style={{ fontSize: 14, color: '#0B214D', fontWeight: 'bold', textAlign: 'left' }}>Country or Region</Text>
            </View>
            <View>
            </View>

            <View style={{ paddingBottom: 15 }}>
              <DropDownPicker
                items={data2}
                defaultNull
                placeholder="Country name"
                placeholderStyle={{ color: 'rgba(0, 0, 0, 0.6)' }}
                containerStyle={{ height: 40, marginTop: 5 }}
                style={{ backgroundColor: '#ffffff', position: 'absolute', borderRadius: 16 }}
                itemStyle={{ justifyContent: 'flex-start' }}
                dropDownMaxHeight={200}
              />

            </View>
            <View style={{ paddingBottom: 10 }}>
              <TextInput
                style={{ height: 40, width: 300, borderWidth: 0.5, borderColor: '#8e8e8e', backgroundColor: '#FFFFFF', borderRadius: 8 }}
                placeholder="Zip Code"

              />
            </View>
          </View>

          <View style={styles.checkboxContainer}>
            <CheckBox checked={true} color={"#0B214D"} style={{ borderRadius: 30, borderColor: 'black', borderWidth: 1 }} />
            <Text style={styles.label}>Remember this card?</Text>
          </View>


          <View style={{ paddingBottom: 20 }}>
            <Button style={styles.SubmitButton} onPress={() => handlePayment()}>
              <Text style={styles.SubmitButtonView}>Submit Payment</Text>
            </Button>
          </View>

        </View> */}
      </ScrollView>
    </Root>
  );
}

export default connect()(EPCreditCardScreen);
