import React, { useEffect, useState, useRef } from "react";
import { View, Text, ScrollView, Alert, TouchableOpacity } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";
import {
  Header,
  Root,
  TextInput,
  ImageVideoPlaceholder,
  DateInput,
  CustomModalDropDown,
  SingleHeading,
  TextAreaInput,
  TouchableButton,
  CreateEventProgress,
} from "../../../Component";
import { useLoader, useFirebaseUpload } from "../../../hooks";
import Styles from "./indexCss";
import {
  eventCategoriesCollection,
  eventSubCategoriesCollection,
  eventGenreTypesCollection,
  contestTypesCollection,
  firebase,
  eventsCollection,
} from "../../../firebase";
import { wp, hp, getHp, getWp, FONTSIZE } from "../../../utils";
import { useDispatch, useSelector, connect } from "react-redux";
import { readCharities, toggleAlertBox } from "../../../store/actions";
import { updateEventModel, initEventModel } from "../../../store/actions";
import AntDesign from "react-native-vector-icons/AntDesign";
import Entypo from "react-native-vector-icons/Entypo";
Entypo.loadFont();
AntDesign.loadFont();

const ContestTypeScreen = (props) => {
  const [setLoader, LoaderComponent] = useLoader();
  const { firebaseAllCollectionData } = useSelector((s) => s);
  const dispatch = useDispatch();
  const { eventModel } = useSelector((state) => state.event);
  const setEventModel = (newEventModel) => {
    dispatch(updateEventModel(newEventModel));
  };
  const formsRef = useRef({
    contestTypeRef: useRef(),
  });
  const showPopup = ({ text = "", onPressOk }) => {
    return dispatch(
      toggleAlertBox({
        visible: true,
        text: text,
        onPress: () => {
          dispatch(toggleAlertBox("CLOSE"));
          onPressOk();
        },
      })
    );
  };
  const onNextStepPress = () => {
    if (eventModel.createContestFactory.length == 0) {
      showPopup({
        text: "You have to add at-least 1 Contest!",
        onPressOk: () => {},
      });
      return 
    }
    let isAllContestUploaded = eventModel.createContestFactory.every(
      (i) => i.isUploadedOnce
    );
    if (!isAllContestUploaded) {
      showPopup({
        text: "Save All Contest Info!",
        onPressOk: () => {},
      });
      return
    }
    return props.navigation.navigate("EventProfileCreateScreen");
  };
  // useEffect(() => {
  //   const unsubscribe = props.navigation.addListener("focus", () => {
  //     window.onbeforeunload = () => {
  //       return "Data will be lost if you leave the page, are you sure?";
  //     };
  //   });
  //   return () => {
  //     return (window.onbeforeunload = null);
  //   };
  // }, []);

  return (
    <Root>
      <LoaderComponent />
      {/* <Spinner visible={eventModel.loading} /> */}
      <ScrollView
        keyboardShouldPersistTaps={"always"}
        style={Styles.container}
      >
        <Header
          hideMenu
          heading={"Create Event - Contest Type"}
          menuOnPress={() => {
            props.navigation.openDrawer();
          }}
          leftOnPress={() => {
            props.navigation.goBack();
          }}
        />
        <View style={Styles.createContestTypesContainer}>
          <View style={{ width: "90%" }}>
            <CustomModalDropDown
              ref={formsRef.current.contestTypeRef}
              width={"100%"}
              height={45}
              items={eventModel?.contestTypesData || []}
              placeholder="Select Contest Types"
              onSelect={(contest) => {
                setEventModel(eventModel.mutateToContestFactory(contest));
              }}
            />
          </View>
          <View style={Styles.selectedContestContainer}>
            {eventModel.createContestFactory.map((i, index) => {
              return (
                <View style={Styles.selectedContestRow}>
                  <View style={{ flexDirection: "row" }}>
                    {!i.isUploadedOnce ? (
                      <TouchableOpacity
                        onPress={() => {
                          setEventModel(
                            eventModel.mutateToContestFactory(
                              null,
                              "remove",
                              index
                            )
                          );
                        }}
                      >
                        <AntDesign size={18} color={"black"} name={"close"} />
                      </TouchableOpacity>
                    ) : (
                      <Entypo size={18} color={"lightgreen"} name={"check"} />
                    )}
                    <Text style={Styles.selectedContestTextStyle}>
                      {i.isUploadedOnce
                        ? i.uploadedData.contestName
                        : i.selectedContest.contestType}
                    </Text>
                  </View>

                  <TouchableOpacity
                    onPress={() => {
                      props.navigation.navigate("CutomizeContestScreen", {
                        currentContestFactoryIndex: index,
                      });
                    }}
                  >
                    <AntDesign name={"right"} size={18} color={"black"} />
                  </TouchableOpacity>
                </View>
              );
            })}
          </View>

          <Text style={Styles.orTextStyle}>OR</Text>
          <View style={{ width: "90%" }}>
            <TouchableButton
              // type={"small"}
              backgroundColor={"#0B214D"}
              title={"Create Custom Contest"}
              propButtonStyle={Styles.customContestTyle}
              onPress={() => {
                props.navigation.navigate("EventStack", {
                  screen: "CreateContestScreen",
                });
              }}
            />
          </View>
        </View>
        <View style={Styles.nextContainer}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TouchableButton
              type={"prevStep"}
              title={"Previous Step"}
              backgroundColor={"#0B214D"}
              propButtonStyle={{ width: 200 }}
              onPress={() => props.navigation.goBack()}
              titleStyle={{ fontSize: 16 }}
            />
            <View style={{ width: "10%" }} />
            <TouchableButton
              type={"nextStep"}
              title={"Next Step"}
              propButtonStyle={{ width: 200 }}
              onPress={onNextStepPress}
              backgroundColor={"#0B214D"}
              titleStyle={{ fontSize: 16 }}
            />
          </View>

          <CreateEventProgress
            containerStyle={{ marginTop: 30 }}
            selectedIndex={2}
          />
        </View>
        {/* <View style={{ height: 60 }} /> */}
      </ScrollView>
    </Root>
  );
};

export default connect()(ContestTypeScreen);
