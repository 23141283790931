 
//External Imports
import { StyleSheet } from 'react-native';

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from '../../../utils';

export default StyleSheet.create({
    bottomButtonTrayContainer: {
        marginTop: getHp(30),
        marginBottom: getHp(40),
        flexDirection: 'row',
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'space-between'
    },
    charityInfoContainer:{
        marginTop: getHp(8),
        alignItems:'center',
        justifyContent:'center'
    },
    uploadLogoContainerStyle: {
        height: getHp(100),
        width: getHp(100),
        // borderRadius: getHp(100)
    }, 
    childViewStyle:{
        backgroundColor: '#FFF',
        flexDirection: "column",
        justifyContent: "space-between",
        flex: 1
    },
    container: {
        //flex:1,
        //height: "100%",
        backgroundColor: "#fff", 
        
    },
    imageBoxContainer: { 
        alignSelf: "center",
        marginTop: getHp(5),
        height: 100,
        width: 180,
    },
    logoStyle: { 
        height: 100,
        width: 180,
        resizeMode: 'contain'
    },
    formContainer: {
        marginTop: getHp(-50),
        width: "80%",
        alignSelf: "center"
    },
    formHeadingText: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#000"
    },
    inputContainerStyle: {
        marginTop: getHp(5),
    },
    termsConditionContainer:{
        marginTop: getHp(35),
        // width: '95%',
        alignItems: 'center', 
        flexDirection: 'row',
    },
    privacyPolicyStyle: {
        // alignItems:'center',
        fontSize: 15,
        fontWeight: '600',
        color: 'black',
        // marginLeft: getWp(30),
        // lineHeight: getHp(22),
        // marginTop: -getWp(4),
         
    },
    termsConditionSubContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    privactyTouchTextStyle: { 
        top: getWp(4.5),
        fontSize: 15,
        fontWeight: '600',
        color: '#4FC4F6',
     },
     avatarContainerStyle: {
        alignSelf: "center",
        marginTop: getHp(44),
        marginBottom: getHp(30),
        height: getHp(124),
        width: getHp(140),
        justifyContent: 'center',
        alignItems: 'center'
    },
    avatarImageStyle: {
        height: getHp(124),
        width: getHp(140),
    },
    renderExtrasContainer: {
        marginBottom: getHp(80)    ,
        flexDirection: "row",
        alignSelf: "center",
    },
    renderExtrasHeading: {
        fontSize: 16,
        color: '#000'
    },
    clickHereText: {
        color: '#EC2939',
        fontSize:16,
    },
    errorContainer: {
        marginTop: getHp(10),
        marginLeft: getWp(20)
    },
    errorTextStyle: {
        fontSize: 15,
        color: "red"
    }
});