import React, { Fragment } from "react";

class Ads {
  HeaderSection = () => {
    return (
      <Fragment>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
          <ins
            class="adsbygoogle"
            style="display:inline-block;width:728px;height:50px"
            data-ad-client="ca-pub-5599254593925387"
            data-ad-slot="8877009591"
          ></ins>

        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      </Fragment>
    );
  };
}

export default new Ads();
