import React, {useState} from 'react';
import { ActivityIndicator } from "react-native";
import Modal from 'modal-enhanced-react-native-web';
//import Spinner from 'react-native-loading-spinner-overlay';


const useLoader = (initialValue = false) => {
    const [loader, setLoader] = useState(initialValue);

    const Loader = () => {
        return (
            <Modal isVisible={loader}>
              <ActivityIndicator size="large" color='#0B214D' />  
            </Modal>
        );
    }
    return  [
        setLoader,
        Loader
    ]
}

export default useLoader;