class Option {
  selectedValue = "Select";
  selectionValues = ["Select", {}, {}];
  playerData;
  guessData;

  constructor(playerData = {}, guessData = {}) {
    // if (playerData && guessData) {
    // this.playerData = playerData;
    // this.guessData = guessData;
    // }

    if (playerData) {
      this.playerData = playerData;
    }
    if (guessData) {
      this.guessData = guessData;
    }
  }

  static fromJSON = (optionData, guessData = {}) => {
    let newOptionModel = new Option();
    // newOptionModel.selectionValues = [];
    newOptionModel.selectionValues[1] = {
      ...optionData,
      playerName: optionData.player1Name,
    };
    newOptionModel.selectionValues[2] = {
      ...optionData,
      playerName: optionData.player2Name,
    };
    newOptionModel.playerData = optionData;
    newOptionModel.guessData = guessData;
    return newOptionModel;
  };

  setSelectedValue = (value) => {
    this.selectedValue = value;
  };
  setOptions = (value, index) => {
    this.selectionValues[index + 1] = value;
  };
  getWinner = () => {
    let winnerData = {
      winnerID: "",
      winnerName: "",
    };
    if (this.selectedValue.playerName == this.selectedValue.player1Name) {
      winnerData.winnerID = this.selectedValue.player1ID;
      winnerData.winnerName = this.selectedValue.player1Name;
    } else {
      winnerData.winnerID = this.selectedValue.player2ID;
      winnerData.winnerName = this.selectedValue.player2Name;
    }
    return winnerData;
  };
}

export default Option;
