import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  View,
  StyleSheet,
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  Alert,
} from "react-native";
import { useDispatch, useSelector, connect } from "react-redux";
import { useIsFocused, useLinkTo } from "@react-navigation/native";
import { updateUserColData, toggleAlertBox } from "../../store/actions";
import { usersCollection } from "../../firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  Root,
  PRLLogo,
  TextInput,
  Header,
  TouchableButton,
  ImageVideoPlaceholder,
} from "../../Component";
import { useKeyboardStatus, useLoader, useFirebaseUpload } from "../../hooks";
import Styles from "./indexCss";
import SettingModal from "./setting.modal";

const UserSetting = ({ navigation }) => {
  const linkTo = useLinkTo();
  var { convertToBlob, uploadBlobToFirebase } = useFirebaseUpload();
  const createdUserData = useSelector((state) => state.profile.createdUserData);
  const dispatch = useDispatch();
  let {
    auth,
    firebaseAllCollectionData: { isReady, loading, error },
  } = useSelector((state) => state);
  const isScreenFocused = useIsFocused();
  const isKeyboardOpen = useKeyboardStatus();
  const [userData, setUserData] = useState(null);
  const [settingModal, setSettingModal] = useState(SettingModal);
  const [setLoader, LoaderComponent] = useLoader();

  const formsRef = useRef({
    image: useRef(),
  });

  const showPopup = ({ text = "" }) => {
    return dispatch(
      toggleAlertBox({
        visible: true,
        text: text,
        onPress: () => {
          dispatch(toggleAlertBox("CLOSE"));
        },
      })
    );
  };

  let saveSettingDataToFirebase = async (firebaseData) => {
    try {
      const updateProfileResponse = await usersCollection
        .doc(firebaseData.id)
        .update(firebaseData.data);
      let newAuthReduxData = {
        ...auth,
        userCol: {
          ...auth.userCol,
          ...firebaseData.data,
        },
      };
      await AsyncStorage.setItem("userInfo", JSON.stringify(newAuthReduxData));
      dispatch(updateUserColData(newAuthReduxData.userCol));
      setLoader(false);
      setTimeout(() => {
        return showPopup({ text: "Profile Successfully Updated!" });
      }, 200);
    } catch (error) {
      console.log("saveSettingDataToFirebase - ", error);
      throw new Error(error);
    }
  };
  const handleSubmit = async () => {
    try {
      setLoader(true);
      let firebaseSaveData = settingModal.saveToFirebase();
      console.log("JSON_STRING - ", JSON.stringify(firebaseSaveData));

      if (firebaseSaveData.data.userAvatar?.includes("data:")) {
        const userAvatarBlob = await convertToBlob(
          firebaseSaveData.data.userAvatar,
          "profileImages/"
        );
        uploadBlobToFirebase(userAvatarBlob).then((userAvatarURL) => {
          firebaseSaveData.data.userAvatar = userAvatarURL;
          return saveSettingDataToFirebase(firebaseSaveData);
        });
      } else {
        return saveSettingDataToFirebase(firebaseSaveData);
      }
    } catch (error) {
      console.log("UPDATE_PROFILE_ERROR - ", error);
      setLoader(false);
      setTimeout(() => {
        Alert.alert("Message", "Something went wrong!");
      }, 200);
    }
  };

  useEffect(() => {
    if (isScreenFocused) {
      setSettingModal(settingModal.init(auth.userCol));
    }
  }, [isScreenFocused]);

  return (
    <Root>
    <>
      <LoaderComponent />
      <ScrollView
        style={{
          height: "100%",
          width: "99%",
          backgroundColor: "#FFF",
        }}
        showsVerticalScrollIndicator={true}
      >
        <Header
          hideMenu
          heading={"Profile Setting"}
          menuOnPress={() => navigation.openDrawer()}
          leftOnPress={() => navigation.goBack()}
        />
        <View style={Styles.imageBoxContainer}>
          <PRLLogo imgStyle={Styles.logoImgStyle} />
        </View>
        <View style={Styles.formContainer}>
          <Text style={Styles.formHeadingText}>Update your Profile</Text>

          <TextInput
            textContentType={"name"}
            autoCompleteType={"name"}
            containerStyle={Styles.inputContainerStyle}
            placeholder={"Enter Name"}
            value={settingModal.editField.userName}
            onChangeText={(userName) => {
              setSettingModal(
                settingModal.updateEditFields("userName", userName)
              );
            }}
          />

          <TextInput
            textContentType={"nickname"}
            autoCompleteType={"name"}
            containerStyle={Styles.inputContainerStyle}
            placeholder={"Enter Nick Name "}
            value={settingModal.editField.userNickname}
            onChangeText={(userNickname) => {
              setSettingModal(
                settingModal.updateEditFields("userNickname", userNickname)
              );
            }}
          />

          <TextInput
            textContentType={"telephoneNumber"}
            autoCompleteType={"cc-number"}
            containerStyle={Styles.inputContainerStyle}
            placeholder={"Enter Cell Phone Number"}
            value={settingModal.editField.userCellPhone}
            onChangeText={(userCellPhone) => {
              setSettingModal(
                settingModal.updateEditFields("userCellPhone", userCellPhone)
              );
            }}
          />
          {/* <ImageVideoPlaceholder
            ref={formsRef.current.image}
            containerStyle={Styles.avatarContainerStyle}
            imageStyle={Styles.avatarImageStyle}
            type={'photo'}
            viewUri={profileModel.userAvatar}
            renderText={'Add Picture/Avatar'}
            selectedData={userAvatar => {
              setProfileModel(profileModel.update("userAvatar", userAvatar));
            }}
            // resetViewURI={() => {
            //   setProfileModel(profileModel.update("userAvatar", ''));
            // }}
          /> */}
          <ImageVideoPlaceholder
            ref={formsRef.current.image}
            edit={true}
            selectedData={(userAvatar) => {
              setSettingModal(
                settingModal.updateEditFields("userAvatar", userAvatar)
              );
            }}
            viewURI={settingModal.editField.userAvatar}
            type={"photo"}
            mode={"select"}
            containerStyle={Styles.avatarContainerStyle}
            imageStyle={Styles.avatarImageStyle}
            resetViewURI={() =>
              setSettingModal(settingModal.updateEditFields("userAvatar", null))
            }
            renderChildren={false}
          ></ImageVideoPlaceholder>
          <View style={Styles.bottomButtonsTray}>
            <TouchableButton
              //disabled={settingModal.shouldDisableUpdate()}
              type={"small"}
              backgroundColor={"#EC2939"}
              title={"Save"}
              onPress={handleSubmit}
            />
            <TouchableButton
              type={"small"}
              backgroundColor={"#EDCF80"}
              title={"Reset"}
              onPress={() => {
                setSettingModal(settingModal.init(auth.userCol));
              }}
            />
            <TouchableButton
              type={"small"}
              backgroundColor={"#0B214D"}
              title={"Cancel"}
              onPress={() => {
                linkTo("/home");
                // navigation.navigate("HomeScreen");
              }}
            />
          </View>
        </View>
      </ScrollView>
      </>
    </Root>
  );
};

export default connect()(UserSetting);
