import { squareProduction } from "../../../../app.json";

const ENV_TYPE = {
  PRODUCTION: `PRODUCTION`,
  SANDBOX: `SANDBOX`,
};
const CURRENT_ENV = squareProduction ? ENV_TYPE.PRODUCTION : ENV_TYPE.SANDBOX;
const ENV = {
  [ENV_TYPE.PRODUCTION]: {
    appId: "sq0idp-fUQtyqYbf7-rctg1TkLArQ",
    accessToken:
      "EAAAEYjMMib1siF8q0_UtgIMILjUzHOLfeqkVkVBHGyLkZydNuJuzvRuvYfN6c79",
    locationId: "LXM759MRYNJQB",
    firebaseFunction: `https://us-central1-players-recreation-league.cloudfunctions.net/createSquarePayment`,
  },
  [ENV_TYPE.SANDBOX]: {
    appId: "sandbox-sq0idb-3fFpc479kIScMXwgggR5Zw",
    accessToken:
      "EAAAEG0nfb-uTPZFRyWnPTPV_2HC3L2uSsTYoLkbzfjyk8E58UhkDyNEc7wUq74g",
    locationId: "LXM759MRYNJQB",
    firebaseFunction: `https://us-central1-players-recreation-league.cloudfunctions.net/createSquarePaymentSandbox`,
  },
};
function configFunction(callbackAfterPayment, onPaymentFail, total, AppId) {
  console.log('Square App ID --> ', AppId);
  const config = {
    applicationId: AppId.id,
    inputClass: "sq-input",
    autoBuild: false,
    // Customize the CSS for SqPaymentForm iframe elements
    inputStyles: [
      {
        fontSize: "16px",
        lineHeight: "24px",
        padding: "16px",
        placeholderColor: "#a0a0a0",
        backgroundColor: "transparent",
      },
    ],
    // Initialize the credit card placeholders
    cardNumber: {
      elementId: "sq-card-number",
      placeholder: "Card Number",
    },
    cvv: {
      elementId: "sq-cvv",
      placeholder: "CVV",
    },
    expirationDate: {
      elementId: "sq-expiration-date",
      placeholder: "MM/YY",
    },
    postalCode: {
      elementId: "sq-postal-code",
      placeholder: "Postal",
    },
    // SqPaymentForm callback functions
    callbacks: {
      /*
       * callback function: cardNonceResponseReceived
       * Triggered when: SqPaymentForm completes a card nonce request
       */
      cardNonceResponseReceived: async (errors, nonce, cardData) => {
        try {
          if (errors) {
            // Log errors from nonce generation to the browser developer console.
            console.error("Encountered errors:");
            errors.forEach(function (error) {
              console.error("  " + error.message);
            });
            alert("Invalid Card Details!");
            return;
          }
          console.log(
            "Square Payment ---> ",
            JSON.stringify({
              nonce: nonce,
              amount: total * 100,
            })
          );
          const fetchApiResponse = await fetch(
            'https://us-central1-players-recreation-league.cloudfunctions.net/dynamicSquarePayment',
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                nonce: nonce,
                amount: total * 100,
                charityID: AppId.charityID,
                type: CURRENT_ENV
              }),
            }
          );
          if (fetchApiResponse.ok) {
            const jsonResponse = await fetchApiResponse.json();
            if (jsonResponse.paymentSuccess) {
              callbackAfterPayment(jsonResponse);
            } else {
              throw new Error(jsonResponse);
            }
          } else {
            const response = await fetchApiResponse.json();
            throw new Error(response);
          }
        } catch (error) {
          console.log("CATCH_ERROR - ", JSON.stringify(error));
          onPaymentFail(error);
        }
      },
    },
  };
  return config;
}
export default configFunction;
