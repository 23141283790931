import React, { useEffect, useRef, useState } from "react";
import { View, FlatList, TextInput, Text, Image, TouchableOpacity } from "react-native";

import { firebase } from "../../firebase";
import "firebase/firestore";
import { format } from 'date-fns';

const ChatView = (props) => {

    const { gameScheduleId, myUserId, gameID } = props;
    const [messages, setMessages] = useState([]);
    const [chatUsers, setChatUsers] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [sendButtonEnabled, setSendButtonEnabled] = useState(false);

    const unsubscribeRef = useRef();

    const splitIdsArray = (ids) => {
        if (ids.length > 0) {
            let splittedIds = [];
            let seeker = 0;
            while (seeker < ids.length) {
            let elementSplitIds = [];
            for (let idx = 0; idx < 10; idx++) {
                if (seeker + idx < ids.length) {
                elementSplitIds.push(ids[seeker + idx]);
                }
            }

            splittedIds.push(elementSplitIds);
            seeker = seeker + 10;
            }
            return splittedIds;
        } else {
            return [];
        }
    }

    const loadNewUserData = async (newUserIds) => {
        const userIdArraysArray = splitIdsArray(newUserIds);
        const promises = userIdArraysArray.map(idsArray => new Promise(resolve => {
            firebase.firestore().collection("users")
                .where("uid", "in", idsArray)
                .get()
                .then(querySnapshot => {
                    let elems = [];
                    querySnapshot.forEach(documentSnapshot => {
                        let user = documentSnapshot.data();
                        elems = [...elems, user];
                    });
                    resolve(elems);
                });
            }));
        
        const userArraysArray = await Promise.all(promises);

        let users = [];
        userArraysArray.forEach(userArrays => {
            users = [...users, ...userArrays];
        });

        setChatUsers([...chatUsers, ...users]);
    }

    useEffect(() => {
        if (myUserId !== null && gameScheduleId !== null) {
            const ref = firebase.firestore().collection("gameSchedule").doc(gameScheduleId).collection("messages").orderBy("when", "desc");
            unsubscribeRef.current = ref.onSnapshot(querySnapshot => {
                let msgs = [];
                let newUserIds = [];
                querySnapshot.forEach((doc) => {
                    const message = doc.data();
                    const userId = message.userId;
                    if (!(chatUsers.includes(user => user.uid === userId))) {
                        newUserIds.push(userId);
                    }
                    msgs.push(message);
                });
                setMessages(msgs);
                
                if (newUserIds.length > 0) {
                    loadNewUserData(newUserIds);
                }
            });
        }
    }, [gameScheduleId, myUserId]);

    useEffect(() => {
        return () => {
            if (unsubscribeRef?.current) {
                console.log("unsubscribe ref for chat");
                unsubscribeRef?.current();
            }
        }
    }, []);

    useEffect(() => {
        if (inputMessage === "") {
            setSendButtonEnabled(false);
        } else {
            setSendButtonEnabled(true);
        }
    }, [inputMessage]);

    const dateShouldBeShown = (index) => {
        if (index === messages.length - 1) {
            return true;
        } else {
            const currentMessage = messages[index];
            const prevMessage = messages[index + 1];

            const currentMessageDate = format(new Date(currentMessage.when), 'yyyy-MM-dd');
            const prevMessageDate = format(new Date(prevMessage.when), 'yyyy-MM-dd');

            return currentMessageDate !== prevMessageDate;
        }
    }

    const userBarAndTimeShowBeShown = (index) => {
        if (dateShouldBeShown(index)) {
            return true;
        } else {
            const currentMessage = messages[index];
            const prevMessage = messages[index + 1];

            if (currentMessage.userId !== prevMessage.userId) {
                return true;
            } else {
                const timeDiff = currentMessage.when - prevMessage.when;
                return (timeDiff > 3 * 60 * 1000);
            }
        }
    }

    const userNameForMessage = (message) => {
        const user = chatUsers.find(chatUser => chatUser.uid === message.userId);
        if (user === undefined) {
            return '---';
        } else {
            if (user.userName !== undefined && user.userName !== "") {
                return user.userName;
            } else if (user.userNickName !== undefined && user.userNickName !== "") {
                return user.userNickName;
            } else {
                return user.email;
            }
        }
    }

    const userAvatarUrlForMessage = (message) => {
        const user = chatUsers.find(chatUser => chatUser.uid === message.userId);
        if (user === undefined) {
            return '';
        } else {
            return user.userAvatar;
        }
    }

    const renderMessageItem = (gameMessage, index) => {
        return (
            <View style={{flex: 1, flexDirection: 'column', transform: [{scaleY: -1}]}}>
                {dateShouldBeShown(index) &&
                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
                        <View style={{flex: 1, backgroundColor:"rgba(0,0,0,0.1)", height: 1}} />
                        <Text style={{color: 'gray', marginLeft: 20, marginRight: 20}}>
                            {format(new Date(gameMessage.when), 'EEEE, MMM d')}
                        </Text>
                        <View style={{flex: 1, backgroundColor:"rgba(0,0,0,0.1)", height: 1}} />
                    </View>
                }
                <View style={{
                    flexDirection: 'row',  
                    paddingLeft: 15,
                    flex: 1,
                    marginTop: userBarAndTimeShowBeShown(index) ? 10 : 0,
                    marginBottom: (index === 0) ? 15 : 0
                    }}>
                    {userBarAndTimeShowBeShown(index) ? 
                        <Image 
                            style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: 'rgba(0,0,0,0.3)' }}
                            source={{uri: userAvatarUrlForMessage(gameMessage)}}/>
                    :
                        <View style={{ width: 30 }} />
                    }
                    
                    <View style={{flexDirection: 'column', marginLeft: 10, marginRight: 15, flex: 1}}>
                        {userBarAndTimeShowBeShown(index) &&
                            <View style={{flexDirection: 'row', alignItems:'flex-end'}}>
                                <Text style={{fontWeight: 'bold', fontSize: 14}}>
                                    { userNameForMessage(gameMessage) }
                                </Text>
                                <Text style={{color: 'gray', fontSize: 11, marginLeft: 5}}>
                                    {format(new Date(gameMessage.when), 'h:mm a')}
                                </Text>
                            </View>
                        }
                        <Text style={{ marginTop: 5}}>
                            {gameMessage.content}
                        </Text>
                    </View>
                </View>
            </View>
        );
    };

    const handleSend = () => {
        const messageData = {
            userId: myUserId,
            gameID: gameID,
            content: inputMessage,
            when: new Date().getTime()
        };

        setInputMessage("");
        firebase.firestore().collection("gameSchedule").doc(gameScheduleId).collection("messages").doc()
            .set(messageData)
            .catch(err => {
                console.log("Error while sending messages", err);
            });
    }

    return (
        <View style={{
            width: '100%', 
            height: '100%', 
            backgroundColor: 'white', 
            borderRadius: 10, 
            borderColor: 'rgba(0,0,0,0.1)', 
            padding: 15
        }}>
            <FlatList 
                style={{ flex: 1, backgroundColor: '#ffffff', transform: [{scaleY: -1}] }}
                data={ messages }
                extraData={ chatUsers }
                keyboardShouldPersistTaps="handled"
                renderItem={({item, index}) => renderMessageItem(item, index)}/>
            
            <View style={{ 
                height: 1,
                backgroundColor: '#dedede'
            }} />

            <View style={{
                width: '100%',
                flexDirection: 'row',
                height: 100
            }}>
                <TextInput 
                    multiline
                    placeholder="Enter your message..."
                    value={inputMessage}
                    onChangeText={setInputMessage}
                    style={{
                        padding: 10,
                        flex: 1,
                        backgroundColor: '#f6f6f6'
                    }}/>
                <TouchableOpacity
                    onPress={handleSend}
                    disabled={!sendButtonEnabled}
                    style={{
                        alignItems: 'center',
                        alignSelf: 'flex-end',
                        width:60,
                        height: 30
                    }}>
                        <Text style={{
                            color: sendButtonEnabled ? 'black' : 'gray'
                        }}>
                            Send
                        </Text>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default ChatView;