//import liraries
import React, { Component } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import AdSense from "react-adsense";

// create a component
const AdBar = () => {
  return (
    <ScrollView style={styles.container}>
      {/* <AdSense.Google client="ca-pub-5557913403232609" slot="2639931970" /> */}
      {/* <AdSense.Google
        client="ca-pub-5557913403232609"
        slot="2639931970"
        style={{ display: "block" }}
        format="auto"
        responsive="true"
        layoutKey="-gw-1+2a-9x+5c"
      /> */}
      {/* <AdSense.Google
        client="ca-pub-5557913403232609"
        slot="2639931970"
        style={{ width: 300, height: 300, float: "left" }}
        format=""
      /> */}
    </ScrollView>
  );
};

// define your styles
const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "20%",
    backgroundColor: "#0B214D",
  },
});

//make this component available to the app
export default AdBar;
