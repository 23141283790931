import React, { Fragment, useState, useEffect } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View, Text } from "react-native";

//Import Custom Components
import HeaderSection from "../ContestInfoDetail/HeaderSection";
import ContestDesc from "../ContestInfoDetail/ContestDesc";
import ContestDescSection from "../ContestInfoDetail/ContestDescSection";
import ContestRule from "../ContestInfoDetail/ContestRule";
import ContestScoring from "../ContestInfoDetail/ContestScoring";
import ContestEquipment from "../ContestInfoDetail/ContestEquipment";
import ContestGallery from "../ContestInfoDetail/ContestGallery";
import ContestInfoModel from "../ContestInfoDetail/contestInfo.model";
import EditContestScreen from "../ContestInfoDetail/indexEdit";
import { useLoader } from "../../../hooks";
import {
  transformFirebaseValues,
  sortArrayAlphabatically,
} from "../../../utils";
import { Root } from "../../../Component";
import { useRoute } from "@react-navigation/native";
import {
  contestsCollection,
  contestScoringTypesCollection,
  eventsCollection,
  contestBracketTypesCollection,
} from "../../../firebase";
import { toggleAlertBox } from "../../../store/actions";
import { useSelector, useDispatch, connect } from "react-redux";

const ContestInfo = (props) => {
  const dispatch = useDispatch();
  const route = useRoute();
  const [setLoader, LoaderComponent] = useLoader();
  const [shouldEdit, setEdit] = useState(false);
  const [InvalidEvent, setInvalidEvent] = useState(true);
  const [InvalidContest, setInvalidContest] = useState(true);
  const [LoadingData, setLoadingData] = useState(true);
  const [contestIModel, setContestIModel] = useState(() => ContestInfoModel);
  var { firebaseAllCollectionData, auth } = useSelector((s) => s);
  let eventDetails = {};

  const showPopup = ({ text = "" }) => {
    return dispatch(
      toggleAlertBox({
        visible: true,
        text: text,
        onPress: () => {
          dispatch(toggleAlertBox("CLOSE"));
        },
      })
    );
  };

  useEffect(() => {
    (async () => {
      setLoader(true);
      // if (firebaseAllCollectionData.loading) {
      //   return;
      // }
      let contestId = props.route.params.ContestId;
      let eventId = props.route.params.EventId;
      let data = false;
      let data2 = false;
      const contestBracketTypesQuery =
        await contestBracketTypesCollection.get();
      let allBracketTypeData = transformFirebaseValues(
        contestBracketTypesQuery,
        "name",
        [{ isSelected: false }]
      );

      const eventCollectionResponse = await eventsCollection
        .where("eventID", "==", parseInt(eventId))
        .get();
      const eventGetData = transformFirebaseValues(
        eventCollectionResponse,
        "eventID"
      );
      eventGetData.map((event, index) => {
        if (event.eventID == eventId) {
          data = true;
          setInvalidEvent(false);
          eventDetails = { ...event };
        }
      });

      // firebaseAllCollectionData.firebaseCollectionData.eventsData.map(
      //   (event, index) => {
      //     if (event.eventID == eventId) {
      //       data = true;
      //       eventDetails = { ...event };
      //     }
      //   }
      // );
      let contestData = await contestsCollection
        .where("contestID", "==", parseInt(contestId))
        .get();
      let contestDet = {};
      contestData.docs.map((singleContest) => {
        console.log("hhhh", singleContest.id);
        setInvalidContest(false);
        data2 = true;
        contestDet = { ...singleContest.data(), id: singleContest.id };
        return contestDet;
      });
      const contestScoringTypes = await contestScoringTypesCollection.get();
      let transContest = { ...contestDet, id: contestDet.id };
      console.log("trans contest -----", contestDet);
      if (auth.userCol.userType == "admin") {
        setEdit(true);
      } else if (eventDetails.organizerID == auth.userId) {
        setEdit(true);
      }
      if (!data || !data2) {
        setLoader(false);
        setLoadingData(false);
        if (!data && !data2) {
          showPopup({ text: "Invalid Event Id and Contest Id" });
        } else if (!data) {
          showPopup({ text: "Invalid Event Id" });
        } else if (!data2) {
          showPopup({ text: "Invalid Contest Id" });
        }
        return;
      }
      setContestIModel(
        contestIModel.init(
          transContest,
          eventDetails,
          allBracketTypeData,
          contestScoringTypes
        )
      );
      setLoader(false);
    })();
  }, [props.route.params]);

  return (
    <Root>
      <LoaderComponent />
      <ScrollView showsVerticalScrollIndicator style={styles.scrollView}>
        {!contestIModel.loading && LoadingData ? (
          contestIModel.mode == 0 ? (
            <Fragment>
              <HeaderSection
                shouldEdit={shouldEdit}
                onContestEdit={() => {
                  setContestIModel(contestIModel.update("mode", 1));
                }}
                navigation={props.navigation}
                EventID={props.route.params.EventId}
              />
              <ContestDesc
                Contest={contestIModel.ContestDetails}
                Event={contestIModel.EventDetails}
              />
              <ContestDescSection
                contestBracketTypes={contestIModel.allBracketTypeData}
                Contest={contestIModel.ContestDetails}
                Event={contestIModel.EventDetails}
              />
              <ContestRule
                Contest={contestIModel.ContestDetails}
                Event={contestIModel.EventDetails}
              />
              <ContestScoring
                Contest={contestIModel.ContestDetails}
                Event={contestIModel.EventDetails}
              />
              <ContestEquipment
                Contest={contestIModel.ContestDetails}
                Event={contestIModel.EventDetails}
              />
              <ContestGallery Contest={contestIModel.ContestDetails} />
            </Fragment>
          ) : (
            <EditContestScreen
              contestIModel={contestIModel}
              setContestIModel={setContestIModel}
            />
          )
        ) : null}
        {!LoadingData &&
          (InvalidEvent && InvalidContest ? (
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <Text style={styles.invalidIdText}>
                Invalid Event Id and Contest Id
              </Text>
            </View>
          ) : InvalidEvent ? (
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <Text style={styles.invalidIdText}>Invalid Event Id</Text>
            </View>
          ) : InvalidContest ? (
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <Text style={styles.invalidIdText}>Invalid Contest Id</Text>
            </View>
          ) : null)}
        <View style={styles.spacingView} />
      </ScrollView>
    </Root>
  );
};
// export default class ContestInfo extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       ContestDetails: props.route.params.Detail,
//       EventDetails: props.route.params.EventDetail,
//     };
//   }

//   render() {
//     const { ContestDetails, EventDetails } = this.state;
//     return (
//       <ScrollView
//         showsVerticalScrollIndicator={false}
//         contentInsetAdjustmentBehavior="automatic"
//         style={styles.scrollView}
//       >
//         <HeaderSection navigation={this.props.navigation} />
//         <ContestDesc Contest={ContestDetails} Event={EventDetails} />
//         <ContestDescSection Contest={ContestDetails} Event={EventDetails} />
//         <ContestRule Contest={ContestDetails} Event={EventDetails} />
//         <ContestScoring Contest={ContestDetails} Event={EventDetails} />
//         <ContestEquipment Contest={ContestDetails} Event={EventDetails} />
//         <ContestGallery />
//       </ScrollView>
//     );
//   }
// }

const styles = StyleSheet.create({
  scrollView: {
    height: "100%",
    width: "70%",
    backgroundColor: "#FFF",
  },
  spacingView: {
    marginVertical: 20,
  },
  invalidIdText: {
    fontSize: 20,
    fontWeight: "700",
    marginTop: 100,
  },
});

export default ContestInfo;
