import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  View,
  StyleSheet,
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  Alert,
} from "react-native";
import Loader from "react-native-loading-spinner-overlay";
import { useDispatch, useSelector } from "react-redux";
import { useIsFocused } from "@react-navigation/native";
import { usersCollection } from "../../../firebase";
import {
  PRLLogo,
  TextInput,
  TouchableButton,
  AuthFooter,
  ImageVideoPlaceholder,
} from "../../../Component";
import { useKeyboardStatus, useLoader, useFirebaseUpload } from "../../../hooks";
import * as api from "../../../store/api";
import Styles from "./indexCss";
import ProfileModel from "./Profile.model";
import AsyncStorage from "@react-native-async-storage/async-storage";

const CreateProfileScreen = ({ navigation }) => {
  const formsRef = useRef({
    userAvatar: useRef(),
  });
  const createdUserData = useSelector((state) => state.profile.createdUserData);
  const dispatch = useDispatch();
  const isKeyboardOpen = useKeyboardStatus();
  const { auth } = useSelector((state) => state); 
  const [profileModel, setProfileModel] = useState(ProfileModel);
  const [setLoader, LoaderComponent] = useLoader();
  const { convertToBlob, uploadBlobToFirebase } = useFirebaseUpload();
  console.log('user data ---> ', auth?.userCol)
  const handleSubmit = async () => {
    try {
      setLoader(true);
      let profileData = profileModel.saveToFirebase();
      const pictureBlob = await convertToBlob(
        profileData.userAvatar,
        "profileImages/"
      );
      uploadBlobToFirebase(pictureBlob).then(async (userAvatar) => {
        profileData.userAvatar = userAvatar;
        
        let userID = createdUserData?.uid ? createdUserData.uid : auth?.userCol?.uid
        const updatedUserResponse = await usersCollection
          .doc(userID)
          .update(profileData);
        setLoader(false);
        setTimeout(async () => {
          window.alert("Profile Successfully Updated!")
          if(createdUserData?.uid){
            navigation.navigate("LoginScreen")
          }else {
            let data = await AsyncStorage.getItem("userInfo");
            const userColResponse = await usersCollection
            .doc(auth?.userCol?.uid)
            .get();
            let userCol = userColResponse.data();
            let loginRes = { ...data, userCol: userCol };
            await AsyncStorage.setItem("userInfo", JSON.stringify(loginRes));
            navigation.navigate("Home")
          }
          
          // Alert.alert("Message", "Profile Successfully Updated!", [
          //   {
          //     text: "Okay",
          //     onPress: () => navigation.navigate("LoginScreen"),
          //   },
          // ]);
        }, 200);
      });
    } catch (error) {
      console.log("CREATE_PROFILE_ERROR - ", error);
      setLoader(false);
      setTimeout(() => {
        Alert.alert("Message", "Something went wrong!");
      }, 200);
    }
  };
  
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      setProfileModel(profileModel.reset());
    }
  }, [isFocused]);

  const RenderError = ({ error }) => {
    return (
      <View style={Styles.errorContainer}>
        <Text style={Styles.errorTextStyle}>{error}</Text>
      </View>
    );
  };

  return (
    <>
      <LoaderComponent />
      <ScrollView style={Styles.container}>
        <View style={Styles.imageBoxContainer}>
          <PRLLogo imgStyle={{height: 100, width: 180, resizeMode: 'contain'}} />
        </View>
        <View style={Styles.formContainer}>
          <Text style={Styles.formHeadingText}>Create Initial Profile</Text>

          <TextInput
            containerStyle={Styles.inputContainerStyle}
            placeholder={"Enter Name"}
            value={profileModel.userName}
            onChangeText={(userName) => {
              setProfileModel(profileModel.update("userName", userName));
            }}
          />

          <TextInput
            containerStyle={Styles.inputContainerStyle}
            placeholder={"Enter Nick Name"}
            value={profileModel.userNickname}
            onChangeText={(userNickname) => {
              setProfileModel(
                profileModel.update("userNickname", userNickname)
              );
            }}
          />

          <TextInput
            containerStyle={Styles.inputContainerStyle}
            placeholder={"Enter Cell Phone Number"}
            value={profileModel.userCellPhone}
            onChangeText={(userCellPhone) => {
              setProfileModel(
                profileModel.update("userCellPhone", userCellPhone)
              );
            }}
          />
          <ImageVideoPlaceholder
            ref={formsRef.current.userAvatar}
            onDeleteImgProp={() => {
              setProfileModel(profileModel.update("userAvatar", ""));
            }}
            containerStyle={Styles.avatarContainerStyle}
            imageStyle={Styles.avatarImageStyle}
            type={"photo"}
            renderText={"Add Picture/Avatar"}
            selectedData={(userAvatar) => {
              setProfileModel(profileModel.update("userAvatar", userAvatar));
            }}
          />
          <View style={Styles.bottomButtonsTray}>
            <TouchableButton
              disabled={!profileModel.isDisabled()}
              type={"small"}
              backgroundColor={"#EC2939"}
              title={"Create"}
              onPress={handleSubmit}
            />
            <TouchableButton
              type={"small"}
              backgroundColor={"#EDCF80"}
              title={"Clear"}
              onPress={() => {
                formsRef.current.userAvatar.current.reset();
                setProfileModel(profileModel.reset());
              }}
            />
            <TouchableButton
              type={"small"}
              backgroundColor={"#0B214D"}
              title={"Cancel"}
            />
          </View>
        </View>
      </ScrollView>
      {/* <AuthFooter /> */}
    </>
  );
};

export default CreateProfileScreen;
