const Colors = {
  Blue: '#0B214D',
  Red: '#EC2939',
  Yellow: '#EDCF80',
  Grey: '#C4C4C4',
  Black: '#01080C',
  White: '#FFF'
};

export default Colors
