import React, { useState, Fragment } from "react";
import { ScrollView, View } from "react-native";
import { TextInput } from "../../Component";
import Cockpit from "./Cockpit";
import Rounds from "./Rounds";
const BracketUI = (props) => {
  const { bracketModel, onSelectPlayers, setUsersBracketName, userDetails } =
    props;
  const { bracketFactory, isViewMode, outerBracketData } = bracketModel;
  let [selectedBracketIndex, setSelectedBracketIndex] = useState(0);
  let [userBracketName, setUserBracketName] = useState("");
  let currentBracket = bracketFactory[selectedBracketIndex] || null;
  if (!bracketModel.isBracketAvail) {
    return null;
  }
  if (!currentBracket) {
    return null;
    return <center>Something went wrong!</center>;
  }
  const onSelectPlayersRound = (
    isUp = false,
    bracketIndex,
    roundIndex,
    optionIndex,
    value
  ) => {
    return onSelectPlayers(
      isUp,
      bracketIndex,
      roundIndex,
      optionIndex,
      value,
      userBracketName
    );
  };
  return (
    <Fragment>
      {!isViewMode && (
        <View style={{ width: "70%", alignSelf: "center", marginTop: -40 }}>
          <TextInput
            containerStyle={{
              width: "100%",
              alignSelf: "center",
              marginVertical: 20,
              height: 50,
            }}
            inputStyle={{ height: 45 }}
            placeholder={"Enter Bracket Name"}
            value={userBracketName}
            onChangeText={(name) => {
              setUserBracketName(name);
            }}
          />
        </View>
      )}

      {outerBracketData.length > 0 && (
        <Cockpit
          isViewMode={isViewMode}
          onSelectFromUp={onSelectPlayersRound.bind(null, true)}
          outerBracketData={outerBracketData}
        />
      )}

      <div
        className="tabset"
        style={{ width: "100%", maxWidth: "2560px", padding: "10px" }}
      >
        {bracketFactory.map((singleBracket, index, brArr) => {
          let labelCal = 100 / brArr.length;
          let labelWidth = `${labelCal}%`;
          let fontSize = "12px";
          return (
            <Fragment>
              <input
                onClick={() => {
                  setSelectedBracketIndex((i) => index);
                }}
                type="radio"
                name="tabset"
                id={`tab${index}`}
                aria-controls={`tabAr${index}`}
                checked={selectedBracketIndex == index}
              />
              <label
                style={{
                  width: labelWidth,
                  fontSize: fontSize,
                  fontWeight: "bold",
                }}
                for={`tab${index}`}
              >
                {
                  singleBracket.rounds[0].options[0].selectionValues[1]
                    .bracketName
                }
                {" - " +
                  singleBracket.rounds[0].options[0].selectionValues[1]
                    .bracketNumber}
              </label>
            </Fragment>
          );
        })}

        <div class="tab-panels">
          {bracketFactory.map((singleBracket, index) => {
            return (
              <section id={`tabAr-${index}`} class="tab-panel">
                <Rounds
                  onSelectPlayersRound={onSelectPlayersRound.bind(
                    null,
                    false,
                    index
                  )}
                  rounds={singleBracket.rounds}
                  isViewMode={isViewMode}
                  userDetails={userDetails}
                />
              </section>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default BracketUI;

// import React, { useState, Fragment } from "react";
// import Cockpit from "./Cockpit";
// import Rounds from "./Rounds";
// const BracketUI = (props) => {
//   const { bracketModel, onSelectPlayers } = props;
//   const { bracketFactory, isViewMode, outerBracketData } = bracketModel;
//   let [selectedBracketIndex, setSelectedBracketIndex] = useState(0);
//   let currentBracket = bracketFactory[selectedBracketIndex] || null;
//   if (!bracketModel.isBracketAvail) {
//     return null;
//   }
//   if (!currentBracket) {
//     return null;
//     return <center>Something went wrong!</center>;
//   }
//   const onSelectPlayersRound = (
//     bracketIndex,
//     roundIndex,
//     optionIndex,
//     value
//   ) => {
//     onSelectPlayers(bracketIndex, roundIndex, optionIndex, value);
//   };
//   return (
//     <Fragment>
//       {outerBracketData.length > 0 && (
//         <Cockpit outerBracketData={outerBracketData} />
//       )}
//       <div className="tabset">
//         {bracketFactory.map((singleBracket, index, brArr) => {
//           console.log("BRR_LEN - ", brArr.length);
//           let labelCal = 100 / brArr.length;
//           let labelWidth = `${labelCal}%`;
//           return (
//             <Fragment>
//               <input
//                 onClick={() => {
//                   setSelectedBracketIndex((i) => index);
//                 }}
//                 type="radio"
//                 name="tabset"
//                 id={`tab${index}`}
//                 aria-controls={`tabAr${index}`}
//                 checked={selectedBracketIndex == index}
//               />
//               <label style={{ width: labelWidth }} for={`tab${index}`}>
//                 Bracket {`${index + 1}`}
//               </label>
//             </Fragment>
//           );
//         })}

//         <div class="tab-panels">
//           {bracketFactory.map((singleBracket, index) => {
//             return (
//               <section id={`tabAr-${index}`} class="tab-panel">
//                 <Rounds
//                   onSelectPlayersRound={onSelectPlayersRound.bind(null, index)}
//                   rounds={singleBracket.rounds}
//                   isViewMode={isViewMode}
//                 />
//               </section>
//             );
//           })}
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default BracketUI;
