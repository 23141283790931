import React, { Fragment } from "react";
import {
  View,
  StyleSheet,
  SafeAreaView,
  StatusBar,
  Platform,
  ScrollView,
  KeyboardAvoidingView,
} from "react-native";
import { useSelector, useDispatch, connect } from "react-redux";

import SideNav from "../../Routes/SideDrawer/SideNav";
import AdBar from "../AdBar";

const MyWrapper = Platform.select({
  ios: SafeAreaView,
  android: View,
  web: View,
});

const RootComponent = ({
  children,
  headerColor,
  footerColor,
  barStyle,
  childViewStyle = {},
}) => {
  var { auth } = useSelector((s) => s);
  const setBarStyle =
    barStyle === "dark"
      ? "dark-content"
      : barStyle === "light"
      ? "light-content"
      : "default";
  return (
    <>
      <StatusBar barStyle={setBarStyle} />
      <MyWrapper
        style={[
          style.customStatusBar,
          headerColor && { backgroundColor: headerColor },
        ]}
      />
      <View
        style={[
          style.body,
          childViewStyle,
          footerColor && { backgroundColor: footerColor },
        ]}
      >
        <View style={{ height: "100%", width: "100%", flexDirection: "row" }}>
          <SideNav />
          {children}
          <AdBar />
        </View>
      </View>
    </>
  );
};

const style = StyleSheet.create({
  customStatusBar: {
    flex: 0,
    backgroundColor: "#F8F8F8",
  },
  body: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
});

export default RootComponent;
