export const TOGGLE_ALERT_BOX = 'TOGGLE_ALERT_BOX';

export const toggleAlertBox = (iPayload = {}) => {
    let payload = null;
    if (iPayload == 'CLOSE') {
        payload = {
            visible: false,
            text: '',
            onPress: null
        }
    }else {
        payload = {...iPayload};
    }
    return {
        type: TOGGLE_ALERT_BOX,
        payload
    }
}