import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import React, { Component, useCallback, useEffect } from "react";
import "react-native-gesture-handler";
import { connect, useDispatch, useSelector } from "react-redux";
import SideDrawer from "./src/Routes/SideDrawer/SideDrawer";
import { Colors } from "./src/Routes/SideDrawer/tools";
import {
  AuthStack,
  ChallengesListStack,
  CharitiesStack,
  EventInfoStack,
  EventStack,
  GamesListStack,
  HomeStack,
  Stack,
  UserSettingStack,
} from "./src/Routes/stacks";
import BracketEntryScreen from "./src/Screens/BracketEntry";
import CompetitorDrawerScreen from "./src/Screens/CompetitorScreen";
import SeedingsScreen from "./src/Screens/SeedingsScreen";
import {
  fetchAppAboutInfo,
  initFirebaseCollectionsData,
  tryAutoLoginAction,
} from "./src/store/actions";

const Drawer = createDrawerNavigator();

const MainDrawer = () => {
  const { firebaseAllCollectionData, auth } = useSelector((s) => s);
  const dispatch = useDispatch();
  const loadFirebaseCollections = useCallback(() => {
    dispatch(initFirebaseCollectionsData(auth));
  });
  useEffect(() => {
    loadFirebaseCollections();
  }, []);
  return (
    <Drawer.Navigator
      drawerContent={(props) => <SideDrawer {...props} />}
      drawerStyle={{ backgroundColor: Colors.blue }}
    >
      <Drawer.Screen name="Home" component={HomeStack} />
      <Drawer.Screen name="EventInfoStack" component={EventInfoStack} />
      <Drawer.Screen name="EventStack" component={EventStack} />
      <Drawer.Screen name="CharityStack" component={CharitiesStack} />
      <Drawer.Screen
        name="GamesList"
        component={GamesListStack}
        initialParams={{ isMine: false }}
      />
      <Drawer.Screen
        name="MyGamesList"
        component={GamesListStack}
        initialParams={{ isMine: true }}
      />
      <Drawer.Screen
        name="ChallengesListStack"
        component={ChallengesListStack}
      />
      <Drawer.Screen name="UserSettingStack" component={UserSettingStack} />
      <Drawer.Screen
        name="CompetitorDrawerScreen"
        component={CompetitorDrawerScreen}
      />
      <Drawer.Screen name="SeedingsScreen" component={SeedingsScreen} />
      <Drawer.Screen name="BracketEntryScreen" component={BracketEntryScreen} />
    </Drawer.Navigator>
  );
};
const GenerateConfig = (isAuth) => {
  return {
    screens: {
      Main: {
        screens: {
          Home: {
            screens: {
              ReviewProfileScreen: "/reviewProfile",
              ProfileScreen: "/profile",
              HomeScreen: "/home",
              AboutScreen: "/about",
            },
          },
          EventInfoStack: {
            screens: {
              JoinEventScreen: "/joinEvent",
              ContestInfoScreen: isAuth ? "/contestInfo" : "/contestInf",
              PlayerListScreen: "/playerList",
              PlayerProfileScreen: "/playerProfile",
              EventInfoScreen: isAuth ? "/eventInformation" : "/eventInfo",
              AddNewContestScreen: "/addContest",
              EventPaymentSignupScreen: "/paymentSignup",
              EPCreditCardScreen: "/cardPayment",
              EPConfirmationScreen: "/paymentConfirmation",
              CreateBracketGroup: "/createBracketGroup",
            },
          },
          EventStack: {
            screens: {
              CreateEventScreen: "/createEvent",
              ContestTypeScreen: "/ContestType",
              CutomizeContestScreen: "/cutomizeContest",
              CreateContestScreen: "/createContest",
              EventProfileCreateScreen: "/createEventProfile",
              EventFeesScreen: "/eventFees",
            },
          },
          CharityStack: {
            screens: {
              CreateCharityScreen: "/createCharity",
              PlayerProfileEntry: "/playerProfileEntry",
              PlayerProfileList: "/playerProfileList",
              ViewCharityScreen: isAuth ? "/viewCharity" : "viewCharit",
              ViewAthleteScreen: isAuth ? "/viewStudentAthlete" : "viewAthlete",
              EditCharityScreen: "/editCharity",
            },
          },
          GamesList: {
            screens: {
              AllGames: "/allGames",
              GameScreen: "/game",
              UpcomingGameScreen: "/upcomingGame",
              GameChallengesView: "/gameChallenges",
              CompletedGameScreen: "/completedGame",
              JudgeScoreGameScreen: "/judgeScoreGame",
              UserScoringView: "/UserScoringView",
            },
          },
          MyGamesList: {
            screens: {
              AllGames: "/myAllGames",
              GameScreen: "/myGame",
              UpcomingGameScreen: "/myUpcomingGame",
              GameChallengesView: "/myGameChallenges",
              CompletedGameScreen: "/myCompletedGame",
            },
          },
          ChallengesListStack: {
            screens: {
              ChallengesList: "/challengesList",
            },
          },
          UserSettingStack: {
            screens: {
              UserSettingScreen: "/setting",
            },
          },
          CompetitorDrawerScreen: "/competitors",
          SeedingsScreen: "/seedings",
          BracketEntryScreen: "/bracketEntry/:bracketType",
        },
      },

      AuthScreen: {
        screens: {
          LoginScreen: "/login",
          RegisterScreen: "/register",
          CreateInitialProfileScreen: "/initialProfile",
          ResetPasswordScreen: "/resetPassword",
          TermsConditionsScreen: "/terms&conditions",
          ContestInfoScreen: !isAuth ? "/contestInfo" : "/contestInf",
          EventInfoScreen: !isAuth ? "/eventInformation" : "/eventInfo",
          ViewCharityScreen: !isAuth ? "/viewCharity" : "viewCharit",
        },
      },
    },
  };
};

import CustomPopup from "./src/Component/CustomPopup";

class App extends Component {
  componentDidMount() {
    this.props.tryAutoLogin();
    this.props.getAppInfoData();
    document.title = "PRL APP";
  }

  render() {
    if (!this.props.autoLogin) {
      return null;
    }
    let genLinking = GenerateConfig(this.props.isAuthenticated);
    let link = {
      prefixes: ["http://localhost:19006/"],
      config: genLinking,
    };
    return (
      <NavigationContainer linking={link}>
        <CustomPopup />
        <Stack.Navigator>
          {!this.props.isAuthenticated ? (
            <Stack.Screen
              name="AuthScreen"
              component={AuthStack}
              options={{
                headerShown: false,
              }}
            />
          ) : (
            <Stack.Screen
              name="Main"
              component={MainDrawer}
              options={{
                headerShown: false,
              }}
            />
          )}
        </Stack.Navigator>
      </NavigationContainer>
    );
  }
}

/*
<Fragment>
              <Stack.Screen
                name="home"
                component={HomeScreen}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="viewevent"
                component={ViewEvent}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="createEvent"
                component={CreateEventScreen}
                options={{ headerShown: false }}
              />
            </Fragment>
            */
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuth,
    autoLogin: state.auth.autoLogin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tryAutoLogin: () => dispatch(tryAutoLoginAction()),
    getAppInfoData: () => dispatch(fetchAppAboutInfo()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
