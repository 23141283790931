 
import { sliceIntoChunks, sortArrayAlphabatically } from "../../utils/array";
import OptionModel from "./Option";

class Round {
  options = [];
  static fromJSON = (roundsData = [], index, bracketGuessData = []) => {
    roundsData = sortArrayAlphabatically(roundsData, "gameNumber");
    let round = new Round();
    round.options = roundsData.map((singlePlayer) => {
      let guessData = bracketGuessData.find((bracket) => bracket.gameID == singlePlayer.gameID) || {};
      return new OptionModel(singlePlayer, guessData);
    });
    if (index == 0) {
      let options = [];
      roundsData.map((singlePlayer, i) => {
        let guessData = bracketGuessData.find((bracket) => bracket.gameID == singlePlayer.gameID) || {};
        options.push(OptionModel.fromJSON(singlePlayer, guessData));
      });
      round.options = options;
    }
    return round;
  };

  onSelectOptions = (optionIndex, selectedValue) => {
    this.options[optionIndex].setSelectedValue(selectedValue);
  };
}

export default Round;
