import { useIsFocused } from "@react-navigation/native";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import AntDesign from "react-native-vector-icons/AntDesign";
import Feather from "react-native-vector-icons/Feather";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  CreateEventProgress,
  CustomModalDropDown,
  DateInput,
  Header,
  ImageVideoPlaceholder,
  Root,
  SingleHeading,
  TextAreaInput,
  TextInput,
  TouchableButton,
} from "../../../Component";
import { eventsCollection } from "../../../firebase";
import { useFirebaseUpload, useLoader } from "../../../hooks";
import {
  initEventModel,
  toggleAlertBox,
  updateEventModel,
  updateViewEventModel,
} from "../../../store/actions";
import Styles from "./indexCss";
// import { Picker } from "@react-native-picker/picker";
Feather.loadFont();
AntDesign.loadFont();

const CreateEventScreen = (props) => {
  var [setLoader, LoaderComponent] = useLoader();
  var { convertToBlob, uploadBlobToFirebase } = useFirebaseUpload();
  var { firebaseAllCollectionData, auth } = useSelector((s) => s);
  const viewEventModel = useSelector((state) => state.event.viewEventModel);
  var dispatch = useDispatch();
  const [backState, setBackState] = useState(false);
  const [filterCharity, setFilterCharity] = useState([]);
  const screenFocused = useIsFocused();
  var { eventModel } = useSelector((state) => state.event);
  var setEventModel = (newEventModel) => {
    dispatch(updateEventModel(newEventModel));
  };
  const setViewEventModel = useCallback(
    (payload) => dispatch(updateViewEventModel(payload)),
    []
  );
  var formsRef = useRef({
    categoryRef: useRef(),
    subCategoryRef: useRef(),
    genreRef: useRef(),
    contestTypeRef: useRef(),
    uploadLogo: useRef(),
    uploadPicture: useRef(),
    uploadVideo: useRef(),
    startDate: useRef(),
    endDate: useRef(),
    charityData: useRef(),
    charityType: useRef(),
  });

  const showPopup = ({ text = "" }) => {
    return dispatch(
      toggleAlertBox({
        visible: true,
        text: text,
        onPress: () => {
          dispatch(toggleAlertBox("CLOSE"));
        },
      })
    );
  };

  var loadData = async () => {
    await dispatch(
      initEventModel({
        ...firebaseAllCollectionData.firebaseCollectionData,
      })
    );
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    if (firebaseAllCollectionData.loading) {
      return;
    }
    loadData();
  }, [eventModel.eventName, firebaseAllCollectionData.loading]);

  useEffect(() => {
    if (props.route.params.resetForm) {
      setTimeout(() => {
        clearHandler(true);
      }, 300);
    }
  }, [props.route.params.resetForm]);

  useEffect(() => {
    if (screenFocused) {
      if (eventModel.eventFormMode == 1) {
        setBackState((i) => true);
      }
    } else {
      setBackState((i) => false);
    }
  }, [screenFocused]);

  // useBackHandler(() => {
  //   //will not go back
  //   //return true;

  //   //will go back
  //   //return false;
  //   if (backState && eventModel.eventFormMode == 1) {
  //     Alert.alert("Message", "All Progress will be Lost!", [
  //       {
  //         text: "Okay",
  //         onPress: () => {
  //           clearHandler(true);
  //           props.navigation.goBack();
  //         },
  //       },
  //       {
  //         text: "Cancel",
  //       },
  //     ]);
  //   }
  //   return backState;
  // });

  const onChangeCharityType = (charityType) => {
    let customCharity = [];
    eventModel.charityData.map((d) => {
      if (d.charityType === charityType) {
        customCharity.push(d);
      }
    });
    setFilterCharity(customCharity);
  };

  const saveEventToFirebase = async (uploadEventToFirebase, mode, id) => {
    let savedEventFormFields = {
      id: null,
      data: null,
    };
    if (mode == "add") {
      console.log("CREATE_EVENT_POST - ", uploadEventToFirebase);
      const savedEvent = await eventsCollection.add(uploadEventToFirebase);
      savedEventFormFields.id = savedEvent.id;
      savedEventFormFields.data = { ...uploadEventToFirebase };

      // ** CODE FOR ADDING PRIVATE EVENT TO VIEW MODEL** //
      //setViewEventModel(viewEventModel.addPrivateEvent(savedEvent.id,uploadEventToFirebase))
      // ** CODE ENDS HERE ** //
    } else {
      console.log("UPDATE_EVENT_POST - ", uploadEventToFirebase);
      const updateEvent = await eventsCollection
        .doc(id)
        .update(uploadEventToFirebase);
      savedEventFormFields.id = id;
      savedEventFormFields.data = { ...uploadEventToFirebase };
    }
    return savedEventFormFields;
  };

  const handleCreateHandler = async () => {
    if (eventModel.EventFormFields.eventName.length == 0) {
      return showPopup({ text: "Enter Event Name" });
    }
    if (eventModel.EventFormFields.eventLogo.length == 0) {
      return showPopup({ text: "Select Event Logo" });
    }
    if (eventModel.EventFormFields.eventDate.length == 0) {
      return showPopup({ text: "Select Event Start Date" });
    }
    if (eventModel.EventFormFields.eventDateEnd.length == 0) {
      return showPopup({ text: "Select Event End Date" });
    }
    if (Object.keys(eventModel.selectedCharityData).length == 0) {
      return showPopup({ text: "Select Charity" });
    }
    if (eventModel.EventFormFields.eventCategory.length == 0) {
      return showPopup({ text: "Select Category" });
    }
    if (eventModel.EventFormFields.eventSubCategory.length == 0) {
      return showPopup({ text: "Select Sub Category" });
    }
    if (eventModel.EventFormFields.eventGenre.length == 0) {
      return showPopup({ text: "Select Genre" });
    }
    // if (eventModel.EventFormFields.eventPicture.length == 0) {
    //   return showPopup({text:"Select Event Picture"});
    // }
    // if (eventModel.EventFormFields.eventVideo.length == 0) {
    //   return Alert.alert('Message', 'Select Event Video');
    // }
    await createHandler();
  };

  const createHandler = async function () {
    try {
      setLoader(true);
      let { uploadEventToFirebase, eventFormMode, savedEventFormFields } =
        eventModel.getFirebaseData();
      if (eventFormMode == 1) {
        let saveUpdatedData = { ...uploadEventToFirebase };
        saveUpdatedData.eventLogo = savedEventFormFields.data.eventLogo;
        saveUpdatedData.eventPicture = savedEventFormFields.data.eventPicture;
        saveUpdatedData.eventVideo = savedEventFormFields.data.eventVideo;
        const updateEventCurrentForm = await saveEventToFirebase(
          saveUpdatedData,
          "update",
          savedEventFormFields.id
        );
        setLoader(false);
        setTimeout(() => {
          setEventModel(
            eventModel.mutationsOnSavedEvents(updateEventCurrentForm)
          );
          props.navigation.navigate("ContestTypeScreen");
          // props.navigation.navigate('EventStack', {
          //   screen: 'ContestTypeScreen'
          // });
        }, 200);
        return;
      }
      const logoBlob = await convertToBlob(
        eventModel.EventFormFields.eventLogo,
        "events&contestsImages/"
      );
      const pictureBlob = await convertToBlob(
        eventModel.EventFormFields.eventPicture,
        "events&contestsImages/"
      );
      const videoBlob = await convertToBlob(
        eventModel.EventFormFields.eventVideo,
        "events&contestsVideos/"
      );
      uploadBlobToFirebase(logoBlob)
        .then((eventLogoUrl) => {
          uploadEventToFirebase.eventLogo = eventLogoUrl;
          return uploadBlobToFirebase(pictureBlob);
        })
        .then((eventPictureUrl) => {
          uploadEventToFirebase.eventPicture = eventPictureUrl;
          return uploadBlobToFirebase(videoBlob);
        })
        .then(async (videoURL) => {
          uploadEventToFirebase.eventVideo = videoURL;
          uploadEventToFirebase.organizerName = auth.userCol.userName;
          uploadEventToFirebase.organizerID = auth.userCol.uid;
          const createEventResponse = await saveEventToFirebase(
            uploadEventToFirebase,
            "add"
          );
          setLoader(false);
          setTimeout(() => {
            console.log(
              "GETTING_DATA_ON_FIRST_SAVE - ",
              JSON.stringify(createEventResponse)
            );
            setEventModel(
              eventModel.mutationsOnSavedEvents(createEventResponse)
            );
            props.navigation.navigate("ContestTypeScreen");
            // props.navigation.navigate('EventStack', {
            //   screen: 'ContestTypeScreen'
            // });
          }, 200);
        })
        .catch((error) => {
          console.log("ERROR_WHILE_ASSETS_UPLOAD - ", error);
          setLoader(false);
          setTimeout(() => {
            alert("Message Error while Uploading Assets!");
          }, 400);
        });
    } catch (error) {
      setLoader(false);
      console.log("EVENT_CREATE_HANDLER - ", error);
      setTimeout(() => {
        alert("Message Error while Saving Event!");
      }, 400);
    }
  };

  const clearHandler = (shouldResetId = false) => {
    if (shouldResetId) {
      setEventModel(eventModel.resetEventModalForm(shouldResetId));
    }
    for (let key in formsRef.current) {
      if (formsRef.current[key].current) {
        formsRef.current[key].current.reset();
      }
    }
  };

  return (
    <Root>
      <LoaderComponent />
      <ScrollView style={Styles.container} showsVerticalScrollIndicator={true}>
        <Header
          hideMenu
          heading={"Create Event - Info"}
          menuOnPress={() => {
            props.navigation.openDrawer();
          }}
          leftOnPress={() => {
            props.navigation.goBack();
          }}
        />
        <View>
          <TextInput
            containerStyle={Styles.inputContainerStyle}
            inputStyle={Styles.inputStyle}
            placeholder={"Enter Event Name"}
            value={eventModel.EventFormFields.eventName}
            onChangeText={(eventName) =>
              setEventModel(eventModel.updateEventForm("eventName", eventName))
            }
          />
          <View style={Styles.imagePlateContainer}>
            <View>
              <ImageVideoPlaceholder
                mode={eventModel.eventFormMode == 0 ? "select" : "view"}
                ref={formsRef.current.uploadLogo}
                renderText={"Upload Logo upto 4mb"}
                type={"photo"}
                selectedData={(eventLogo) =>
                  setEventModel(
                    eventModel.updateEventForm("eventLogo", eventLogo)
                  )
                }
              />
            </View>

            <View style={Styles.imagePlateRightChildView}>
              <View style={Styles.datePickerContainer}>
                <DateInput
                  ref={formsRef.current.startDate}
                  title={"Start Date"}
                  onDateSet={(eventDate) =>
                    setEventModel(
                      eventModel.updateEventForm("eventDate", eventDate)
                    )
                  }
                />
                <DateInput
                  ref={formsRef.current.endDate}
                  title={"End Date"}
                  onDateSet={(eventDateEnd) =>
                    setEventModel(
                      eventModel.updateEventForm("eventDateEnd", eventDateEnd)
                    )
                  }
                />
              </View>
              <CustomModalDropDown
                ref={formsRef.current.charityType}
                onSelect={(charity) => {
                  setEventModel(
                    eventModel.updateEventForm("charityType", charity.value)
                  );
                  onChangeCharityType(charity.value);
                }}
                width={"95%"}
                height={37}
                items={[
                  { name: "Charity", value: "Charity" },
                  { name: "Student Athlete", value: "Student Athlete" },
                ]}
                placeholder="Select Charity Type"
              />
              <CustomModalDropDown
                ref={formsRef.current.charityData}
                onSelect={(charity) => {
                  setEventModel(
                    eventModel.updateCharityOnSelect(charity.charityID, charity)
                  );
                }}
                width={"95%"}
                height={37}
                items={filterCharity}
                placeholder="Select Charity"
              />
            </View>
          </View>
        </View>
        <SingleHeading
          containerStyle={Styles.singleHeadingContainer}
          placeholder={"Enter Details about your Event below"}
        />
        <View style={Styles.eventDetailsContainer}>
          <CustomModalDropDown
            ref={formsRef.current.categoryRef}
            onSelect={(event) =>
              setEventModel(
                eventModel.updateEventForm("eventCategory", event.eventCategory)
              )
            }
            width={"100%"}
            height={37}
            items={eventModel.categoryData || []}
            placeholder="Select Category"
          />

          <CustomModalDropDown
            ref={formsRef.current.subCategoryRef}
            onSelect={(eventSubcategory) =>
              setEventModel(
                eventModel.updateEventForm(
                  "eventSubCategory",
                  eventSubcategory.eventSubCategory
                )
              )
            }
            width={"100%"}
            height={37}
            items={eventModel.subCategoryData || []}
            placeholder="Select Sub Category"
          />

          <CustomModalDropDown
            ref={formsRef.current.genreRef}
            onSelect={(eventGenre) =>
              setEventModel(
                eventModel.updateEventForm(
                  "eventGenre",
                  eventGenre.eventGenreType
                )
              )
            }
            width={"100%"}
            height={37}
            items={eventModel.genreData || []}
            placeholder="Select Genre"
          />

          <TextAreaInput
            textInputStyle={Styles.eventDescriptionTextStyle}
            placeholder={"Event Description"}
            value={eventModel.EventFormFields.eventDescription}
            onChangeText={(eventDescription) =>
              setEventModel(
                eventModel.updateEventForm("eventDescription", eventDescription)
              )
            }
          />
          <Text style={Styles.galleryLabelStyle}>Gallery</Text>
          <View style={Styles.bottomTrayContainer}>
            <ImageVideoPlaceholder
              ref={formsRef.current.uploadPicture}
              renderText={"Upload Picture upto 4mb"}
              type={"photo"}
              mode={eventModel.eventFormMode == 0 ? "select" : "view"}
              selectedData={(picture) =>
                setEventModel(
                  eventModel.updateEventForm("eventPicture", picture)
                )
              }
              containerStyle={Styles.uploadPicStyle}
              imageStyle={Styles.uploadPicStyle}
            />
            <ImageVideoPlaceholder
              mode={eventModel.eventFormMode == 0 ? "select" : "view"}
              ref={formsRef.current.uploadVideo}
              renderText={"Upload Video"}
              type={"video"}
              viewURI={
                eventModel.eventFormMode == 0
                  ? null
                  : eventModel.savedEventFormFields.data?.eventVideo
              }
              containerStyle={Styles.uploadVideoContainerStyle}
              imageStyle={Styles.uploadVideoContainerStyle}
              selectedData={(video) =>
                setEventModel(eventModel.updateEventForm("eventVideo", video))
              }
              renderChildren={eventModel.eventFormMode == 0 ? false : true}
            >
              <Feather name="play" color="#FFF" size={30} />
            </ImageVideoPlaceholder>
          </View>
          {eventModel.eventFormMode != 0 ? (
            <TouchableButton
              type={"small"}
              backgroundColor={"#EC2939"}
              title={eventModel.eventFormMode == 0 ? "Save" : "Update"}
              onPress={handleCreateHandler}
              containerStyle={Styles.saveUpdateContainerStyle}
            />
          ) : null}
          <View style={Styles.bottomButtonsTray}>
            <TouchableButton
              type={"nextStep"}
              title={"Next Step"}
              backgroundColor={"#0B214D"}
              propButtonStyle={{ width: 200 }}
              onPress={
                eventModel.eventFormMode == 0
                  ? handleCreateHandler
                  : () => {
                      props.navigation.navigate("EventStack", {
                        screen: "ContestTypeScreen",
                      });
                    }
              }
              titleStyle={{ fontSize: 16 }}
            />
          </View>
        </View>
        <CreateEventProgress
          containerStyle={{ marginTop: 30 }}
          selectedIndex={1}
        />
        <View style={{ height: 60 }} />
      </ScrollView>
    </Root>
  );
};

export default connect()(CreateEventScreen);
