import React, { memo, useState, useCallback, useImperativeHandle } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import {Button} from 'react-native-elements'
import moment from "moment";
// import DateTimePickerModal from "react-native-modal-datetime-picker";
import Styles from "./indexCss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "modal-enhanced-react-native-web";

const FilterDatePicker = React.forwardRef((props, ref) => {
  const {
    onDateSet = () => { },
    title = "Add Title",
    containerStyle = {},
  } = props;
  const [datePickerVisibility, setDatePickerVisibility] = useState(false);
  const [date, setDate] = useState(new Date());
  const [value, setValue] = useState({ date: "", month: "" });
  useImperativeHandle(ref, () => ({
    reset: () => {
      setValue({ date: "", month: "" });
    },
  }));

  const hideDatePicker = () => setDatePickerVisibility(false);
  const handleConfirm = (selectedDate) => {
    hideDatePicker()
    const currentDate = selectedDate || date;
    setDate(currentDate);
    onDateSet(new Date(currentDate));
    let value = { date: "", month: "" };
    value.date = moment(currentDate).format("DD");
    value.month = moment(currentDate).format("MM");
    setValue(value);
  };
  const switchDatePicker = useCallback(
    () => setDatePickerVisibility((i) => !i),
    []
  );

  return (<View>
    <TouchableOpacity
       onPress={() => setDatePickerVisibility(true)}
      style={[Styles.containerStyle, containerStyle]}
    >
      <Text style={Styles.labelTextStyle}>
        {title}
      </Text>
      <View style={Styles.singleDateStyleContainer}>
        <Text style={Styles.dateTextStyle}>{value.date}</Text>
      </View>
      <Text style={Styles.slashTextStyle}>/</Text>
      <View style={Styles.singleDateStyleContainer}>
        <Text style={Styles.dateTextStyle}>{value.month}</Text>
      </View>
    </TouchableOpacity>
    <Modal isVisible={datePickerVisibility} style={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <DatePicker
          minDate={new Date(new Date().getFullYear(), 0, 1)}
          maxDate={new Date(new Date().getFullYear() + 4, 11, 31)}
          open={datePickerVisibility}
          selected={date}
          className={'react-datepicker-popper'}
          onSelect={handleConfirm} //when day is clicked
          onChange={handleConfirm} //only when value has changed
          
        />
          {/* <Button style={{alignSelf:'center'}} title="Confirm" onPress={()=>hideDatePicker()} /> */}
    </Modal>

  </View>
  );
});

export default memo(FilterDatePicker);
