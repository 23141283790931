import { createElement } from "react-native-web";

const WebVideo = (props) => {
    const attrs = {
      src: props.source,
      style: props.style,
      controls: "controls"
    }
    return createElement('video', attrs);
  }

export default WebVideo;