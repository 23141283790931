import {
  SQIPCardEntry,
  SQIPCore
} from 'react-native-square-in-app-payments';
// import {
//   APP_CONFIGURATIONS
// } from '@constants';

const ENV_TYPE = {
  PRODUCTION: `PRODUCTION`,
  SANDBOX: `SANDBOX`
};
const CURRENT_ENV = ENV_TYPE.PRODUCTION;
const ENV = {
  [ENV_TYPE.PRODUCTION]: {
    appId: 'sq0idp-fUQtyqYbf7-rctg1TkLArQ',
    accessToken: 'EAAAEYjMMib1siF8q0_UtgIMILjUzHOLfeqkVkVBHGyLkZydNuJuzvRuvYfN6c79',
    locationId: 'LKP91SRN4RVW4',
    firebaseFunction: `https://us-central1-players-recreation-league.cloudfunctions.net/createSquarePayment`
  },
  [ENV_TYPE.SANDBOX]: {
    appId: 'sandbox-sq0idb-3fFpc479kIScMXwgggR5Zw',
    accessToken: 'EAAAEG0nfb-uTPZFRyWnPTPV_2HC3L2uSsTYoLkbzfjyk8E58UhkDyNEc7wUq74g',
    locationId: 'LXM759MRYNJQB',
    firebaseFunction: `https://us-central1-players-recreation-league.cloudfunctions.net/createSquarePaymentSandbox`
  }
};
class SquarePayment {
  constructor(onCardEntryComplete = () => { }, onCardEntryCancel = () => { }) {
    this.cardEntryConfig = {
      collectPostalCode: false,
    };
    this.onCardEntryCompleteProp = onCardEntryComplete;
    this.oncardEntryCancelProp = onCardEntryCancel;
    this.amount = 0;
    this.email = '';
  }

  onStartCardEntry = async (amount, email) => {
    try {
      this.amount = amount;
      this.email = email;
      await SQIPCore.setSquareApplicationId(ENV[CURRENT_ENV].appId);

      if (Platform.OS === 'ios') {
        await SQIPCardEntry.setIOSCardEntryTheme({
          saveButtonFont: {
            size: 25,
          },
          saveButtonTitle: 'Pay 💳',
          keyboardAppearance: 'Dark',
          saveButtonTextColor: {
            r: 255,
            g: 0,
            b: 125,
            a: 0.5,
          },
        });
      }

      await SQIPCardEntry.startCardEntryFlow(
        this.cardEntryConfig,
        this.onCardNonceRequestSuccess,
        this.onCardEntryCancel,
      );
    } catch (e) {
      console.log('ERROR_ON_CARD_ENTRY_', e);
    }
    console.log('CARD_ENTRY_DONE');
  }

  onCardEntryCancel = () => {
    console.log('CARD_ENTRY_CANCEL');
    this.oncardEntryCancelProp();
  }

  onCardEntryComplete = async (paymentResponse) => {
    console.log('CARD_ENTRY_COMPLETE');
    this.onCardEntryCompleteProp(paymentResponse);
  }

  onCardNonceRequestSuccess = async (cardNounce) => {
    try {
      console.log('CARD_NOUNCE_CREATED - ', JSON.stringify(cardNounce));

      let reqBody = JSON.stringify({
        nonce: cardNounce.nonce,
        amount: this.amount,
        email: this.email
      });
      console.log('PAYMENT_REQUEST_BODY - ', reqBody);

      const validatePayment = await fetch(
        ENV[CURRENT_ENV].firebaseFunction, {
        method: 'post',
        body: reqBody,
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      });
      const validatePaymentResponse = await validatePayment.json();
      console.log('VALIDATE_PAYMENT_RESPONSE - ', JSON.stringify(validatePaymentResponse));
      if (validatePaymentResponse.paymentSuccess) {
        await SQIPCardEntry.completeCardEntry(
          await this.onCardEntryComplete(validatePaymentResponse),
        );
        return;
      }
      throw new Error(validatePaymentResponse);
    } catch (ex) {
      console.log('CARD_NOUNCE_REQ_FAIL_', JSON.stringify(ex));
      await SQIPCardEntry.showCardNonceProcessingError('Invalid Card Details!');
    }
  }
}

export default SquarePayment;

/*
  Sandbox token - sandbox-sq0idb-wfHpKLbqe4g0MIBJBfv3Ow
  JSON response from the react-native-square-in-app-payments
  {
    "card": {
      "prepaidType":"UNKNOWN",
      "type":"UNKNOWN",
      "postalCode":null,
      "expirationYear":2022,
      "expirationMonth":8,
      "lastFourDigits":"1111",
      "brand":"VISA"
    },
    "nonce": "cnon:CBASEJZfNFJzRAHRxRgAmGsYtrE"
  }
*/
