import React, { memo, useEffect, useState, useImperativeHandle } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Alert,
  Image,
  Platform,
} from "react-native";
import * as ImagePicker from "expo-image-picker";
import AntDesign from "react-native-vector-icons/AntDesign";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

import Modal from "modal-enhanced-react-native-web";
//import Modal from 'react-native-modal';
//import Video from 'react-native-video';
import Styles from "./indexCss";
import { getWp } from "../../utils";
import { WebView } from "react-native-web-webview";
import { toggleAlertBox } from '../../store/actions';
import { useSelector, useDispatch, connect } from 'react-redux';
AntDesign.loadFont();
MaterialIcons.loadFont();

const CustomPopup = (props, ref) => {
  const {
    modalVisible = true,
    onClose = () => { },
    html = "<h1>Hello World</h1>",
    containerStyle = {},
    renderComponent = false,
  } = props;

  const alertBoxState = useSelector(state => state.appReducer.alertBox);
  const dispatch = useDispatch();

  const onOkayPress = () => {
    if (alertBoxState.onPress) {
      return alertBoxState.onPress();
    }
    return dispatch(toggleAlertBox('CLOSE'));
  }
  return (
      <Modal
      isVisible={alertBoxState.visible}
    >
      <View style={Styles.modalContainer}>
        <View>
          <Text style={Styles.AlertTextStyle} >{alertBoxState.text}</Text>
        </View>

        <View style={{  alignSelf: 'center',position:'absolute',bottom:10 }}>
          <TouchableOpacity
            style={Styles.OkayButtonStyle}
            onPress={() => onOkayPress()}
          >
            <Text style={Styles.OkayTextStyle} >Ok</Text>
          </TouchableOpacity>
        </View>
        
      </View>
    </Modal>
   );
};

export default connect()(CustomPopup);

// import * as React from "react";
// import {
//   StyleSheet,
//   View,
//   Button,
//   //Modal,
//   Text,
//   TouchableWithoutFeedback
// } from "react-native";

// import Modal from "modal-enhanced-react-native-web";

// const AlertHandler = createAlertHandler();

// function createAlertHandler() {
//   // local state of alert handler
//   let subscriber;
//   let alerts = [];

//   let setAlerts = (previousAlertsCallback) => {
//     alerts = previousAlertsCallback(alerts);
//     // notify alert root
//     subscriber && subscriber(alerts);
//   };

//   function use() {
//     // eslint-disable-next-line
//     let [localAlerts, s] = React.useState(alerts);

//     // subscribe to external changes
//     // eslint-disable-next-line
//     React.useEffect(() => {
//       subscriber = s;
//       return () => {
//         subscriber = undefined;
//       };
//     }, [s]);

//     // set callback
//     return [localAlerts, setAlerts];
//   }

//   return {
//     api: {
//       alert: (title, message, buttons, options) => {
//         setAlerts((prev) => [...prev, { title, message, buttons, options }]);
//       }
//     },
//     use
//   };
// }

// export function AlertRoot() {
//   const [alerts, setAlerts] = AlertHandler.use();

//   const onClose = (indexToRemove) => {
//     setAlerts((prev) => prev.filter((_, i) => i !== indexToRemove));
//   };

//   const backdrop = <View style={[StyleSheet.absoluteFill, styles.backdrop]} />;

//   return alerts.map(({ title, message, buttons, options }, alertIndex) => (
//     <Modal
//       key={alertIndex}
//       visible={true}
//       animationType="none"
//       transparent
//       onRequestClose={
//         options && options.cancelable ? () => onClose(alertIndex) : () => null
//       }
//     >
//       <View style={[StyleSheet.absoluteFill, styles.modalInner]}>
//         {options && options.cancelable ? (
//           <TouchableWithoutFeedback onPress={() => onClose(alertIndex)}>
//             {backdrop}
//           </TouchableWithoutFeedback>
//         ) : (
//           backdrop
//         )}
//         <View style={styles.alert}>
//           <Text style={styles.title}>{title}</Text>
//           {message ? <Text style={styles.message}>{message}</Text> : null}
//           {buttons ? (
//             <View style={styles.buttons}>
//               {buttons.map(({ text, onPress, style }, buttonIndex) => {
//                 const hasThree = buttons.length === 3;
//                 const isFirst = buttonIndex === 0;
//                 return (
//                   <React.Fragment key={buttonIndex}>
//                     {isFirst && !hasThree ? <View style={styles.fill} /> : null}
//                     {isFirst ? null : <View style={styles.spacer} />}
//                     <Button
//                       onPress={()=>onClose(alertIndex)}
//                       title={text}
//                       color={style === "cancel" ? "red" : undefined}
//                     />
//                     {isFirst && hasThree ? <View style={{ flex: 1 }} /> : null}
//                   </React.Fragment>
//                 );
//               })}
//             </View>
//           ) : null}
//         </View>
//       </View>
//     </Modal>
//   ));
// }

// const styles = StyleSheet.create({
//   modalInner: { justifyContent: "flex-start", alignItems: "center" },
//   backdrop: {
//     flex: 1,
//     backgroundColor: "rgba(0,0,0,0.2)"
//   },
//   alert: {
//     backgroundColor: "#fff",
//     width: "100%",
//     maxWidth: 400,
//     shadowColor: "#000",
//     shadowOffset: {
//       width: 0,
//       height: 2
//     },
//     shadowOpacity: 0.25,
//     shadowRadius: 3.84,
//     margin: 12,
//     marginTop: 0,
//     padding: 12,
//     borderBottomStartRadius: 5,
//     borderBottomEndRadius: 5
//   },
//   title: { fontSize: 22 },
//   message: { marginTop: 6 },
//   buttons: { flexDirection: "row", marginTop: 24 },
//   fill: { flex: 1 },
//   spacer: { width: 6 }
// });

// export default AlertHandler.api;
