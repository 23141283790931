import React, { Fragment, useEffect, useState } from "react";
import { View, Text } from "react-native";
import {
  TouchableButton,
  SingleHeading,
  CustomModalDropDown,
  TextInput,
} from "../../Component";
import { useSelector } from "react-redux";
import {
  contestsCollection,
  eventsCollection,
  userEnteredContestsCollection,
  bracketUserGuessCollection,
} from "../../firebase";

import Styles from "./indexCss";
import { removeDuplicateFromArr, transformFirebaseValues } from "../../utils";
import { useLoader } from "../../hooks";

const SearchPlate = (props) => {
  const [setLoader, Loader] = useLoader(true);
  const [events, setEvents] = useState([]);
  const [contests, setContests] = useState([]);
  const [userEnteredContestData, setUserEnteredContestData] = useState([]);
  const [selected, setSelected] = useState({ event: {}, contest: {} });
  const [bracketRef, setBracketRef] = useState("");
  const bracketType = props.parentProp?.route?.params?.bracketType ?? "add";
  const { auth, firebaseAllCollectionData } = useSelector((s) => s);
  useEffect(() => {
    // if (firebaseAllCollectionData?.firebaseCollectionData?.eventsData) {
    //   setLoader(false);
    //   console.log('Firebase Event Data ----> ', JSON.stringify(firebaseAllCollectionData?.firebaseCollectionData?.eventsData))
    //   setEvents((i) => [
    //     ...firebaseAllCollectionData.firebaseCollectionData.eventsData,
    //   ]);
    // }
    let enteredData = [];
    let unsubscribe2 = userEnteredContestsCollection.onSnapshot(function (
      querySnapshot
    ) {
      querySnapshot.forEach(function (doc) {
        let docData = doc.data();
        if (docData.userID == auth.userId) {
          enteredData.push({
            ...docData,
            id: doc.id,
            value: docData["referenceNo"],
            isSelected: false,
          });
        }
      });
      console.log("New Array --> ", enteredData);
      let removeDuplicate = removeDuplicateFromArr(enteredData, "eventID");
      setUserEnteredContestData(enteredData);
    });

    let unsubscribe = eventsCollection.onSnapshot(function (querySnapshot) {
      var events = [];

      querySnapshot.forEach(function (doc) {
        let docData = doc.data();
        enteredData.map((singleEnteredEvent) => {
          if (
            docData.eventID == singleEnteredEvent.eventID ||
            auth.userId == docData.organizerID
          ) {
            events.push({
              ...docData,
              id: doc.id,
              value: docData["eventName"],
              isSelected: false,
            });
          }
          // else {
          //   if (
          //     docData.eventCategory == "Private" &&
          //     (auth.userId == docData.organizerID ||
          //       docData.eventID == singleEnteredEvent.eventID)
          //   ) {
          //     events.push({
          //       ...docData,
          //       id: doc.id,
          //       value: docData["eventName"],
          //       isSelected: false,
          //     });
          //   }
          // }
        });
      });

      let removeDuplicate = removeDuplicateFromArr(events, "eventID");

      setTimeout(() => {
        setEvents((i) => [...removeDuplicate]);
        setLoader(false);
      }, 1000);
    });

    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, [firebaseAllCollectionData.firebaseCollectionData.eventsData]);

  const fetchContest = async (eventID) => {
    setLoader(true);
    const contestsRes = await contestsCollection
      .where("eventID", "==", eventID)
      .get();
    let transformedContest = transformFirebaseValues(
      contestsRes,
      "contestName"
    );
    let Contests = [];
    userEnteredContestData.map((paidConst, Pindex) => {
      transformedContest.map((singleContest) => {
        if (
          singleContest.bracketGuessingOn == true &&
          paidConst.contestID == singleContest.contestID &&
          eventID == paidConst.eventID
        ) {
          console.log(
            "comparison --> ",
            paidConst.contestID,
            singleContest.contestID
          );
          Contests.push(singleContest);
        }
      });
    });
    let sortedData = Contests.sort((a,b)=> parseInt(a.contestID) - parseInt(b.contestID))

    setContests((i) => sortedData);
    setLoader(false);
  };
  const fetchRefNo = async (contestID) => {
    setLoader(true);
    const bracketGuessRes = await bracketUserGuessCollection
      .where("userID", "==", auth.userId)
      .where("contestID", "==", contestID)
      .get();
    let transformedData = transformFirebaseValues(
      bracketGuessRes,
      "referenceNo"
    );
    let enterRef = [];
    transformedData.map((docData) => {
      enterRef.push({
        ...docData,
        id: docData.id,
        value: `${docData["referenceNo"]}-${docData["userBracketName"]}`,
        isSelected: false,
      });
    });
    let removeDuplicate = removeDuplicateFromArr(enterRef, "value");
    setUserEnteredContestData(removeDuplicate);
    setLoader(false);
  };
  return (
    <Fragment>
      <Loader />
      <SingleHeading
        containerStyle={Styles.singleHeadingContainer}
        placeholder={"Search Bracket"}
      />
      <View style={{ alignSelf: "center", width: "70%", marginTop: 20 }}>
        <CustomModalDropDown
          onSelect={(event) => {
            if (Object.keys(event).length == 0) {
              return;
            }
            fetchContest(event?.eventID);
            setSelected((s) => ({ ...s, event, contest: {} }));
          }}
          width={"100%"}
          height={37}
          items={events || []}
          placeholder="Select Event"
        />
        <CustomModalDropDown
          onSelect={(contest) => {
            if (Object.keys(contest).length == 0) {
              return;
            }
            fetchRefNo(contest?.contestID);
            setSelected((s) => ({ ...s, contest }));
          }}
          width={"100%"}
          height={37}
          items={contests || []}
          placeholder="Select Contest"
        />
        {bracketType == "view" && contests.length > 0 && (
          <CustomModalDropDown
            onSelect={(bracketRefs) => {
              if (Object.keys(bracketRefs).length == 0) {
                return;
              }
              setBracketRef((i) => bracketRefs.referenceNo);
            }}
            width={"100%"}
            height={37}
            items={userEnteredContestData || []}
            placeholder="Select reference No"
          />
          /* <TextInput
            containerStyle={{
              width: "100%",
              alignSelf: "center",
              marginVertical: 20,
              height: 50,
            }}
            inputStyle={{ height: 45 }}
            placeholder={"Enter Bracket Reference No"}
            value={bracketRef}
            onChangeText={(bracketRef) => setBracketRef((i) => bracketRef)}
          /> */
        )}
        <View style={Styles.saveButtonStyle}>
          <TouchableButton
            type={"large"}
            backgroundColor={"#01080B"}
            title={"Search Bracket"}
            onPress={() => {
              if (Object.keys(selected.event).length == 0) {
                return props.showPopup({ text: "Select Event" });
              }
              if (Object.keys(selected.contest).length == 0) {
                return props.showPopup({ text: "Select Contest" });
              }
              return props.onSearchClick(selected, bracketRef);
            }}
          />
        </View>
      </View>
    </Fragment>
  );
};

export default SearchPlate;
