// External Imports
import React from "react";
import { View, Image, Text, TouchableOpacity } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import Icons from "react-native-vector-icons/Feather";
import styles from "./indexCss";

function HeaderBlack(props) {
  const { onBackPress, onMenuPress, hideMenu = false } = props;
  return (
    <View style={{}}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 10,
        }}
      >
        {!hideMenu ? (
          <TouchableOpacity onPress={onMenuPress}>
            <View>
              <Icons
                name="menu"
                size={30}
                color="black"
                style={{ paddingHorizontal: 20 }}
              />
            </View>
          </TouchableOpacity>
        ) : null}

        <TouchableOpacity onPress={onBackPress}>
          <View>
            <Icon
              name="angle-left"
              size={40}
              color="black"
              style={{ paddingHorizontal: 10 }}
            />
          </View>
        </TouchableOpacity>

        {/* <View>
            <Text style={{paddingHorizontal:20,color:'black',fontWeight:'bold',fontSize:16}}>Payment Overview</Text>
            </View> */}
      </View>
    </View>
  );
}

export default HeaderBlack;
