import {
    eventsCollection,
    firebase,
    usersCollection,
    charitiesCollection,
    prlAboutTermsPrivacyCollection
} from '../../firebase';
import { transformFirebaseValues } from '../../utils';
import { Platform } from 'react-native'

export const registerUser = async ({ email, password }) => {
    try {
        const response = await firebase.auth()
            .createUserWithEmailAndPassword(email, password);
        const { user } = response;
        const userProfile = {
            uid: user.uid,
            email: email,
            userAvatar: '',
            userCellPhone: '',
            userName: '',
            userNickname: '',
            //userType: 'admin',
            userType: 'user',
            createdAt: new Date(),
            fcmToken: '',
            platform: Platform.OS,

            fcmToken: '',
            permissions: {
                showAds: false,
                showHostEvents: false,
                showAddCharities: false
            }
        };
        await usersCollection.doc(user.uid).set(userProfile);
        return { user: userProfile }
    } catch (error) {
        return Promise.reject(error);
    }
}

export const login = async ({ email, password }) => {
    try {
        const response = await firebase.auth().signInWithEmailAndPassword(email, password);
        const { user } = response;
        return { user }
    } catch (error) {
        return Promise.reject(error);

    }
}

export const writeProfileInformation = async ({ userId, name, nickName, eventName, charity }) => {

    const response = await usersCollection.doc(userId).set({
        name,
        nickName,
        eventName,
        charity
    });
    return response;
}

export const readProfileInformation = async (userId) => {

    const response = await usersCollection.doc(userId).get()
    console.log(userId);
    return response.data()
}

export const getAllEvents = async () => {
    const response = await eventsCollection.get();
    return response;
}
export const getAllCharities = async () => {
    const response = await charitiesCollection.get();
    return response;
}
export const getAppInfoData = async () => {
    const response = await prlAboutTermsPrivacyCollection.get();
    const transResponse = transformFirebaseValues(response);
    //console.log('GET_APP_INFO_DATA - ', transResponse[0]);
    return transResponse[0];
}