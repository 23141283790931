import { removeDuplicateFromArr, sortArrayAlphabatically } from "./array";

const filterAndMergeDataOnUniqueKey = (data, key) => {
  try {
    let allGroupData = [];
    data = sortArrayAlphabatically(data, key).slice();
    let allDataBelongsToKey = data.map((d) => d[key]);
    let filteredOperatedData = removeDuplicateFromArr(allDataBelongsToKey);
    filteredOperatedData.map((fData) => {
      let singleBluePrint = [];
      data.map((d) => {
        if (fData == d[key]) {
          singleBluePrint.push(d);
        }
      });
      allGroupData.push(singleBluePrint);
    });
    return allGroupData;
  } catch (error) {
    console.log('Error --> ', error);
    return [];
  }
};

export { filterAndMergeDataOnUniqueKey };
