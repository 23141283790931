class ReactModel {
  listeners = [];
  addListener = (cb) => {
    this.listeners.push(cb);
    return this.attachRemoveListener(cb);
  };
  notifyListener = () => {
    this.listeners.map((CB) => {
      CB();
    });
  };
  attachRemoveListener = (cb) => {
    return {
      ...this,
      removeListener: () => {
        this.listeners = this.listeners.filter((CB) => CB != cb);
      },
    };
  };
}

export default ReactModel;
