import { INIT_PAYMENT_SIGNUP_CONFIRMATION,
    INIT_NAVIGATE_TO_SIGNUP_SCREEN } from '../actions';

const initialState = {
    paymentSignupToPaymentConfirmation: {
        initialized: false
    },
    navigateToSignup: {
        initialized: false,
        eventID: 0
    }
}

const navReducer = (state = initialState, action) => {
    switch(action.type) {
        case INIT_PAYMENT_SIGNUP_CONFIRMATION : 
        return {
            ...state, 
            paymentSignupToPaymentConfirmation: {
                ...state.paymentSignupToPaymentConfirmation,
                initialized: true,
                ...action.payload
            }
        };
        case INIT_NAVIGATE_TO_SIGNUP_SCREEN: 
        return {
            ...state,
            navigateToSignup: {
                ...state.navigateToSignup,
                initialized: true,
                eventID: action.eventID
            }
        }
        default: return state;
    }
}

export default navReducer;