class BracketUserGuess {
  contestID = 3;
  eventID = 1;
  gameID;
  userBracketName = "TEST";
  userID = "TEST_USER";
  winnerID;
  winnerName; 
  constructor(userID = "") {
    this.userID = userID;
  }

  setBracketName = (value) =>{
    this.userBracketName = value
  }

  toJSON = () => {
    return {
      contestID: this.contestID,
      eventID: this.eventID,
      gameID: this.gameID,
      userBracketName: this.userBracketName,
      userID: this.userID,
      winnerID: this.winnerID,
      winnerName: this.winnerName,
    };
  };
}

export default BracketUserGuess;
