import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { TouchableOpacity } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { createDrawerNavigator } from "@react-navigation/drawer";

//Import Screens
import HomeScreen from "../Screens/home/HomeScreen/HomeScreen";
import JoinEventScreen from "../Screens/event/joinEvent";
import LoginScreen from "../Screens/auth/login";
import ResetPasswordScreen from "../Screens/auth/resetPassword";
import RegisterScreen from "../Screens/auth/register";
import NewRegisterScreen from "../Screens/auth/RegisterPageNew";
import CreateInitialProfileScreen from "../Screens/auth/createProfile";
import CreateEventScreen from "../Screens/event/createEvent";
import CutomizeContestScreen from "../Screens/event/customizeContest";
import EventProfileCreateScreen from "../Screens/event/createProfile";
import EventFeesScreen from "../Screens/event/eventFees";
import CreateBracketGroup from '../Screens/event/createBracketGroup';

import TermsConditionsScreen from "../Screens/auth/TermsConditions";
import PrivacyPolicyScreen from "../Screens/auth/PrivacyPolicy";
import AboutScreen from "../Screens/auth/AboutUs";
import EventInfoScreen from "../Screens/event/components/EventInfo";
import AddNewContestScreen from '../Screens/event/EventInfoDetail/AddNewContestScreen';
import CreateContestScreen from '../Screens/event/createContest';
import CreateCharityScreen from "../Screens/CharityFolder/CreateCharity";
import EditCharityScreen from "../Screens/CharityFolder/EditCharity/editCharity";
import ViewCharityScreen from "../Screens/CharityFolder/Charity";
import ViewAthleteScreen from "../Screens/CharityFolder/StudentAthlete";
import PlayerProfileScreen from "../Screens/home/user/profile";
import EventPaymentSignupScreen from "../Screens/home/event/eventPaymentSignup";
import EPCreditCardScreen from "../Screens/home/event/EPCreditCard";
import EPConfirmationScreen from "../Screens/home/event/EPConfirmation";
import GamesListScreen from "../Screens/gamelist";
import ChallengesListScreen from "../Screens/challenges";
// import GameChallengesView from "../Screens/challenges/GameChallengesView";
import EventChallengesView from '../Screens/challenges/EventChallengesView'
import EventChallengesSummaryView from '../Screens/challenges/EventChallengesSummaryView'
import GameChallengesListView from '../Screens/challenges/GameChallengesListView'
import GameScreen from "../Screens/game";
import UpcomingGameView from "../Screens/game/upcomingGameView";
import CompletedGameView from "../Screens/game/completedGameView/CompletedGameWithRecordingsView";
import ProfileScreen from "../Screens/home/user/profile";
import ReviewProfileScreen from "../Screens/home/user/ReviewProfile";
import PlayerListScreen from "../Screens/PlayerProfileList";
import ContestInfoScreen from "../Screens/event/components/ContestInfo";
import ContestTypeScreen from "../Screens/event/contestType";
import UserSettingScreen from "../Screens/UserSetting";
import EventScoreBoardView from "../Screens/challenges/EventScoreBoard";

import EventChannelRoomScreen from "../Screens/channel";

import ContestChatScreen from "../Screens/game/ContestChatView";
import JudgeScoreGame from "../Screens/judgeScoreGame";
import UserScoringView from "../Screens/judgeScoreGame/UserScoringView";

export const Drawer = createDrawerNavigator();

export const Stack = createStackNavigator();

export const HomeStack = () => {
  return (
    <Stack.Navigator headerMode={"none"}>
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
      <Stack.Screen
        name="ReviewProfileScreen"
        component={ReviewProfileScreen}
      />
      <Stack.Screen name="ProfileScreen" component={ProfileScreen} />
      <Stack.Screen name="AboutScreen" component={AboutScreen} />
    </Stack.Navigator>
  );
};

export const GamesListStack = ({ navigation, route }) => {
  return (
    <Stack.Navigator initialRouteName="AllGames">
      <Stack.Screen
        name="AllGames"
        component={GamesListScreen}
        options={{ headerShown: false }}
        initialParams={{ isMine: route.params.isMine }}
      />
      <Stack.Screen
        name="ContestChatScreen"
        component={ContestChatScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ChannelRoom"
        component={EventChannelRoomScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="GameScreen"
        component={GameScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpcomingGameScreen"
        component={UpcomingGameView}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="PlayerProfileScreen"
        component={PlayerProfileScreen}
      />
      <Stack.Screen
        name="JudgeScoreGame"
        component={JudgeScoreGame}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UserScoringView"
        component={UserScoringView}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="GameChallengesListView"
        component={GameChallengesListView}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CompletedGameScreen"
        component={CompletedGameView}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

export const ChallengesListStack = () => {
  return (
    <Stack.Navigator initialRouteName="ChallengesList">
      <Stack.Screen
        name="ChallengesList"
        options={{ headerShown: false }}
        component={ChallengesListScreen}
      />
      <Stack.Screen
        name="Event Challenges List"
        options={{ headerShown: false }}
        component={EventChallengesView} />
      <Stack.Screen
        name="Event Score Board"
        options={{ headerShown: false }}
        component={EventScoreBoardView} />
      <Stack.Screen
        name="Event Challenges Summary"
        options={{ headerShown: false}}
        component={ EventChallengesSummaryView } />
      <Stack.Screen
        name="Game Challenges List"
        options={{ headerShown: false }}
        component={ GameChallengesListView } />
    </Stack.Navigator>
  );
};

export const UserSettingStack = () => {
  return (
    <Stack.Navigator initialRouteName="UserSettingScreen">
      <Stack.Screen
        name="UserSettingScreen"
        options={{ headerShown: false }}
        component={UserSettingScreen}
      />
    </Stack.Navigator>
  );
};

export const EventInfoStack = () => {
  return (
    <Stack.Navigator initialRouteName="JoinEventScreen" headerMode={"none"}>
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="JoinEventScreen"
        component={JoinEventScreen}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="ContestInfoScreen"
        component={ContestInfoScreen}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="PlayerListScreen"
        component={PlayerListScreen}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="PlayerProfileScreen"
        component={PlayerProfileScreen}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="EventInfoScreen"
        component={EventInfoScreen}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="AddNewContestScreen"
        component={AddNewContestScreen}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="CreateContestTypeScreen"
        component={CreateContestScreen}
        initialParams={{ fromEdit: true }}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="EventPaymentSignupScreen"
        component={EventPaymentSignupScreen}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="EPCreditCardScreen"
        component={EPCreditCardScreen}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="EPConfirmationScreen"
        component={EPConfirmationScreen}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="CreateBracketGroup"
        component={CreateBracketGroup}
      /> 
    </Stack.Navigator>
  );
};

export const EventStack = () => {
  return (
    <Stack.Navigator initialRouteName="CreateEventScreen" headerMode={"none"}>
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="CreateEventScreen"
        component={CreateEventScreen}
      />

      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="ContestTypeScreen"
        component={ContestTypeScreen}
      />

      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="CutomizeContestScreen"
        component={CutomizeContestScreen}
      />

      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="CreateContestScreen"
        component={CreateContestScreen}
      />

      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="EventProfileCreateScreen"
        component={EventProfileCreateScreen}
      />

      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="EventFeesScreen"
        component={EventFeesScreen}
      />
      
    </Stack.Navigator>
  );
};

const AuthStackNav = createStackNavigator();
export const AuthStack = () => {
  return (
    <AuthStackNav.Navigator
      initialRouteName={"LoginScreen"}
      headerMode={"none"}
    >
      <Stack.Screen name={"LoginScreen"} component={LoginScreen} />
      {/* <Stack.Screen name={"RegisterScreen"} component={RegisterScreen} /> */}
      <Stack.Screen name={"NewRegisterScreen"} component={NewRegisterScreen} />
      <Stack.Screen
        name={"CreateInitialProfileScreen"}
        component={CreateInitialProfileScreen}
      />
      <Stack.Screen
        name={"ResetPasswordScreen"}
        component={ResetPasswordScreen}
      />
      <Stack.Screen
        name={"TermsConditionsScreen"}
        component={TermsConditionsScreen}
      />
      <Stack.Screen
        name={"PrivacyPolicyScreen"}
        component={PrivacyPolicyScreen}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="EventInfoScreen"
        component={EventInfoScreen}
      />
      <Stack.Screen
        options={{ gestureEnabled: false }}
        name="ContestInfoScreen"
        component={ContestInfoScreen}
      />
      <CharitiesStackNav.Screen
        name={"ViewCharityScreen"}
        component={ViewCharityScreen}
      />
      <CharitiesStackNav.Screen
        name={"ViewAthleteScreen"}
        component={ViewAthleteScreen}
      />
    </AuthStackNav.Navigator>
  );
};

const CharitiesStackNav = createStackNavigator();

export const CharitiesStack = () => {
  return (
    <CharitiesStackNav.Navigator
      initialRouteName={"CreateCharityScreen"}
      headerMode={"none"}
    >
      <CharitiesStackNav.Screen
        options={{ gestureEnabled: false }}
        name="CreateCharityScreen"
        component={CreateCharityScreen}
      />
      <CharitiesStackNav.Screen
        name={"ViewCharityScreen"}
        component={ViewCharityScreen}
      />
      <CharitiesStackNav.Screen
        name={"ViewAthleteScreen"}
        component={ViewAthleteScreen}
      />
      <CharitiesStackNav.Screen
        name={"EditCharityScreen"}
        component={EditCharityScreen}
      />
    </CharitiesStackNav.Navigator>
  );
};
