import React, { memo, useEffect, useState, useImperativeHandle } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Alert,
  Image,
  Platform,
  ImageBackground,
} from "react-native";
import * as ImagePicker from "expo-image-picker";
import AntDesign from "react-native-vector-icons/AntDesign";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import Feather from "react-native-vector-icons/Feather";
// import { createThumbnail } from "react-native-create-thumbnail";
import Modal from "modal-enhanced-react-native-web";
import Styles from "./indexCss";
import { getWp, hp, wp } from "../../utils";
import { WebView } from "react-native-webview";
import { Video } from "expo-av";

AntDesign.loadFont();
MaterialIcons.loadFont();
Feather.loadFont();

//mode = "view" : "select";
const ImageVideoPlaceholder = React.forwardRef((props, ref) => {
  const {
    renderText = "",
    type,
    selectedData = () => {},
    containerStyle = {},
    mode = "select",
    viewURI = undefined,
    resetViewURI = () => {},
    imageStyle = {},
    renderChildren = false,
    disabledOnPress = false,
    onDeleteImgProp = () => {},
  } = props;
  const [data, setData] = useState({ type, content: undefined });
  const [modalVisibility, setModalVisibility] = useState(false);
  const [thumbnail, setThumbnail] = useState(undefined);
  const [hg, setHeight] = useState(window.innerHeight);
  const [wt, setWidth] = useState(window.innerWidth);
  // useEffect(() => {
  //   if (mode == "view" && type == "video") {
  //     createThumbnail({
  //       url: viewURI,
  //       timeStamp: 10000,
  //     })
  //       .then((response) => {
  //         console.log("ON_THUMNAIL_GET - ", response);
  //         setThumbnail(response);
  //       })
  //       .catch((err) => console.log({ err }));
  //   }
  // }, []);
  const update = () => {
    let height = window.innerHeight;
    let width = window.innerWidth;
    setTimeout(() => {
      setHeight((i) => window.innerHeight);
      setWidth((i) => window.innerWidth);
    }, 1000);
  };

  useEffect(() => {
    (async () => {
      await getCameraRequest();
    })();
    let rem = window.addEventListener("resize", () => update());
    removeEventListener("resize", rem);
  }, []);

  useEffect(() => {
    (async () => {
      await getCameraRequest();
    })();
    let rem = window.addEventListener("resize", () => update());
    removeEventListener("resize", rem);
  }, []);

  useEffect(() => {
    (async () => {
      await getCameraRequest();
    })();
    let rem = window.addEventListener("resize", () => update());
    removeEventListener("resize", rem);
  }, []);

  useImperativeHandle(ref, () => ({
    reset: () => {
      setData({ type, content: undefined });
    },
  }));
  const getCameraRequest = async () => {
    if (Platform.OS !== "web" && Platform.OS != "ios") {
      const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
      if (status !== "granted") {
        alert("Sorry, we need camera roll permissions to make this work!");
      }
    }
  };

  const pickImage = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes:
          type == "photo"
            ? ImagePicker.MediaTypeOptions.Images
            : ImagePicker.MediaTypeOptions.Videos,
        quality: 1,
        base64: false,
      });
      //console.log('RESULT_GET_HERE_FOR_PICK - ', JSON.stringify(result));
      if (!result.cancelled) {
        setData({
          type,
          content: result,
        });
        selectedData(result.uri);
      }
    } catch (error) {
      Alert.alert("Message", "Something went wrong!");
      console.log(error);
    }
  };
  const RenderImagePlaceholderText = () => {
    return <Text style={Styles.textStyle}>{renderText}</Text>;
  };
  const selectOnPressHandler = () => {
    if ((!viewURI || viewURI?.length == 0) && mode == "view") {
      return setModalVisibility(false);
    }
    if (viewURI || viewURI?.length > 0 || mode == "view") {
      return setModalVisibility(true);
    }

    try {
      if (!data.content) {
        return pickImage();
      }
      return setModalVisibility(true);
    } catch (error) {
      return Alert.alert("Message", "Something went wrong!");
    }
  };
  const RenderImage = React.useCallback(
    ({ style = {} }) => {
      if (!viewURI && !data?.content?.uri) {
        return null;
      }
      let uri = viewURI ? viewURI : data?.content?.uri;
      return (
        <View>
          <Image
            source={{ uri }}
            style={[
              Styles.imageStyle,
              imageStyle,
              style,
              { height: hg * 0.8, width: wt * 0.8 },
            ]}
          />
        </View>
      );
    },
    [viewURI, data, hg, wt]
  );
  const RenderImage2 = React.useCallback(
    ({ style = {} }) => {
      // console.log("RenderImage2")
      if (!viewURI && !data?.content?.uri) {
        return null;
      }
      let uri = viewURI ? viewURI : data?.content?.uri;
      return (
        <Image
          source={{ uri }}
          style={[Styles.imageStyle, imageStyle, style]}
        />
      );
    },
    [viewURI, data]
  );
  const deleteDataHandler = () => {
    if (viewURI) {
      setModalVisibility(false);
      return resetViewURI();
    }
    onDeleteImgProp();
    setModalVisibility(false);
    setTimeout(() => {
      setData({ type, content: undefined });
    }, 200);
  };
  const RenderModalContent = () => {
    // console.log('Video URL ----> ', JSON.stringify(viewURI))
    if (!viewURI && !data.content?.uri) {
      return (
        <View
          style={{
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            borderRadius: 10,
          }}
        >
          <Text
            style={{
              fontSize: 25,
              fontWeight: "bold",
              padding: 10,
              color: "#0B214D",
            }}
          >
            No Video
          </Text>
        </View>
      );
    }
    return (
      <View>
        {mode == "select" && (
          <TouchableOpacity
            style={Styles.deleteIconView}
            onPress={deleteDataHandler}
          >
            <MaterialIcons name={"delete"} size={getWp(10)} color={"white"} />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={Styles.closeContainer}
          onPress={() => setModalVisibility(false)}
        >
          <AntDesign name={"close"} size={getWp(10)} color={"white"} />
        </TouchableOpacity>
        {data.type == "photo" ? (
          <RenderImage style={Styles.renderModalImage} />
        ) : data.type == "video" && mode == "select" ? (
          <Video
            source={data.content?.uri ? data.content.uri : viewURI}
            rate={1.0}
            volume={1.0}
            isMuted={false}
            resizeMode="contain"
            shouldPlay={modalVisibility}
            useNativeControls
            style={{
              width: window.innerWidth * 0.6,
              height: window.innerHeight * 0.95,
              alignSelf: "center",
            }}
          />
        ) : (
          <Video
            source={viewURI}
            rate={1.0}
            volume={1.0}
            isMuted={false}
            resizeMode="contain"
            shouldPlay={modalVisibility}
            useNativeControls
            style={{
              width: window.innerWidth * 0.6,
              height: window.innerHeight * 0.95,
              alignSelf: "center",
              aspectRatio: 1,
            }}
          />
        )}
      </View>
    );
  };

  const RenderVideoThumnail = ({ style = {} }) => {
    if (!viewURI || viewURI?.length == 0 || viewURI == undefined) {
      return <Text style={Styles.textStyle}>{"No Video"}</Text>;
    }
    return <Feather name="play" color="#fff" size={30} />;
  };

  return (
    <View>
      <TouchableOpacity
        disabled={disabledOnPress}
        onPress={selectOnPressHandler}
        style={[Styles.container, containerStyle]}
      >
        {/* {renderChildren ? (
props.children
) : data.content?.uri || viewURI ? (
<RenderImage />
) : renderText.length > 0 ? (
<RenderImagePlaceholderText />
) : null} */}

        {renderChildren ? (
          props.children
        ) : mode == "view" && type == "video" ? (
          <RenderVideoThumnail />
        ) : mode == "select" && type == "video" && renderText.length == 0 ? (
          <RenderVideoThumnail />
        ) : data.content?.uri || (viewURI && type == "photo") ? (
          <RenderImage2 />
        ) : renderText.length > 0 ? (
          <RenderImagePlaceholderText />
        ) : null}

        <Modal
          isVisible={modalVisibility}
          style={{ height: hg * 0.6, width: wt * 0.6, alignSelf: "center" }}
          onBackButtonPress={() => setModalVisibility(false)}
          onBackdropPress={() => setModalVisibility(false)}
        >
          <View>
            <RenderModalContent />
          </View>
        </Modal>
      </TouchableOpacity>
    </View>
  );
});

export default memo(ImageVideoPlaceholder);
