import { StyleSheet } from 'react-native';
import { wp, hp, getHp, getWp, FONTSIZE } from '../../utils';

export default StyleSheet.create({
    dateTouchContainer: {
        //height: getHp(53),
        //padding: getHp(6),
        borderWidth: .3,
        borderRadius: 8,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 6
    },
    textStyle: {
        fontSize: 14,
        color: "#000",
        textAlign: "center",
        paddingHorizontal: 5
    }
})