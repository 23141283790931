
import { sortArrayAlphabatically } from './index';

export const getLargeNum = (arr = [], key = '') => {

    let allkeys = [...arr].map(d => d[key]?d[key]:0);
    console.log("FILT - ", allkeys);
    return Math.max(...allkeys);
}
export const transformFirebaseValues = (
        data, 
        keyForValue = '', 
        addFields = []
    ) => {
    return data.docs.map((doc) => {
        let data = doc.data();
        let inJectNewKeys = {};
        if (addFields.length > 0) {
            for (let key in addFields) {
                for (let objKey in addFields[key]) {
                    inJectNewKeys[objKey] = addFields[key][objKey];
                }
            }
        }
        return {
            id: doc.id,
            ...data,
            value: data[keyForValue] || "",
            ...inJectNewKeys
        };
    });
}

 
export const generateBracketRegionalHierarchy = (roundData) => {
    let data = [
        1,16,8,9,5,12,4,13,6,11,3,14,7,10,2,15
    ];

    let rounds = [];
        
    for(let d of data) {
        Object.keys(roundData).map(function(key, index) {
            if (d == parseInt(key)) {
                let data = {key: key, ...roundData[key]};
                rounds.push(data);
            }
        });
    }
    return rounds;
}

export const transformBracketGuessingWhileFetch = (data, outerType = 'region1') => {
    let trans = { type: outerType, bracketData: []}; 
    data.map((d, index) => {
        let bracketObj = {type:d.id, data: []};
        Object.keys(d).map((innerKey, index) => {
            if(typeof d[innerKey] == 'object') {
                bracketObj.data.push(d[innerKey]);
            }
        });
        let sorted = sortArrayAlphabatically(bracketObj.data, 'index');
        trans.bracketData.push(bracketObj);
    });  

    return trans;
}