import React, { memo } from 'react';
import { View, Text, Image } from 'react-native';


import Styles from './indexCss';
const SingleHeading = (props) => {
    const {
        placeholder = "",
        containerStyle = {},
        textColor = 'black',
        onRightComponent = null,
        onLeftComponent = null,
        nullPlaceholder = false,
        imgURL = '',
    } = props;
    const RenderPlaceholder = () => {
        return <Text style={[Styles.placeholderTextStyle, { color: textColor }]}>
            {placeholder}
        </Text>
    }
    const Left = () => {
        return <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Image
          source={{ uri: imgURL }}
          style={{
            height: 50,
            width: 50,
            borderRadius: 50,
          }}
        />
        <View style={{ marginLeft: 20 }}>
          <RenderPlaceholder/>
        </View>
      </View>
    }
    return (
        <View style={[Styles.container, containerStyle]}>
            {
                onLeftComponent && <Left/>
            }
            {
                !nullPlaceholder  && <RenderPlaceholder />
            }
            {
                onRightComponent && onRightComponent()
            }
        </View>

    );
}

export default memo(SingleHeading);
