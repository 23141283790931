import React from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity } from "react-native";
import { RightArrow } from "../../../../icon";
import Avatar from "../../../../assets/avatar1.jpg";

const PlayerItem = ({ item, navigation, event, allEvents }) => {
  //console.log('Player List Single Item --> ', JSON.stringify(item))
  let profileImg = { type: "source", Avatar };
  if (item?.userData?.userAvatar?.length > 0) {
    profileImg = { type: "uri", Avatar: item?.userData?.userAvatar };
  }
  let completeContestText = "";
  if (item.contestData?.length > 0) {
    let CD = item.contestData?.sort(
      (a , b) => a.contestInfoData.contestTypeID - b.contestInfoData.contestTypeID
    );
    completeContestText = CD.map((i) => {
      if (i.contestInfoData.contestName) {
        return i.contestInfoData.contestName;
      }
      return "";
    }).join(",");
  }
  let userType = item?.contestData[0]?.userContestParticipationType || "";
  if (userType) {
    userType = `(${userType})`;
  } else {
    userType = "";
  }
  return (
    <View style={styles.container}>
      <View style={{ width: "20%", overflow: "hidden" }}>
        <Image
          style={styles.img}
          source={
            profileImg.type == "uri"
              ? { uri: profileImg.Avatar }
              : profileImg.Avatar
          }
          //source={{uri: item.userAvatar}}
        />
      </View>
      <View style={{ marginLeft: 20, width: "80%" }}>
        <Text style={styles.name}>
          {item?.userData?.userName || ""}
          {" " + userType}
        </Text>
        <Text style={styles.otherInfo}>
          {item?.userData?.userNickname || ""}
        </Text>
        <Text style={[styles.otherInfo, { marginTop: 10 }]}>
          {completeContestText}
        </Text>
      </View>

      <TouchableOpacity
        onPress={() => {
          navigation.navigate("EventInfoStack", {
            screen: "PlayerProfileScreen",
            params: {
              ...{
                userID: item.userData.id,
                eventID: item.eventData.eventID,
                ob: 2,
              },
            },
          });
        }}
        style={{
          right: 15,
          top: 20,
          position: "absolute",
          // borderWidth: 1,
          // borderColor: 'red',
          padding: 15,
        }}
      >
        <RightArrow />
      </TouchableOpacity>
    </View>
  );
};
export default PlayerItem;
const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingVertical: 20,
    borderWidth: 1,
    borderColor: "#F2F2F2",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  info: { flexDirection: "row", alignItems: "center" },
  img: { height: 80, width: 80, borderRadius: 50 },
  name: { fontWeight: "bold", fontSize: 18, lineHeight: 22, color: "black" },
  otherInfo: {
    fontSize: 16,
    lineHeight: 22,
    color: "black",
    paddingHorizontal: 5,
  },
  ParticipationType: {
    fontSize: 14,
    lineHeight: 22,
    color: "black",
    paddingLeft: 3,
    fontWeight: "bold",
  },
});
