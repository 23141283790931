import {
    generateLink
} from '../../../utils';

let bracketModel = {
    groupName: '',
    hostName: '',
    pointsPerRound: {
        championship: '',
        finalFour: '',
        round1: '',
        round2: '',
        round3: '',
        round4: ''
    },
    text: ''
}
class BracketHostModel {
    events = [];
    contests = [];
    selectedEventId = null;
    selectedContestId = null;
    formData = {};
    constructor() {
        let formD = { ...bracketModel };
        formD.pointsPerRound = { ...formD.pointsPerRound };
        this.formData = { ...formD };
    }
    init = (events) => {
        this.events = events;
        return { ...this };
    }
    updateForm = (key, value) => {
        let newFormData = { ...this.formData };
        newFormData[key] = value;
        this.formData = newFormData;
        return { ...this };
    }
    updatePointsPerRound = (key, value) => {
        let newFormData = { ...this.formData };
        newFormData.pointsPerRound[key] = value;
        this.formData = newFormData;
        return { ...this };
    }

    validateData = () => {
        let isValidate = true;
        let message = '';
        if (this.formData.groupName.length == 0) {
            isValidate = false;
            message = 'Enter Group Name';
        } else if (this.formData.hostName.length == 0) {
            isValidate = false;
            message = 'Enter Host Name';
        } else if (isNaN(this.formData.pointsPerRound.championship)
            || this.formData.pointsPerRound.championship.length == 0) {
            isValidate = false;
            message = 'Enter Championship in Numeric Digit';
        } else if (isNaN(this.formData.pointsPerRound.finalFour)
            || this.formData.pointsPerRound.finalFour.length == 0
        ) {
            isValidate = false;
            message = 'Enter Final four in Numeric Digit';
        } else if (isNaN(this.formData.pointsPerRound.round1)
            || this.formData.pointsPerRound.round1.length == 0
        ) {
            isValidate = false;
            message = 'Enter Round 1 in Numeric Digit';
        } else if (isNaN(this.formData.pointsPerRound.round2)
            || this.formData.pointsPerRound.round2.length == 0
        ) {
            isValidate = false;
            message = 'Enter Round 2 in Numeric Digit';
        } else if (isNaN(this.formData.pointsPerRound.round3)
            || this.formData.pointsPerRound.round3.length == 0
        ) {
            isValidate = false;
            message = 'Enter Round 3 in Numeric Digit';
        }
        else if (isNaN(this.formData.pointsPerRound.round4)
            || this.formData.pointsPerRound.round4.length == 0
        ) {
            isValidate = false;
            message = 'Enter Round 4 in Numeric Digit';
        } else if (this.formData.text.length == 0) {
            isValidate = false;
            message = 'Enter Info Text';
        } else if (this.selectedEventId == null) {
            isValidate = false;
            message = 'Select Event';
        } else if (this.selectedContestId == null) {
            isValidate = false;
            message = 'Select Contest';
        }
        return {
            isValidate,
            message
        }
    }
    getFirebaseValue = () => {
        let firebaseValues = { ...this.formData };
        let groupLink = this.getBracketURL()
        return {
            groupLink,
            groupName: firebaseValues.groupName,
            hostName: firebaseValues.hostName,
            eventID: this.selectedEventId,
            contestID: this.selectedContestId,
            createdAt: new Date(),
            pointsPerRound: {
                championship: parseInt(firebaseValues.pointsPerRound.championship),
                finalFour: parseInt(firebaseValues.pointsPerRound.finalFour),
                round1: parseInt(firebaseValues.pointsPerRound.round1),
                round2: parseInt(firebaseValues.pointsPerRound.round2),
                round3: parseInt(firebaseValues.pointsPerRound.round3),
                round4: parseInt(firebaseValues.pointsPerRound.round4),
            },
            text: firebaseValues.text
        }
        return firebaseValues;
    };
    clear = (formsRef) => {
        if (formsRef) {
            formsRef.current.event.current.reset();
            formsRef.current.contest.current.reset();
        }
        this.contests = [];
        let formD = { ...bracketModel };
        formD.pointsPerRound = { ...bracketModel.pointsPerRound };
        this.formData = { ...formD };
        this.selectedContestId = null;
        this.selectedEventId = null;
        return { ...this };
    };
    update = (key, value) => {
        this[key] = value;
        return { ...this };
    };
    getBracketURL = () => {
        let URL = 'https://players-recreation-league.web.app/bracketEntry/';
        let eventId = this.selectedEventId;
        let contestID = this.selectedContestId;
        if (eventId && contestID) {
            URL = URL + `${eventId}/${contestID}/${this.formData.groupName.convertToSlug()}`;
        }
        
        return URL;

    }
}

export default BracketHostModel;