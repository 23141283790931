// External Imports
import PropTypes from "prop-types";
import React, {
  forwardRef, useImperativeHandle, useRef,
  useState
} from "react";
import { View } from "react-native";

// Internal Imports
import { Picker } from "@react-native-picker/picker";
import Feather from "react-native-vector-icons/Feather";
Feather.loadFont();

const PreviewFilterDropdown = forwardRef((props, refs) => {
  const {
    items,
    onSelect,
    placeholder,
    width,
    height,
    containerStyle = {},
    dropdownContainer = {},
    showBorders = true,
  } = props;
  const [resetD, setresetD] = useState(undefined);
  const [expanded, setExpanded] = useState(false);
  const dropdownRef = useRef();
  const [currentValue,setCurrentValue] = useState(JSON.stringify({}));
  useImperativeHandle(refs, () => ({
    reset: () => {
      setCurrentValue(JSON.stringify({}));
    },
  })); 

  return (
    <View style={containerStyle}>
      {/* <ModalDropdown
                ref={dropdownRef}
                style={[styles.dropdownContainer, dropdownContainer]}
                textStyle={styles.dropdownSelectedText}
                dropdownStyle={[styles.dropdown, width && { width: width * .9 }]}
                defaultValue={placeholder}
                options={items}
                renderButtonText={renderButtonText}
                renderRow={renderDropdownItem.bind(this)}
                onSelect={index => onSelect(items[index])}
                onDropdownWillShow={() => setExpanded(true)}
                onDropdownWillHide={() => setExpanded(false)}
            /> */}
      <Picker
        ref={dropdownRef}
        selectedValue={currentValue}
        mode="dialog"
        onValueChange={(itemValue, itemIndex) => {
          // let i =  itemIndex == 0 ? itemIndex : itemIndex -1
          // onSelect(items[i]);
          setCurrentValue(itemValue);
          onSelect(JSON.parse(itemValue));
        }}
        style={[
          containerStyle,
          {
            height: height,
            width: width,
            borderWidth: showBorders ? 1 : 0,
            borderRadius: 18.5,
            marginTop: showBorders ? 15 : 0,
          },
        ]}
      >
        <Picker.Item label={placeholder} value={JSON.stringify({})} />
        {items?.length > 0
          ? items.map((Items, index) => (
              <Picker.Item key={index} label={Items.value} value={JSON.stringify(Items)} />
            ))
          : []}
      </Picker>
      {/* <TouchableOpacity onPress={toggleIcon}>
                <Feather name={expanded ? "chevron-up" : "chevron-down"} size={20} />
            </TouchableOpacity> */}
    </View>
  );
});

PreviewFilterDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  selectedValue: PropTypes.object,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
};

PreviewFilterDropdown.defaultProps = {
  items: [],
  selectedValue: null,
  placeholder: "",
  onSelect: () => {},
};

export default PreviewFilterDropdown;
