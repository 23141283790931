import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  ScrollView,
  View,
  Text,
  Alert,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  Header,
  Root,
  StaticEventImageHeader,
  TripleHeading,
  TextInputHeading,
  // CollapsibleViewWithHeading,
  TouchableButton,
  EventFeesInputRow,
  CustomModalDropDown,
} from "../../../../Component";
import Styles from "./indexCss";
import FeesModel from "./Fees.model";
import { getFromToDate, getHp } from "../../../../utils";
import { useLoader, useFirebaseUpload } from "../../../../hooks";
import {
  db,
  userEnteredContestsCollection,
  eventContestFeesCollection,
  eventProfileQuestionsCollection,
  playerEventProfileCollection,
  stripeTransactionsCollection,
  squareTransactionsCollection,
  charitiesPaymentCollection,
  eventsCollection,
} from "../../../../firebase";
import { toggleAlertBox } from "../../../../store/actions";
import { transformFirebaseValues, getLargeNum } from "../../../../utils";
import PaymentComponent from "../../../../Component/PaymentForm";
import { FirebaseEmail } from "../../../../app/classes";
import { dispatchSignupToConfirm } from "../../../../store/actions";
import Modal from "modal-enhanced-react-native-web";
import { Elements, useElements } from "@stripe/react-stripe-js";
import { loadStripe, ConfirmPaymentData } from "@stripe/stripe-js";
import StripePaymentForm from "../../../../Component/StripePaymentForm";
import { stripeProduction, squareProduction } from "../../../../../app.json";
import PaymentHostInfo from "./PaymentHostInfo";

let ENV = {
  sandbox:
    "pk_test_51HQ0VUByjbstD2KsSb6Ve2adXLsLdsgNO3IIsPAtwvPCbOata2544fLbbsaL4G1ki3G7ehArW2lqevPvZYPFuNv700JpcOpUFc",
  production:
    "pk_live_51HQ0VUByjbstD2KstOvN1wBuQJPuZ7cAy4ydU8LR8VQK1hzbT8PLmEI40NcTnrkZhNhWlePH8aUFop3Z3k0OGUEE00lPUwBX2Z",
};
const Publish_Key = stripeProduction ? ENV.production : ENV.sandbox;
let stripePromise = loadStripe(Publish_Key);

const EventFees = (props) => {
  const [paymentModel, setPaymentModel] = useState(false);
  const [stripePaymentModel, setStripePaymentModel] = useState(false);
  const formsRef = useRef({
    charityData: useRef(),
  });
  const { eventId = {} } = props.route.params;
  const [feesModel, setFeesModel] = useState(FeesModel);
  const authState = useSelector((state) => state.auth);
  const { firebaseAllCollectionData, auth } = useSelector((state) => state);
  const [setLoader, LoaderComponent] = useLoader();
  const [eventData, setEventData] = useState();
  const [currentCharity, setCurrentCharity] = useState();
  const [sqAppId, setSqAppId] = useState();
  const [allPaymentConfig, setAllPaymentConfig] = useState([]);
  const [payCharity, setPayCharity] = useState(0);
  const [stripePromiseRef, setStripePromiseRef] = useState(stripePromise);

  const [paymentMode, setPaymentMode] = useState("Square");
  const [currentPayInfo, setCurrentPayInfo] = useState();

  const [charityData, setCharityData] = useState([]);
  const dispatch = useDispatch();

  const loadData = async (eventPropData) => {
    const contestFeeColData = await eventContestFeesCollection
      .where("eventID", "==", parseInt(eventPropData))
      .get();
    const preSignupRes = await userEnteredContestsCollection
      .where("eventID", "==", parseInt(eventPropData))
      .where("userID", "==", authState.userId)
      .get();
    const currentEvent = await eventsCollection
      .where("eventID", "==", parseInt(eventPropData))
      .get();
    const preEventData = currentEvent.docs[0].data();
    const charityPaymentData = await charitiesPaymentCollection.get();
    let d = [];
    charityPaymentData.docs.map((SingleCharityData) => {
      d.push(SingleCharityData.data());
    });
    setAllPaymentConfig(d);
    const preSignupData = transformFirebaseValues(preSignupRes, "eventID");
    const firebaseCollectionData = {
      ...firebaseAllCollectionData.firebaseCollectionData,
    };
    const allCharityData = [
      ...firebaseAllCollectionData.firebaseCollectionData.charityData,
    ];
    let currentEventData = preEventData;
    // firebaseCollectionData.eventsData.map((event, index) => {
    //   if (event.eventID == parseInt(eventId)) {
    //     currentEventData = { ...event };
    //   }
    // });
    if (currentEventData.charityID != 0) {
      allCharityData.map((singleCharity, sIndex) => {
        if (singleCharity.charityID == currentEventData.charityID) {
          setCurrentCharity(singleCharity);
        }
      });
    }

    setEventData(currentEventData);
    setFeesModel(
      feesModel.onInit(
        contestFeeColData,
        currentEventData,
        preSignupData,
        allCharityData
      )
    );
    if (
      !(
        currentEventData?.charityID == 0 &&
        !feesModel?.selectedCharityData?.charityName
      )
    ) {
      const paymentData = d.find(
        (s) => s.charityID == currentEventData?.charityID
      );
      const universalPaymentData = d.find((s) => s.charityID == 0);
      let usingPaymentData = {};
      console.log("event Data ---> ", currentEventData);
      if (paymentData && paymentData.enablePaymentProcessing) {
        usingPaymentData = paymentData;
        setPayCharity(currentEventData?.charityID);
      } else {
        usingPaymentData = universalPaymentData;
        setPayCharity(0);
      }
      let HostInfo = new PaymentHostInfo(usingPaymentData);
      HostInfo.initPaymentInfoProcess();
      let host = HostInfo.getActivePaymentHosts();
      let hostPayInfo = HostInfo.getCurrentPaymentInfo();
      console.log("host --> ", JSON.stringify(host));
      console.log("host Pay Info --> ", JSON.stringify(hostPayInfo));
      setPaymentMode(host[0]);
      setCurrentPayInfo(hostPayInfo);
      if (host[0] == "Square") {
        if (squareProduction) {
          setSqAppId({
            id: hostPayInfo?.productionApplicationID,
            charityID: 0,
          });
        } else {
          setSqAppId({
            id: hostPayInfo?.sandboxApplicationID,
            charityID: 0,
          });
        }
      } else if (host[0] == "Stripe") {
        if (stripeProduction) {
          setStripePromiseRef(
            loadStripe(hostPayInfo?.productionPublishableKey)
          );
        } else {
          setStripePromiseRef(loadStripe(hostPayInfo?.sandboxPublishableKey));
        }
      }
      console.log("Host Data -> ", hostPayInfo);
    }
    setLoader(false);
    loadCharity(currentEventData);
  };

  const loadCharity = (currentEventData) => {
    let Arr = [];
    if (currentEventData?.charityType && currentEventData?.charityType === "Student Athlete") {
      Arr = feesModel?.allCharityData.filter(
        (s) => s?.charityType === currentEventData?.charityType
      );
      Arr.sort((a, b) => a.charityID - b.charityID);
      setCharityData(Arr);
    } else {
      Arr = feesModel?.allCharityData.filter(
        (s) => s?.charityType === "Charity" && s?.charityID !== 0
      );
      setCharityData(Arr);
    }
  };

  useEffect(() => {
    setLoader(true);
    if (firebaseAllCollectionData.loading) {
      return;
    }
    setFeesModel(feesModel.resetScreen());

    setTimeout(() => {
      loadData(eventId);
    }, 500);
  }, [eventId, firebaseAllCollectionData.loading]);

  const showPopup = ({ text = "", onPressOk }) => {
    return dispatch(
      toggleAlertBox({
        visible: true,
        text: text,
        onPress: () => {
          dispatch(toggleAlertBox("CLOSE"));
          onPressOk();
        },
      })
    );
  };

  let playerEventProfileModalCol = {
    eventID: 0,
    profileA1: "",
    profileA2: "",
    profileA3: "",
    profileA4: "",
    profileFirstName: "",
    profileID: "",
    profileImage: "",
    profileImageQ: "",
    profileNickname: "",
    profilePlayerForCharityID: 1,
    profilePlayerPicture: "",
    profileQ1Label: "",
    profileQ2Label: "",
    profileQ3Label: "",
    profileQ4Label: "",
    profileVideo: "",
    profileVideoQ: "",
    userID: "",
  };

  const createPlayerProfileEntry = async () => {
    try {
      let isProfileExist = await playerEventProfileCollection
        .where("userID", "==", authState.userId)
        .where("eventID", "==", eventData.eventID)
        .get();
      if (isProfileExist.docs.length > 0) {
        return isProfileExist.docs[0].data;
      }
      const getEventProfileQuestions =
        await eventProfileQuestionsCollection.get();
      const getEventProfileQuestionsTrans = transformFirebaseValues(
        getEventProfileQuestions,
        "eventID"
      );
      let profileQuestion = {};
      getEventProfileQuestionsTrans.map((i) => {
        if (i.eventID == eventData.eventID) {
          profileQuestion = { ...i };
        }
      });
      ////console.log('GOT_PLAYER_QUESTIONS - ', JSON.stringify(profileQuestion));
      if (Object.keys(profileQuestion).length == 0) {
        return;
      }
      let playerEventProfileModal = { ...playerEventProfileModalCol };
      playerEventProfileModal.eventID = eventData.eventID;
      (playerEventProfileModal.profilePlayerForCharityID = eventData.charityID),
        (playerEventProfileModal.profileFirstName = authState.userCol.userName);
      playerEventProfileModal.profileID = Date.now();
      playerEventProfileModal.profileImageQ = profileQuestion.profileImageQ;
      playerEventProfileModal.profileNickname = authState.userCol.userNickname;
      playerEventProfileModal.profileQ1Label = profileQuestion.profileQ1Label;
      playerEventProfileModal.profileQ2Label = profileQuestion.profileQ2Label;
      playerEventProfileModal.profileQ3Label = profileQuestion.profileQ3Label;
      playerEventProfileModal.profileQ4Label = profileQuestion.profileQ4Label;
      playerEventProfileModal.profileVideoQ = profileQuestion.profileVideoQ;
      playerEventProfileModal.userID = authState.userId;
      ////console.log('CREATE_PLAYER_EVENT_PROFILE - ', JSON.stringify(playerEventProfileModal));
      const savePlayerEventProfile = await playerEventProfileCollection.add(
        playerEventProfileModal
      );
      console.log("PLAYER_EVENT_PROFILE_CREATED - ", savePlayerEventProfile.id);
      return savePlayerEventProfile.id;
    } catch (e) {
      console.log("Error --> ", JSON.stringify(e));
    }
  };

  const saveFeesDataToFirebase = (firebaseSaveData) => {
    var batch = db.batch();
    firebaseSaveData.forEach((doc) => {
      var docRef = userEnteredContestsCollection.doc();
      batch.set(docRef, doc);
    });
    batch.commit();
  };

  const popModal = () => {
    let selected = feesModel.selectedFeeLength();
    let total = feesModel.getTotal();
    //console.log('All charityData --> ', feesModel?.allCharityData);
    if (
      eventData?.charityID == 0 &&
      !feesModel?.selectedCharityData?.charityName
    ) {
      return showPopup({
        text: "Please select Charity",
        onPressOk: () => {},
      });
    }

    if (selected == 0) {
      showPopup({
        text: "To enter the event, you must select at least one of the options",
        onPressOk: () => {},
      });
    } else if (selected > 0 && total != "$00.00") {
      if (currentPayInfo != undefined) {
        setTimeout(() => {
          if (paymentMode == "Stripe") {
            setStripePaymentModel((i) => !i);
          } else if (paymentMode == "Square") {
            setPaymentModel((i) => !i);
          }
        }, 1500);
      } else {
        showPopup({
          text: "We are not able to process the payment at this time. Please check back later.",
          onPressOk: () => {},
        });
      }
    } else {
      showPopup({
        text: "You have successfully signed up for the event",
        onPressOk: () => {
          paymentOnPress();
        },
      });
    }
  };

  const paymentOnError = () => {
    showPopup({
      text: "Payment Fail !!",
      onPressOk: () => {},
    });
  };

  const paymentOnPress = async (res) => {
    setLoader(true);
    try {
      if (paymentMode == "Square") {
        const squareTransactionsBody = {};
        squareTransactionsBody.paymentResponse = JSON.stringify(res);
        squareTransactionsBody.eventID = eventData?.eventID;
        squareTransactionsBody.userID = authState.userId;
        squareTransactionsBody.createdAt = new Date();

        squareTransactionsBody.amountPaid =
          feesModel.getTotal(true).selectedTotalFees;

        squareTransactionsBody.charityID = feesModel?.selectedCharityData
          .charityID
          ? feesModel?.selectedCharityData?.charityID
          : eventData?.charityData?.charityID;

        squareTransactionsBody.paidCharityID = payCharity;

        squareTransactionsBody.charityName = feesModel?.selectedCharityData
          .charityName
          ? feesModel?.selectedCharityData?.charityName
          : eventData?.charityData?.charityName;

        squareTransactionsBody.env = stripeProduction
          ? "PRODUCTION"
          : "SANDBOX";
        const saveStripeTransaction = await squareTransactionsCollection.add(
          squareTransactionsBody
        );
        console.log("All Square Done");
      }
      const getData = await userEnteredContestsCollection.get();
      const transData = transformFirebaseValues(
        getData,
        "userEnteredContestID"
      );
      let savedId = getLargeNum(transData, "userEnteredContestID");
      let firebaseSaveData = feesModel.getFirebaseData(
        authState?.user?.uid,
        ++savedId
      );
      let itemsPaidFor = firebaseSaveData.map((i) => i.contestName).join(", ");
      const sendEmailResponse = await FirebaseEmail.sendMail(
        FirebaseEmail.paymentConfirmation({
          event: eventData.eventName,
          eventDate: eventData.eventDate,
          itemsPaidFor: itemsPaidFor,
          totalPayment: feesModel.getTotal(),
          userName: auth.userCol.userName,
          toUids: auth.userId,
        })
      );
      await createPlayerProfileEntry();
      await saveFeesDataToFirebase(firebaseSaveData);
      setPaymentModel(false);
      dispatch(
        dispatchSignupToConfirm({
          eventId: eventData.eventID,
          selectedFeesData: firebaseSaveData,
        })
      );
      setTimeout(() => {
        setLoader(false);
        props.navigation.navigate("EventInfoStack", {
          screen: "EPConfirmationScreen",
        });
      }, 1000);
      return false;
    } catch (error) {
      setLoader(false);
      Alert.alert("Message", "Something went wrong!");
    }
  };

  const CharityDropDownComponent = () => {
    return (
      <CustomModalDropDown
        ref={formsRef.current.charityData}
        onSelect={(charity) => {
          setFeesModel(feesModel.update("selectedCharityData", { ...charity }));
          const PaymentData = allPaymentConfig.find(
            (s) => s.charityID == charity.charityID
          );
          const UniversalPaymentData = allPaymentConfig.find(
            (s) => s.charityID == 0
          );
          let usingPaymentData = {};
          if (PaymentData && PaymentData.enablePaymentProcessing) {
            usingPaymentData = PaymentData;
            setPayCharity(charity.charityID);
          } else {
            usingPaymentData = UniversalPaymentData;
            setPayCharity(0);
          }
          let HostInfo = new PaymentHostInfo(usingPaymentData);
          HostInfo.initPaymentInfoProcess();
          let host = HostInfo.getActivePaymentHosts();
          let hostPayInfo = HostInfo.getCurrentPaymentInfo();
          setPaymentMode(host[0]);
          setCurrentPayInfo(hostPayInfo);
          if (host[0] == "Square") {
            if (squareProduction) {
              setSqAppId({
                id: hostPayInfo?.productionApplicationID,
                charityID: charity.charityID,
              });
            } else {
              setSqAppId({
                id: hostPayInfo?.sandboxApplicationID,
                charityID: charity.charityID,
              });
            }
          } else if (host[0] == "Stripe") {
            if (stripeProduction) {
              setStripePromiseRef(
                loadStripe(hostPayInfo?.productionPublishableKey)
              );
            } else {
              setStripePromiseRef(
                loadStripe(hostPayInfo?.sandboxPublishableKey)
              );
            }
          }
          console.log("Host -> ", HostInfo.getActivePaymentHosts());
          console.log("Current Payment Info -> ", hostPayInfo);
        }}
        width={240}
        height={37}
        items={
          feesModel?.allCharityData?.length > 0 ? charityData : []
        }
        placeholder={
          feesModel?.selectedCharityData?.charityName
            ? feesModel?.selectedCharityData?.charityName
            : "Select"
        }
      />
    );
  };

  return (
    <Root>
      <ScrollView style={Styles.container}>
        {paymentModel ? (
          <PaymentComponent
            onPaymentFail={() => paymentOnError()}
            onPaymentSuccess={(e) => paymentOnPress(e)}
            config={payCharity}
            btnTotal={feesModel.getTotal()}
            total={feesModel.getTotal(true).selectedTotalFees}
            isVisible={paymentModel}
            closePayment={() => setPaymentModel((i) => !i)}
            AppId={sqAppId}
          />
        ) : null}
        {stripePaymentModel ? (
          <Modal
            isVisible={stripePaymentModel}
            onBackdropPress={() => {
              setStripePaymentModel((i) => !i);
            }}
          >
            <Elements stripe={stripePromiseRef}>
              <View style={styles.modalView}>
                <StripePaymentForm
                  config={payCharity}
                  btnTotal={feesModel.getTotal()}
                  total={feesModel.getTotal(true).selectedTotalFees}
                  eventName={eventData?.eventName}
                  charityName={
                    currentCharity
                      ? currentCharity.charityName
                      : feesModel?.selectedCharityData?.charityName
                  }
                  charityID={
                    currentCharity
                      ? currentCharity.charityName
                      : feesModel?.selectedCharityData?.charityID
                  }
                  email={authState?.user?.email}
                  eventID={eventData?.eventID}
                  setLoader={(i) => setLoader(i)}
                  onPaymentSuccess={() => paymentOnPress()}
                  closePayment={() => setStripePaymentModel((i) => !i)}
                  onPaymentFail={() => paymentOnError()}
                />
              </View>
            </Elements>
          </Modal>
        ) : null}
        <LoaderComponent />
        <Header
          hideMenu
          heading={`Payment Overview`}
          menuOnPress={() => props.navigation.openDrawer()}
          leftOnPress={() =>
            props.navigation.navigate("EventInfoStack", {
              screen: "EventInfoScreen",
              params: { eventID: eventId },
            })
          }
        />
        <StaticEventImageHeader
          eventImageURI={eventData?.eventLogo}
          eventName={eventData?.eventName}
          date={getFromToDate(eventData?.eventDate, eventData?.eventDateEnd)}
          charity={currentCharity ? currentCharity.charityName : "Add Charity"}
          containerStyle={Styles.staticEventImageContainerStyle}
          renderCharityDropDown={eventData?.charityID == 0}
          CharityDropDownComponent={CharityDropDownComponent}
        />
        {/* <View style={styles.paymentView}>
            <TouchableOpacity
              style={{ flexDirection: "row", alignItems: "center" }}
              onPress={() => {
                setPaymentMode("square");
              }}
            >
              <View
                style={
                  paymentMode == "square"
                    ? styles.selectedPaymentMode
                    : styles.nonSelectedPaymentMode
                }
              ></View>
              <Text style={styles.textOfPayment}>Pay via Square</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ flexDirection: "row", alignItems: "center" }}
              onPress={() => {
                setPaymentMode("stripe");
              }}
            >
              <View
                style={
                  paymentMode == "stripe"
                    ? styles.selectedPaymentMode
                    : styles.nonSelectedPaymentMode
                }
              ></View>
              <Text style={styles.textOfPayment}>Pay via Stripe</Text>
            </TouchableOpacity>
          </View> */}

        <TripleHeading
          left={eventData?.eventSubCategory}
          center={eventData?.eventGenre}
          right={eventData?.eventCategory}
          containerStyle={Styles.tripleHeadingContainer}
        />
        <TextInputHeading
          heading={"Payment Terms"}
          placeholder={"Type Payment Terms..."}
          value={eventData?.eventPaymentTerms || " "}
          editable={false}
          onChangeText={() => {}}
        />
        {/* <CollapsibleViewWithHeading
                    defaultCollapseValue={false}
                    heading={'See Details'}
                    // collapseStyle={{ minHeight: getHp(300) }}
                    containerStyle={{ marginTop: getHp(30) }}> */}
        {feesModel.allContestTypeFees.map((cFees, index) => {
          return (
            <EventFeesInputRow
              key={index}
              isAlreadyPaid={cFees.isAlreadyPaid}
              editable={false}
              text={cFees?.contestName || ""}
              value={"" + cFees?.eventContestFeeCents / 100}
              selectable
              isSelected={cFees.isSelected}
              onPress={() => {
                setFeesModel(
                  feesModel.pressBtns(cFees, index, "allContestTypeFees")
                );
              }}
            />
          );
        })}

        {feesModel.allEventTypeFees.map((eFees, index) => {
          return (
            <EventFeesInputRow
              key={index}
              isAlreadyPaid={eFees.isAlreadyPaid}
              editable={false}
              text={eFees?.eventContestFeeTypeName || eFees?.contestName || ""}
              value={"" + eFees?.eventContestFeeCents / 100}
              selectable
              isSelected={eFees.isSelected}
              onPress={() => {
                setFeesModel(
                  feesModel.pressBtns(eFees, index, "allEventTypeFees")
                );
              }}
              renderIncluded={
                eFees.contestName == "Spectator" &&
                feesModel.isSpectatorMode.mode &&
                feesModel.conditionCheck()
              }
            />
          );
        })}

        <View style={{ height: 10 }} />
        {/* </CollapsibleViewWithHeading> */}
        <View style={Styles.feeSummaryContainer}>
          <Text style={Styles.feeSummaryTextStyle}>Fee Summary</Text>
          <Text style={Styles.feeSummaryTextStyle}>
            Total: {feesModel.getTotal(true).selectedTotalFees}
          </Text>
        </View>

        {feesModel.shouldRenderPayButton() && (
          <TouchableButton
            title={feesModel.getPayButtonText()}
            type={"paymentBTN"}
            containerStyle={Styles.creaditCartStyle}
            onPress={() => popModal()}
          />
        )}
        {/* <TouchableButton
                    title={'Pay with Paypal'}
                    type={'paymentBTN'}
                    containerStyle={Styles.creaditCartStyle}
                    onPress={() => paymentOnPress()}
                /> */}

        <View style={{ marginVertical: 20 }} />
      </ScrollView>
    </Root>
    // </Elements>
  );
};

export default EventFees;

const styles = StyleSheet.create({
  selectedPaymentMode: {
    backgroundColor: "#0B214D",
    borderColor: "#0B214D",
    height: 13,
    width: 13,
    borderRadius: 7,
    borderWidth: 0.5,
    marginTop: 5,
  },
  nonSelectedPaymentMode: {
    borderColor: "#0B214D",
    backgroundColor: "#FFF",
    height: 13,
    width: 13,
    borderRadius: 7,
    borderWidth: 0.5,
    marginTop: 5,
  },
  textOfPayment: {
    fontSize: 16,
    color: "black",
    marginLeft: 10,
  },
  paymentView: {
    width: "60%",
    alignSelf: "center",
    justifyContent: "center",
    marginTop: 15,
  },
  modalView: {
    backgroundColor: "#C1C1C1",
    width: "50%",
    height: 210,
    alignSelf: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
});
