// External Imports
import { useIsFocused, useLinkTo } from "@react-navigation/native";
import React, { useCallback, useEffect } from "react";
import {
  ScrollView, Text, TouchableOpacity, View
} from "react-native";
import Entypo from "react-native-vector-icons/Entypo";
import { connect, useDispatch, useSelector } from "react-redux";
import AllGamesPNG from "../../../assets/AllGames.png";
import BetIcon from "../../../assets/Beticon.png";
import CalendarPNG from "../../../assets/Calendar.png";
import CharityIcon from "../../../assets/CharityIcon.png";
import CompetitionPNG from "../../../assets/Competition.png";
import JoinEvents from "../../../assets/JoinEventsIcon.png";
import {
  ImageVideoPlaceholder, PRLLogo, Root, TouchableBox,
  TouchableButton
} from "../../../Component";
import { useLoader } from "../../../hooks";
import { initFirebaseCollectionsData } from "../../../store/actions";
import styles from "./indexCss";

Entypo.loadFont();

function HomeScreen({ navigation }) {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();
  const isFocused = useIsFocused();
  const [setLoader, LoaderComponent] = useLoader();
  let { auth, firebaseAllCollectionData } = useSelector((state) => state);
  const loadFirebaseCollections = useCallback(() => {
    dispatch(initFirebaseCollectionsData(auth));
  });

  const installGoogleAds = () => {
    const elem = document.createElement("script");
    elem.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    elem.async = true;
    elem.defer = true;
    document.body.insertBefore(elem, document.body.firstChild);
  };

  useEffect(() => {
    setLoader(true);
    if (!firebaseAllCollectionData.loading) {
      setLoader(false);
      installGoogleAds();
    } else {
      return;
    }
  }, [firebaseAllCollectionData.loading]);
  const Profile = (
    <View style={{ flexDirection: "row", alignSelf: "center" }}>
      <View
        style={{
          height: 72,
          width: 72,
          borderRadius: 36,
          marginHorizontal: 20,
        }}
      >
        <ImageVideoPlaceholder
          viewURI={auth?.userCol?.userAvatar}
          mode="view"
          type="photo"
          containerStyle={{
            backgroundColor: "#DCE4F9",
            height: 72,
            width: 72,
            borderRadius: 36,
            overflow: "hidden",
          }}
        />
      </View>
      <View style={{ justifyContent: "space-between" }}>
        <Text style={styles.ProfileWelcomeText}>{`Welcome, ${
          auth?.userCol?.userName || ""
        }!`}</Text>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("ReviewProfileScreen", {
              isCompetition: false,
            });
          }}
        >
          <View style={styles.ProfileButtonView}>
            <Text style={styles.ProfileButtonText}>Review Profile</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );

  const ActionSection = (
    <View style={styles.SectionMainView}>
      <Text style={styles.SectionText}>Get in the action!</Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <TouchableBox
          onPress={
            () => {
              linkTo("/joinEvent");
            }
          }
          containerStyle={{
            backgroundColor: "#EC2939",
          }}
          name={"Join Events"}
          logo={JoinEvents}
        />
      </View>
    </View>
  );

  const GameSection = (
    <View style={styles.SectionMainView}>
      <Text style={styles.SectionText}>Your Games</Text>
      <View style={styles.SectionSubView}>
        <TouchableButton
          onPress={() => linkTo("/myAllGames")}
          containerStyle={[
            styles.inputContainerStyle,
            { width: "80%", alignSelf: "center" },
          ]}
          type={"imgBtn"}
          title={"When You Play"}
          icon={CalendarPNG}
          iconStyle={{ resizeMode: "contain" }}
        />
        <TouchableButton
          onPress={() => linkTo("/allGames")}
          containerStyle={[
            styles.inputContainerStyle,
            { width: "80%", alignSelf: "center" },
          ]}
          type={"imgBtn"}
          title={"See All Games"}
          icon={AllGamesPNG}
          iconStyle={{ resizeMode: "contain" }}
        />
        <TouchableButton
          onPress={() => navigation.navigate("CompetitorDrawerScreen")}
          containerStyle={[
            styles.inputContainerStyle,
            { width: "80%", alignSelf: "center" },
          ]}
          type={"imgBtn"}
          title={"See Competition"}
          icon={CompetitionPNG}
          iconStyle={{ resizeMode: "contain" }}
        />
        <TouchableButton
          onPress={() => linkTo("/challengesList")}
          containerStyle={[
            styles.inputContainerStyle,
            { width: "80%", alignSelf: "center" },
          ]}
          type={"imgBtn"}
          title={"Pick The Winners"}
          icon={BetIcon}
          iconStyle={{ resizeMode: "contain" }}
        />
      </View>
    </View>
  );

  const CharitySection = (
    <View
      style={{
        flexDirection: "row",
        width: "70%",
        alignSelf: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={styles.CharityView}>
        <Text style={styles.SectionText}>Support a Charity</Text>

        <TouchableBox
          containerStyle={{
            backgroundColor: "#EDCF80",
          }}
          name={"See Charities"}
          logo={CharityIcon}
          textStyle={{ color: "#000" }}
          onPress={() =>
            navigation.navigate("CharityStack", { screen: "ViewCharityScreen" })
          }
        />
      </View>
      <View style={styles.CharityView}>
        <Text style={styles.SectionText}>Support an Athlete</Text>

        <TouchableBox
          containerStyle={{
            backgroundColor: "#EC2939",
          }}
          name={"Student Athlete"}
          logo={JoinEvents}
          textStyle={{ color: "#FFF" }}
          onPress={() =>
            navigation.navigate("CharityStack", { screen: "ViewAthleteScreen" })
          }
        />
      </View>
    </View>
  );

  return (
    <Root>
      <LoaderComponent />
      <ScrollView
        style={{
          height: "100%",
          width: "70%",
          backgroundColor: "#FFF",
          alignSelf: "center",
        }}
        showsVerticalScrollIndicator
      >
        <View style={styles.HeaderView}>
          <View style={{ width: "10%" }} />
          <View>
            <PRLLogo
              imgStyle={{ height: 100, width: 180, resizeMode: "contain" }}
            />
          </View>
          {/* <View>
            <TouchableOpacity onPress={() => navigation.openDrawer()}>
              <Entypo name="menu" size={40} color="#0B214D" />
            </TouchableOpacity>
          </View> */}
        </View>
        {Profile}
        <View style={{ paddingVertical: 5 }} />
        {ActionSection}
        <View style={{ paddingVertical: 5 }} />
        {GameSection}
        <View style={{ paddingVertical: 10 }} />
        {CharitySection}
      </ScrollView>
      {/* <AdBar /> */}
    </Root>
  );
}

export default connect()(HomeScreen);
