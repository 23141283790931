import {StyleSheet} from 'react-native';
export default StyleSheet.create({
  container: {
    width: '20%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor:'#0B214D'
  },
  profileImg: {
    width: 150,
    height: 80,
    resizeMode: 'contain'
  },
  DrawerIcons: {
    width: 25,
    height: 25,
    // borderRadius: 40,
    resizeMode: 'contain'
  },
  itemContainer: {
    flexDirection: 'column',
    // marginTop: getHp(15),
  },

  footerContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    // right: getWp(12),
    // left: getWp(12),
    // bottom: getHp(26),
  },

  icon: {
    // marginTop: getHp(7),
    // marginBottom: getHp(14),
  },

  text: {
    fontSize: 14,
    // color: COLORS.percentageCaptionTextColor,
    textAlign: 'center',
  },
});
