import React, { Component } from "react";
import { View, Text } from "react-native";

//Import Vector Icons
import Antdesign from "react-native-vector-icons/AntDesign";
import Feather from "react-native-vector-icons/Feather";

import Colors from "../../common/Colors";
import { ImageVideoPlaceholder } from "../../../Component";
Antdesign.loadFont();
Feather.loadFont();

export default class ContestGallery extends Component {
  render() {
    const { Contest } = this.props;
    return (
      <View>
        <View style={{ marginTop: 10 }}>
          <Text
            style={{
              fontSize: 18,
              lineHeight: 22,
              fontWeight: "bold",
              paddingLeft: 30,
              color: "#000",
            }}
          >
            Gallery
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <ImageVideoPlaceholder
            viewURI={Contest.contestPhoto}
            type={"photo"}
            mode={"view"}
            containerStyle={{
              width: 210,
              height: 130,
              marginRight: 5,
              backgroundColor: Colors.Grey,
            }}
            imageStyle={{
              width: 210,
              height: 130,
            }}
          />

          <ImageVideoPlaceholder
            viewURI={Contest.contestVideo}
            type={"video"}
            mode={"view"}
            containerStyle={{
              width: 110,
              height: 70,
              marginRight: 5,
              backgroundColor: Colors.Grey,
            }}
            imageStyle={{
              width: 210,
              height: 130,
            }}
          >
          </ImageVideoPlaceholder>
        </View>
      </View>
    );
  }
}
