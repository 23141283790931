//External Imports
import { StyleSheet } from "react-native";

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from "../../../../utils";

export default StyleSheet.create({
  childViewStyle: {
    backgroundColor: "#fff",
  },
  container: {
    height: "100%",
    width: "70%",
    backgroundColor: "#fff",
  },
  inputContainerStyle: {
    width: "80%",
    alignSelf: "center",
    marginTop: 20,
    height: 45,
    // borderWidth:1,
    // borderColor: "red"
  },
  inputStyle: {
    height: 45,
    // borderWidth:1,
    // borderColor: "green"
  },
  imagePlateContainer: {
    width: "85%",
    marginTop: 20,
    alignSelf: "center",
    justifyContent: "space-evenly",
    flexDirection: "row",
  },
  imagePlateRightChildView: {
    //borderWidth:1,
    borderColor: "red",
    width: "70%",
  },
  datePickerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 20,
  },
  singleHeadingContainer: {
    marginTop: 15,
  },
  eventDetailsContainer: {
    alignSelf: "center",
  },
  eventDescriptionTextStyle: {
    marginTop: 20,
    height: 100,
    borderWidth: 0,
    width: "90%",
    alignSelf: "center",
    marginBottom: 20,
  },
  bottomTrayContainer: {
    marginTop: 20,
    width: "90%",
    alignSelf: "center",
  },
  uploadVideoContainerStyle: {
    marginTop: 15,
    marginLeft: 25,
    // height: getHp(70),
    // width: getWp(112)
  },
  bottomButtonsTray: {
    paddingHorizontal: 20,
    flexDirection: "row",
    marginTop: 30,
    justifyContent: "space-between",
    width: "100%",
    alignSelf: "center",
    marginBottom: 40,
  },
  createContestTypesContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addContestTypeStyle: {
    marginTop: 25,
  },
  addContestTypeIconStyle: {
    fontSize: 25,
    color: "black",
  },
  maxNumplayersStyle: {
    height: 40,
    marginTop: 18,
  },
  maxNumplayersTextStyle: {
    height: 40,
  },
  selectContestTypeHeadingContainer: {
    marginTop: 15,
    height: 35,
  },
  selectBracketTypeScoringContainer: {
    height: 35,
  },
  galleryView: {
    flexDirection: "row",
    marginTop: 10,
    alignSelf: "center",
  },
  galleryTextStyle: {
    fontSize: 18,
    fontWeight: "700",
  },
  uploadPhotoContainerStyle: {
    width: 214,
    height: 134,
  },
  nextContainer: {
    marginVertical: 20,
  },
  nextContainerRow: {
    width: "90%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignSelf: "center",
  },
});
