import React, { useEffect, useState } from "react";
import { View } from "react-native"

const VideoBroadcaster = (props) => {

    const { gameScheduleId, player1ID, player2ID, player1Name, player2Name } = props;

    const [urlCombine, setUrlCombine] = useState(null);
    const [callUrl, setCallUrl] = useState(null);

    useEffect(() => {
        if (gameScheduleId !== undefined && gameScheduleId !== ""
        && player1ID !== undefined && player1ID !== ""
        && player2ID !== undefined && player2ID !== ""
        && player1Name !== undefined && player1Name !== ""
        && player2Name !== undefined && player2Name !== "") {
            const combine = gameScheduleId + player1ID + player2ID + player1Name + player2Name;
            if (urlCombine !== combine) {
                setUrlCombine(combine);
            }
        }
    }, [props]);

    useEffect(() => {
        const url = `https://plr-video-service.web.app?userId=webapp${new Date().getTime() % 100}&gameId=` + gameScheduleId + "&" + player1ID + "=" + player1Name + "&" + player2ID + "=" + player2Name; 
        console.log("iframe call url => ", url);
        setCallUrl(url);
    }, [urlCombine]);

    return (
        <View style={{ width: '100%', height: '100%', position: 'relative' }}>
            {(callUrl !== null) &&
            <iframe src={callUrl}
                style={{flex: 1, alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}/>
            }
        </View>
    )
};

export default VideoBroadcaster;