import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Alert,
  SectionList,
  TouchableOpacity,
  RefreshControl,
  StatusBar,
  ActivityIndicator,
  ScrollView,
  Dimensions
} from "react-native";

import { Root } from "../../Component";

import Feather from "react-native-vector-icons/Feather";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { StyleSheet } from "react-native";
import { firebase, firebaseAuth } from "../../firebase";

MaterialIcons.loadFont();
Feather.loadFont();

const EventChannelRoomView = (props) => {

  const {eventId, eventName} = props.route.params;
  const [me, setMe] = useState(null);
  const windowHeight = Dimensions.get('window').height;

  const urlForEventChannelRoomPopupPage = () => {
    if (me) {
      const url = `https://plr-video-service.web.app?style=popup&type=event_room&eventId=Event-${eventId}&userId=${me.uid}`;
      return url;
    } else {
      return "";
    }
  }


  const urlForEventChannelRoom = () => {
    if (me) {
      const url = `https://plr-video-service.web.app?type=event_room&eventId=Event-${eventId}`;
      return url;
    } else {
      return "";
    }
  }

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        setMe(user);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (me) {
      window.open(urlForEventChannelRoomPopupPage(), 'hello', "width=500,height=500");
    }
  }, [me]);

  return (
    <Root>
      <ScrollView style={styles.scrollContainer}>
        <View style={styles.container}>
          <View style={styles.headerBar}>
            <TouchableOpacity
              onPress={() => {
                props.navigation.goBack();
              }}
              style={styles.headerIconContainer}>
                <MaterialIcons name="chevron-left" size={24} color="white"/>
            </TouchableOpacity>
            <View style={styles.headerTitleContainer}>
              <Text style={styles.titleText}>{eventName}</Text>
            </View>
            <View style={{width: 44}} />
          </View>

          <View style={[
            styles.contentContainer, 
            {
              height: windowHeight - 44,
            }]}>
              <iframe 
                src={urlForEventChannelRoom()}
                style={{
                  width: '100%',
                  flex: 1
                  }}
                allow="camera;microphone" />
              <Text style={styles.bottomNote}>
                You can look at the channel room in web version.
              </Text>
          </View>
        </View>
      </ScrollView>
    </Root>
  );
};

export default EventChannelRoomView;

const styles = StyleSheet.create({
  scrollContainer: { 
    height: "100%", 
    width: "99%", 
    backgroundColor: '#0B214D' 
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'center'
  },
  titleText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 24,
  },
  headerBar: {
    height: 44,
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerIconContainer: {
    width: 44,
    height: 44,
    justifyContent: 'center', 
    alignItems: 'center'
  },
  headerTitleContainer: {
    flex: 1, 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  contentContainer: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center'
  },
  bottomNote: {
    color: 'white',
    marginTop: 15,
    marginBottom: 15
  }
});
