import ReactModel from "../../app/ReactModel";
import { filterAndMergeDataOnUniqueKey } from "../../utils";
import {
  Bracket as BracketModel,
  BracketUserGuess as BracketUserGuessModel,
} from "../../app/Models";
import { splitArrayDesendingOrder } from "../../utils/array";
import OptionModel from "../../app/Models/Option";
class BracketEntryModel extends ReactModel {
  isBracketAvail = false;
  isBracketLoaded = false;
  isViewMode = false;
  isLoading = false;
  userBracketName = "";
  bracketFactory = [];
  outerBracketData = [];

  reset = () => {
    this.isBracketAvail = false;
    this.isBracketLoaded = false;
    this.isViewMode = false;
    this.isLoading = false;
    this.userBracketName = "";
    this.bracketFactory = [];
    this.outerBracketData = [];
  };
  toggleLoader = (value = null) => {
    if (value != null) {
      this.isLoading = value;
    } else {
      this.isLoading = !this.isLoading;
    }
    this.notifyListener();
  };
  noBracketFound = () => {
    this.isLoading = false;
    this.isBracketAvail = false;
    this.isBracketLoaded = true;
    this.notifyListener();
  };
  init = (
    bracketsDataParam = [],
    bracketGuessData = [],
    isViewMode = false
  ) => {
    let bracketsData = bracketsDataParam.filter((b) => !b?.bracketUpDirection);
    let bracketUpData = bracketsDataParam.filter((b) => b?.bracketUpDirection);
    const composedBracket = filterAndMergeDataOnUniqueKey(
      bracketsData,
      "bracketNumber"
    );
    const composedUpBracket = filterAndMergeDataOnUniqueKey(
      bracketUpData,
      "bracketRound"
    );
    console.log("composedUpBracket=>>>>>>>>>>>>>>", bracketUpData);
    this.bracketFactory = composedBracket.map((singleBracket) => {
      return BracketModel.fromJSON(singleBracket, bracketGuessData);
    });
    if (bracketUpData.length > 0) {
      this.outerBracketData = composedUpBracket
        .map((singleBracket) => {
          return BracketModel.fromUpJSON(singleBracket, bracketGuessData);
        })
        .reverse();
      // this.outerBracketData = splitArrayDesendingOrder(
      //   this.bracketFactory.length,
      //   OptionModel
      // ).reverse();
    }

    this.isBracketAvail = true;
    this.isViewMode = isViewMode;
    this.isBracketLoaded = true;
    this.isLoading = false;
    this.notifyListener();
    console.log("DOWN_BRACKET_DATA - ", this.bracketFactory);
    console.log("UP_BRACKET_DATA - ", JSON.stringify(this.outerBracketData));
  };

  onSelectPlayers = (bracketIndex, roundIndex, optionIndex, value) => {
    console.log(
      "BRACKET ENTRY MODAL -> ",
      JSON.stringify(this.bracketFactory[bracketIndex]),
      roundIndex
    );
    this.bracketFactory[bracketIndex].rounds[roundIndex].options[
      optionIndex
    ].setSelectedValue(value);

    if (this.bracketFactory[bracketIndex].rounds.length - 1 > roundIndex) {
      // let optionChunks = sliceIntoChunks(
      //   this.bracketFactory[bracketIndex].rounds[roundIndex].options,
      //   2
      // );

      let computedOptionIndex = parseInt(optionIndex / 2);
      this.bracketFactory[bracketIndex].rounds[roundIndex + 1].options[
        computedOptionIndex
      ].setSelectedValue("Select");
      this.bracketFactory[bracketIndex].rounds[roundIndex + 1].options[
        computedOptionIndex
      ].setOptions(value, optionIndex % 2);
    }
    if (this.bracketFactory[bracketIndex].rounds.length - 1 == roundIndex) {
      // this.outerBracketData[this.bracketFactory.length - 1][
      //   bracketIndex
      // ].setSelectedValue(value);
      //console.log('Outer Bracket Data --> ', JSON.stringify(this.outerBracketData[this.outerBracketData.length - 1]))
      if (this.outerBracketData.length > 0) {
        this.outerBracketData[this.outerBracketData.length - 1].rounds[
          parseInt(bracketIndex / 2)
        ].setOptions(value, bracketIndex % 2);
      }
    }
    this.notifyListener();
  };

  setBracketName = (name) => {
    this.userBracketName = name;
  };

  onSubmitBracket = (userData) => {
    console.log("Bracket Factory ----> ", JSON.stringify(this.bracketFactory));
    console.log(
      "outer Bracket Data ----> ",
      JSON.stringify(this.outerBracketData)
    );

    let returnValue = {
      isBracketValidate: true,
      bracketGuessData: [],
    };
    let operationData = [];
    this.bracketFactory.map((bFac) => {
      bFac.rounds.map((round) => {
        round.options.map((option) => {
          operationData.push(option);
          if (!returnValue.isBracketValidate) {
            return;
          }
          if (option.selectedValue == "Select") {
            returnValue.isBracketValidate = false;
          }
        });
      });
    });

    this.outerBracketData?.map((bFac) => {
      bFac.rounds.map((round) => {
        operationData.push(round);
        if (!returnValue.isBracketValidate) {
          return;
        }
        if (round.selectedValue == "Select") {
          returnValue.isBracketValidate = false;
        }
      });
    });
    if (!returnValue.isBracketValidate) {
      return returnValue;
    }
    let firebaseRecord = [];

    console.log("operation Data ----> ", JSON.stringify(operationData));

    operationData?.map((option) => {
      let winnerData = option.getWinner();
      let bracketGuess = new BracketUserGuessModel(userData.userId);
      bracketGuess.setBracketName(this.userBracketName);
      bracketGuess.winnerID = winnerData.winnerID;
      bracketGuess.winnerName = winnerData.winnerName;
      bracketGuess.bracketUpDirection = true;
      bracketGuess.gameID = option.playerData.gameID;
      bracketGuess.bracketNumber = option.playerData.bracketNumber;
      bracketGuess.bracketRound = option.playerData.bracketRound;
      bracketGuess.eventID = option.playerData.eventID;
      bracketGuess.contestID = option.playerData.contestID;
      bracketGuess.gameNumber = option.playerData.gameNumber;
      bracketGuess.bracketName = option.playerData.bracketName;
      firebaseRecord.push(bracketGuess);
    });
    returnValue.bracketGuessData = firebaseRecord;

    return returnValue;
  };

  onSelectUpPlayers = (bracketIndex, roundIndex, value) => {
    console.log("SELECTED_UP_1_BRACKET_INDEX - ", bracketIndex);
    console.log("SELECTED_UP_1_ROUND_INDEX - ", roundIndex);
    console.log("SELECTED_UP_1_VALUE - ", value);
    this.outerBracketData[bracketIndex].rounds[roundIndex].setSelectedValue(
      value
    );

    // **** TEST
    if (bracketIndex - 1 > -1) {
      this.outerBracketData[bracketIndex - 1].rounds[
        parseInt(roundIndex / 2)
      ].setOptions(value, roundIndex % 2);
    }

    // **** TEST
    this.notifyListener();
  };
}
let model = new BracketEntryModel();
export default model;

// import ReactModel from "../../app/ReactModel";
// import { filterAndMergeDataOnUniqueKey } from "../../utils";
// import {
//   Bracket as BracketModel,
//   BracketUserGuess as BracketUserGuessModel,
// } from "../../app/Models";
// import { splitArrayDesendingOrder } from "../../utils/array";
// import OptionModel from "../../app/Models/Option";
// class BracketEntryModel extends ReactModel {
//   isBracketAvail = false;
//   isBracketLoaded = false;
//   isViewMode = false;
//   isLoading = false;

//   bracketFactory = [];
//   outerBracketData = [];
//   toggleLoader = () => {
//     this.isLoading = !this.isLoading;
//     this.notifyListener();
//   };
//   noBracketFound = () => {
//     this.isLoading = false;
//     this.isBracketAvail = false;
//     this.isBracketLoaded = true;
//     this.notifyListener();
//   };
//   init = (
//     bracketsDataParam = [],
//     bracketGuessData = [],
//     isViewMode = false
//   ) => {
//     let bracketsData = bracketsDataParam.filter((b) => !b?.upDirection);
//     let bracketUpData = bracketsDataParam.filter((b) => b?.upDirection);
//     const composedBracket = filterAndMergeDataOnUniqueKey(
//       bracketsData,
//       "bracketNumber"
//     );
//     const composedUpBracket = filterAndMergeDataOnUniqueKey(
//       bracketUpData,
//       "round"
//     );
//     console.log("COMP_+UP - ", JSON.stringify(composedUpBracket));
//     this.bracketFactory = composedBracket.map((singleBracket) => {
//       return BracketModel.fromJSON(singleBracket, bracketGuessData);
//     });

//     if (bracketUpData.length > 0) {
//       this.outerBracketData = composedUpBracket.map((singleBracket) => {
//         return BracketModel.fromUpJSON(singleBracket, bracketGuessData);
//       }).reverse();
//       // this.outerBracketData = splitArrayDesendingOrder(
//       //   this.bracketFactory.length,
//       //   OptionModel
//       // ).reverse();
//     }
//     console.log(
//       "UP_DIR_BRACKET_CHECK_2 - ",
//       JSON.stringify(this.outerBracketData)
//     );
//     this.isBracketAvail = true;
//     this.isViewMode = isViewMode;
//     this.isBracketLoaded = true;
//     this.isLoading = false;
//     this.notifyListener();
//   };

//   onSelectPlayers = (bracketIndex, roundIndex, optionIndex, value) => {
//     this.bracketFactory[bracketIndex].rounds[roundIndex].options[
//       optionIndex
//     ].setSelectedValue(value);

//     if (this.bracketFactory[bracketIndex].rounds.length - 1 > roundIndex) {
//       // let optionChunks = sliceIntoChunks(
//       //   this.bracketFactory[bracketIndex].rounds[roundIndex].options,
//       //   2
//       // );

//       let computedOptionIndex = parseInt(optionIndex / 2);
//       this.bracketFactory[bracketIndex].rounds[roundIndex + 1].options[
//         computedOptionIndex
//       ].setSelectedValue("Select");
//       this.bracketFactory[bracketIndex].rounds[roundIndex + 1].options[
//         computedOptionIndex
//       ].setOptions(value, optionIndex % 2);
//     }
//     if (this.bracketFactory[bracketIndex].rounds.length - 1 == roundIndex) {
//       // this.outerBracketData[this.bracketFactory.length - 1][
//       //   bracketIndex
//       // ].setSelectedValue(value);
//       this.outerBracketData[this.outerBracketData.length - 1].rounds[
//         parseInt(bracketIndex / 2)
//       ].setOptions(value, bracketIndex % 2);
//     }
//     this.notifyListener();
//   };

//   onSubmitBracket = (userData) => {
//     let returnValue = {
//       isBracketValidate: true,
//       bracketGuessData: [],
//     };
//     let operationData = [];

//     this.bracketFactory.map((bFac) => {
//       bFac.rounds.map((round) => {
//         round.options.map((option) => {
//           operationData.push(option);
//           if (!returnValue.isBracketValidate) {
//             return;
//           }
//           if (option.selectedValue == "Select") {
//             returnValue.isBracketValidate = false;
//           }
//         });
//       });
//     });
//     if (!returnValue.isBracketValidate) {
//       return returnValue;
//     }
//     let firebaseRecord = [];

//     operationData.map((option) => {
//       let winnerData = option.getWinner();
//       let bracketGuess = new BracketUserGuessModel(userData.userId);
//       bracketGuess.winnerID = winnerData.winnerID;
//       bracketGuess.winnerName = winnerData.winnerName;
//       bracketGuess.gameID = option.playerData.gameID;
//       firebaseRecord.push(bracketGuess);
//     });
//     returnValue.bracketGuessData = firebaseRecord;
//     return returnValue;
//   };
// }
// let model = new BracketEntryModel();
// export default model;
