// External Imports
import React from "react";
import { View, Image, Text, TouchableOpacity } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import Icons from "react-native-vector-icons/Feather";
import styles from "./indexCss";
import { Button } from "react-native-elements";
import EDIT from "./EditPen.png";

function HeaderBlackEdit(props) {
  const { onBack, onEdit,menuOnPress = () => {}, hideMenu = false } = props;
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingVertical: 10,
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {!hideMenu ? (
          <TouchableOpacity onPress={menuOnPress}>
            <View style={{ paddingHorizontal: 20 }}>
              <Icons name="menu" size={30} color="Black" />
            </View>
          </TouchableOpacity>
        ) : null}

        {onBack && (
          <TouchableOpacity onPress={onBack}>
            <View style={{ paddingHorizontal: 20 }}>
              <Icon name="angle-left" size={40} color="Black" />
            </View>
          </TouchableOpacity>
        )}
      </View>
      {/* <View>
            <Text style={{color:'Black',fontWeight:'bold',fontSize:16}}>{props.headerText}</Text>
         </View> */}
      {onEdit && (
        <TouchableOpacity onPress={onEdit}>
          <View style={{ paddingHorizontal: 20 }}>
            <Image
              source={EDIT}
              style={{ height: "20px", width: "20px" }}
              {...props}
            />
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
}

export default HeaderBlackEdit;
