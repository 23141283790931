
//External Imports
import { StyleSheet } from 'react-native';
import { wp, hp, getHp, getWp, FONTSIZE } from '../../../utils';
//Internal Imports


export default StyleSheet.create({
    CheckboxStyle: {
        borderRadius: 30,
        borderColor: 'black',
        borderWidth: 1
    },
    checkboxContainer: {
        paddingVertical: 5,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    label: {
        marginLeft: -10
    },
    modalContainer: {
        flex: 1,
        height: '100%',
        width: '100%',
        backgroundColor: '#fff'
        // borderWidth:1,
        // borderColor: "red", 
        // justifyContent: "center",
        // alignItems: "center"
    },
    closeContainer: {
        position: 'absolute',
        right: getWp(10),
        top: getHp(10),
        zIndex: 10,
        borderWidth: 1,
        borderColor: 'red',
        borderRadius: 20,
        padding: getHp(4),
        backgroundColor: 'red'
    },
    childViewStyle: {
        backgroundColor: '#FFF',
        flexDirection: "column",
        justifyContent: "space-between"
    },
    container: {
        height: '100%',
        width: '100%',
        backgroundColor: "#fff",
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    formContainer: {
        marginTop: 30,
        width: "80%",
        alignSelf: "center"
    },
    formHeadingText: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#000"
    },
    inputContainerStyle: {
        marginTop: 10,
    },
    renderExtrasContainer: {
        marginTop: 20,
        flexDirection: "row",
        alignSelf: "center",
        alignItems: "center"
    },
    renderExtrasHeading: {
        fontSize: 16,
        color: '#000'
    },
    clickHereText: {
        color: '#EC2939',
        fontSize: 16,
    },
    errorContainer: {
        marginTop: 10,
        marginLeft: 20
    },
    errorTextStyle: {
        fontSize: 15,
        color: "red"
    },
    imageBoxContainer: {
        alignSelf: "center",
        marginTop: 20,
        height: 100,
        width: 180,
    },
    logoStyle: {
        height: 100,
        width: 180,
        resizeMode: 'contain',
        alignSelf: 'center'
    }
});