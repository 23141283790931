import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  FlatList
} from "react-native";

import { firebase } from '../../firebase';
import 'firebase/firestore';
import { ActivityIndicator } from "react-native";

import Feather from "react-native-vector-icons/Feather";
import { Root } from "../../Component";
import { ScrollView } from "react-native-web";

Feather.loadFont();


const EventScoreBoardView = ({ navigation, route }) => {

  const [me, setMe] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [event, setEvent] = useState(route.params.event);
  const [eventUserProfiles, setEventUserProfiles] = useState([]);
  const [scores, setScores] = useState([]);

  const splitIdsArray = (ids) => {
    if (ids.length > 0) {
      let splittedIds = [];
      let seeker = 0;
      while (seeker < ids.length) {
        let elementSplitIds = [];
        for (let idx = 0; idx < 10; idx++) {
          if (seeker + idx < ids.length) {
            elementSplitIds.push(ids[seeker + idx]);
          }
        }

        splittedIds.push(elementSplitIds);
        seeker = seeker + 10;
      }
      return splittedIds;
    } else {
      return [];
    }
  }

  const loadUserData = async (userIds, scoreData) => {
    const userIdArraysArray = splitIdsArray(userIds);
    let users = [];
    const promises = userIdArraysArray.map(async idsArray => {
      const querySnapshot = await firebase.firestore().collection("users")
        .where("uid", "in", idsArray)
        .get();
      
      querySnapshot.forEach(documentSnapshot => {
        let user = documentSnapshot.data();
        users = [...users, user];
      });

    });
    
    await Promise.all(promises);

    setEventUserProfiles(users.sort((user1, user2) => {
      const score1 = scoreData.find(score => score.userID == user1.uid);
      const score2 = scoreData.find(score => score.userID == user2.uid);
      
      const totalScore1 = score1 === undefined ? 0 : (score1.totalPoints === undefined ? 0 : score1.totalPoints);
      const totalScore2 = score2 === undefined ? 0 : (score2.totalPoints === undefined ? 0 : score2.totalPoints);

      const availableScore1 = score1 === undefined ? 0 : (score1.availablePoints === undefined ? 0 : score1.availablePoints);
      const availableScore2 = score2 === undefined ? 0 : (score2.availablePoints === undefined ? 0 : score2.availablePoints);

      if (totalScore1 > totalScore2) {
        return -1;
      } else if (totalScore1 < totalScore2) {
        return 1;
      } else {
        return availableScore1 > availableScore2 ? -1 : 1;
      }
    }));

  }

  const loadScores = async () => {
    const pointData = await firebase.firestore().collection("playerEventChallengePoints")
      .where("eventID", "==", event.eventID)
      .get();
    
    let eventUserIds = [];
    let scoreData = [];

    pointData.forEach(documentSnapshot => {
      const point = documentSnapshot.data();
      const userId = point.userID;
      scoreData = [...scoreData, point];
      if (userId !== undefined && !eventUserIds.includes(userId)) {
        eventUserIds = [...eventUserIds, userId];
      }
    });
    setScores(scoreData);
    await loadUserData(eventUserIds, scoreData);
  }

  const findUserScore = (userId) => {
    const score = scores.find(score => score.userID == userId);
    if (score === undefined || score.totalPoints === undefined) {
      return "0/0"
    } else {
      return `${score.availablePoints} / ${score.totalPoints}pt`
    }
  }

  // useEffect(() => {
  //   loadUserData(eventUserIds);
  // }, [scores]);

  const renderUser = (userItem) => {
    return (
      <View style={{ flexDirection: 'column'}}>
        <View style={{ flexDirection: 'row', alignItems: 'center', height: 60, paddingRight: 20 }}>
          <Text style={{ marginLeft: 20 }}>#{userItem.index + 1}</Text>
          <TouchableOpacity 
            style={{ width: 40, height: 40, marginLeft: 10 }}>
              <Image style={{ width: 40, height: 40, backgroundColor: 'rgba(0, 0, 0, 0.2)', borderRadius: 20 }}
                  source={{uri: userItem.item.userAvatar}} />
          </TouchableOpacity>
          <Text style={{ flex: 1, marginLeft: 15 }}>{ userItem.item.userName }</Text>
          <Text>{findUserScore(userItem.item.uid)}</Text>
        </View>
        <View style={{height: 1, backgroundColor: 'rgba(0,0,0,0.2)'}} />
      </View>
    );
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setMe(user);
      } else {
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setEvent(route.params.event);
  }, [route.params.event]);

  useEffect(() => {
    if (me) {
      loadScores();
    }
  }, [me, event]);

  return (
    <Root>
      <ScrollView style={{ width: "100%", height: '99%' }}>
        <View style={{ height: 40, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
          <TouchableOpacity style={{ width: 40, height: 40, alignItems: 'center', justifyContent: 'center' }}
            onPress={() => {
              navigation.goBack();
            }}>
            <Feather name="chevron-left" size={25} color={'#000'} />
          </TouchableOpacity>
          <Text style={{ flex: 1, color: 'black', fontWeight: 'bold', fontSize: 20, textAlign: 'center' }}>
            {route.params.event.eventName}
          </Text>
          <TouchableOpacity style={{ width: 40, height: 40 }}
            onPress={() => {
            }}>
          </TouchableOpacity>
        </View>
        {loaded ? 
          <View style={{ flexDirection: 'column'}}>
            <Text style={{marginLeft: 15, marginTop: 15}}>Challenge Scoreboard</Text>
            <View style={{ flexDirection: 'column'}}>
              <Text style={{marginLeft: 15, marginTop: 15, width: '100%'}}>
                Your points - <Text style={{fontWeight: 'bold'}}>{me !== null ? findUserScore(me.uid) : ""}</Text>
              </Text>
              <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 15}}>
                <Text style={{marginLeft: 15, flex: 1}}>Challenge Scoreboard</Text>
                <Text style={{fontWeight: 'bold', marginRight: 15}}>Avail./Total</Text>
              </View>
            </View>
            <FlatList 
              data={ eventUserProfiles }
              extraData={ eventUserProfiles }
              renderItem={(item) => renderUser(item)}
              keyExtractor={(item) => item.uid}
            />
          </View>
        :
          <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <ActivityIndicator />
          </View>
        }
        
      </ScrollView>
    </Root>
  );
};

export default EventScoreBoardView;