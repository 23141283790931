import React, { Fragment, Component, useRef, useEffect } from "react";
import { ScrollView, View, ActivityIndicator, Text } from "react-native";

import { bracketMasterCollection } from "../../firebase";
import {
  filterAndMergeDataOnUniqueKey,
  sortArrayAlphabatically,
  transformFirebaseValues,
} from "../../utils";
import {
  BracketUI,
  Root,
  TouchableButton,
  SingleHeading,
} from "../../Component";
import BracketModel from "./BracketModel";

import { toggleAlertBox } from "../../store/actions";
import { connect } from "react-redux";
import {
  db,
  bracketUserGuessCollection,
  usersCollection,
} from "../../firebase";
import Modal from "modal-enhanced-react-native-web";
import BracketMasterData from "./BracketMasterRecords";
import SearchPlate from "./SearchPlate";
import Styles from "./indexCss";

class BracketEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.userDetails = [];
    this.bracketModel = BracketModel.addListener(() => {
      this.setState(() => ({}));
    });
  }
  componentWillUnmount() {
    this.bracketModel.removeListener();
  }

  async componentDidMount() {
    const userDetail = await usersCollection.get();

    const allData = [];
    userDetail.docs.map((doc) => {
      allData.push(doc.data());
    });
    this.userDetails = allData;
    // const batch = db.batch();
    // BracketMasterData.map((bracket) => {
    //   const docRef = bracketMasterCollection.doc();
    //   batch.set(docRef, bracket);
    // });
    // await batch.commit();
    // this.fetchBrackets();
    // this.showGuesses();
  }

  showGuesses = async (eventID, contestID, bracketRef) => {
    try {
      this.bracketModel.toggleLoader();
      const bracketDataRes = await bracketMasterCollection
        .where("eventID", "==", eventID)
        .where("contestID", "==", contestID)
        .get();
      const bracketGuessDataRes = await bracketUserGuessCollection
        .where("eventID", "==", eventID)
        .where("contestID", "==", contestID)
        .where("referenceNo", "==", +bracketRef)
        .get();
      if (
        bracketDataRes.docs.length == 0 ||
        bracketGuessDataRes.docs.length == 0
      ) {
        return this.bracketModel.noBracketFound();
      }
      let bracketGuessData = transformFirebaseValues(
        bracketGuessDataRes,
        "eventID"
      );
      let bracketData = transformFirebaseValues(bracketDataRes, "eventID");
      const printData = filterAndMergeDataOnUniqueKey(
        bracketData,
        "bracketNumber"
      );
      //printData[0] = sortArrayAlphabatically(printData[0], "gameID");
      //printData[1] = sortArrayAlphabatically(printData[1], "gameID");
      // console.log(
      //   "PRINT_DATA_1 - ",
      //   JSON.stringify([...printData[0], ...printData[1]])
      // );
      this.bracketModel.toggleLoader();
      this.bracketModel.init(bracketData, bracketGuessData, true);
    } catch (error) {
      this.bracketModel.toggleLoader();
      let msg = "Something went wrong!";
      console.log("error --> ", error);
      if (error.message) {
        msg = error.message;
      }
      this.showPopup({ text: msg });
    }
  };

  fetchBrackets = async (eventID, contestID) => {
    try {
      this.bracketModel.reset();
      this.bracketModel.toggleLoader();
      const bracketDataRes = await bracketMasterCollection
        .where("eventID", "==", eventID)
        .where("contestID", "==", contestID)
        .get();
      let bracketDataConverted = transformFirebaseValues(
        bracketDataRes,
        "eventID"
      );
      console.log(
        "bracket converted Data -> ",
        JSON.stringify(bracketDataConverted)
      );
      if (bracketDataRes.docs.length == 0) {
        return this.bracketModel.noBracketFound();
      }
      this.bracketModel.init(bracketDataConverted);
    } catch (error) {
      this.bracketModel.toggleLoader();
      let msg = "Something went wrong!";
      if (error.message) {
        msg = error.message;
      }
      this.showPopup({ text: msg });
    }
  };

  onSelectPlayers = (
    isUp,
    bracketIndex,
    roundIndex,
    optionIndex,
    value,
    userBracketName
  ) => {
    try {
      this.bracketModel.setBracketName(userBracketName);
      if (!isUp) {
        return this.bracketModel.onSelectPlayers(
          bracketIndex,
          roundIndex,
          optionIndex,
          value
        );
      }
      return this.bracketModel.onSelectUpPlayers(
        bracketIndex,
        roundIndex,
        value
      );
    } catch (error) {
      let msg = "Something went wrong!";
      console.log(error);
      if (error.message) {
        msg = error.message;
      }
      this.showPopup({ text: msg });
    }
  };

  onHandleSubmitBracket = async () => {
    try {
      this.bracketModel.toggleLoader();
      const { auth: userData } = this.props.reduxState;
      const bracketData = this.bracketModel.onSubmitBracket(userData);
      console.log("BRACKET_SUBMIT_DATA_2 - ", JSON.stringify(bracketData));
      // return false;
      if (!bracketData.isBracketValidate) {
        throw new Error("Select Complete Bracket Data");
      }
      const batch = db.batch();
      let referenceNo = new Date().getTime();
      bracketData.bracketGuessData.map((bracketGuess) => {
        const docRef = bracketUserGuessCollection.doc();
        let bracketJSONDATA = bracketGuess.toJSON();
        bracketJSONDATA.referenceNo = referenceNo;
        batch.set(docRef, bracketJSONDATA);
      });
      await batch.commit();
      this.bracketModel.toggleLoader();
      this.showPopup(
        {
          text: "Bracket Successfully Saved - Reference N0 - " + referenceNo,
        },
        () => location.reload()
      );
    } catch (error) {
      this.bracketModel.toggleLoader();
      let msg = "Something went wrong!";
      if (error.message) {
        msg = error.message;
      }
      this.showPopup({ text: msg });
    }
  };

  showPopup = ({ text, onPressOK = () => {} }) => {
    return this.props.dispatch(
      toggleAlertBox({
        visible: true,
        text: text,
        onPress: () => {
          this.props.dispatch(toggleAlertBox("CLOSE"));
          onPressOK();
        },
      })
    );
  };

  onSearchClick = ({ event, contest }, bracketRef) => {
    console.log("SELECTED_ATA - ", event, contest);
    let bracketViewType = this.props?.route?.params?.bracketType ?? "add";
    if (bracketViewType == "view") {
      return this.showGuesses(event.eventID, contest.contestID, bracketRef);
    }
    return this.fetchBrackets(event.eventID, contest.contestID);
  };

  addBracketName = (Bname) => {
    this.bracketModel.setBracketName(Bname);
  };

  render() {
    return (
      <Root>
        <Modal isVisible={BracketModel.isLoading}>
          <ActivityIndicator size="large" color="#0B214D" />
        </Modal>
        <ScrollView style={{ width: "80%" }}>
          <SearchPlate
            showPopup={this.showPopup}
            onSearchClick={this.onSearchClick}
            parentProp={this.props}
          />
          {BracketModel.isLoading ? null : (
            <BracketUI
              onSelectPlayers={this.onSelectPlayers}
              bracketModel={BracketModel}
              setUsersBracketName={this.addBracketName}
              userDetails={this.userDetails}
            />
          )}
          {!BracketModel.isLoading &&
            BracketModel.isBracketLoaded &&
            !BracketModel.isBracketAvail && (
              <View style={Styles.noBracketFoundView}>
                <Text style={Styles.noBracketFoundTextView}>
                  No Bracket Found
                </Text>
              </View>
            )}
          {!BracketModel.isLoading &&
            !BracketModel.isViewMode &&
            BracketModel.isBracketLoaded &&
            BracketModel.isBracketAvail && (
              <View style={Styles.saveButtonStyle}>
                <TouchableButton
                  type={"small"}
                  backgroundColor={"#01080B"}
                  title={"Save"}
                  onPress={this.onHandleSubmitBracket}
                />
              </View>
            )}
        </ScrollView>
      </Root>
    );
  }
}
const mapStateToProps = (state) => ({ reduxState: state });
const mapDispatchToProps = (dispatch) => ({ dispatch: dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(BracketEntry);
// import React, { Fragment, Component } from "react";
// import { ScrollView, View, ActivityIndicator,Text } from "react-native";

// import { bracketMasterCollection } from "../../firebase";
// import {
//   filterAndMergeDataOnUniqueKey,
//   sortArrayAlphabatically,
//   transformFirebaseValues,
// } from "../../utils";
// import {
//   BracketUI,
//   Root,
//   TouchableButton,
//   SingleHeading,
// } from "../../Component";
// import BracketModel from "./BracketModel";

// import { toggleAlertBox } from "../../store/actions";
// import { connect } from "react-redux";
// import { db, bracketUserGuessCollection } from "../../firebase";
// import Modal from "modal-enhanced-react-native-web";
// import BracketMasterData from "./BracketMasterRecords";
// import SearchPlate from "./SearchPlate";
// import Styles from "./indexCss";

// class BracketEntry extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//     this.bracketModel = BracketModel.addListener(() => {
//       this.setState(() => ({}));
//     });
//   }
//   componentWillUnmount() {
//     this.bracketModel.removeListener();
//   }
//   async componentDidMount() {
//     // const batch = db.batch();
//     // BracketMasterData.map((bracket) => {
//     //   const docRef = bracketMasterCollection.doc();
//     //   batch.set(docRef, bracket);
//     // });
//     // await batch.commit();
//     // this.fetchBrackets();
//     // this.showGuesses();
//   }
//   showGuesses = async () => {
//     try {
//       const bracketDataRes = await bracketMasterCollection
//         .where("eventID", "==", 1)
//         .where("contestID", "==", 3)
//         .get();
//       const bracketGuessDataRes = await bracketUserGuessCollection
//         .where("eventID", "==", 1)
//         .where("contestID", "==", 3)
//         .get();
//       let bracketGuessData = transformFirebaseValues(
//         bracketGuessDataRes,
//         "eventID"
//       );
//       let bracketData = transformFirebaseValues(bracketDataRes, "eventID");
//       const printData = filterAndMergeDataOnUniqueKey(
//         bracketData,
//         "bracketNumber"
//       );
//       printData[0] = sortArrayAlphabatically(printData[0], "gameID");
//       printData[1] = sortArrayAlphabatically(printData[1], "gameID");
//       console.log(
//         "PRINT_DATA_1 - ",
//         JSON.stringify([...printData[0], ...printData[1]])
//       );
//       this.bracketModel.init(bracketData, bracketGuessData, true);
//     } catch (error) {
//       this.bracketModel.toggleLoader();
//       let msg = "Something went wrong!";
//       if (error.message) {
//         msg = error.message;
//       }
//       this.showPopup({ text: msg });
//     }
//   };
//   fetchBrackets = async (eventID, contestID) => {
//     try {
//       this.bracketModel.toggleLoader();
//       const bracketDataRes = await bracketMasterCollection
//         .where("eventID", "==", eventID)
//         .where("contestID", "==", contestID)
//         .get();
//       let bracketDataConverted = transformFirebaseValues(bracketDataRes, "eventID");
//       if (bracketDataRes.docs.length == 0) {
//         return this.bracketModel.noBracketFound();
//       }
//       this.bracketModel.init(bracketDataConverted);
//     } catch (error) {
//       this.bracketModel.toggleLoader();
//       let msg = "Something went wrong!";
//       if (error.message) {
//         msg = error.message;
//       }
//       this.showPopup({ text: msg });
//     }
//   };
//   onSelectPlayers = (bracketIndex, roundIndex, optionIndex, value) => {
//     try {
//       console.log("BRACKET_INDEX - ", bracketIndex);
//       console.log("ROUND_INDEX - ", roundIndex);
//       console.log("OPTION_INDEX - ", optionIndex);
//       console.log("VALUE = ", value);
//       this.bracketModel.onSelectPlayers(
//         bracketIndex,
//         roundIndex,
//         optionIndex,
//         value
//       );
//     } catch (error) {
//       let msg = "Something went wrong!";
//       if (error.message) {
//         msg = error.message;
//       }
//       this.showPopup({ text: msg });
//     }
//   };

//   onHandleSubmitBracket = async () => {
//     try {
//       this.bracketModel.toggleLoader();
//       const { auth: userData } = this.props.reduxState;
//       const bracketData = this.bracketModel.onSubmitBracket(userData);
//       console.log("BRACKET_SUBMIT_DATA_2 - ", JSON.stringify(bracketData));
//       // return false;
//       if (!bracketData.isBracketValidate) {
//         throw new Error("Select Complete Bracket Data");
//       }
//       const batch = db.batch();

//       bracketData.bracketGuessData.map((bracketGuess) => {
//         const docRef = bracketUserGuessCollection.doc();
//         batch.set(docRef, bracketGuess.toJSON());
//       });
//       await batch.commit();
//       this.bracketModel.toggleLoader();
//       this.showPopup({ text: "Bracket Successfully Saved" });
//     } catch (error) {
//       this.bracketModel.toggleLoader();
//       let msg = "Something went wrong!";
//       if (error.message) {
//         msg = error.message;
//       }
//       this.showPopup({ text: msg });
//     }
//   };
//   showPopup = ({ text, onPressOK = () => {} }) => {
//     return this.props.dispatch(
//       toggleAlertBox({
//         visible: true,
//         text: text,
//         onPress: () => {
//           this.props.dispatch(toggleAlertBox("CLOSE"));
//           onPressOK();
//         },
//       })
//     );
//   };
//   onSearchClick = ({event, contest}) => {
//     console.log("SELECTED_ATA - ", event, contest);
//     this.fetchBrackets(event.eventID, contest.contestID);
//   }
//   render() {
//     return (
//       <Root>
//         <Modal isVisible={BracketModel.isLoading}>
//           <ActivityIndicator size="large" color="#0B214D" />
//         </Modal>
//         <ScrollView style={{ width: "80%" }}>
//           <SearchPlate
//             showPopup={this.showPopup}
//             onSearchClick={this.onSearchClick}
//           />
//           {BracketModel.isLoading ? null : (
//             <BracketUI
//               onSelectPlayers={this.onSelectPlayers}
//               bracketModel={BracketModel}
//             />
//           )}
//       {
//         !BracketModel.isLoading && BracketModel.isBracketLoaded && !BracketModel.isBracketAvail &&
//         <View style={Styles.noBracketFoundView}>
//           <Text style={Styles.noBracketFoundTextView}>No Bracket Found</Text>
//         </View>
//       }
//           {!BracketModel.isLoading &&
//             !BracketModel.isViewMode &&
//             BracketModel.isBracketLoaded &&
//             BracketModel.isBracketAvail && (
//               <View style={Styles.saveButtonStyle}>
//                 <TouchableButton
//                   type={"small"}
//                   backgroundColor={"#01080B"}
//                   title={"Save"}
//                   onPress={this.onHandleSubmitBracket}
//                 />
//               </View>
//             )}
//         </ScrollView>
//       </Root>
//     );
//   }
// }
// const mapStateToProps = (state) => ({ reduxState: state });
// const mapDispatchToProps = (dispatch) => ({ dispatch: dispatch });
// export default connect(mapStateToProps, mapDispatchToProps)(BracketEntry);
