export const maxNumberArrOfObj = (array, key) => {
  return Math.max.apply(
    Math,
    array.map(function (o) {
      return o[key];
    })
  );
};

export const removeDuplicateFromArr = (array, key = undefined) => {
  const counterVar = new Set();

  const filteredArr = array.filter((el) => {
    let data = key == undefined ? el : el[key];
    const duplicate = counterVar.has(data);
    counterVar.add(data);
    return !duplicate;
  });
  return filteredArr;
};

export const sortArrayAlphabatically = (arr, key) => {
  console.log("sorting array --> ", arr, key);
  return arr.sort((i, j) => {
    if (i[key] > j[key]) {
      return 1;
    } else {
      return -1;
    }
  });
};

export const generateLink = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  let str = result;
  return str.substr(0, 5) + Date.now() + str.substr(5, 15);
};

export function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export function splitArrayDesendingOrder(arrLen = 4, prototype = null) {
  let arr = [];

  while (true) {
    if (arrLen == 1) {
      arr.push(new Array(1).fill({}));
      break;
    }
    arr.push(new Array(arrLen).fill({}));
    arrLen = parseInt(arrLen / 2);
  }
  arr = arr.map((iArr) => {
    return iArr.map(() => {
      if (prototype != null) {
        return new prototype();
      }
    });
  });
  return arr;
}
