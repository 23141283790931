import React, { useEffect,useState, memo, useImperativeHandle, forwardRef } from "react";
import { Text, View, TouchableOpacity } from "react-native";
//import DateTimePickerModal from 'react-native-modal-datetime-picker';
//import DateTimePicker from "@react-native-community/datetimepicker";

import moment from "moment";
import Styles from "./indexCss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "modal-enhanced-react-native-web";

const DateInput = forwardRef((props, refs) => {
  const {
    onDateSet = () => {},
    title,
    onSelectedDate = "",
    maximumDate = null,
    minimumDate = null,
    firstCallCb = () => true,
    dateTextStyle = {},
    dateTouchContainer = {},
  } = props;
  const [datePickerVisibility, setDatePickerVisibility] = useState(false);
  const [date, setDate] = useState(new Date());
  const [value, setValue] = useState("");
  const handleConfirm = (selectedDate) => {
    if (firstCallCb()) {
      const currentDate = selectedDate || date;
      setDate(currentDate);
      onDateSet(moment(currentDate).format("YYYY-MM-DD"));
      var val = moment(currentDate).format("DD MMMM YYYY");
      setValue(val);
      hideDatePicker();
    } else {
      hideDatePicker();
    }
  };
  useImperativeHandle(refs, () => ({
    reset: () => {
      setValue("");
    },
  }));
  const hideDatePicker = () => setDatePickerVisibility(false);
  useEffect(() => {
    if (onSelectedDate || onSelectedDate.length > 0) {
      console.log("ONSELECT_RECIEVE - ", onSelectedDate);
      try {
        var val = moment(onSelectedDate).format("DD MMMM YYYY");
        setValue(val);
      } catch (error) {
        console.log("INVALID_DATE - ", error);
        setValue("");
      }
    } else {
      setValue("");
    }
  }, [onSelectedDate]);
  return (
    <View>
      <TouchableOpacity
        style={Styles.dateTouchContainer}
        onPress={() => setDatePickerVisibility(true)}
      >
        <Text style={Styles.textStyle}>{value ? value : title}</Text>
      </TouchableOpacity>
      <Modal
        isVisible={datePickerVisibility}
        style={{
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DatePicker
          minDate={minimumDate ? new Date(minimumDate) : new Date()}
          maxDate={maximumDate ? new Date(maximumDate) :
                        new Date(new Date().getFullYear() + 4, 11, 31)}
          open={datePickerVisibility}
          selected={date}
          className={"react-datepicker-popper"}
          onSelect={handleConfirm} //when day is clicked
          onChange={handleConfirm} //only when value has changed
        />
      </Modal>
    </View>
  );
});
export default memo(DateInput);
