import React, {
  forwardRef,
  Fragment,
  memo,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { View, Text, TextInput, TouchableOpacity } from "react-native";
import DialogInput from "react-native-dialog-input";
import Modal from "modal-enhanced-react-native-web";

const RedeemDialogInput = forwardRef((props, ref) => {
  const {
    title = "Redeem Event",
    message = "Enter Your Invite code for unlock the Event",
    hintInput = "Enter Invite Code",
    submitInput = () => {},
    closeDialog = () => {},
  } = props;
  const [dialogVisible, setIsDialogVisible] = useState(false);
  const [textData, setTextData] = useState("");
  const switchDialog = useCallback(() => setIsDialogVisible((s) => !s), []);
  useImperativeHandle(ref, () => ({
    showDialog: () => {
      switchDialog();
    },
  }));
  const switchMode = (mode) => {
    switchDialog();
    if (mode) {
      submitInput(textData);
    } else {
      closeDialog();
    }
  };
  return (
    <Modal isVisible={dialogVisible}>
      <View
        style={{
          backgroundColor: "#FFF",
          width: "30%",
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            fontSize: 24,
            fontWeight: "bold",
            paddingTop: 10,
            textAlign: "center",
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            fontSize: 15,
            fontWeight: "600",
            paddingTop: 5,
            textAlign: "center",
          }}
        >
          {message}
        </Text>
        <TextInput
          style={{
            height: 35,
            width: "85%",
            alignSelf: "center",
            fontSize: 14,
            paddingLeft: 5,
            marginVertical: 10,
          }}
          placeholder={hintInput}
          value={textData}
          onChangeText={(text) => {
            setTextData(text);
          }}
        />
        <View
          style={{
            flexDirection: "row",
            width: "90%",
            alignSelf: 'center',
            justifyContent: "space-evenly",
            marginVertical: 15,
          }}
        >
          <TouchableOpacity onPress={() => switchMode(false)}>
            <View
              style={{
                padding: 10,
                borderWidth: 0.5,
                backgroundColor: "#C9C9C9",
              }}
            >
              <Text style={{fontSize: 14, fontWeight: '600', color: '#000'}}>Cancel</Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => switchMode(true)}>
            <View style={{
                padding: 10,
                borderWidth: 0,
                backgroundColor: '#0B214D'
            }}>
              <Text style={{fontSize: 14, fontWeight: '600', color: '#FFF'}}>Submit</Text>
            </View>
          </TouchableOpacity>
        </View>
        {/* <DialogInput
          isDialogVisible={dialogVisible}
          title={title}
          message={message}
          hintInput={hintInput}
          submitInput={(i) => switchMode(true, i)}
          closeDialog={() => switchMode(false)}
        ></DialogInput> */}
      </View>
    </Modal>
  );
});

export default RedeemDialogInput;
