
//External Imports
import { StyleSheet } from 'react-native';

//Internal Imports
// import { wp, hp, getHp, getWp, FONTSIZE } from '';

export default StyleSheet.create({
    containerStyle: { 
        height: 50,
        borderRadius: 15,
        backgroundColor: '#0B214D',
        alignItems: 'center',
        flexDirection: 'row'
    },
    redBig: {
        backgroundColor: '#EC2939',
        borderRadius: 15,
        height: 50,
        paddingVertical: 5
    },
    titleStyle: {
        fontSize: 18,
    },
    small: {
        backgroundColor: "#EC2939",
        width: 104,
        height: 40,
        borderRadius: 100
    },
    iconStyle: {
        height: 25,
        width: 25,
        marginLeft: 40
    },
    textTitleStyle: {
        marginLeft: 30,
        color: 'white',
        fontSize: 16,
        fontWeight: '700'
    },
    paymentBTN: {
        backgroundColor: '#0B214D', 
        height: 50,
        paddingVertical: 5,
        borderRadius: 15
    }
});