import { StyleSheet } from "react-native";

export default StyleSheet.create({
  singleHeadingContainer: {
    marginTop: 20,
    marginBottom: 40,
  },
  saveButtonStyle:{
    alignSelf: "center",
    marginTop: 20,
    marginBottom: 40,
  },
  noBracketFoundView: {
    marginVertical: 50,
    alignSelf: 'center'
  },
  noBracketFoundTextView: {
    fontSize: 20,
    fontWeight: '600'
  }
});
