import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { TextField } from "../../../../BasicComponents/components";
import { Back, Email, Forward, Phone } from "../../../../icon";
import { COLOR } from "../../../../utils";
import { transformFirebaseValues } from "../../../../utils";
import { charitiesCollection } from "../../../../firebase";
import AntDesign from "react-native-vector-icons/AntDesign";

AntDesign.loadFont();

const ProfileInfo = ({
  name,
  web,
  email,
  phone,
  edit,
  onNext,
  onPrevious,
  Username,
  onChangeNameText,
  Userweb,
  onChangeWebText,
  onChangeEmailText,
  Useremail,
  Userphone,
  onChangePhoneText,
}) => {
  const [charityArray, setCharityArray] = useState(null);
  const loadProfileData = async () => {
    const charitiesData = await charitiesCollection.get();
    let charityData = transformFirebaseValues(charitiesData, "charityID");
    setCharityArray(charityData);
  };

  useEffect(() => {
    loadProfileData();
    // window.confirm("asda")
  }, []);

  return (
    <View>
      <View style={styles.infoContainer}>
        {!edit && (
          <AntDesign
            name="left"
            size={25}
            onPress={() => onPrevious()}
            style={styles.LeftArrowStyle}
          />
        )}
        {edit ? (
          <View style={styles.ecenter}>
            <TextField
              // placeholder="Enter Charity Name"
              placeholder={Username == null ? name : Username}
              onChangeText={onChangeNameText}
              value={Username}
            />
            <TextField
              placeholder={Userweb == null ? web : Userweb}
              onChangeText={onChangeWebText}
              value={Userweb}
            />
          </View>
        ) : (
          <View style={styles.center}>
            <Text style={styles.name}>{name}</Text>

            <Text style={styles.web}>{web}</Text>
          </View>
        )}
        {!edit && (
          <AntDesign
            name="right"
            size={25}
            onPress={() => onNext()}
            style={styles.LeftArrowStyle}
          />
        )}
      </View>
      {edit ? (
        <View style={styles.emetaContainer}>
          {/* <Email style={{marginLeft: 13}} /> */}

          <TextField
            placeholder={Useremail == null ? email : Useremail}
            onChangeText={onChangeEmailText}
            value={Useremail}
            //  placeholder={value==null ?text:value}
            //  onChangeText={onChangeText}
            //  value={value}
          />
          <TextField
            placeholder={Userphone == null ? phone : Userphone}
            onChangeText={onChangePhoneText}
            value={Userphone}
          />
          {/* <Phone style={{marginLeft: 13}} />
  <Text style={styles.text}>{phone}</Text> */}
        </View>
      ) : (
        <View style={styles.metaContainer}>
          <Email style={{ marginLeft: 13 }} />

          <Text style={styles.text}>{email}</Text>
          <Phone style={{ marginLeft: 13 }} />
          <Text style={styles.text}>{phone}</Text>
        </View>
      )}
    </View>
  );
};
export default ProfileInfo;

const styles = StyleSheet.create({
  LeftArrowStyle: {
    display: "inline-block",
    position: "relative",
    zIndex: 1,
    padding: "2em",
    margin: "-2em",
  },
  infoContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 58,
    paddingVertical: 0,
    alignItems: "center",
  },
  center: { justifyContent: "center", alignItems: "center" },
  ecenter: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 100,
  },
  name: {
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 22,
    color: COLOR.BLACK,
  },
  web: { fontSize: 16, lineHeight: 19, color: COLOR.BLACK },
  metaContainer: {
    marginTop: 10,
    padding: 58,
    paddingVertical: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  emetaContainer: {
    marginTop: 10,
    padding: 10,
    paddingVertical: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // width:"100%"
  },
  text: { marginLeft: 13, fontSize: 16, lineHeight: 19 },
});
