import React, { memo } from "react";
import { View, TextInput, Text } from "react-native";

import Styles from "./indexCss";

const TextInputComponent = (props) => {
  const {
    containerStyle = {},
    placeholder = "Placeholder",
    onChangeText,
    value = "",
    inputStyle = {},
    multiline = false,
    isNumeric = false,
    editable = true,
    disabledView = false,
    // autoCompleteType = false,
    // textContentType = false,
  } = props;
  // return null;
  return (
    <View style={[Styles.inputViewContainerStyle, containerStyle]}>
      {disabledView ? (
        <Text style={Styles.disabledViewTextStyle}>{value}</Text>
      ) : (
        <TextInput
          editable={editable}
          keyboardType={isNumeric ? "numeric" : "default"}
          blurOnSubmit={true}
          placeholderTextColor={"grey"}
          placeholder={placeholder}
          value={value}
          onChangeText={onChangeText}
          style={[Styles.textInputStyle, inputStyle]}
          returnKeyType="default"
          multiline={multiline}
          textAlignVertical={"top"}
        />
      )}
    </View>
  );
};

export default TextInputComponent;
