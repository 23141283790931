import React, { useEffect, useState, useRef, useCallback } from "react";
import { View, Text, ScrollView, Alert } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";
import {
    Root,
    Header,
    TextInput,
    ImageVideoPlaceholder,
    DateInput,
    CustomModalDropDown,
    SingleHeading,
    TextAreaInput,
    TouchableButton,
    CreateEventProgress,
} from "../../../Component";
import { useLoader, useFirebaseUpload } from "../../../hooks";
import Styles from "./indexCss";
import {
    bracketGroupDetailsCollection,
    contestsCollection,
    bracketInfoDetailsCollection
} from "../../../firebase";
import { useDispatch, useSelector, connect } from "react-redux";
import {
    toggleAlertBox,
    updateEventModel,
    initEventModel,
    updateViewEventModel,
} from "../../../store/actions";
import {
    transformFirebaseValues
} from '../../../utils';
import AntDesign from "react-native-vector-icons/AntDesign";
import Feather from "react-native-vector-icons/Feather";
// import { Picker } from "@react-native-picker/picker";
import BracketModel from './model';
Feather.loadFont();
AntDesign.loadFont();
const CreateBracketGroup = (props) => {
    const dispatch = useDispatch();
    const [model, setModel] = useState(() => new BracketModel());
    const { auth, firebaseAllCollectionData } = useSelector(s => s);
    var [setLoader, LoaderComponent] = useLoader();
    const formRef = useRef({
        event: useRef(),
        contest: useRef()
    })
    useEffect(() => {
        setLoader(true);
        document.title = 'Create Bracket Group';
        if (auth.userId == '') {
            props.navigation.navigate('AuthScreen', {
                screen: 'LoginScreen'
            })
        }
        if (!firebaseAllCollectionData.isReady) {
            return;
        }
        setModel(model.init([...firebaseAllCollectionData.firebaseCollectionData.eventsData]));
        setLoader(false);
    }, [firebaseAllCollectionData.isReady]);

    const handleCreateBracketGroup = async () => {
        try {
            let validateFields = model.validateData();
            if (validateFields.isValidate == false) {
                return alert(validateFields.message);
            }
            setLoader(true);
            let bracketData = model.getFirebaseValue();
            let isBracketExistQuery = await bracketGroupDetailsCollection.where('groupLink', '==', bracketData.groupLink).get();
            if (isBracketExistQuery.docs.length > 0) {
                setLoader(false);
                return setTimeout(() => {
                    return dispatch(
                        toggleAlertBox({
                            visible: true,
                            text: 'Group Link already exist! Try New One.',
                            onPress: () => {
                                dispatch(toggleAlertBox("CLOSE"));
                            },
                        })
                    );
                }, 500); 

            }
            console.log('BRACKET_SAVE_DATA - ', bracketData);
            let isBracketExist = await bracketInfoDetailsCollection
                .where('eventID', '==', bracketData.eventID)
                .where('contestID', '==', bracketData.contestID)
                .get();
            if (isBracketExist.docs.length == 0) {
                setLoader(false);
                return setTimeout(() => {
                    return dispatch(
                        toggleAlertBox({
                            visible: true,
                            text: 'No Bracket exist for this Event and Contest',
                            onPress: () => {
                                dispatch(toggleAlertBox("CLOSE"));
                            },
                        })
                    );
                }, 500); 
            }
            let bracketInfoDetailsData = transformFirebaseValues(isBracketExist, 'eventID')[0];
            const saveBracketData = await bracketGroupDetailsCollection.add({...bracketData,
                bracketInfoDetailsID: bracketInfoDetailsData.id
            });
            console.log('BRACKET_DATA_RES - ', saveBracketData.id);
            setTimeout(() => {
                setLoader(false);
                setModel(model.clear(formRef));
                setTimeout(() => {
                    return dispatch(
                        toggleAlertBox({
                            visible: true,
                            text: 'Group Link Successfully Created!',
                            onPress: () => {
                                dispatch(toggleAlertBox("CLOSE"));
                            },
                        })
                    );
                }, 500);

            }, 1000);
        } catch (error) {
            console.log('ERROR_SAVING_BRACKET_GROUP - ', error);
            setLoader(false);
            setTimeout(() => {
                return dispatch(
                    toggleAlertBox({
                        visible: true,
                        text: 'Something went wrong!',
                        onPress: () => {
                            dispatch(toggleAlertBox("CLOSE"));
                        },
                    })
                );
            }, 1000)
        }
    }
    const fetchContest = async (eventID) => {
        setLoader(true);
        const contestsRes = await contestsCollection.where('eventID', '==', eventID).get();
        const contests = transformFirebaseValues(contestsRes, 'contestName');
        setModel(model.update('contests', contests));
        setLoader(false);
    }
    return (
        <Root>
            <LoaderComponent />
            <ScrollView
                style={Styles.container}
                showsVerticalScrollIndicator={true}
            >
                <Header
                    hideMenu
                    heading={"Create Bracket Group"}
                    menuOnPress={() => {
                        props.navigation.openDrawer();
                    }}
                    leftOnPress={() => {
                        props.navigation.goBack();
                    }}
                />
                <View>


                </View>
                <SingleHeading
                    containerStyle={Styles.singleHeadingContainer}
                    placeholder={"Enter Details about your Bracket Group"}
                />
                <View style={{ alignSelf: 'center', width: '70%', marginTop: 20, }}>
                    <CustomModalDropDown
                        ref={formRef.current.event}
                        onSelect={(event) => {
                            if (Object.keys(event).length == 0) {
                                return;
                            }
                            fetchContest(event?.eventID);
                            setModel(model.update('selectedEventId', event?.eventID || null));
                        }}
                        width={"100%"}
                        height={37}
                        items={model.events || []}
                        placeholder="Select Event"
                    />
                </View>

                <View style={{ alignSelf: 'center', width: '70%', marginTop: 20, }}>
                    <CustomModalDropDown
                        ref={formRef.current.contest}
                        onSelect={(contest) => {
                            if (Object.keys(contest).length == 0) {
                                return;
                            }
                            setModel(model.update('selectedContestId', contest?.contestID || null));
                        }}
                        width={"100%"}
                        height={37}
                        items={model.contests || []}
                        placeholder="Select Contest"
                    />
                </View>

                <TextInput
                    containerStyle={Styles.inputContainerStyle}
                    inputStyle={Styles.inputStyle}
                    placeholder={"Enter Group Name"}
                    value={model.formData.groupName}
                    onChangeText={(groupName) =>
                        setModel(model.updateForm("groupName", groupName))
                    }
                />
                <View style={{
                    ...Styles.inputContainerStyle,
                    height: 20,
                    marginTop: 15,
                    width: '70%',
                    alignSelf: 'center'
                }}>
                    <Text>Bracket Link : {model.getBracketURL()}</Text>
                </View>
                <TextInput
                    containerStyle={Styles.inputContainerStyle}
                    inputStyle={Styles.inputStyle}
                    placeholder={"Enter Host Name"}
                    value={model.formData.hostName}
                    onChangeText={(hostName) =>
                        setModel(model.updateForm("hostName", hostName))
                    }
                />

                <SingleHeading
                    containerStyle={Styles.singleHeadingContainer}
                    placeholder={"Points Per Round"}
                />

                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '70%',
                    alignSelf: 'center',
                    alignItems: 'center'
                }}>
                    <Text style={{ fontSize: 18, fontWeight: '700' }}>
                        Championship :
                    </Text>
                    <TextInput
                        containerStyle={Styles.inputContainerStyle}
                        inputStyle={Styles.inputStyle}
                        placeholder={"Enter Num of Championship"}
                        value={model.formData.pointsPerRound.championship}
                        onChangeText={(championship) =>
                            setModel(model.updatePointsPerRound("championship", championship))
                        }
                    />
                </View>

                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '70%',
                    alignSelf: 'center',
                    alignItems: 'center'
                }}>
                    <Text style={{ fontSize: 18, fontWeight: '700' }}>
                        Final Four :
                    </Text>
                    <TextInput
                        containerStyle={Styles.inputContainerStyle}
                        inputStyle={Styles.inputStyle}
                        placeholder={"Enter Final Four"}
                        value={model.formData.pointsPerRound.finalFour}
                        onChangeText={(finalFour) =>
                            setModel(model.updatePointsPerRound("finalFour", finalFour))
                        }
                    />
                </View>
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '70%',
                    alignSelf: 'center',
                    alignItems: 'center'
                }}>
                    <Text style={{ fontSize: 18, fontWeight: '700' }}>
                        Round 1 :
                    </Text>
                    <TextInput
                        containerStyle={Styles.inputContainerStyle}
                        inputStyle={Styles.inputStyle}
                        placeholder={"Enter Round 1"}
                        value={model.formData.pointsPerRound.round1}
                        onChangeText={(round1) =>
                            setModel(model.updatePointsPerRound("round1", round1))
                        }
                    />
                </View>
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '70%',
                    alignSelf: 'center',
                    alignItems: 'center'
                }}>
                    <Text style={{ fontSize: 18, fontWeight: '700' }}>
                        Round 2 :
                    </Text>
                    <TextInput
                        containerStyle={Styles.inputContainerStyle}
                        inputStyle={Styles.inputStyle}
                        placeholder={"Enter Round 2"}
                        value={model.formData.pointsPerRound.round2}
                        onChangeText={(round2) =>
                            setModel(model.updatePointsPerRound("round2", round2))
                        }
                    />
                </View>

                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '70%',
                    alignSelf: 'center',
                    alignItems: 'center'
                }}>
                    <Text style={{ fontSize: 18, fontWeight: '700' }}>
                        Round 3 :
                    </Text>
                    <TextInput
                        containerStyle={Styles.inputContainerStyle}
                        inputStyle={Styles.inputStyle}
                        placeholder={"Enter Round 3"}
                        value={model.formData.pointsPerRound.round3}
                        onChangeText={(round3) =>
                            setModel(model.updatePointsPerRound("round3", round3))
                        }
                    />
                </View>

                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '70%',
                    alignSelf: 'center',
                    alignItems: 'center'
                }}>
                    <Text style={{ fontSize: 18, fontWeight: '700' }}>
                        Round 4 :
                    </Text>
                    <TextInput
                        containerStyle={Styles.inputContainerStyle}
                        inputStyle={Styles.inputStyle}
                        placeholder={"Enter Round 4"}
                        value={model.formData.pointsPerRound.round4}
                        onChangeText={(round4) =>
                            setModel(model.updatePointsPerRound("round4", round4))
                        }
                    />
                </View>
                <TextInput
                    containerStyle={{ ...Styles.inputContainerStyle, height: 110, width: '70%', alignSelf: 'center', marginTop: 20 }}
                    inputStyle={Styles.inputStyle}
                    placeholder={"Enter Text"}
                    multiline={true}
                    value={model.formData.text}
                    onChangeText={(text) =>
                        setModel(model.updateForm("text", text))
                    }
                />

                <View style={{
                    flexDirection: 'row', justifyContent: 'space-between',
                    width: '60%', alignSelf: 'center'
                }}>
                    <TouchableButton
                        type={"small"}
                        backgroundColor={"#EC2939"}
                        title={"Create Bracket Group"}
                        onPress={() => handleCreateBracketGroup()}
                        containerStyle={Styles.saveUpdateContainerStyle}
                        buttonStyleProp={{ width: 240 }}
                    />

                    <TouchableButton
                        type={"small"}
                        backgroundColor={"#EC2939"}
                        title={"Clear"}
                        containerStyle={Styles.saveUpdateContainerStyle}
                        buttonStyleProp={{ width: 150 }}
                        onPress={() => setModel(model.clear(formRef))}
                    />
                </View>
                <View style={{ height: 30 }} />
            </ScrollView>
        </Root>
    );
}

export default connect()(CreateBracketGroup);