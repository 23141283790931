//External Imports
import { StyleSheet } from 'react-native';

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from '../../utils';

export default StyleSheet.create({
    rootContainer: {
        flexDirection: "row", 
        width: "85%",
        alignSelf: "center"
    },
    rightContainer: {
        marginLeft: getWp(20)
    },
    eventNameStyle: {
        fontWeight: 'bold',
        fontSize: 18,
        color: 'black'
    },
    dateStyle: {
        fontSize: 16,
        color: 'black'
    },
    charityTextStyle: {
        fontSize: 18,
        color: 'black',
        fontWeight: 'bold',
        marginTop: getHp(10)
    }
});