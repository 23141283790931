
//External Imports
import { StyleSheet } from 'react-native';

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from '../../utils';

export default StyleSheet.create({
    textInputStyle: {
        minHeight: getHp(55),
        width: '90%',
        borderRadius: 10,
        borderWidth: 0.5,
        borderColor: "#000",
        paddingHorizontal: 12,
        alignItems: "center",
    }
});