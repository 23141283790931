import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  Alert,
} from "react-native";
import Spinner from "react-native-loading-spinner-overlay";
import {
  toggleAlertBox,
  initNavigateToSignupScreen,
} from "../../../store/actions";
//Import Custom Component
import HeaderSection from "../EventInfoDetail/HeaderSection";
import EventDetail from "../EventInfoDetail/EventDetail";
import EventDesc from "../EventInfoDetail/EventDesc";
import ContestDetails from "../EventInfoDetail/ContestDetails";
import ParticipationSection from "../EventInfoDetail/ParticipationSection";
import Gallery from "../EventInfoDetail/Gallery";
import { StaticEventImageHeader, Root } from "../../../Component";
import {
  getFromToDate,
  getHp,
  transformFirebaseValues,
  sortArrayAlphabatically,
} from "../../../utils";
import {
  contestsCollection,
  eventsCollection,
  eventProfileQuestionsCollection,
  eventCategoriesCollection,
  eventSubCategoriesCollection,
  eventGenreTypesCollection,
  charitiesCollection,
} from "../../../firebase";
import { useLoader } from "../../../hooks";
import { useSelector, useDispatch } from "react-redux";
import EventInfoModel from "../EventInfoDetail/eventInfo.model";
import EditEventScreen from "../EventInfoDetail/editEvent";

const EventInfo = (props) => {
  const dispatch = useDispatch();
  const [setLoader, LoaderComponent] = useLoader();
  const [isDataAvailable, setDataAvailable] = useState(false);
  const [isDataLoading, setDataLoading] = useState(true);
  const { firebaseAllCollectionData, auth } = useSelector((s) => s);
  const [eventIModel, setEventIModel] = useState(() => EventInfoModel);
  const [arrow, setArrow] = useState(false);
  const initForSignupLogin = () => {
    dispatch(
      initNavigateToSignupScreen(
        props.route.params.eventId || props.route.params.eventID
      )
    );
  };
  const getEventData = async () => {
    try {
      const eventID = props.route.params.eventId || props.route.params.eventID;
      console.log("EVENT_ID - ", eventID);
      if (eventID) {
        const firebaseCollectionData = {
          ...firebaseAllCollectionData.firebaseCollectionData,
        };
        let currentEventData = {};
        let data = true;
        // firebaseAllCollectionData.firebaseCollectionData.eventsData.map(
        //   (event, index) => {
        //     if (event.eventID == eventID) {
        //       setDataAvailable(true);
        //       data = false;
        //       currentEventData = { ...event };
        //     }
        //   }
        // );
        const eventCategoriesQuery = await eventCategoriesCollection
          .where("isActive", "==", true)
          .get();
        const eventSubCategoriesQuery = await eventSubCategoriesCollection
          .where("isActive", "==", true)
          .get();
        const eventGenreQuery = await eventGenreTypesCollection
          .where("isActive", "==", true)
          .get();
        const charitiesQuery = await charitiesCollection
          .where("isActive", "==", true)
          .get();
        const eventCollectionResponse = await eventsCollection
          .where("eventID", "==", parseInt(eventID))
          .get();

        let eventCategoriesData = transformFirebaseValues(
          eventCategoriesQuery,
          "name",
          [{ isSelected: false }]
        );
        let eventSubCategoriesData = transformFirebaseValues(
          eventSubCategoriesQuery,
          "name",
          [{ isSelected: false }]
        );
        let eventGenreData = transformFirebaseValues(
          eventGenreQuery,
          "eventGenreType",
          [{ isSelected: false }]
        );
        let charityDataa = transformFirebaseValues(
          charitiesQuery,
          "charityName",
          [{ isSelected: false }]
        );
        let charityData = sortArrayAlphabatically(charityDataa, "sortOrder");
        let Allfirebase = {
          eventCategoriesData,
          eventSubCategoriesData,
          eventGenreData,
          charityData,
        };

        const eventGetData = transformFirebaseValues(
          eventCollectionResponse,
          "eventID"
        );
        eventGetData.map((event, index) => {
          if (event.eventID == eventID) {
            setDataAvailable(true);
            data = false;
            currentEventData = { ...event };
          }
        });
        const contestCollectionResponse = await contestsCollection
          .where("eventID", "==", parseInt(eventID))
          .get();
        const playerProfileResponse = await eventProfileQuestionsCollection
          .where("eventID", "==", parseInt(eventID))
          .get();
        if (data) {
          setLoader(false);
          setDataLoading(false);
          showPopup({ text: "Invalid Event Id" });
          return;
        }
        setEventIModel(
          eventIModel.init(
            currentEventData,
            Allfirebase,
            contestCollectionResponse,
            playerProfileResponse
          )
        );
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setTimeout(() => {
        alert("Message" + "Something went wrong!" + error);
      }, 200);
    }
  };

  const showPopup = ({ text = "" }) => {
    return dispatch(
      toggleAlertBox({
        visible: true,
        text: text,
        onPress: () => {
          dispatch(toggleAlertBox("CLOSE"));
        },
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    setEventIModel(eventIModel.onRefresh());
    getEventData();
  }, [props?.route?.params]);

  const onAddContest = () => {
    props.navigation.navigate("AddNewContestScreen", {
      eventIModel: eventIModel,
      setEventIModel: setEventIModel,
    });
  };

  return (
    <Root>
      <ScrollView showsVerticalScrollIndicator style={styles.scrollView}>
        <LoaderComponent />

        {!eventIModel.loading && (
          <Fragment>
            {isDataAvailable ? (
              eventIModel.mode == 0 ? (
                <Fragment>
                  <HeaderSection
                    shouldEdit={
                      auth.userCol.userType == "admin"
                        ? true
                        : eventIModel.currentEventData.organizerID ==
                          auth.userId
                        ? true
                        : false
                    }
                    onEditPress={() => {
                      setEventIModel(eventIModel.update("mode", 1));
                    }}
                    data={{ ...eventIModel?.currentEventData }}
                    navigation={props.navigation}
                    isAuthenticated={auth.isAuth}
                  />
                  <StaticEventImageHeader
                    eventImageURI={eventIModel?.currentEventData?.eventLogo}
                    eventName={eventIModel?.currentEventData?.eventName}
                    date={getFromToDate(
                      eventIModel?.currentEventData?.eventDate.length !== 0 &&
                        eventIModel?.currentEventData?.eventDateEnd.length !== 0
                        ? eventIModel?.currentEventData?.eventDate
                        : "",
                      eventIModel?.currentEventData?.eventDateEnd.length !==
                        0 &&
                        eventIModel?.currentEventData?.eventDate.length !== 0
                        ? eventIModel?.currentEventData?.eventDateEnd
                        : ""
                    )}
                    charity={
                      eventIModel?.currentEventData?.charityData?.charityName ||
                      ""
                    }
                    containerStyle={styles.staticEventImageContainerStyle}
                  />
                  <EventDesc data={eventIModel?.currentEventData} />
                  <ContestDetails
                    onAddContest={onAddContest}
                    icon={arrow}
                    onClose={() => setArrow(!arrow)}
                    shouldEdit={
                      auth.userCol.userType == "admin"
                        ? true
                        : eventIModel.currentEventData.organizerID ==
                          auth.userId
                        ? true
                        : false
                    }
                    data={eventIModel.currentEventData}
                    ContestData={eventIModel.currentEventData.contestData}
                    navigation={props.navigation}
                  />
                  <ParticipationSection
                    shouldNotSignUp={false}
                    isAuthenticated={auth.isAuth}
                    data={eventIModel.currentEventData}
                    navigation={props.navigation}
                    initForSignupLogin={initForSignupLogin}
                  />
                  <Gallery data={eventIModel.currentEventData} />
                  <View style={styles.spacingView} />
                </Fragment>
              ) : (
                <EditEventScreen
                  eventIModel={eventIModel}
                  setEventIModel={setEventIModel}
                />
              )
            ): null}
          </Fragment>
        )}
        {!isDataAvailable && !isDataLoading && (
          <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <Text style={styles.invalidIdText}>Invalid Event Id</Text>
          </View>
        )}
      </ScrollView>
    </Root>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    height: "100%",
    width: "70%",
    backgroundColor: "#FFF",
  },
  spacingView: {
    marginVertical: 20,
  },
  staticEventImageContainerStyle: {
    marginVertical: getHp(20),
  },
  invalidIdText : {
    fontSize: 20,
    fontWeight: '700',
    marginTop: 100
  }
});

export default EventInfo;
