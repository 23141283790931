import {
    LOAD_APP_INFO
} from '../actions';

const initialState = {
    appInfoData: {},
    
}

export const AppInfoReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOAD_APP_INFO: 
        return {
            ...state,
            appInfoData: action.payload
        }
        default: return state;
    }
};