//External Imports
import { StyleSheet } from "react-native";

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from "../../../utils";

export default StyleSheet.create({
  childViewStyle: {
    backgroundColor: "#fff",
    flex: 1,
  },
  container: {
    backgroundColor: "#fff",
    height: "100%",
    width: "70%",
  },
  bottomButtonsTray: {
    flexDirection: "row",
    marginTop: 30,
    justifyContent: "space-between",
    alignSelf: "center",
  },
  createContestTypesContainer: {
    width: "90%",
    alignSelf: "center",
    alignItems: "center",
    marginTop: 20,
  },
  orTextStyle: {
    marginVertical: 20,
    alignSelf: "center",
    fontWeight: "bold",
    fontSize: 16,
    color: "black",
  },
  customContestTyle: {
    width: "90%",
  },
  nextContainer: {
    alignSelf: "center",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    marginTop: 200,
  },
  selectedContestContainer: {
    marginVertical: getHp(20),
    // borderWidth:1,
    // borderColor:'red'
  },
  selectedContestRow: {
    marginVertical: getHp(10),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectedContestTextStyle: {
    fontSize: 14,
    color: "black",
    marginLeft: getWp(15),
  },
});
