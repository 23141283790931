//External Imports
import { StyleSheet } from "react-native";

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from "../../utils";

export default StyleSheet.create({
  container: {
    height: getHp(90),
    width: getHp(90),
    backgroundColor: "#C4C4C4",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: getHp(10),
  },
  textStyle: {
    alignSelf: "center",
    fontSize: FONTSIZE.Text16,
    color: "#000000",
    textAlign: "center",
  },
  imageStyle: {
    height: getHp(90),
    width: getHp(90),
  },

  renderModalImage: {
    height: hp(60),
    width: wp(85),
    borderRadius: 0,
  },
  OkayButtonStyle: {
    backgroundColor: "#0B214D",
    borderRadius: 20,
  },
  OkayTextStyle: {
    paddingVertical: 10,
    fontSize: 15,
    paddingHorizontal: 30,
    color: "#fff",
  },
  AlertTextStyle: {
    // paddingVertical:15,
    fontSize: 15,
    paddingHorizontal: 40,
    color: "black",
    // alignItems:'center',
    // justifyContent:'center'
    marginBottom: 60,
  },
  modalContainer: {
    height: 150,
    backgroundColor: "#fff",
    borderRadius: 20,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  closeContainer: {
    position: "absolute",
    right: getWp(5),
    top: getHp(10),
    zIndex: 10,
    borderWidth: 1,
    borderColor: "red",
    borderRadius: 20,
    padding: getHp(4),
    backgroundColor: "red",
  },
  backgroundVideo: {
    height: hp(70),
    width: wp(85),
    backgroundColor: "transparent",
  },
  deleteIconView: {
    position: "absolute",
    left: getWp(10),
    top: getHp(10),
    zIndex: 10,
    color: "black",
    borderWidth: 1,
    borderColor: "red",
    borderRadius: 20,
    padding: getHp(4),
    backgroundColor: "red",
  },
});
