import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Root } from "../../Component";

import {
  FlatList,
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
  ScrollView,
  Image,
} from "react-native";

import { firebase, firebaseAuth } from "../../firebase";
import "firebase/firestore";

import GameChallengesListView from "./GameChallengesListView";
import VideoBroadcaster from "./VideoBroadcaster";
import ChatView from "./chatView";
import { calculateTotalScore, ScoringType } from "./playUtils";

const GameScreen = (props) => {
  const { navigation, route } = props;

  const unsubscribeRef = useRef(null);
  const unsubscribeMessagesRef = useRef(null);
  const [pickGuessingOn, setPickGuessingOn] = useState(false);
  const [tab, setTab] = useState(0);
  const [me, setMe] = useState(null);
  const [overlayedLoading, setOverlayedLoading] = useState(true);
  const [gameScheduleData, setGameScheduleData] = useState({});

  const [gameEachRoundScores, setGameEachRoundScores] = useState({});
  const [currentGamePlayData, setCurrentGamePlayData] = useState({});
  const [gamePlayId, setGamePlayId] = useState("");
  const [currentRound, setCurrentRound] = useState(0);
  const [totalRounds, setTotalRounds] = useState(1);

  const [challengeUsers, setChallengeUsers] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [challenges, setChallenges] = useState([]);

  const [messageIncluded, setMessageIncluded] = useState(false);

  const windowHeight = Dimensions.get("window").height;

  const roundsListView = () => {
    const roundsLoop = [];
    let totalGameRounds = gameScheduleData.gameTotalRounds;
    if (currentGamePlayData.overtimeRounds !== undefined) {
      totalGameRounds += currentGamePlayData.overtimeRounds;
    }

    for (let i = 0; i < totalGameRounds; i++) {
      roundsLoop.push(
        <View style={{ height: 44, flexDirection: "row" }}>
          <View
            style={{
              flex: 1,
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: i >= gameScheduleData.gameTotalRounds ? "red" : "black",
                fontWeight: "bold",
              }}
            >
              {roundScore(gameScheduleData.player1ID, i)}
            </Text>
            <View
              style={{
                backgroundColor: "rgba(0,0,0,0.1)",
                height: 1,
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
              }}
            />
          </View>
          <View
            style={{
              flex: 1,
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: i >= gameScheduleData.gameTotalRounds ? "red" : "black",
                fontWeight: "bold",
              }}
            >
              {roundScore(gameScheduleData.player2ID, i)}
            </Text>
            <View
              style={{
                backgroundColor: "rgba(0,0,0,0.1)",
                height: 1,
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
              }}
            />
          </View>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Text
              style={{
                fontSize: 18,
                fontWeight: "bold",
                marginLeft: 20,
                color: i >= gameScheduleData.gameTotalRounds ? "red" : "black",
              }}
            >
              Round {i + 1}
            </Text>
          </View>
        </View>
      );
    }
    return roundsLoop;
  };

  const roundScore = (playerId, roundNumber) => {
    if (roundNumber > currentRound) {
      return "--";
    }

    if (gameEachRoundScores.hasOwnProperty(playerId)) {
      if (gameEachRoundScores[playerId].hasOwnProperty(roundNumber)) {
        return gameEachRoundScores[playerId][roundNumber];
      }
    }

    return 0;
  };

  const _loadGameInformation = () => {
    if (route.params.gameScheduleId !== "") {
      firebase
        .firestore()
        .collection("gameSchedule")
        .doc(route.params.gameScheduleId)
        .get()
        .then((documentSnapshot) => {
          setOverlayedLoading(false);
          if (documentSnapshot.exists) {
            setGameScheduleData(documentSnapshot.data());
            setTotalRounds(documentSnapshot.data().gameTotalRounds);
            snapshotScore(documentSnapshot.data().gameID);
          }
        })
        .catch((error) => {
          console.log("Error while getting game information", error);
        });
    }
  };

  const snapshotScore = (gameID) => {
    if (gameID !== "") {
      if (unsubscribeRef?.current) {
        console.log(
          "unsubscribing snapshot in GameView while snapshot scoring"
        );
        unsubscribeRef?.current();
      }

      unsubscribeRef.current = firebase
        .firestore()
        .collection("gameScores")
        .where("gameID", "==", gameID)
        .onSnapshot((querySnapshot) => {
          var found = false;
          querySnapshot.forEach((doc) => {
            found = true;
            setCurrentGamePlayData(doc.data());
            console.log("Current game play data => ", doc.data());
            let playingData = doc.data();
            if (gamePlayId === "") {
              console.log("gamePlayID", doc.id);
              setGamePlayId(doc.id);
            }
            if (playingData.hasOwnProperty("round")) {
              setCurrentRound(playingData["round"]);
            } else {
              setCurrentRound(0);
            }

            if (playingData.hasOwnProperty("scores")) {
              setGameEachRoundScores(playingData["scores"]);
            } else {
              setGameEachRoundScores({});
            }
          });

          if (found === false) {
            firebase
              .firestore()
              .collection("gameScores")
              .doc()
              .set({ gameID: gameID, round: 0 });
          }
        });
    }
  };

  const calculatePlayerScore = (playerId) =>
    calculateTotalScore(
      playerId,
      gameScoringType(),
      currentRound,
      gameEachRoundScores
    );

  const splitIdsArray = (ids) => {
    if (ids.length > 0) {
      let splittedIds = [];
      let seeker = 0;
      while (seeker < ids.length) {
        let elementSplitIds = [];
        for (let idx = 0; idx < 10; idx++) {
          if (seeker + idx < ids.length) {
            elementSplitIds.push(ids[seeker + idx]);
          }
        }

        splittedIds.push(elementSplitIds);

        seeker = seeker + 10;
      }
      return splittedIds;
    } else {
      return [];
    }
  };

  const loadChallengeUsers = async (userIds) => {
    let userSplittedIdsArray = splitIdsArray(userIds);
    let users = [];
    for (let idx = 0; idx < userSplittedIdsArray.length; idx++) {
      const querySnapshot = await firebase
        .firestore()
        .collection("users")
        .where("uid", "in", userSplittedIdsArray[idx])
        .get();

      querySnapshot.forEach((documentSnapshot) => {
        let user = documentSnapshot.data();
        users = [...users, user];
      });
    }

    setChallengeUsers(users);
  };

  const loadAudienceUsers = async (userIds) => {
    let userSplittedIdsArray = splitIdsArray(userIds);
    let users = [];
    for (let idx = 0; idx < userSplittedIdsArray.length; idx++) {
      const querySnapshot = await firebase
        .firestore()
        .collection("users")
        .where("uid", "in", userSplittedIdsArray[idx])
        .get();

      querySnapshot.forEach((documentSnapshot) => {
        let user = documentSnapshot.data();
        users = [...users, user];
      });
    }

    setAudiences(users);
  };

  const loadMyChallengesInGame = () => {
    firebase
      .firestore()
      .collection("challenges")
      .where("gameScheduleId", "==", route.params.gameScheduleId)
      .get()
      .then(async (snapshot) => {
        let challenges = [];
        let userIds = [];
        for (const doc of snapshot.docs) {
          let challenge = doc.data();

          if (
            challenge.status !== "expired" &&
            challenge.status !== "pending"
          ) {
            if (challenge.status !== "accepted") {
              console.log("Challenge not processed, yet");
            }

            if (!userIds.includes(challenge.challengeSenderId)) {
              userIds = [...userIds, challenge.challengeSenderId];
            }

            if (challenge.opponent !== "all") {
              if (!userIds.includes(challenge.opponent)) {
                userIds = [...userIds, challenge.opponent];
              }
            }

            challenges = [...challenges, challenge];
          }
        }
        await loadChallengeUsers(userIds);
        setChallenges(challenges);
      });
  };

  const loadGameAudiences = () => {
    firebase
      .firestore()
      .collection("gameSchedule")
      .doc(route.params.gameScheduleId)
      .collection("audiences")
      .get()
      .then(async (snapshot) => {
        let audienceUserIds = [];
        for (const doc of snapshot.docs) {
          audienceUserIds = [...audienceUserIds, doc.id];
        }
        loadAudienceUsers(audienceUserIds);
      });
  };

  const renderItem = ({ item }) => {
    return (
      <View style={{ height: 50, flexDirection: "row", alignItems: "center" }}>
        <Image
          style={{ width: 50, height: 50, borderRadius: 25 }}
          resizeMode={"cover"}
          source={{ uri: item.userAvatar }}
        />
        <Text style={{ marginLeft: 10 }}>{item.userName}</Text>
      </View>
    );
  };

  const gameScoringType = () => {
    if (gameScheduleData.scoringType === undefined) {
      return ScoringType.Normal;
    } else {
      return gameScheduleData.scoringType;
    }
  };
  const getAllContest = (id) => {
    firebase
      .firestore()
      .collection("contests")
      .where("contestID", "==", id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((documentSnapshot) => {
          let contestDetail = documentSnapshot.data();
          let picks = contestDetail?.pickGuessingOn
            ? contestDetail.pickGuessingOn
            : false;
          setPickGuessingOn(picks);
          console.log("Contest detail --> ", contestDetail.pickGuessingOn);
          return;
        });
      })
      .catch((error) => {});
  };
  useLayoutEffect(() => {
    getAllContest(route.params.contestID);
  }, []);
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        setMe(user);
      } else {
        console.log("auth current user is Invalid");
      }
    });
    return () => {
      if (unsubscribeRef?.current) {
        console.log(
          "unsubscribing snapshot in GameView while snapshot scoring"
        );
        unsubscribeRef?.current();
      }
      unsubscribe();

      if (unsubscribeMessagesRef?.current) {
        console.log("unsubscribing snapshot for Messages subcollection chat");
        unsubscribeMessagesRef?.current();
      }
    };
  }, []);

  useEffect(() => {
    if (me) {
      _loadGameInformation();
      loadMyChallengesInGame();
      loadGameAudiences();

      unsubscribeMessagesRef.current = firebase
        .firestore()
        .collection("gameSchedule")
        .doc(route.params.gameScheduleId)
        .collection("messages")
        .limit(1)
        .onSnapshot((querySnapshot) => {
          if (querySnapshot.docs.length > 0) {
            setMessageIncluded(true);
          } else {
            setMessageIncluded(false);
          }
        });
    }
  }, [me]);

  return (
    <Root>
      <ScrollView style={{ height: "100%", width: "99%" }}>
        {overlayedLoading ? (
          <View
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator />
          </View>
        ) : (
          <View style={{ flex: 1, alignItems: "center", height: windowHeight }}>
            <View style={{ height: 60, width: "100%" }}>
              \
              <View
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontWeight: "bold" }}>
                  {route.params.eventName} - {route.params.contestName}
                </Text>
              </View>
              <TouchableOpacity
                style={{
                  justifyContent: "center",
                  position: "absolute",
                  left: 20,
                  height: "100%",
                }}
                onPress={() => {
                  if (unsubscribeRef?.current) {
                    console.log(
                      "unsubscribing snapshot in GameView before going back"
                    );
                    unsubscribeRef?.current();
                  }

                  if (unsubscribeMessagesRef?.current) {
                    console.log(
                      "unsubscribing snapshot in GameView Messages subcollection going back."
                    );
                    unsubscribeMessagesRef?.current();
                  }

                  navigation.goBack();
                }}
              >
                <Text style={{ textAlign: "center" }}>Back</Text>
              </TouchableOpacity>
            </View>
            <Text style={{ fontWeight: "bold" }}>
              Game #{gameScheduleData.gameID}{" "}
              <Text
                style={{
                  fontWeight: "normal",
                  fontStyle: "italic",
                  fontSize: 12,
                }}
              >
                Scoring: {gameScoringType()}
              </Text>
            </Text>
            <View
              style={{
                height: windowHeight - 60 - 40,
                width: "100%",
                flexDirection: "row",
                marginTop: 20,
              }}
            >
              <View
                style={{
                  flex: 3,
                  paddingLeft: 40,
                  paddingRight: 20,
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <View style={{ flexDirection: "row", height: 60 }}>
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: "red",
                      marginTop: 10,
                      marginBottom: 10,
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginLeft: 10,
                      }}
                    ></Text>
                    <Text
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginRight: 10,
                        flex: 1,
                        textAlign: "right",
                      }}
                    >
                      {gameScheduleData.player1Name}
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 2,
                      backgroundColor: "white",
                      borderRadius: 10,
                      borderColor: "rgba(0,0,0,0.2)",
                      borderWidth: 1,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {calculatePlayerScore(gameScheduleData.player1ID)}
                    </Text>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {calculatePlayerScore(gameScheduleData.player2ID)}
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: "#0B214D",
                      marginTop: 10,
                      marginBottom: 10,
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginLeft: 10,
                      }}
                    >
                      {gameScheduleData.player2Name}
                    </Text>
                    <Text
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginRight: 10,
                        flex: 1,
                        textAlign: "right",
                      }}
                    ></Text>
                  </View>
                </View>
                <Text
                  style={{
                    textAlign: "center",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  Game {currentRound + 1} of{" "}
                  {gameScheduleData.gameTotalRounds +
                    (currentGamePlayData.overtimeRounds !== undefined
                      ? currentGamePlayData.overtimeRounds
                      : 0)}
                </Text>
                <View style={{ flex: 1, backgroundColor: "#ddd" }}>
                  <VideoBroadcaster
                    gameScheduleId={route.params.gameScheduleId}
                    player1ID={gameScheduleData.player1ID}
                    player2ID={gameScheduleData.player2ID}
                    player1Name={gameScheduleData.player1Name}
                    player2Name={gameScheduleData.player2Name}
                  />
                </View>
                <Text
                  style={{
                    textAlign: "center",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  Players cannot broadcast video on the web browser. Use your
                  mobile device to broadcast video.
                  <br />
                  You can still look at the videos of other players and can
                  update scores.
                </Text>
              </View>
              <View
                style={{
                  flex: 2,
                  paddingLeft: 20,
                  paddingRight: 20,
                  flexDirection: "column",
                }}
              >
                <View style={{ height: 60, flexDirection: "row" }}>
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: "red",
                      borderTopLeftRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ color: "white", fontWeight: "bold" }}>
                      {gameScheduleData.player1Name}
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: "#0B214D",
                      borderTopRightRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ color: "white", fontWeight: "bold" }}>
                      {gameScheduleData.player2Name}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }} />
                </View>

                {roundsListView()}

                <View
                  style={{
                    height: 60,
                    marginTop: 20,
                    backgroundColor: "white",
                    borderRadius: 10,
                    flexDirection: "row",
                    borderRadius: 10,
                    overflow: "hidden",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: tab === 0 ? "#0B214D" : "transparent",
                    }}
                    onPress={() => setTab(0)}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        color: tab === 0 ? "white" : "black",
                      }}
                    >
                      My Picks
                    </Text>
                  </TouchableOpacity>
                  <View
                    style={{
                      width: 1,
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  />
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: tab === 1 ? "#0B214D" : "transparent",
                    }}
                    onPress={() => setTab(1)}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        color: tab === 1 ? "white" : "black",
                      }}
                    >
                      All picks
                    </Text>
                  </TouchableOpacity>
                  <View
                    style={{
                      width: 1,
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  />
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: tab === 2 ? "#0B214D" : "transparent",
                    }}
                    onPress={() => setTab(2)}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        color: tab === 2 ? "white" : "black",
                      }}
                    >
                      Audiences
                    </Text>
                  </TouchableOpacity>
                  {/* <View
                  style={{
                    width: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <TouchableOpacity
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: tab === 3 ? "#0B214D" : "transparent",
                  }}
                  onPress={() => setTab(3)}
                >
                  <Text
                    style={{ fontSize: 18, color: tab === 3 ? "white" : "black" }}
                  >
                    Chat
                  </Text>
                  {messageIncluded &&
                    <View style={{
                      marginLeft: 4,
                      width: 6,
                      height: 6,
                      borderRadius: 3,
                      backgroundColor: 'red'
                    }} />
                  }
                </TouchableOpacity> */}
                </View>
                <View style={{ flex: 1, paddingTop: 15, paddingBottom: 15 }}>
                  {tab === 0 && pickGuessingOn && (
                    <GameChallengesListView
                      player1ID={gameScheduleData.player1ID}
                      player2ID={gameScheduleData.player2ID}
                      player1Name={gameScheduleData.player1Name}
                      player2Name={gameScheduleData.player2Name}
                      challengeUsers={challengeUsers}
                      challenges={challenges.filter((challenge) => {
                        challenge.challengeSenderId === me.uid ||
                          challenge.opponent === me.uid;
                      })}
                    />
                  )}
                  {tab === 1 && pickGuessingOn && (
                    <GameChallengesListView
                      player1ID={gameScheduleData.player1ID}
                      player2ID={gameScheduleData.player2ID}
                      player1Name={gameScheduleData.player1Name}
                      player2Name={gameScheduleData.player2Name}
                      challengeUsers={challengeUsers}
                      challenges={challenges}
                    />
                  )}
                  {tab === 2 && (
                    <FlatList
                      data={audiences}
                      numColumns={3}
                      columnWrapperStyle={{
                        flex: 1,
                        justifyContent: "space-around",
                      }}
                      renderItem={renderItem}
                    />
                  )}
                  {tab === 3 && (
                    <ChatView
                      gameID={gameScheduleData.gameID}
                      gameScheduleId={route.params.gameScheduleId}
                      myUserId={me.uid}
                    />
                  )}
                </View>
              </View>
            </View>
          </View>
        )}
      </ScrollView>
    </Root>
  );
};

export default GameScreen;
