import React, { useState, useCallback } from "react";
import { View, Text } from "react-native";
//import Modal from 'react-native-modal';
import Modal from "modal-enhanced-react-native-web";

import Styles from "./indexCss";
import { ScrollView } from "react-native-gesture-handler";
const FilterModel = (props) => {
  const { isVisible = false, setIsModalVisible } = props;
  return (
    <Modal
      onBackdropPress={setIsModalVisible}
      onBackButtonPress={setIsModalVisible}
      //   coverScreen={true}
      presentationStyle={"fullScreen"}
      isVisible={isVisible}
      style={Styles.modalStyle}
      animationIn={"slideInRight"}
      animationOut={"slideOutRight"}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={Styles.filterContainerStyle}>
          {props?.children ? props.children : null}
        </View>
      </ScrollView>
    </Modal>
  );
};

export default FilterModel;
