import React, { memo } from "react";
import { View, Text } from "react-native";

import { wp, hp, getHp, getWp, FONTSIZE } from "../../utils";
import Styles from "./indexCss";
import CustomModalDropDown from "../CustomModalDropDown";
import { Picker } from "@react-native-picker/picker";

const DoubleHeadingDropdown = (props) => {
  const {
    leftPlaceHolder = "",
    containerStyle = {},
    items = [],
    onSelect = () => {},
    backgroundColor = null,
    rightPlaceHolder = "",
  } = props;
  console.log('Item from double heading --->', JSON.stringify(items))
  return (
    <View
      style={[
        Styles.container,
        backgroundColor && { backgroundColor },
        containerStyle,
      ]}
    >
      {/* <CustomModalDropDown
        width={222}
        height={35}
        items={items}
        placeholder={leftPlaceHolder}
        onSelect={onSelect}
        containerStyle={[
          Styles.dropdownStyle,
          backgroundColor && { backgroundColor },
        ]}
        dropdownContainer={[
          Styles.dropdownContainerStyle,
          backgroundColor && { backgroundColor },
        ]}
        showBorders={false}
      /> */}
      <Picker
        mode="dialog"
        onValueChange={(itemValue, itemIndex) => {
          let i =  itemIndex == 0 ? itemIndex : itemIndex -1
          onSelect(items[i]);
        }}
        style={{
          height: 35,
          width: 222,
          borderRadius: 18.5,
          backgroundColor: backgroundColor,
          borderWidth: 0,
        }}
      >
        <Picker.Item label={leftPlaceHolder} value={leftPlaceHolder} />
        {items?.length > 0
          ? items.map((items2) => (
              <Picker.Item label={items2.name} value={items2.value} />
            ))
          : []}
      </Picker>
      <Text style={{marginLeft:40}}>{rightPlaceHolder}</Text>
    </View>
  );
};

export default DoubleHeadingDropdown;
