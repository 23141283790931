//External Imports
import { StyleSheet } from "react-native";

//Internal Imports
import { wp, hp, getHp, getWp, FONTSIZE } from "../../../utils";

export default StyleSheet.create({
  childViewStyle: {
    backgroundColor: "#fff",
  },
  container: {
    height: "100%",
    width: "99%",
    backgroundColor: "#fff",
  },
  inputContainerStyle: {
    width: "70%",
    alignSelf: "center",
    marginTop: 20,
    height: 50,
    // borderWidth:1,
    // borderColor: "red"
  },
  inputStyle: {
    height: 45,
    // borderWidth:1,
    // borderColor: "green"
  },
  imagePlateContainer: {
    width: "80%",
    marginTop: 20,
    alignSelf: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  imagePlateRightChildView: {
    //borderWidth:1,
    borderColor: "red",
    width: "90%",
  },
  datePickerContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  singleHeadingContainer: {
    marginTop: 15,
  },
  eventDetailsContainer: {
    width: "90%",
    marginTop: 10,
    alignSelf: "center",
  },
  eventDescriptionTextStyle: {
    marginTop: 20,
    height: 100,
  },
  bottomTrayContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 20,
    alignItems: "center",
    marginLeft: 20,
  },
  saveUpdateContainerStyle: {
    marginTop: 20,
    alignSelf: "center", 
  },
  uploadVideoContainerStyle: {
    marginLeft: 30,
  },
  bottomButtonsTray: {
    flexDirection: "row",
    marginTop: 30,
    flexWrap: 1,
    justifyContent: "space-evenly",
  },
  createContestTypesContainer: {
    width: "90%",
    flexDirection: "row",
    alignSelf: "center",
  },
  addContestTypeStyle: {
    marginTop: 25,
  },
  addContestTypeIconStyle: {
    fontSize: 25,
    color: "black",
  },
  sideButtonsContainer: {
    width: "60%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  enterFeesBtnContainer: {
    marginTop: 10,
  },
  createProfileTitleStyle: {
    fontSize: 14,
  },
  uploadPicStyle: {
    width: 200,
    height: 104,
  },
  galleryLabelStyle: {
    marginTop: 25,
    fontSize: 18,
    fontWeight: "700",
    color: "black",
  },
});
