import React, { Fragment } from "react";
import Round from "./Round";
import "./Round.css";
import noAvtar from "../../assets/avatar1.jpg";
const BracketPlayersInfo = ({ options, userDetails }) => {
  console.log("userDetails", userDetails);
  return (
    <>
      <div>
        <h3>
          {options.options[0].playerData.bracketRoundName +
            " - " +
            options.options[0].playerData.bracketRound}
          <h6>{options.options[0].playerData.bracketStartTime}</h6>
        </h3>
      </div>
      {options.options.map((player) => {
        // userDetails?.map((user) => {
        //   if (user.uid === player.playerData.player1Name)
        //     console.log("player Data", user);
        // });
        var user1 = userDetails.filter(
          (user) => user.uid === player.playerData.player1ID
        );
        var user2 = userDetails.filter(
          (user) => user.uid === player.playerData.player2ID
        );

        let playerData = player.playerData;
        return (
          <Fragment>
            <li class="team-item-2">
              <div class="player">
                {" "}
                <img
                  src={user1[0]?.userAvatar ? user1[0]?.userAvatar : noAvtar}
                />
                <p>{playerData.player1Name}</p>
              </div>

              <div
                class="description"
                style={{
                  border: playerData.gameDescription
                    ? "1px solid black"
                    : "none",
                  height: !playerData.gameDescription && "20px",
                }}
              >
                {playerData.gameDescription}
              </div>

              <div class="player">
                {" "}
                <img
                  src={user1[0]?.userAvatar ? user2[0]?.userAvatar : noAvtar}
                />
                <p>{playerData.player2Name}</p>
              </div>
            </li>
          </Fragment>
        );
      })}
    </>
  );
};
const Rounds = (props) => {
  const { rounds = [], onSelectPlayersRound, isViewMode, userDetails } = props;
  console.log("ALL Rounds -->> ", JSON.stringify(rounds));
  return (
    <div class="tournament-brackets">
      <ul class="bracket bracket-1">
        <BracketPlayersInfo options={rounds[0]} userDetails={userDetails} />
      </ul>

      {rounds.map((round, index) => {
        return (
          <ul class={`bracket bracket-${index + 2}`}>
            <Round
              isViewMode={isViewMode}
              round={round}
              headData={rounds[index + 1]}
              index={index}
              roundCount={index + 1}
              onSelectOptionsFromRound={(optionIndex, value) =>
                onSelectPlayersRound(index, optionIndex, value)
              }
            />
          </ul>
        );
      })}
    </div>
  );
};

export default Rounds;
