import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  Image,
  ScrollView,
  Platform,
} from "react-native";
import { useLinkTo, useNavigation } from "@react-navigation/native";

import Header from "../../../Header/HeaderBlackEdit";
import { COLOR } from "../../../utils";
import { Description, Mission, WatchVideo, ProfileInfo } from "./components";
import { charitiesCollection } from "../../../firebase";
import {
  transformFirebaseValues,
  sortArrayAlphabatically,
} from "../../../utils";
import { useLoader } from "../../../hooks";
import { ImageVideoPlaceholder, Root } from "../../../Component";
import Feather from "react-native-vector-icons/Feather";
import { connect, useSelector, useDispatch } from "react-redux";
import EditCharity from "../EditCharity/editCharity";

Feather.loadFont();
const StudentAthlete = ({ navigation }) => {
  const linkTo = useLinkTo();
  const [charityArray, setCharityArray] = useState(null);
  const [userChoice, setUserChoice] = useState(0);
  const [isEdit, setisEdit] = useState(false);
  const [setLoader, LoaderComponent] = useLoader();
  const { auth } = useSelector((s) => s);
  const loadProfileData = async () => {
    setLoader(true);
    const charitiesData = await charitiesCollection
      .where("charityType", "==", "Student Athlete")
      .where("charityID", "!=", 0)
      .get();
    console.log("Student Athlete --> ", charitiesData)
    let charityData = transformFirebaseValues(charitiesData, "charityID");
    let sortedCharityData = sortArrayAlphabatically(charityData, "charityName");
    setCharityArray(sortedCharityData);
    setLoader(false);
  };

  const handleChoice = (choice) => {
    console.log(choice);
    if (userChoice == 0 && choice == -1) setUserChoice(0);
    else if (userChoice == charityArray.length - 1 && choice == 1)
      setUserChoice(charityArray.length - 1);
    else setUserChoice(userChoice + choice);
  };

  const onEditPress = () => {
    setisEdit(true);
    return;
    navigation.navigate("CharityStack", {
      screen: "EditCharityScreen",
      params: { propsData: charityArray[`${userChoice}`] },
    });
  };
  const onEdit = () => {
    if (auth.userId.length == 0) {
      return null;
    }
    if (charityArray) {
      if (
        auth.userCol.userType == "admin" ||
        charityArray[`${userChoice}`].organizerID == auth.userId
      ) {
        return onEditPress;
      } else {
        return null;
      }
    }
    return null;
  };
  const updateToSpecificEntry = (data, index) => {
    setTimeout(() => {
      setCharityArray((c) => {
        let newC = [...c];
        newC[index] = { ...data };
        return newC;
      });
      loadProfileData();
    }, 300);
  };

  useEffect(() => {
    loadProfileData();
    const unsubscribe = navigation.addListener("focus", () => {
      loadProfileData();
    });
    removeEventListener("focus", unsubscribe);
  }, [navigation]);

  return (
    <Root>
      <LoaderComponent />
      {isEdit ? (
        <EditCharity
          propsData={charityArray[`${userChoice}`]}
          setisEdit={setisEdit}
          userChoice={userChoice}
          updateToSpecificEntry={updateToSpecificEntry}
        />
      ) : (
        <ScrollView
          style={{
            height: "100%",
            width: "70%",
          }}
          showsVerticalScrollIndicator
        >
          <View style={styles.container}>
            <Header
              hideMenu={true}
              onBack={() => {
                if (auth.userId.length == 0) {
                  return linkTo("/login");
                }
                linkTo("/home");
              }}
              onEdit={onEdit()}
            />

            <View>
              <View style={styles.center}>
                <Image
                  style={styles.logo}
                  source={{
                    uri:
                      charityArray === null
                        ? "https://via.placeholder.com/150"
                        : `${charityArray[`${userChoice}`].charityLogo}`,
                  }}
                />
              </View>
              <View style={{ marginTop: 55 }}>
                <ProfileInfo
                  onNext={() => handleChoice(+1)}
                  onPrevious={() => handleChoice(-1)}
                  name={
                    charityArray === null
                      ? ""
                      : charityArray[`${userChoice}`].charityName
                  }
                  web={
                    charityArray === null
                      ? ""
                      : charityArray[`${userChoice}`].charityURL
                  }
                  email={
                    charityArray === null
                      ? ""
                      : charityArray[`${userChoice}`].charityContactEmail
                  }
                  phone={
                    charityArray === null
                      ? ""
                      : charityArray[`${userChoice}`].charityContactNumber
                  }
                />

                <Mission
                  text={
                    charityArray === null
                      ? ""
                      : charityArray[`${userChoice}`].charityMission
                  }
                />
                <Description
                  edit={false}
                  onUpload={() => {
                    selectPhoto();
                  }}
                  component={(viewURI, imageStyle) => {
                    return (
                      <ImageVideoPlaceholder
                        selectedData={(p) => setPicture(p)}
                        viewURI={
                          charityArray === null
                            ? ""
                            : `${charityArray[`${userChoice}`].charityPicture}`
                        }
                        type={"photo"}
                        mode={"view"}
                        containerStyle={imageStyle}
                        imageStyle={[imageStyle, { marginTop: 0 }]}
                        resetViewURI={() => setPicture(undefined)}
                        renderChildren={false}
                      >
                        <Feather name="play" color="#FFF" size={30} />
                      </ImageVideoPlaceholder>
                    );
                  }}
                  url={
                    charityArray === null
                      ? ""
                      : `${charityArray[`${userChoice}`].charityPicture}`
                  }
                  description={
                    charityArray === null
                      ? ""
                      : charityArray[`${userChoice}`].charityDescription
                  }
                />
                <WatchVideo
                  url={
                    charityArray === null
                      ? "charity Video"
                      : `${charityArray[`${userChoice}`].charityVideo}`
                  }
                  component={() => {
                    return (
                      <ImageVideoPlaceholder
                        viewURI={
                          charityArray === null
                            ? null
                            : `${charityArray[`${userChoice}`].charityVideo}`
                        }
                        type={"video"}
                        mode={"view"}
                        // containerStyle={{
                        //   width: 300,
                        //   height: 100,
                        //   marginLeft: 35,
                        //   backgroundColor: COLOR.GREY,
                        //   marginTop: 20,
                        //   justifyContent: "center",
                        //   alignItems: "center",
                        // }}
                        // imageStyle={{
                        //   width: 110,
                        //   height: 70,
                        // }}
                      >
                        <Feather name="play" color="#FFF" size={30} />
                      </ImageVideoPlaceholder>
                    );
                  }}
                />

                {/* <View>
              <ImageVideoPlaceholder
                viewURI={
                  charityArray === null
                    ? null
                    : `${charityArray[`${userChoice}`].charityVideo}`
                }
                type={"video"}
                mode={"view"}
                containerStyle={{
                  width: 300,
                  height: 100,
                  marginLeft: 35,
                  backgroundColor: COLOR.GREY,
                  marginTop: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                imageStyle={{
                  width: 110,
                  height: 70,
                }}
                renderChildren
              >
                <Feather name="play" color="#FFF" size={30} />
              </ImageVideoPlaceholder>
            </View> */}
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </Root>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  logo: {
    borderRadius: 50,
    position: "absolute",
    top: -50,
    backgroundColor: COLOR.GRAY,
    height: 93,
    width: 93,
    resizeMode: "contain",
  },
  center: { alignItems: "center" },
});
export default connect()(StudentAthlete);
